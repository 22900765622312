import {
  Grid,
  TextField,
  Typography,
  makeStyles,
  InputAdornment,
} from "@material-ui/core";
import React from "react";
import Styles from "../../../Styles.module.css";
import SearchIcon from "@material-ui/icons/Search";
import AppItem from "./AppItem";

const useStyles = makeStyles((theme) => ({
  textField: { padding: "15px 14px !important" },
}));

const mortgageBrokerApps = [
  {
    imageUrl: "/app/images/apps/realtor/docu_sign.png",
    title: "Docusign",
    description:
      "DocuSign is the most reliable and globally trusted service for electronic signatures and…",
  },
  {
    imageUrl: "/app/images/apps/mortgageBroker/filogix.png",
    title: "Filogix",
    description:
      "We bring deep expertise and an unrivaled range of pre-integrated solutions spanning…",
  },
  {
    imageUrl: "/app/images/apps/deposit_rocket.png",
    title: "DepositRocket",
    description:
      "It is simply a guarantee that you will pay the required deposit upon closing of the…",
  },
  {
    imageUrl: "/app/images/apps/mortgageBroker/velocity.png",
    title: "Velocity",
    description:
      "Velocity Mortgage Capital is a nationwide, direct portfolio lender dedicated exclusively…",
  },
  {
    imageUrl: "/app/images/apps/mortgageBroker/NAS.png",
    title: "NAS",
    description:
      "NAS offers a complete appraisal management system that provides fast, rel…",
  },
  {
    imageUrl: "/app/images/apps/mortgageBroker/purview.png",
    title: "Purview",
    description:
      "The report leverages Teranet’s proprietary data and provides a comprehensive snapshot of…",
  },
  {
    imageUrl: "/app/images/apps/mortgageBroker/rps.png",
    title: "RPS",
    description:
      "As an Appraisal Management Company (AMC), we provide home appraisals, automated…",
  },
  {
    imageUrl: "/app/images/apps/realtor/google_drive.png",
    title: "Google Drive",
    description:
      "Drive integrates seamlessly with Docs, Sheets, and Slides, cloud-native apps that enabl…",
  },
  {
    imageUrl: "/app/images/apps/realtor/onedrive.png",
    title: "OneDrive",
    description:
      "Share files and store photos online, and access on any device with OneDrive cloud st…",
  },
  {
    imageUrl: "/app/images/apps/realtor/dropbox.png",
    title: "DropBox",
    description:
      "Bring your files and cloud content together with the tools your team wants to use.",
  },
  {
    imageUrl: "/app/images/apps/mortgageBroker/fct.png",
    title: "FCT",
    description:
      "Looking for a forward-thinking company you can rely on? From introducing title insuran…",
  },
  {
    imageUrl: "/app/images/apps/mortgageBroker/fnf.png",
    title: "FNF",
    description:
      "FNF Canada is a comprehensive and innovative provider of mortgage and loa…",
  },
  {
    imageUrl: "/app/images/apps/lawyers/realtiWeb.png",
    title: "RealtiWeb",
    description:
      "RealtiWeb puts you in control of real estate transactions. You can produce documents, hos…",
  },
  {
    imageUrl: "/app/images/apps/notarize.png",
    title: "Notarize",
    description: "Legally sign and notarize documents 100% online.",
  },
];
export default function MortgageBroker() {
  const classes = useStyles();

  return (
    <Grid
      container
      style={{
        padding: "0 24px 120px 24px",
      }}
    >
      <Typography variant="h4" className={Styles.navyBlueColor}>
        Mortgage Broker Apps ({mortgageBrokerApps && mortgageBrokerApps.length})
      </Typography>
      <Grid item xs={12}>
        <TextField
          disabled
          variant="outlined"
          fullWidth
          placeholder="Search your client here by name, email address or phone number"
          style={{
            backgroundColor: "#FFFFFF",
            marginTop: 20,
          }}
          InputProps={{
            classes: { input: classes.textField },
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <img
          style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
          src="/app/images/apps/apps_background.svg"
          alt="apps background"
          title="apps baclground"
        />
      </Grid>
      <Grid container spacing={3}>
        {mortgageBrokerApps &&
          mortgageBrokerApps.length &&
          mortgageBrokerApps.map((item) => <AppItem data={item} />)}
      </Grid>
    </Grid>
  );
}
