import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useActions } from "../../useActions";
import {
  SET_PLAN_DURATION,
  SET_PROMO_CODE,
  SET_HAVE_PROMO_CODE,
  SET_TEAM_PLAN_ID,
  SET_PROFILE_STATUS,
  SET_INVOICE_DATA,
  SET_USER_PLAN_ID,
  SET_PREV_USER_PLAN_ID,
  SET_PREV_PLAN_DURATION,
} from "../../../souqh-redux/reducers/actionConstants";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { useApiClient } from "../../useApiClient";
import { isEmpty } from "lodash";
import {
  ADVANCED,
  PREMIUM,
  SERVICE_PROVIDER_USER,
  TEAM_MEMBER_USER,
} from "../../../web/src/components/ServiceProvider/serviceProviderConstants";
import { isMarketplacePlan } from "../../../web/src/utils/AppUtils";
import { useRegistration } from "../useRegistration";

const getRemoteSubscriptionPlansActionCreator = (apiClient) => (dispatch) => {
  dispatch({ type: "SET_LOADING", value: true });
  return apiClient
    .get(`/service-provider/subscription/plans`)
    .then(function (response) {
      if (!response.data.result.length) {
        // we need response.data.result with values in every condition
        throw Error("Server Error : Plans not found");
      } else {
        dispatch({
          type: "SET_SUBSCRIPTION_PLANS",
          plans: response.data.result,
        });
      }
    })
    .catch(function (error) {
      console.error(error);
    })
    .then(function () {
      // always executed
      dispatch({ type: "SET_LOADING", value: false });
    });
};

export const useSubscriptionPlan = (page) => {
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();
  const user = useAuthUser();
  const plans = useSelector((state) => state.metaData.plans);
  const team = useSelector((state) => state.spSetup.team);
  const {
    planDuration,
    selectedTeamPlan,
    selectedUserPlan,
    selectedPrevUserPlan,
    selectedPrevPlanDuration,
  } = useSelector((state) => state.subscriptionPlan);

  const actions = useActions(subscriptionPlansActionCreators);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [teamSubscriptionPlans, setTeamSubscriptionPlans] = useState([]);
  const [selectedSubscriptionPlans, setSelectedSubscriptionPlans] =
    useState(null);
  const [selectedTeamSubscriptionPlans, setSelectedTeamSubscriptionPlans] =
    useState(null);
  const [defaultSelectedCard, setDefaultSelectedCard] = useState("");
  const [defaultSelectedTeamPlan, setDefaultSelectedTeamPlan] = useState("");

  useEffect(() => {
    if (!plans) {
      dispatch(getRemoteSubscriptionPlansActionCreator(apiClient));
    }
  }, [plans]);

  const getSelectedSubscriptionPlan = () => {
    let url =
      user.userType === SERVICE_PROVIDER_USER ||
      (user.userType === TEAM_MEMBER_USER &&
        (user.subscriptionPlanName === PREMIUM || user.subscriptionPlanName === ADVANCED))
        ? `/service-provider/subscription/${user.serviceProviderId}`
        : `/service-provider/subscription/${user.parentServiceProviderId}`;
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(url)
      .then(function (response) {
        if (response.data.status === 200) {
          dispatch({
            type: SET_USER_PLAN_ID,
            payload: response.data.result.primarySubscriptionPlan.id,
          });
          setSelectedSubscriptionPlans(response.data.result);
          actions.setPlanDuration(response.data.result.paymentCycle);
          if (response.data.result.teamSubscriptionPlan) {
            dispatch({
              type: SET_TEAM_PLAN_ID,
              payload: response.data.result.teamSubscriptionPlan.id,
            });
            setSelectedTeamSubscriptionPlans(
              response.data.result.teamSubscriptionPlan
            );
          }
        } else {
          setSelectedSubscriptionPlans({});
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  useEffect(() => {
    if (plans && plans.length && selectedSubscriptionPlans) {
      setSubscriptionPlans(getPlanList(plans, team, selectedSubscriptionPlans));
      setTeamSubscriptionPlans(
        getTeamPlanList(plans, team, selectedTeamSubscriptionPlans)
      );
    }
  }, [plans, team, selectedSubscriptionPlans, selectedTeamSubscriptionPlans]);

  useEffect(() => {
    if (!page || page !== "registration") {
      getSelectedSubscriptionPlan();
    }
  }, []);

  useEffect(() => {
    if (!page || page !== "registration") {
      setDefaultSelectedCard(subscriptionPlans.defaultSelected);
    }
  }, [subscriptionPlans]);

  useEffect(() => {
    setDefaultSelectedTeamPlan(teamSubscriptionPlans.defaultSelected);
  }, [teamSubscriptionPlans]);

  return {
    plans,
    team,
    planDuration,
    actions,
    subscriptionPlans,
    teamSubscriptionPlans,
    defaultSelectedCard,
    setDefaultSelectedCard,
    defaultSelectedTeamPlan,
    setDefaultSelectedTeamPlan,
    selectedTeamPlan,
    selectedUserPlan,
    selectedPrevUserPlan,
    selectedPrevPlanDuration,
  };
};

const subscriptionPlansActionCreators = {
  setPlanDuration: (payload) => ({
    type: SET_PLAN_DURATION,
    payload,
  }),
};

const getPlanList = (plans, team, data = {}) => {
  let subscriptionPlans = [];
  if (plans && plans.length) {
    if (!team.additionalUsers.length || team.additionalUsers.length > 0) {
      // if no team members added or less then 10 team members added show pro , plus and premium plan
      plans.filter((item) => {
        // if (item.id === 1 || item.id === 2 || item.id === 3) {
        if (item.id === 2 || item.id === 3 || item.id === 6) {
          subscriptionPlans.push(item);
          subscriptionPlans.defaultSelected = !isEmpty(data)
            ? data.primarySubscriptionPlan.planName
            : PREMIUM;
          subscriptionPlans.case = 1;
        }
      });
    }

    // if (team.additionalUsers.length > 10) {
    //   // if more then 10 team members added show enterprise plan
    //   plans.filter((item) => {
    //     if (item.id === 4) {
    //       subscriptionPlans.push(item);
    //       subscriptionPlans.defaultSelected = "Enterprise Plan";
    //       subscriptionPlans.case = 4;
    //     }
    //   });
    // }
  }
  return subscriptionPlans;
};

const getTeamPlanList = (plans, team, data = {}) => {
  let teamSubscriptionPlans = [];
  if (team.additionalUsers.length > 0) {
    plans.filter((item) => {
      // if (item.id === 1 || item.id === 2 || item.id === 3) {
      if (item.id === 2 || item.id === 3 || item.id === 6) {
        teamSubscriptionPlans.push(item);
        teamSubscriptionPlans.defaultSelected = data ? data.planName : "Plus";
        teamSubscriptionPlans.case = 2;
      }
    });
  }
  return teamSubscriptionPlans;
};

const useSavePlanActionCreators = {
  setSelectedTeamPlan: (payload) => ({
    type: SET_TEAM_PLAN_ID,
    payload,
  }),
  setSelectedUserPlan: (payload) => ({
    type: SET_USER_PLAN_ID,
    payload,
  }),
  setPrevSelectedUserPlan: (payload) => ({
    type: SET_PREV_USER_PLAN_ID,
    payload,
  }),
  setSelectedPrevPlanDuration: (payload) => ({
    type: SET_PREV_PLAN_DURATION,
    payload,
  }),
};

export const useSavePlanData = () => {
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();
  const user = useAuthUser();
  let plansData = {};
  const actions = useActions(useSavePlanActionCreators);

  const data = useSelector((state) => state.subscriptionPlan);

  const savePlanData = (
    onResult,
    isMarketplaceplan = false,
    fromVerfiyBTn = false
  ) => {
    plansData = {
      paymentCycle: data.planDuration,
      primaryPlanId: isMarketplaceplan ? 5 : data.selectedUserPlan,
      serviceProviderId: user.serviceProviderId,
      teamPlanId: isMarketplaceplan ? null : data.selectedTeamPlan,
    };
    submitPlanData(plansData, onResult);
  };
  const submitPlanData = (plansData, onResult) => {
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .post("/service-provider/subscription", plansData)
      .then((res) => {
        if (res.data.result.status === 200) {
          dispatch({
            type: SET_PROFILE_STATUS,
            payload: {
              data: {
                key: "isPaymentPlanSelected",
                value: res.data.result.isPaymentPlanSelected,
              },
            },
          });
        }
        dispatch({ type: "SET_TOASTER", value: true });
        onResult && onResult(res);
      })
      .catch((error) => {
        console.error(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    savePlanData,
    actions,
  };
};

const verifyPromoCodeActionCreators = {
  setPromoCode: (payload) => ({ type: SET_PROMO_CODE, payload }),
  setHavePromoCode: (payload) => ({ type: SET_HAVE_PROMO_CODE, payload }),
  resetPromoCode: () => ({
    type: "SET_VERFIY_PROMOCODE",
    verifyPromoCode: null,
  }),
};
export const useVerifyPromocode = (isCampaign, page, selectedProvinces) => {
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();
  const user = useAuthUser();
  const actions = useActions(verifyPromoCodeActionCreators);
  const havePromoCode = useSelector(
    (state) => state.subscriptionPlan.havePromoCode
  );

  const verifyPromoCodeData = useSelector(
    (state) => state.metaData.verifyPromoCode
  );
  const promoCode = useSelector((state) => state.subscriptionPlan.promoCode);

  const { generateInvoiceMarketplace } = useMarketplacePlan();
  const { generateInvoiceRegistration } = useRegistration();

  useEffect(() => {
    if (!havePromoCode) {
      actions.setPromoCode("");
      dispatch({
        type: "SET_VERFIY_PROMOCODE",
        verifyPromoCode: null, // clear verification data, to clean the message
      });
    }
  }, [havePromoCode]);

  const verifyPromoCode = (onResult) => {
    let url =
      page === "registration"
        ? `/service-provider/subscription/promocode/verify?promocode=${promoCode}`
        : `/service-provider/subscription/promocode/verify/${isCampaign}?promocode=${promoCode}&serviceProviderId=${
            user.serviceProviderId || user.parentServiceProviderId
          }`;
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(url)
      .then(function (response) {
        dispatch({
          type: "SET_VERFIY_PROMOCODE",
          verifyPromoCode: {
            result: response.data.result.verified,
            message: response.data.message,
          },
        });
        if (response.data.result.verified) {
          if (page === "registration") {
            generateInvoiceRegistration(selectedProvinces);
          } else {
            if (isMarketplacePlan(user)) {
              generateInvoiceMarketplace();
            } else {
              dispatch({
                type: SET_INVOICE_DATA,
                payload: {
                  invoiceData: response.data.result.invoice,
                },
              });
            }
          }
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    havePromoCode,
    promoCode,
    verifyPromoCodeData,
    verifyPromoCode,
    actions,
  };
};

export const useMarketplacePlan = () => {
  const { apiClient } = useApiClient();
  const dispatch = useDispatch();
  const user = useAuthUser();

  const data = useSelector((state) => state.subscriptionPlan);

  const generateInvoiceMarketplace = (onResult) => {
    const paymentSubscriptionRequest = {
      couponCode: data.promoCode ? data.promoCode : null,
      paymentCycle: data.planDuration,
      primaryPlanId: data.selectedUserPlan,
      serviceProviderId: user.serviceProviderId,
      teamPlanId: data.selectedTeamPlan,
    };
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .post(
        `/service-provider/subscription/invoice-marketplace`,
        paymentSubscriptionRequest
      )
      .then(function (response) {
        if (response.data.status === 200) {
          //   dispatch({
          //     type: SET_PROFILE_STATUS,
          //     payload: {
          //       data: {
          //         key: "isPaymentPlanSelected",
          //         value: res.data.result.isPaymentPlanSelected,
          //       },
          //     },
          //   });
          dispatch({
            type: SET_INVOICE_DATA,
            payload: { invoiceData: response.data.result },
          });
        }
        dispatch({ type: "SET_TOASTER", value: true });
        onResult && onResult(response);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const upgradeMarketplacePlan = (onResult) => {
    const paymentSubscriptionRequest = {
      couponCode: data.promoCode ? data.promoCode : null,
      paymentCycle: data.planDuration,
      primaryPlanId: data.selectedUserPlan,
      serviceProviderId: user.serviceProviderId,
      teamPlanId: data.selectedTeamPlan,
    };
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .post(
        `/service-provider/subscription/upgrade-marketplace`,
        paymentSubscriptionRequest
      )
      .then(function (response) {
        if (response.data.status === 200) {
          //   dispatch({
          //     type: SET_PROFILE_STATUS,
          //     payload: {
          //       data: {
          //         key: "isPaymentPlanSelected",
          //         value: res.data.result.isPaymentPlanSelected,
          //       },
          //     },
          //   });
        }
        dispatch({ type: "SET_TOASTER", value: true });
        onResult && onResult(response);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    generateInvoiceMarketplace,
    upgradeMarketplacePlan,
  };
};
