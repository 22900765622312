import {
  SET_EVENTS_TAB,
  SET_PENDING_ACTIONS,
  REMOVE_PENDING_ACTION,
} from "souqh-redux/reducers/actionConstants";
import { useActions } from "../../useActions";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthUser } from "../../useLogin";
import { useApiClient } from "../../useApiClient";
import { useHistory } from "react-router-dom";
import { HOME_BUYER_USER } from "../../../web/src/components/ServiceProvider/serviceProviderConstants";
import {
  getClientColumnValue,
  getUploadedDocuments,
} from "../../../web/src/utils/AppUtils";
import { color_info } from "../../toasterColors";

const PAGE_SIZE = 25;
const actionCreator = {
  setEventsTab: (payload) => ({ type: SET_EVENTS_TAB, payload }),
  removePendingAction: (payload) => ({
    type: REMOVE_PENDING_ACTION,
    payload,
  }),
};

export const useUpcomingEeventsPendingActions = () => {
  const dispatch = useDispatch();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("requestDate");
  const userInfo = useAuthUser();
  const { apiClient } = useApiClient();
  const history = useHistory();
  const actions = useActions(actionCreator);

  const [openDocumentAccessRequestDialog, setOpenDocumentAccessRequestDialog] =
    useState(false);
  const [openProfileAccessRequestDialog, setOpenProfileAccessRequestDialog] =
    useState(false);
  const [openAddReviewDialog, setOpenAddReviewDialog] = useState({
    open: false,
    data: {},
  });
  const [openViewTagDialog, setOpenViewTagDialog] = useState(false);
  const [viewTagDialogData, setViewTagDialogData] = useState(null);
  const [requestedDocumentData, setRequestedDocumentData] = useState(null);
  const [openRequestedDocumentDialog, setOpenRequestedDocumentDialog] =
    useState(false);
  const [requestedDocAccessName, setRequestedDocAccessName] = useState(null);
  const [docAccessRequestedData, setDocAccessRequestedData] = useState(null);
  const [documentAccessRequestorSP, setDocumentAccessRequestorSP] =
    useState(null);
  const [pendingActionId, setPendingActionId] = useState(null);
  const [openHomeBuyingJourneyDialog, setOpenHomeBuyingJourneyDialog] =
    useState(false);
  const [openOtherActionDialog, setOpenOtherActionDialog] = useState(false);
  const [openRecommendedSPDialog, setOpenRecommendedSPDialog] = useState(false);
  const [eventAndActionsearchText, setEventAndActionsearchText] = useState("");
  const [openAddMemberDialog, setOpenAddMemberDialog] = useState(false);
  const [
    openInviteSPDialogForPendingAction,
    setOpenInviteSPDialogForPendingAction,
  ] = useState(false);
  const [openViewInvoiceDialog, setOpenViewInvoiceDialog] = useState(false);
  const [viewInvoiceDialogData, setViewInvoiceDialogData] = useState(null);
  const [displayPendingActionsData, setDisplayPendingActionsData] = useState(
    {}
  );
  const [openDocumetDialog, setOpenDocumetDialog] = useState(false);
  const [documentItem, setDocumentItem] = useState(null);
  const [eventIndex, setEventIndex] = useState(null);
  const [openSignDocumentDialog, setOpenSignDocumentDialog] = useState(false);
  const [signDocumentItem, setSignDocumentItem] = useState(null);
  const [recommendedSpData, setRecommendedSpData] = useState(null);
  const [otherActionData, setOtherActionData] = useState(null);
  const [profileAccessData, setProfileAccessData] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [docCategoryId, setDocCategoryId] = useState(null);
  const [docNameForDownload, setDocNameForDownload] = useState(null);
  const [docUserId, setDocUserId] = useState(null);


  const [movingChecklist, setMovingChecklist] = useState([]);
  const [openMovingChecklistPopup, setOpenMovingChecklistPopup] = useState({
    open: false,
    data: {},
  });
  const [openMovingChecklistAddressPopup, setOpenMovingChecklistAddressPopup] = useState({
    open: false,
    data: {},
  });

  const { eventsTab, pendingActions } = useSelector(
    (state) => state.upcomingEeventsPendingActions
  );
  const pendingActionsList = pendingActions.pendingActionsList;
  useEffect(() => {
    setEventAndActionsearchText("");
  }, [eventsTab]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const reloadPendingActionData = () => {
    getAllPendingActions();
  };

  const getAllPendingActions = (page = 0, pageSize = PAGE_SIZE) => {
    let url = "";
    if (userInfo.userType === HOME_BUYER_USER) {
      url = `/pending-actions/home-buyer/${userInfo.userId}?page=${page}&size=${pageSize}`;
    } else {
      url = `/pending-actions/service-provider/${userInfo.userId}?page=${page}&size=${pageSize}`;
    }
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(url)
      .then(function (response) {
        if (response.status === 200) {
          response.data.response.map((el) => {
            el.clientName = getClientColumnValue(el);
          });
          dispatch({
            type: SET_PENDING_ACTIONS,
            payload: response.data,
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const getAllMovingChecklist = () => {
    if (userInfo && userInfo.hbCurrentAddressCity && ((userInfo.hbCurrentAddressCity.cityDTO && userInfo.hbCurrentAddressCity.cityDTO.length) || userInfo.hbCurrentAddressCity.city) && userInfo.hbNewAddressCity && userInfo.hbNewAddressCity.cityDTO && userInfo.hbNewAddressCity.cityDTO.length) {
      let url;
      if (userInfo.hbCurrentAddressCity.cityDTO && userInfo.hbCurrentAddressCity.cityDTO.length) {
        url = `/movingchecklist/${userInfo.homeBuyerId}/${userInfo.hbCurrentAddressCity.cityDTO[0].id}/${userInfo.hbNewAddressCity.cityDTO[0].id}/all`;
      }
      if (userInfo.hbCurrentAddressCity.city) {
        url = `/movingchecklist/${userInfo.homeBuyerId}/${userInfo.hbCurrentAddressCity.city.id}/${userInfo.hbNewAddressCity.cityDTO[0].id}/all`;
      }

    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(url)
      .then(function (response) {
        if (response.status === 200) {
          setMovingChecklist(response.data.result)
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
    }
  };

  const handlePageChange = (event, page) => {
    getAllPendingActions(page - 1);
  };
  const searchEventsAndActions = (searchText) => {
    setEventAndActionsearchText(searchText);
    if (eventsTab === 1) {
      let filteredRows = [];
      pendingActions &&
        pendingActions.response.length &&
        pendingActions.response.map((row) => {
          if (
            (row.requestorSPDetails &&
              row.requestorSPDetails.storeFrontName
                .toLowerCase()
                .includes(searchText.toLowerCase())) ||
            row.eventDescription
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            filteredRows.push(row);
          }
        });
      let data = {
        currentPage: 0,
        response: filteredRows,
        totalItems: filteredRows.length,
        totalPages: Math.round(filteredRows.length / PAGE_SIZE),
      };
      setDisplayPendingActionsData(data);
    } else if (eventsTab === 0) {
      // search for Upcoming Events will be done over here
    }
  };

  const acceptDocumentAccessRequest = (
    requestorUserId,
    referenceDocumentId,
    signnowDocumentId,
    isApproved,
    onResult
  ) => {
    const url = signnowDocumentId
      ? `pending-actions/home-buyer/accept-reject/sign-now/document/access/${requestorUserId}/${signnowDocumentId}/${isApproved}`
      : `pending-actions/home-buyer/accept-reject/document/access/${requestorUserId}/${referenceDocumentId}/${isApproved}`;
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .post(url)
      .then(function (response) {
        actions.removePendingAction(eventIndex);
        onResult && onResult(response);
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const acceptRejectProfileAccessRequest = (
    isProfileAccessRequestApproved,
    serviceRequestId,
    onResult
  ) => {
    const url = `/pending-actions/home-buyer/accept-reject/profile/access/${serviceRequestId}/${isProfileAccessRequestApproved}`;
    if (serviceRequestId) {
      dispatch({ type: "SET_LOADING", value: true });
      return apiClient
        .post(url)
        .then(function (response) {
          actions.removePendingAction(serviceRequestId);
          onResult && onResult(response);
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function () {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const deletePendingAction = (pendingActionId) => {
    if (pendingActionId) {
      const url = `/pending-actions/delete/${pendingActionId}`;
      dispatch({ type: "SET_LOADING", value: true });
      apiClient
        .delete(url)
        .then((response) => {
          if (response.data.status === 200) {
            actions.removePendingAction(pendingActionId);
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .then(function (error) {
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const getSFDataForRecommendedSP = (pendingActionId) => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(`/pending-actions/home-buyer/recommended-sp/${pendingActionId}`)
      .then(function (response) {
        if (response && response.data && response.data.result) {
          setRecommendedSpData(response.data.result);
          setOpenRecommendedSPDialog(true);
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  const handlePendingAction = (rowData) => {
    switch (rowData.pendingAction) {
      //HB Pending Actions
      case "COMPLETE_HB_PROFILE":
      case "COMPLETE_PROFILE_REQUEST":
        history.push("/app/usersetup");
        break;
      case "ADD_REVIEW_FOR_COMPLETED_SERVICE_REQUEST":
        let data = {
          userId: userInfo.userId,
          serviceRequestId: rowData.serviceRequestId,
          storefrontId: rowData.requestorSPDetails.storefrontId,
          displayPicture: JSON.parse(
            rowData.requestorSPDetails.storeFrontContent
          ).displayPicture,
          storefrontName: rowData.requestorSPDetails.storeFrontName,
          serviceName: rowData.serviceName,
          email: rowData.requestorSPDetails.email,
          contactNumber: rowData.requestorSPDetails.contactNumber,
        };
        setOpenAddReviewDialog({ open: true, data: data });
        break;
      case "SELECT_HOME_BUYING_JOURNEY_STAGE":
        setOpenHomeBuyingJourneyDialog(true);
        break;
      case "GRANT_ACCESS":
        if (
          rowData.documentDetailDTO.documentId ||
          rowData.documentDetailDTO.signNowDocumentId
        ) {
          setOpenDocumentAccessRequestDialog(true);
          setRequestedDocAccessName(rowData.documentDetailDTO.documentName);
          setDocumentAccessRequestorSP(rowData.requestorSPDetails.email);
          setPendingActionId(rowData.pendingActionId);
          setDocAccessRequestedData({
            requestorUserId: rowData.requestorSPDetails.userId,
            referenceDocumentId: rowData.documentDetailDTO.documentId,
            signnowDocumentId: rowData.documentDetailDTO.signNowSouqhDocumentId,
          });
        } else {
          dispatch({
            type: "SET_TOASTER",
            payload: {
              message: `Looks like you have not uploaded any document so far.`,
              bgColor: color_info,
              open: true,
            },
          });
        }
        break;
      case "DOCUMENT_REQUESTED":
        setOpenRequestedDocumentDialog(true);
        setRequestedDocumentData(rowData);
        setPendingActionId(rowData.pendingActionId);
        break;
      case "VIEW_INVOICE":
        setOpenViewInvoiceDialog(true);
        setViewInvoiceDialogData(rowData.invoiceDetailDTO);
        break;
      case "REVIEW_DOCUMENT":
        setDocumentItem(getUploadedDocuments(rowData.documentDetailDTO));
        setDownloadUrl(rowData.documentDetailDTO.documentUrl);
        setDocCategoryId(rowData.documentDetailDTO.categoryId);
        setDocNameForDownload(rowData.documentDetailDTO.fileName);
        setDocUserId(rowData.documentDetailDTO.userId);
        setOpenDocumetDialog(true);
        break;
      case "RECOMMENDED_SERVICE_PROVIDER":
        getSFDataForRecommendedSP(rowData.pendingActionId);
        break;
      case "OTHER_ACTION":
        setOpenOtherActionDialog(true);
        setOtherActionData(rowData);
        break;
      case "PROFILE_ACCESS_REQUEST":
        setOpenProfileAccessRequestDialog(true);
        setProfileAccessData(rowData);
        break;
      case "MOVING_CHECKLIST":
        history.push("/app/homebuyer/events?tabIndex=2");
        break;
      //SP Pending Actions
      case "VIEW_NEW_LEAD":
        history.push("/app/serviceprovider/clients?tabIndex=3");
        break;
      case "COMPLETE_STOREFRONT_SETUP":
        history.push("/app/serviceprovider/storefront");
        break;
      case "COMPLETE_TEAM_MEMBER_PROFILE":
        history.push("/app/team/updateDetails");
        break;
      case "INVITE_TEAM_MEMBERS":
        setOpenAddMemberDialog(true);
        break;
      case "INVITE_SERVICE_PROVIDERS_FROM_YOUR_NETWORK":
        setOpenInviteSPDialogForPendingAction(true);
        break;
      case "TAG_MEMBER":
        setOpenViewTagDialog(true);
        setViewTagDialogData(rowData);
        break;
      case "SIGN_DOCUMENT":
        setOpenSignDocumentDialog(true);
        setSignDocumentItem(rowData.documentDetailDTO);
        break;
      case "CLIENT_ASSIGNED":
        history.push("/app/serviceprovider/clients?tabIndex=0");
        break;
      default:
        break;
    }
  };

  const handleMovingChecklist = (rowData) => {
    switch (rowData.movingChecklistConstant) {
      case "RECYCLE_MY_ELECTRONICS":
      case "UPDATE_DRIVERS_LICENSE":
      case "UPDATE_HEALTH_CARD":
      case "START_MAIL_FORWARDING":
      case "VIEW_FEDERAL_SERVICES":
        window.open(
          rowData.link, "_blank"
        );
        break;
      case "CHECK_GARBAGE_COLLECTION_SERVICES":
        let garbageCollectionData = {
          title: "Garbage Collection",
          subHeader: "",
          subCategories: rowData.subCategories
        };
        setOpenMovingChecklistPopup({ open: true, data: garbageCollectionData });
        break;
      case "UPDATE_FINANCIAL_INSTITUTION":
        let data = {
          title: "Financial Institutions",
          subHeader: "Update your financial institutions regarding your new home address",
          subCategories: rowData.subCategories
        };
        setOpenMovingChecklistPopup({ open: true, data: data });
        break;
      case "HIRE_A_MOVER":
        // window.history.pushState(null, null, `/homebuyer/explore/mover/${userInfo?.hbCurrentAddressCity?.cityDTO?.[0]?.shortName}`);
        // window.location.replace(`/homebuyer/explore/mover/${userInfo?.hbCurrentAddressCity?.cityDTO?.[0]?.shortName}`);

        // history.push(`/homebuyer/explore/mover/${userInfo?.hbCurrentAddressCity?.cityDTO?.[0]?.shortName}?openBroadcastDialog=true`);
        // history.push(`/homebuyer/serviceproviders`);

        history.push({
          pathname: `/app/homebuyer/explore/mover/${userInfo?.hbCurrentAddressCity?.cityDTO?.[0]?.shortName}`,
          // search: `?openBroadcastDialog=true`,
          state: {
            searchedService: 'Mover',
            dontReloadPage: true
          },
        });

        //   let locat = location.href;
        //   console.log(locat)
        // window.history.replaceState({id:'page3'},'Page3',locat);
        // window.history.pushState(null,null,`/homebuyer/explore/mover/${userInfo?.hbCurrentAddressCity?.cityDTO?.[0]?.shortName}`);
        break;
      case "GET_JUNK_REMOVAL_SERVICES":
        // history.push(`/homebuyer/explore/junk%20removal/${userInfo?.hbCurrentAddressCity?.cityDTO?.[0]?.shortName}`);
        history.push({
          pathname: `/app/homebuyer/explore/junk%20removal/${userInfo?.hbCurrentAddressCity?.cityDTO?.[0]?.shortName}`,
          state: {
            // searchedService: 'Mover',
            dontReloadPage: true
          },
        });
        break;
      case "VIEW_STORAGE_OPTIONS":
        // history.push(`/homebuyer/explore/storage/${userInfo?.hbCurrentAddressCity?.cityDTO?.[0]?.shortName}`);
        history.push({
          pathname: `/app/homebuyer/explore/storage/${userInfo?.hbCurrentAddressCity?.cityDTO?.[0]?.shortName}`,
          state: {
            searchedService: 'Storage',
            dontReloadPage: true
          },
        });
        break;
      case "UPDATE_TV_INTERNET_PHONE_SERVICES":
        let tvInternetData = [{
          title: "Internet & TV",
          textFieldPlaceholder: "Internet Service Providers",
          movingFromAddress: userInfo.hbCurrentAddressCity.cityDTO[0].name,
          movingToAddress: userInfo.hbNewAddressCity.cityDTO[0].name,
          currentCiyDropdownValues: rowData.internetPhoneDetails,
          newCiyDropdownValues: rowData.internetPhoneDetails,
          subTitle: "Internet",
        }, {
          title: "Internet & TV",
          textFieldPlaceholder: "TV Service Providers",
          movingFromAddress: userInfo.hbCurrentAddressCity.cityDTO[0].name,
          movingToAddress: userInfo.hbNewAddressCity.cityDTO[0].name,
          currentCiyDropdownValues: rowData.tvDetails,
          newCiyDropdownValues: rowData.tvDetails,
          subTitle: "TV",
        }];
        setOpenMovingChecklistAddressPopup({ open: true, data: tvInternetData });
        break;
      case "UPDATE_ELECTRICITY_SERVICE":
        let electricityServiceData = [{
          title: "Electricity Services",
          textFieldPlaceholder: "Electricity Service Providers",
          movingFromAddress: userInfo.hbCurrentAddressCity.cityDTO[0].name,
          movingToAddress: userInfo.hbNewAddressCity.cityDTO[0].name,
          currentCiyDropdownValues: rowData.currentCityElectricityDetails,
          newCiyDropdownValues: rowData.newCityElectricityDetails,
        }];
        setOpenMovingChecklistAddressPopup({ open: true, data: electricityServiceData });
        break;
      case "UPDATE_GAS_SERVICE":
        let gasServiceData = [{
          title: "Gas Service",
          textFieldPlaceholder: "Gas Service Providers",
          movingFromAddress: userInfo.hbCurrentAddressCity.cityDTO[0].name,
          movingToAddress: userInfo.hbNewAddressCity.cityDTO[0].name,
          currentCiyDropdownValues: rowData.currentCityNaturalGasDetails,
          newCiyDropdownValues: rowData.newCityNaturalGasDetails,
        }];
        setOpenMovingChecklistAddressPopup({ open: true, data: gasServiceData });
        break;
      case "UPDATE_HYDRO_SERVICE":
        let hydroServiceData = [{
          title: "Hydro Service",
          textFieldPlaceholder: "Hydro Service Providers",
          movingFromAddress: userInfo.hbCurrentAddressCity.cityDTO[0].name,
          movingToAddress: userInfo.hbNewAddressCity.cityDTO[0].name,
          currentCiyDropdownValues: rowData.currentCityHydroDetails,
          newCiyDropdownValues: rowData.newCityHydroDetails,
        }];
        setOpenMovingChecklistAddressPopup({ open: true, data: hydroServiceData });
        break;
      case "HIRE_HOME_INSPECTORS":
        // history.push(`/homebuyer/explore/property-inspector-services/${userInfo?.hbCurrentAddressCity?.cityDTO?.[0]?.shortName}`);
        history.push({
          pathname: `/app/homebuyer/explore/property-inspector-services/${userInfo?.hbCurrentAddressCity?.cityDTO?.[0]?.shortName}`,
          state: {
            searchedService: 'Property Inspector',
            dontReloadPage: true
          },
        });
        break;
      default:
        break;
    }
  };

  const setMovingChecklistCheckedValue = (checkedValue, movingChecklistId) => {
    let url = `/movingchecklist/updateHomeBuyerMovingChecklistStatus`;

    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .put(url, null, {
        params: {
          homeBuyerId: userInfo.homeBuyerId,
          movingChecklistId: movingChecklistId,
          status: checkedValue,
        },
      })
      .then(function (response) {
        if (response.status === 200) {
          let newList = [...movingChecklist]
          for (i = 0; i < newList.length; i++) {
            if (newList[i].id === movingChecklistId) {
              newList[i].checked = checkedValue
            }
          }
          setMovingChecklist(newList)
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  }

  return {
    eventsTab,
    actions,
    order,
    orderBy,
    handleRequestSort,
    getAllPendingActions,
    pendingActionsList,
    userInfo,
    handlePendingAction,
    openDocumentAccessRequestDialog,
    setOpenDocumentAccessRequestDialog,
    openProfileAccessRequestDialog,
    setOpenProfileAccessRequestDialog,
    openViewTagDialog,
    setOpenViewTagDialog,
    openRequestedDocumentDialog,
    setOpenRequestedDocumentDialog,
    openAddReviewDialog,
    setOpenAddReviewDialog,
    eventAndActionsearchText,
    setEventAndActionsearchText,
    openHomeBuyingJourneyDialog,
    setOpenHomeBuyingJourneyDialog,
    openOtherActionDialog,
    setOpenOtherActionDialog,
    openRecommendedSPDialog,
    setOpenRecommendedSPDialog,
    openAddMemberDialog,
    setOpenAddMemberDialog,
    openInviteSPDialogForPendingAction,
    setOpenInviteSPDialogForPendingAction,
    searchEventsAndActions,
    requestedDocAccessName,
    documentAccessRequestorSP,
    pendingActionId,
    acceptDocumentAccessRequest,
    openViewInvoiceDialog,
    setOpenViewInvoiceDialog,
    viewInvoiceDialogData,
    viewTagDialogData,
    openDocumetDialog,
    setOpenDocumetDialog,
    documentItem,
    setDocumentItem,
    setEventIndex,
    openSignDocumentDialog,
    setOpenSignDocumentDialog,
    signDocumentItem,
    setSignDocumentItem,
    reloadPendingActionData,
    deletePendingAction,
    requestedDocumentData,
    recommendedSpData,
    otherActionData,
    profileAccessData,
    acceptRejectProfileAccessRequest,
    downloadUrl,
    docAccessRequestedData,
    docCategoryId,
    docNameForDownload,
    docUserId,
    pendingActions,
    handlePageChange,
    displayPendingActionsData,
    setDisplayPendingActionsData,
    handleMovingChecklist,
    getAllMovingChecklist,
    movingChecklist,
    openMovingChecklistPopup,
    setOpenMovingChecklistPopup,
    setMovingChecklistCheckedValue,
    openMovingChecklistAddressPopup,
    setOpenMovingChecklistAddressPopup,
  };
};
