import {
  Grid,
  TextField,
  Typography,
  makeStyles,
  InputAdornment,
} from "@material-ui/core";
import React from "react";
import Styles from "../../../Styles.module.css";
import SearchIcon from "@material-ui/icons/Search";
import AppItem from "./AppItem";

const useStyles = makeStyles((theme) => ({
  textField: { padding: "15px 14px !important" },
}));

const realtorApps = [
  {
    imageUrl: "/app/images/apps/realtor/image_1.png",
    title: "Realtor.ca",
    description:
      "REALTOR.ca is the most popular and most trusted real estate website in Canada.",
  },
  {
    imageUrl: "/app/images/apps/realtor/image_2.png",
    title: "Brokeybay",
    description:
      "Introducing the most advanced, streamlined and user-friendly showing…",
  },
  {
    imageUrl: "/app/images/apps/deposit_rocket.png",
    title: "DepositRocket",
    description:
      "It is simply a guarantee that you will pay the required deposit upon closing of the…",
  },
  {
    imageUrl: "/app/images/apps/realtor/docu_sign.png",
    title: "Docusign",
    description:
      "DocuSign is the most reliable and globally trusted service for electronic signatures and…",
  },
  {
    imageUrl: "/app/images/apps/realtor/authenti_sign.png",
    title: "AuthentiSign",
    description:
      "Say goodbye to manual entry and uploads. Authentisign is built into Transactions so…",
  },
  {
    imageUrl: "/app/images/apps/realtor/calendly.png",
    title: "Calendly",
    description:
      "Calendly is the modern scheduling platform that makes “finding time” a breeze.",
  },
  {
    imageUrl: "/app/images/apps/realtor/cam_scanner.png",
    title: "CamScanner",
    description:
      "Send scanned documents anytime anywhere. Auto sharpen & brighten, easy…",
  },
  {
    imageUrl: "/app/images/apps/realtor/turbo_scan.png",
    title: "TurboScan",
    description:
      "TurboScan turns your iPhone into a full-featured and powerful scanner for docume…",
  },
  {
    imageUrl: "/app/images/apps/realtor/income_estate.png",
    title: "InCom Real Estate",
    description:
      "Real Estate website design for REALTORS- agents, brokers, offices and companies.",
  },
  {
    imageUrl: "/app/images/apps/realtor/spark.png",
    title: "Spark.re",
    description:
      "Sell, market and manage new development real estate. Spark is simplifying new home sales…",
  },
  {
    imageUrl: "/app/images/apps/realtor/sierra.png",
    title: "Sierra Interactive",
    description:
      "Sierra Interactive is a complete real estate technology platform, purpose-built to hel…",
  },
  {
    imageUrl: "/app/images/apps/realtor/active_campaign.png",
    title: "ActiveCampaign",
    description:
      "Save hours daily by automating your email marketing and spend more ti…",
  },
  {
    imageUrl: "/app/images/apps/realtor/google_drive.png",
    title: "Google Drive",
    description:
      "Drive integrates seamlessly with Docs, Sheets, and Slides, cloud-native apps that enabl…",
  },
  {
    imageUrl: "/app/images/apps/realtor/onedrive.png",
    title: "OneDrive",
    description:
      "Share files and store photos online, and access on any device with OneDrive cloud st…",
  },
  {
    imageUrl: "/app/images/apps/realtor/dropbox.png",
    title: "DropBox",
    description:
      "Bring your files and cloud content together with the tools your team wants to use.",
  },
  {
    imageUrl: "/app/images/apps/realtor/move_snap.png",
    title: "MoveSnap",
    description:
      "A digital moving concierge service that helps your clients complete their moving-relate…",
  },
  {
    imageUrl: "/app/images/apps/realtor/idx.svg",
    title: "IDX",
    description: "No information added",
  },
  {
    imageUrl: "/app/images/apps/realtor/ratemy_agent.png",
    title: "RateMyAgent",
    description:
      "RateMyAgent provides recommendations, ranking and reviews for real estate a…",
  },
  {
    imageUrl: "/app/images/apps/realtor/follow_upboss.png",
    title: "FollowUpBoss",
    description:
      "Follow Up Boss is simple and complete, with everything in one place · Automatically pull…",
  },
  {
    imageUrl: "/app/images/apps/realtor/showing_time.png",
    title: "ShowingTime",
    description:
      "ShowingTime is the real estate industry’s leading showing management and market…",
  },
  {
    imageUrl: "/app/images/apps/notarize.png",
    title: "Notarize",
    description: "Legally sign and notarize documents 100% online.",
  },
];
export default function Realtor() {
  const classes = useStyles();

  return (
    <Grid
      container
      style={{
        padding: "0 24px 120px 24px",
      }}
    >
      <Typography variant="h4" className={Styles.navyBlueColor}>
        Realtor Apps ({realtorApps && realtorApps.length})
      </Typography>
      <Grid item xs={12}>
        <TextField
          disabled
          variant="outlined"
          fullWidth
          placeholder="Search realtor apps"
          style={{
            backgroundColor: "#FFFFFF",
            marginTop: 20,
          }}
          InputProps={{
            classes: { input: classes.textField },
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <img
          style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
          src="/app/images/apps/apps_background.svg"
          alt="apps background"
          title="apps background"
        />
      </Grid>
      <Grid container spacing={3}>
        {realtorApps.map((item) => (
          <AppItem data={item} />
        ))}
      </Grid>
    </Grid>
  );
}
