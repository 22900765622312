import {
  AppBar,
  Avatar,
  ButtonBase,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Styles from "../../../Styles.module.css";
import { useHistory } from "react-router-dom";
import Rating from "@material-ui/lab/Rating";
import { useStorefrontClientReviews } from "souqh-react-redux-hooks/common/useStorefrontClientReviews";
import clsx from "clsx";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import {
  getMetadataInfo,
  isMarketplacePlan,
  isMobileDevice,
  isOnboardingInComplete,
} from "../../../utils/AppUtils";
import MoreTrafficDialog from "./MoreTrafficDialog";
import NoDataAvailable from "../../common/NoDataAvailable";

export default function ClientReviewsWidget({
  xlUp,
  openPlanUpgradeMsgDialog,
  setOpenPlanUpgradeMsgDialog,
  storeFrontDetails,
}) {
  const [openMoreTrafficDialog, setopenMoreTrafficDialog] = useState(false);
  let history = useHistory();

  const user = useAuthUser();

  const { reviewsList, storeFrontRouteName, actions } =
    useStorefrontClientReviews(
      10,
      "storefront/service-review/sp-view/all",
      user.storefrontId
    ); // passed page size

  useEffect(() => {
    return () => {
      actions.clearReviewList();
    };
  }, []);

  return (
    <React.Fragment>
      <MoreTrafficDialog
        open={openMoreTrafficDialog}
        setopenMoreTrafficDialog={setopenMoreTrafficDialog}
        storeFrontId={user.storefrontId}
        storeFrontRouteName={storeFrontRouteName}
        data={getMetadataInfo(storeFrontDetails)}
      />
      {reviewsList && reviewsList.length ? (
        <TableContainer
          component={Paper}
          elevation={0}
          className="hideScroll"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Table
            aria-label="collapsible table"
            stickyHeader
            style={{
              padding: "8px 8px 32px 8px",
              //  flexGrow: 1
            }}
          >
            <TableBody>
              {reviewsList.map((data, index) => (
                <TableRow key={index}>
                  <TableCell style={{ padding: "8px 0px" }}>
                    <Grid container>
                      <Grid item xs={2}>
                        <Avatar
                          style={{ height: 30, width: 30 }}
                          src="/app/broken-image.jpg"
                        >
                          {(data.userName || "").charAt(0)}
                        </Avatar>
                      </Grid>
                      <Grid container item xs={10}>
                        <Grid container item xs={12}>
                          <Grid item xs={7}>
                            <Typography
                              className={Styles.navyBlueColor}
                              style={{
                                fontWeight: 600,
                                fontSize: isMobileDevice()
                                  ? 14
                                  : xlUp
                                  ? 16
                                  : 11,
                              }}
                            >
                              {data.userName}
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Rating
                              name="rating"
                              value={data.starRating}
                              className={Styles.navyBlueColorForce}
                              style={{
                                fontSize: isMobileDevice() || xlUp ? 14 : 12,
                              }}
                              readOnly
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            style={{
                              fontSize: isMobileDevice() ? 12 : 11,
                            }}
                            className={clsx(
                              Styles.navyBlueColor,
                              Styles.sqEllipsisTwoLines
                            )}
                          >
                            {data.reviewComments}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {reviewsList && reviewsList.length ? (
            <div
              style={{
                // position: "absolute",
                position: "sticky",
                alignItems: "flex-end",
                bottom: 0,
                // right: 0,
                width: "100%",
              }}
            >
              <AppBar
                position="sticky"
                style={{
                  top: "auto",
                  bottom: 0,
                  backgroundColor: "#FFFFFF",
                  color: "#FA7E61",
                  height: 48,
                }}
              >
                <Grid
                  container
                  item
                  justifyContent="flex-end"
                  alignContent="center"
                  style={{ paddingRight: xlUp ? "7%" : "5%", height: "100%" }}
                >
                  <ButtonBase
                    onClick={() => {
                      if (isMarketplacePlan(user)) {
                        setOpenPlanUpgradeMsgDialog(true);
                      } else {
                        history.push("/app/serviceprovider/clients/reviews");
                      }
                    }}
                  >
                    <Typography style={{ fontSize: xlUp ? 14 : 12 }}>
                      View All
                    </Typography>
                  </ButtonBase>
                </Grid>
              </AppBar>
            </div>
          ) : null}
        </TableContainer>
      ) : (
        <NoDataAvailable>
          <Typography
            style={{
              color: "#868DA6",
              fontSize: 11,
              marginTop: -60,
              padding: "0px 24px",
            }}
          >
            Looks like you do not have any client reviews at this time. Click{" "}
            <Link
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (!isOnboardingInComplete(user)) {
                  if (isMarketplacePlan(user)) {
                    setOpenPlanUpgradeMsgDialog(true);
                  } else {
                    setopenMoreTrafficDialog(true);
                  }
                }
              }}
            >
              here
            </Link>{" "}
            to drive more traffic by sharing your storefront
          </Typography>
        </NoDataAvailable>
      )}
    </React.Fragment>
  );
}
