import {
  Divider,
  Grid,
  List,
  ListItemText,
  makeStyles,
  Typography,
  Tooltip,
  Link as MaterialLink,
} from "@material-ui/core";
import { ListItemButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import Styles from "../../../Styles.module.css";
import {
  isMarketplacePlan,
  isMobileDevice,
  isTabletDevice,
} from "../../../utils/AppUtils";
import { useCampaignAnalytics } from "souqh-react-redux-hooks/serviceProvider/marketingCampaign/useCampaignAnalytics";

let analyticsFields = [
  {
    section: "Exposure",
    headings: [
      {
        title: "Accounts Reached",
        field: "accReached",
        tooltip:
          "Number of Home-buyers eligible to view the marketing campaign",
      },
      {
        title: "Impressions",
        field: "impressions",
        tooltip:
          "Number of times Storefront tile got displayed on Dashboard, Marketplace and Website",
      },
    ],
    src: "/app/images/AccountsReached.svg",
  },
  {
    section: "INTERACTIONS",
    headings: [
      {
        title: "Clicks",
        field: "clickThroughRate",
        tooltip: "Number of clicks received on the storefront page",
      },
      {
        title: "Leads",
        field: "leads",
        tooltip:
          "Total number of leads during start and end date of the campaign",
      },
    ],
    src: "/app/images/InteractionIcon.svg",
  },
  {
    section: "PROMOTION",
    headings: [
      {
        title: "Amount Spent",
        field: "amountSpend",
        tooltip: "Total cost for marketing campaign",
      },
      {
        title: "Cost per Impression",
        field: "costPerImpression",
        tooltip: "Total cost / Total number of impressions",
      },
    ],
    unit: "$",
    src: "/app/images/Promotion.svg",
  },
];

function groupByField(list, field) {
  return list.reduce(function (results, item) {
    results[item[field]] = item;
    return results;
  }, {});
}

const useStyles = makeStyles((theme) => ({
  selectedListItem: {
    backgroundColor: "#0E1C4E0A !important",
    color: "#FA7E61 !important",
  },
  listText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: 500,
    maxWidth: "14.25rem",
  },
  list: {
    overflow: "auto",
    height: "15.25rem",
  },
  heading: {
    padding: "0.5rem 1rem 0",
  },
  rightSection: {
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
  },
}));

export default function MarketingCampaignsWidget({
  setNewCampaignDialog,
  openPlanUpgradeMsgDialog,
  setOpenPlanUpgradeMsgDialog,
  userInfo,
}) {
  const classes = useStyles();
  const [selectedCampaign, setSelectedCampaign] = useState("");

  const { analytics, getCampaignAnalytics } = useCampaignAnalytics();

  const [analyticsByName, setAnalyticsByName] = useState({});

  useEffect(() => {
    getCampaignAnalytics();
  }, []);

  useEffect(() => {
    const groupedData = groupByField(analytics, "name");
    const selected = Object.keys(groupedData)[0];
    setAnalyticsByName(groupedData);
    setSelectedCampaign(selected);
  }, [analytics]);

  return (
    <Grid
      container
      item
      xs={12}
      style={
        isMobileDevice()
          ? {
              display: "flex",
              flexWrap: "nowrap",
              overflowX: "auto",
            }
          : {}
      }
    >
      {analytics.length ? (
        <>
          <Grid
            item
            xs={4}
            style={{
              minWidth: isMobileDevice() ? 150 : isTabletDevice() && 190,
            }}
          >
            <Typography
              classes={{ root: classes.heading }}
              className={Styles.navyBlueColor}
              variant="subtitle1"
            >
              Recent Campaigns
            </Typography>
            <List
              classes={{ root: classes.list }}
              component="nav"
              style={{ padding: isMobileDevice() && 0 }}
              className="hideScroll"
            >
              {Object.keys(analyticsByName).map((name) => (
                <Tooltip key={name} title={name}>
                  <ListItemButton
                    selected={selectedCampaign === name}
                    onClick={() => {
                      if (isMarketplacePlan(userInfo)) {
                        setOpenPlanUpgradeMsgDialog(true);
                      } else {
                        setSelectedCampaign(name);
                      }
                    }}
                    style={{ paddingBottom: 0 }}
                    className={clsx(Styles.navyBlueColorForce, {
                      [classes.selectedListItem]: selectedCampaign === name,
                    })}
                  >
                    <ListItemText
                      primary={name}
                      classes={{ primary: classes.listText }}
                      primaryTypographyProps={{ variant: "subtitle1" }}
                    />
                  </ListItemButton>
                </Tooltip>
              ))}
            </List>
          </Grid>
          <Divider orientation="vertical" />
          <Grid
            classes={{ root: classes.rightSection }}
            style={{
              padding: "1.5rem 1rem",
              minWidth: isMobileDevice() ? 500 : isTabletDevice() && 510,
            }}
          >
            {analyticsFields.map((item, index) => (
              <Grid
                key={item.section}
                container
                alignItems="center"
                item
                xs={4}
                style={{
                  minWidth: isMobileDevice() ? 100 : isTabletDevice() && 140,
                  borderRight: index < 2 && "2px solid #0E1C4E1F",
                }}
              >
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Grid item xs={12}>
                    <img
                      src={item.src}
                      style={{ width: "1.5rem", height: "1.5rem" }}
                    />
                  </Grid>
                  <Typography
                    className={Styles.navyBlueColor}
                    style={{ fontSize: 12, fontWeight: "500" }}
                  >
                    {item.section}
                  </Typography>
                </Grid>
                {item.headings.map((heading) => (
                  <Grid
                    key={heading.title}
                    item
                    xs={12}
                    style={{ textAlign: "center" }}
                  >
                    <Tooltip title={heading.tooltip}>
                      <Typography
                        className={Styles.navyBlueColor}
                        style={{ fontSize: 11 }}
                      >
                        {heading.title}
                      </Typography>
                    </Tooltip>
                    <Grid item>
                      <Typography
                        className={Styles.navyBlueColor}
                        style={{ display: "inline-flex" }}
                        variant="h6"
                      >
                        {`${item.unit || ""}  ${
                          analyticsByName[selectedCampaign]?.[heading.field] ||
                          0
                        }`}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            item
            xs={12}
            md={3}
            style={{ textAlign: isMobileDevice() && "center" }}
          >
            <img
              alt="no-result-found"
              src="/app/images/no-result-found-blue.svg"
              style={{
                height: isMobileDevice() && 121,
                width: isMobileDevice() && 124,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
            <Typography
              style={{
                color: "#868DA6",
                fontSize: 11,
                padding: isMobileDevice() && "0px 24px",
                marginTop: isMobileDevice() && -60,
              }}
            >
              Looks like you do no have any marketing campaigns running at this
              time. Click{" "}
              <MaterialLink
                style={{ cursor: "pointer" }}
                onClick={setNewCampaignDialog}
              >
                here
              </MaterialLink>{" "}
              to drive more traffic by marketing your storefront
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
