import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Divider,
  makeStyles,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";
import { useDeactivateAccount } from "souqh-react-redux-hooks/settings/accountPreferences/useDeactivateAccount";
import InviteCheck from "../InviteCheck";
import { useLogout } from "souqh-react-redux-hooks/useLogin";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 637,
    width: 800,
  },
}));

function DeactivateAccountDialog({
  openDeactivateAccountDialog,
  setOpenDeactivateAccountDialog,
  setDeactivateAccount,
}) {
  const classes = useStyles();
  const { logout } = useLogout();
  let history = useHistory();
  const {
    deactivateAccount,
    email,
    setEmail,
    reason,
    setReason,
    reasonValue,
    setReasonValue,
    clearData,
    allowSubmit,
    validations,
  } = useDeactivateAccount();

  let reasons = [
    { id: 1, value: "Not getting enough value from paid plans" },
    { id: 2, value: "The tools/features are not working for me" },
    { id: 3, value: "Don't have time to use this platform" },
  ];

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        open={openDeactivateAccountDialog}
        // disableBackdropClick
        onClose={(event, reason) => {
          if (reason === "escapeKeyDown" || reason === "backdropClick") {
            return;
          }
          setOpenDeactivateAccountDialog(false);
        }}
        maxWidth={"md"}
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <DialogTitle className={Styles.navyBlueColor}>
          <Typography variant="subtitle1">Deactivate Account</Typography>
        </DialogTitle>
        <Divider style={{ marginBottom: 10 }} />
        <DialogContent>
          <Grid container className={Styles.navyBlueColor}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Reasons for deactivating your account
              </Typography>
            </Grid>
            <FormGroup
              onChange={(e) => {
                setReasonValue(e.target.value);
                setReason(e.target.name);
              }}
            >
              {reasons.map((el, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      color={Styles.navyBlueColorForce}
                      value={el.id}
                      name={el.value}
                      checked={reason === el.value && reasonValue == el.id}
                      // onClick={(e) => {
                      //   if (e.target.checked) {
                      //     setReason(e.target.value);
                      //   }
                      // }}
                    />
                  }
                  label={el.value}
                />
              ))}
            </FormGroup>
            <Grid item xs={12} style={{ paddingBottom: 16 }}>
              <TextField
                multiline
                placeholder="Add comments"
                required
                rows={7}
                rowsMax={7}
                variant="outlined"
                fullWidth
                inputProps={{
                  className: Styles.navyBlueColor,
                  maxLength: 500,
                }}
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value);
                }}
              />
              <Grid container item justifyContent="flex-end">
                <Typography variant="body2" style={{ color: "#17174C99" }}>
                  {reason.length}/500
                </Typography>
              </Grid>
            </Grid>
            <Typography variant="subtitle1">
              To deactivate, please enter your Email ID
            </Typography>
            <TextField
              placeholder="Email ID"
              required
              variant="outlined"
              fullWidth
              inputProps={{
                className: Styles.navyBlueColor,
                maxLength: 500,
              }}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              error={!!validations.email}
              helperText={
                validations.email && <InviteCheck validations={validations} />
              }
            />
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="outlined"
            className={Styles.navyBlueColorForce}
            style={{ textTransform: "capitalize", marginRight: 8 }}
            onClick={() => {
              setOpenDeactivateAccountDialog(false);
              setDeactivateAccount(false);
              clearData();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!allowSubmit}
            className={Styles.primaryBtnAccent}
            style={{
              textTransform: "capitalize",
              marginRight: 16,
              padding: "6px 24px",
            }}
            onClick={() => {
              deactivateAccount((res) => {
                if (res.data.status === 200) {
                  setOpenDeactivateAccountDialog(false);
                  clearData();
                  logout((res) => {
                    if (res.data.status === 200) {
                      history.push("/app/login");
                    }
                  });
                }
              });
            }}
          >
            Deactivate
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default DeactivateAccountDialog;
