import {
  Grid,
  TableRow,
  TableCell,
  Typography,
  ButtonBase,
  Tooltip,
} from "@material-ui/core";
import React, { useState } from "react";
import Styles from "../../../Styles.module.css";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import MoreOptionsOnClientInfo from "./MoreOptionsOnClientInfo";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import RejectionReasonDialog from "./RejectionReasonDialog";
import * as moment from "moment";
import { useLeadsClientInfoCard } from "souqh-react-redux-hooks/serviceProvider/clientManagement/useLeadsClientInfoCard";
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import Attachments from "../../HomeBuyer/SPManagement/Attachments";
import DocumentPreviewDialog from "../../HomeBuyer/Explore/StorefrontPage/DocumentPreviewDialog";
import {
  getUploadedDocuments,
  viewClientDetails,
} from "../../../utils/AppUtils";
import clsx from "clsx";
import ServiceRequestFulfilledDialog from "./ServiceRequestFulfilledDialog";
import { useNavigateToMessage } from "souqh-react-redux-hooks/common/useNavigateToMessage";
import { useMessaging } from "souqh-react-redux-hooks/messaging/useMessaging";

export default function LeadsClientTableRow({
  data,
  index,
  getClientManagementData,
}) {
  const history = useHistory();
  const { navigateMessage } = useNavigateToMessage();
  const { requestServiceMessageFromBackground } = useMessaging(false);

  let { path } = useRouteMatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openRejectionReasonDialog, setOpenRejectionReasonDialog] =
    useState(false);
  const [openDocumetDialog, setOpenDocumetDialog] = useState(false);
  const [documentItem, setDocumentItem] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [docCategoryId, setDocCategoryId] = useState(null);
  const [docNameForDownload, setDocNameForDownload] = useState(null);
  const [docUserId, setDocUserId] = useState(null);
  const [
    openServiceRequestFulfilledDialog,
    setOpenServiceRequestFulfilledDialog,
  ] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const { accpetServiceRequest } = useLeadsClientInfoCard();

  return (
    <React.Fragment>
      <ServiceRequestFulfilledDialog
        openServiceRequestFulfilledDialog={openServiceRequestFulfilledDialog}
        setOpenServiceRequestFulfilledDialog={
          setOpenServiceRequestFulfilledDialog
        }
      />
      <RejectionReasonDialog
        openRejectionReasonDialog={openRejectionReasonDialog}
        setOpenRejectionReasonDialog={setOpenRejectionReasonDialog}
        getClientManagementData={getClientManagementData}
        title={"Decline Request"}
        message={`Lead Rejected - ${data.name}`}
        actionType="REJECT_LEAD_REQUEST"
      />
      <MoreOptionsOnClientInfo
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        showTagMemberOption={true}
        showSendMessageOption={true}
        email={data.email}
        notes={data.notes}
        serviceRequestId={data.serviceRequestId}
        toUserId={data.hbDetails.userId}
      />
      <DocumentPreviewDialog
        openDocumetDialog={openDocumetDialog}
        setOpenDocumetDialog={setOpenDocumetDialog}
        documentItem={documentItem}
        setDocumentItem={setDocumentItem}
        downloadUrl={downloadUrl}
        source="leads-page"
        docCategoryId={docCategoryId}
        docNameForDownload={docNameForDownload}
        docUserId={docUserId}
      />
      <TableRow>
        <TableCell>
          <Typography className={Styles.navyBlueColor}>{index + 1}</Typography>
        </TableCell>
        <TableCell
          container
          onClick={(event) => {
            if (!data.broadCastServiceRequestDto) {
              viewClientDetails(history, path, data);
            }
          }}
          style={{ cursor: !data.broadCastServiceRequestDto && "pointer" }}
        >
          <Grid container>
            <Grid item xs={4}>
              <img
                src={data.avatarUrl}
                style={{
                  height: 50,
                  border: "1px solid #0E1C4E1F",
                  borderRadius: 4,
                  marginRight: 10,
                }}
              />
            </Grid>
            <Grid container item xs={8} alignContent="center">
              <Typography
                variant="body1"
                className={Styles.navyBlueColor}
                style={{ fontWeight: 600 }}
              >
                {data.hbDetails.name}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
        {/* <TableCell>
          <Typography style={{ textTransform: "uppercase" }}>
            <ClientStatus clientStatus={data.status || "VIEWED"} />
          </Typography>
        </TableCell> */}
        <TableCell>
          <Typography className={Styles.navyBlueColor}>
            {moment(data.requestedDate).format("MMM DD, YYYY")}
          </Typography>
        </TableCell>
        <TableCell>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Tooltip title={data.notes}>
                <Typography
                  className={clsx(
                    Styles.navyBlueColor,
                    Styles.sqEllipsisTwoLines
                  )}
                >
                  {data.notes || "No details available"}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              {data.attachments && data.attachments.length ? (
                <Attachments
                  attachments={data.attachments}
                  limit={1}
                  setDocumentItem={setDocumentItem}
                  getUploadedDocuments={getUploadedDocuments}
                  setOpenDocumetDialog={setOpenDocumetDialog}
                  setDownloadUrl={setDownloadUrl}
                  setDocCategoryId={setDocCategoryId}
                  setDocNameForDownload={setDocNameForDownload}
                  setDocUserId={setDocUserId}
                />
              ) : (
                <Typography
                  className={clsx(Styles.navyBlueColor, Styles.font14)}
                  style={{ fontStyle: "italic" }}
                >
                  No Attachments
                </Typography>
              )}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Grid container item alignItems="center">
            <Grid item xs={6} container>
              <Tooltip title="Accept">
                <CheckCircleIcon
                  style={{
                    color:
                      !data.broadCastServiceRequestDto ||
                      (data.broadCastServiceRequestDto &&
                        data.broadCastServiceRequestDto?.status === "ACTIVE")
                        ? "#5cd39c"
                        : "#868da6",
                    fontSize: 32,
                    cursor:
                      // (!data.broadCastServiceRequestDto ||
                      //   (data.broadCastServiceRequestDto &&
                      //     data.broadCastServiceRequestDto?.status ===
                      //       "ACTIVE")) &&
                      "pointer",
                  }}
                  onClick={(event) => {
                    if (
                      !data.broadCastServiceRequestDto ||
                      (data.broadCastServiceRequestDto &&
                        data.broadCastServiceRequestDto?.status === "ACTIVE")
                    ) {
                      accpetServiceRequest(data.serviceRequestId, (res) => {
                        if (res && res.data.status === 200) {
                          getClientManagementData();
                          // if (data.broadCastServiceRequestDto) {
                          //   requestServiceMessageFromBackground(
                          //     data.email,
                          //     data.notes,
                          //     []
                          //     // data.attachments
                          //   ).then(() =>
                          //     navigateMessage(event, data.email, true)
                          //   );
                          // } else {
                            // viewClientDetails(history, path, data);
                            navigateMessage(event, data.email, true);
                          // }
                        }
                      });
                    } else {
                      setOpenServiceRequestFulfilledDialog(true);
                    }
                  }}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={6} container>
              <Tooltip title="Reject">
                <CancelIcon
                  style={{ color: "#f04f5b", fontSize: 32, cursor: "pointer" }}
                  onClick={() =>
                    setOpenRejectionReasonDialog({
                      open: true,
                      id: data.serviceRequestId,
                    })
                  }
                />
              </Tooltip>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <ButtonBase>
            <MoreHorizIcon
              onClick={handleClick}
              className={Styles.navyBlueColor}
            />
          </ButtonBase>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
