import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "souqh-redux";
import { Provider } from "react-redux";
import configureAmplify from "./utils/chimeServicesConfig";
import "emoji-mart/css/emoji-mart.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import * as FullStory from "@fullstory/browser";

configureAmplify();
// Comment this line for dev and qa env
FullStory.init({ orgId: "14X9B3" });

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
