import {
  IconButton,
  makeStyles,
  Grid,
  Avatar,
  Tooltip,
  Drawer,
  useTheme,
  useMediaQuery,
  Divider,
  Badge,
} from "@material-ui/core";
import MessageIcon from "@material-ui/icons/Message";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import ProfileMenuDialog from "./ProfileMenuDialog";
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSideDrawer } from "souqh-react-redux-hooks/homeBuyer/useSideDrawer";
import clsx from "clsx";
import { useLocation } from "react-router";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PersonIcon from "@material-ui/icons/Person";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import StoreIcon from "@material-ui/icons/Store";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { useSelector } from "react-redux";
import Styles from "../Styles.module.css";
import NotificationsIcon from "@material-ui/icons/Notifications";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AppsIcon from "@material-ui/icons/Apps";
import Notifications from "./Notifications";
import {
  isMarketplacePlan,
  isMobileDevice,
  isOnboardingInComplete,
  isProPlan,
  isTabletDevice,
} from "../utils/AppUtils";
import MobileBottomNavigation from "../mobileComponents/ServiceProvider/MobileBottomNavigation";
import SearchIcon from "@material-ui/icons/Search";
import UpgradePlanIconSection from "./common/UpgradePlanIconSection";

const drawerWidth = 80;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& .MuiIconButton-root.Mui-disabled": {
      color: "grey",
    },
  },
  grid: {
    marginBottom: 20,
  },
  listItem: {
    textTransform: "capitalize",
    color: "white",
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#17174c",
    flexShrink: 0,
    whiteSpace: "nowrap",
    color: "white",
    overflow: "hidden",
    height: "100%",
    // borderRadius: "0 8px 8px 0",
  },
  marginTopLarge: {
    marginTop: "1.5rem",
  },
  brandIcon: {
    marginTop: "1rem",
  },
  icon: {
    padding: "0 12px",
    width: "1.5em !important",
    height: "1.5em !important",
  },
  selectedIcon: {
    color: "#FA7E61",
  },
  tooltip: {
    backgroundColor: "#FA7E61",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  arrow: {
    color: "#FA7E61",
  },
  marketingIcon: {
    width: "1em",
    height: "1em",
  },
  marketingIcon_small: {
    width: "1.25rem",
    height: "1.25rem",
  },
  customBadge: {
    backgroundColor: "#FA7E61",
  },
}));
export default function LeftNavBar() {
  const history = useHistory();
  const classes = useStyles();
  const spnotificationRef = useRef();
  const { activeSection, setActiveSection } = useSideDrawer();
  let location = useLocation();
  let locationParam = location.pathname.split("/"); // read URL and split it on "/"
  let activeSectionFromURL = locationParam[locationParam.length - 1]; // take last section from URL
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
  const user = useAuthUser();
  const theme = useTheme();
  const unreadChannels = useSelector((state) => state.messaging.unreadChannels);
  const { notificationsList } = useSelector((state) => state.notifications);
  const mdUp = useMediaQuery(theme.breakpoints.up("xl"));
  const mdDown = useMediaQuery(theme.breakpoints.down("lg"));
  let storeFrontDetails = useSelector((state) => state.storefront);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationsClick = (event) => {
    setNotificationsAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationsClose = () => {
    setNotificationsAnchorEl(null);
    if (localStorage.getItem("showNotification")) {
      localStorage.setItem("showNotification", false);
    }
  };
  useEffect(() => {
    if (activeSectionFromURL === "serviceprovider") {
      setActiveSection("dashboard");
    } else {
      setActiveSection(activeSectionFromURL);
    }
  }, [location]);

  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("showNotification")) &&
      notificationsList.response &&
      notificationsList.response.length
    ) {
      setNotificationsAnchorEl(spnotificationRef.current);
    }
  }, [notificationsList]);

  const unreadNotifications = notificationsList.response.filter(
    (el) => el.isRead === false
  );

  return (
    <React.Fragment>
      <Notifications
        notificationsAnchorEl={notificationsAnchorEl}
        handleNotificationsClose={handleNotificationsClose}
      />
      <ProfileMenuDialog anchorEl={anchorEl} handleClose={handleClose} />
      {!isMobileDevice() && !isTabletDevice() ? (
        <div>
          <Drawer
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
          >
            <Grid container item xs={12}>
              <Grid
                container
                item
                xs={12}
                justifyContent="center"
                alignContent="flex-start"
                classes={{
                  root: classes.root,
                }}
              >
                <IconButton color="inherit">
                  <img
                    style={{ height: 40, width: 40, marginTop: 16 }}
                    src="/app/images/Only-Logo– white-line.svg"
                    alt="logo-white-line"
                    title="logo-white-line"
                  />
                </IconButton>
                <Divider
                  orientation="horizontal"
                  style={{
                    backgroundColor: "#FFFFFF1F",
                    width: "60%",
                    height: 2,
                  }}
                />
                <IconButton
                  color="inherit"
                  onClick={() => {
                    history.push(`/app/serviceprovider/dashboard`);
                    setActiveSection("dashboard");
                  }}
                  className={clsx({
                    [classes.marginTopLarge]: mdUp,
                  })}
                >
                  <Tooltip
                    title="Dashboard"
                    placement="right-start"
                    classes={{
                      tooltip: classes.tooltip,
                      arrow: classes.arrow,
                    }}
                    arrow
                  >
                    <DashboardIcon
                      className={clsx({
                        [classes.selectedIcon]: activeSection === "dashboard",
                      })}
                      classes={{
                        root: clsx({
                          [classes.icon]: mdUp,
                        }),
                      }}
                    />
                  </Tooltip>
                </IconButton>
                <IconButton
                  color="inherit"
                  onClick={() => {
                    history.push(`/app/serviceprovider/storefront`);
                    setActiveSection("storefront");
                  }}
                  className={clsx({
                    [classes.marginTopLarge]: mdUp,
                  })}
                >
                  <Tooltip
                    title="Storefront"
                    placement="right-start"
                    classes={{
                      tooltip: classes.tooltip,
                      arrow: classes.arrow,
                    }}
                    arrow
                  >
                    <StoreIcon
                      className={clsx({
                        [classes.selectedIcon]: activeSection === "storefront",
                      })}
                      classes={{
                        root: clsx({
                          [classes.icon]: mdUp,
                        }),
                      }}
                    />
                  </Tooltip>
                </IconButton>
                <IconButton
                  disabled={
                    isOnboardingInComplete(user) || isMarketplacePlan(user)
                  }
                  color="inherit"
                  onClick={() => {
                    history.push(`/app/serviceprovider/clients`);
                    setActiveSection("customer");
                  }}
                  className={clsx({
                    [classes.marginTopLarge]: mdUp,
                  })}
                >
                  <Tooltip
                    title="Clients"
                    placement="right-start"
                    classes={{
                      tooltip: classes.tooltip,
                      arrow: classes.arrow,
                    }}
                    arrow
                  >
                    <PersonIcon
                      className={clsx({
                        [classes.selectedIcon]: activeSection === "clients",
                      })}
                      classes={{
                        root: clsx({
                          [classes.icon]: mdUp,
                        }),
                      }}
                    />
                  </Tooltip>
                </IconButton>
                <IconButton
                  disabled={
                    isOnboardingInComplete(user) || isMarketplacePlan(user)
                  }
                  color="inherit"
                  onClick={() => {
                    history.push(`/app/serviceprovider/documents`);
                    setActiveSection("documents");
                  }}
                  className={clsx({
                    [classes.marginTopLarge]: mdUp,
                  })}
                >
                  <Tooltip
                    title="Documents"
                    placement="right-start"
                    classes={{
                      tooltip: classes.tooltip,
                      arrow: classes.arrow,
                    }}
                    arrow
                  >
                    <InsertDriveFileIcon
                      className={clsx({
                        [classes.selectedIcon]: activeSection === "documents",
                      })}
                      classes={{
                        root: clsx({
                          [classes.icon]: mdUp,
                        }),
                      }}
                    />
                  </Tooltip>
                </IconButton>
                <IconButton
                  disabled={
                    isOnboardingInComplete(user) || isMarketplacePlan(user)
                  }
                  color="inherit"
                  onClick={() => {
                    if (!isProPlan(user)) {
                      history.push(`/app/serviceprovider/calendar`);
                      setActiveSection("calendar");
                    }
                  }}
                  className={clsx({
                    [classes.marginTopLarge]: mdUp,
                  })}
                  style={{ color: isProPlan(user) && "grey" }}
                >
                  <Tooltip
                    title="Calendar"
                    placement="right-start"
                    classes={{
                      tooltip: classes.tooltip,
                      arrow: classes.arrow,
                    }}
                    arrow
                    disableHoverListener={isProPlan(user)}
                  >
                    <div style={{ position: "relative" }}>
                      <DateRangeIcon
                        className={clsx({
                          [classes.selectedIcon]: activeSection === "calendar",
                        })}
                        classes={{
                          root: clsx({
                            [classes.icon]: mdUp,
                          }),
                        }}
                      />
                      {isProPlan(user) && (
                        <UpgradePlanIconSection
                          iconBtnStyle={{
                            position: "absolute",
                            fontSize: 16,
                            left: 14,
                            top: -16,
                            fill: "#fa7e61",
                          }}
                          iconStyle={{
                            position: "absolute",
                            fontSize: 16,
                            top: 12,
                            fill: "#fa7e61",
                          }}
                        />
                      )}
                    </div>
                  </Tooltip>
                </IconButton>
                <IconButton
                  disabled={
                    isOnboardingInComplete(user) || isMarketplacePlan(user)
                  }
                  color="inherit"
                  onClick={() => {
                    history.push(`/app/serviceprovider/marketing`);
                    setActiveSection("marketing");
                  }}
                  className={clsx({
                    [classes.marginTopLarge]: mdUp,
                  })}
                  style={{
                    opacity:
                      isOnboardingInComplete(user) || isMarketplacePlan(user)
                        ? 0.5
                        : 1,
                  }}
                >
                  <Tooltip
                    title="Marketing"
                    placement="right-start"
                    classes={{
                      tooltip: classes.tooltip,
                      arrow: classes.arrow,
                    }}
                    arrow
                  >
                    <img
                      src={
                        activeSection === "marketing"
                          ? "/app/images/megaphone_salmon.svg"
                          : "/app/images/megaphone.svg"
                      }
                      alt="megaphone"
                      title="megaphone"
                      className={clsx({
                        [classes.selectedIcon]: activeSection === "marketing",
                        [classes.marketingIcon]: mdUp,
                        [classes.marketingIcon_small]: mdDown,
                      })}
                    />
                  </Tooltip>
                </IconButton>
                <IconButton
                  disabled={
                    isOnboardingInComplete(user) || isMarketplacePlan(user)
                  }
                  color="inherit"
                  onClick={() => {
                    history.push(`/app/serviceprovider/explore`);
                    setActiveSection("explore");
                  }}
                  className={clsx({
                    [classes.marginTopLarge]: mdUp,
                  })}
                >
                  <Tooltip
                    title="Marketplace"
                    placement="right-start"
                    classes={{
                      tooltip: classes.tooltip,
                      arrow: classes.arrow,
                    }}
                    arrow
                  >
                    <SearchIcon
                      className={clsx({
                        [classes.selectedIcon]: activeSection === "explore",
                      })}
                      classes={{
                        root: clsx({
                          [classes.icon]: mdUp,
                        }),
                      }}
                    />
                  </Tooltip>
                </IconButton>
              </Grid>
              <Grid
                container
                item
                justifyContent="center"
                alignContent="flex-end"
                classes={{
                  root: classes.root,
                }}
              >
                <IconButton
                  disabled={
                    isOnboardingInComplete(user) || isMarketplacePlan(user)
                  }
                  ref={spnotificationRef}
                  color="inherit"
                  onClick={handleNotificationsClick}
                  className={clsx({
                    [classes.marginTopLarge]: mdUp,
                  })}
                >
                  <Tooltip
                    title="Notifications"
                    placement="right-start"
                    classes={{
                      tooltip: classes.tooltip,
                      arrow: classes.arrow,
                    }}
                    arrow
                  >
                    <Badge
                      variant="dot"
                      classes={{ badge: classes.customBadge }}
                      invisible={unreadNotifications.length ? false : true}
                    >
                      <NotificationsIcon
                        classes={{
                          root: clsx({
                            [classes.icon]: mdUp,
                          }),
                        }}
                      />
                    </Badge>
                  </Tooltip>
                </IconButton>
                <IconButton
                  disabled={
                    isOnboardingInComplete(user) || isMarketplacePlan(user)
                  }
                  color="inherit"
                  onClick={() => {
                    history.push(`/app/serviceprovider/messaging`);
                    setActiveSection("messaging");
                  }}
                >
                  <Tooltip
                    title="Messages"
                    placement="right-start"
                    classes={{
                      tooltip: classes.tooltip,
                      arrow: classes.arrow,
                    }}
                    arrow
                    className={clsx({
                      [classes.selectedIcon]: activeSection === "messaging",
                    })}
                  >
                    <div style={{ position: "relative" }}>
                      <MessageIcon />
                      {unreadChannels && unreadChannels.length ? (
                        <FiberManualRecordIcon
                          style={{
                            position: "absolute",
                            fontSize: 16,
                            left: 16,
                            top: -4,
                            fill: "#fa7e61",
                          }}
                          className={Styles.msgUpdateIndicator}
                        />
                      ) : null}
                    </div>
                  </Tooltip>
                </IconButton>
                <IconButton
                  disabled={
                    isOnboardingInComplete(user) || isMarketplacePlan(user)
                  }
                  color="inherit"
                  onClick={() => {
                    history.push(`/app/serviceprovider/apps`);
                    setActiveSection("apps");
                  }}
                >
                  <Tooltip
                    title="Apps"
                    placement="right-start"
                    classes={{
                      tooltip: classes.tooltip,
                      arrow: classes.arrow,
                    }}
                    arrow
                    className={clsx({
                      [classes.selectedIcon]: activeSection === "apps",
                    })}
                  >
                    <AppsIcon />
                  </Tooltip>
                </IconButton>
                <IconButton
                  color="inherit"
                  onClick={handleClick}
                  className={clsx({
                    [classes.marginTopLarge]: mdUp,
                  })}
                  style={{ marginBottom: "10px" }}
                >
                  <Tooltip
                    title="Profile"
                    placement="right-start"
                    classes={{
                      tooltip: classes.tooltip,
                      arrow: classes.arrow,
                    }}
                    arrow
                  >
                    <Avatar
                      src={user.spProfilePicture || "/broken-image.jpg"}
                      style={{ width: 25, height: 25 }}
                    />
                  </Tooltip>
                </IconButton>
              </Grid>
            </Grid>
          </Drawer>
        </div>
      ) : (
        <MobileBottomNavigation />
      )}
    </React.Fragment>
  );
}
