import { Divider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import Styles from "../../Styles.module.css";
import clsx from "clsx";
import arnParser from "../../utils/arnParser";
import { awsChimeConfigs } from "../../chimeConfig";
import { IMAGE_FILE_FORMATS } from "../HBRegistration/HomeBuyerConstants";

export default function AttachmentProcessor({
  fileName,
  fileKey,
  senderId,
  downloadAttachment,
}) {
  const [fileUrl, setFileUrl] = useState("");
  useEffect(async () => {
    if (fileKey && senderId) {
      const url = await downloadAttachment(
        fileKey,
        `${awsChimeConfigs.region}:${arnParser(senderId).relativeValue}`
      );
      setFileUrl(url);
    }
  }, [fileKey, senderId]);

  if (!fileName || !fileKey) {
    return null;
  }
  let innerElem = null;
  const fileExtension = fileName && fileName.split(".").pop();
  if (IMAGE_FILE_FORMATS.includes(fileExtension)) {
    innerElem = (
      <img
        src={fileUrl}
        style={{ width: 300, height: 200, backgroundColor: "#ffffff" }}
        alt="file"
        title="file"
      />
    );
  } else {
    innerElem = (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textDecoration: "none",
        }}
      >
        <div>
          <InsertDriveFileIcon style={{ fontSize: 48, fill: "#0e1c4e" }} />
        </div>
        <div
          className={clsx(Styles.sqEllipsis, Styles.navyBlueColor)}
          style={{ paddingBottom: 12 }}
        >
          {fileName}
        </div>
      </div>
    );
  }
  return (
    <div
      style={{
        width: "100%",
        marginBottom: 12,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <a
        target="_blank"
        href={fileUrl}
        download={fileName}
        style={{ textDecoration: "none" }}
      >
        {innerElem}
        <Divider
          orientation="horizontal"
          style={{
            backgroundColor: "#ffffff",
            width: "100%",
            height: 1,
            transform: "scale(1.5, 1)",
          }}
        />
      </a>
    </div>
  );
}
