import {
  Grid,
  Typography,
  Paper,
  useTheme,
  useMediaQuery,
  ButtonBase,
  AppBar,
  TableContainer,
} from "@material-ui/core";
import React from "react";
import Styles from "../../../Styles.module.css";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { useHistory } from "react-router";
import { PLUS, PRO } from "../serviceProviderConstants";
import {
  isMarketplacePlan,
  isMobileDevice,
  isOnboardingInComplete,
} from "../../../utils/AppUtils";

export default function StoreFrontWidget({
  setopenMoreTrafficDialog,
  services,
  displayPicture,
  storefrontName,
  userInfo,
  openPlanUpgradeMsgDialog,
  setOpenPlanUpgradeMsgDialog,
}) {
  const theme = useTheme();
  const history = useHistory();

  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  const user = useAuthUser();

  return (
    <React.Fragment>
      <TableContainer
        component={Paper}
        elevation={0}
        className="hideScroll"
        style={{ overflowX: "hidden" }}
      >
        <Paper
          elevation={0}
          style={{
            padding: "8px 8px 32px 8px",
          }}
        >
          <Grid container item spacing={2}>
            <Grid item xs={3}>
              <img
                style={{
                  height: 60,
                  width: 60,
                  border: "1px solid #0E1C4E1F",
                  borderRadius: 4,
                }}
                src={displayPicture || "/app/images/logo.svg"}
              />
            </Grid>
            <Grid item xs={9} style={{ paddingLeft: 20 }}>
              <Typography
                className={Styles.navyBlueColor}
                variant={isMobileDevice() || xlUp ? "body1" : "body2"}
                style={{ fontWeight: 500 }}
              >
                {storefrontName}
              </Typography>
              <Typography
                className={Styles.navyBlueColor}
                style={{
                  fontSize: isMobileDevice() || xlUp ? 14 : 10,
                  fontStyle: "italic",
                }}
              >
                {user.businessTypes && user.businessTypes.length
                  ? user.businessTypes[0].name === "Other"
                    ? user.businessTypes[0].otherBusinessName
                    : user.businessTypes[0].name
                  : ""}
              </Typography>
              {/* <Grid container>
                <StorefrontSocialLinks
                  socialLinks={webPresence.socialLinks}
                ></StorefrontSocialLinks>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item style={{ marginTop: 10 }}>
            <Typography
              className={Styles.navyBlueColor}
              style={{
                fontSize: isMobileDevice() || xlUp ? 14 : 10,
                fontWeight: 500,
              }}
            >
              Products / Services
            </Typography>
          </Grid>
          {services && services.length
            ? services.map((data, index) => (
                <Grid container item alignItems="center" key={index}>
                  <Grid item>
                    <CreditCardIcon
                      className={Styles.navyBlueColor}
                      style={{ fontSize: 15 }}
                    />
                  </Grid>
                  <Grid item style={{ paddingLeft: 5 }}>
                    <Typography
                      className={Styles.navyBlueColor}
                      style={{ fontSize: isMobileDevice() || xlUp ? 14 : 10 }}
                    >
                      {data.name}
                    </Typography>
                  </Grid>
                </Grid>
              ))
            : null}

          {!isOnboardingInComplete(user) ? (
            <Grid
              container
              onClick={() => {
                if (!isOnboardingInComplete(user)) {
                  if (isMarketplacePlan(userInfo)) {
                    setOpenPlanUpgradeMsgDialog(true);
                  } else {
                    setopenMoreTrafficDialog(true);
                  }
                }
              }}
              style={{ marginTop: 30, marginBottom: 40 }}
              title={
                user.plan === PRO || user.plan === PLUS
                  ? `Souqh Plus Partner`
                  : `Souqh Premium Partner`
              }
            >
              <a style={{ display: "flex" }}>
                <img
                  style={{
                    cursor: "pointer",
                    height: xlUp ? 40 : 36,
                    width: xlUp ? 40 : 36,
                  }}
                  src={
                    user.plan === PRO || user.plan === PLUS
                      ? `/app/images/teal_badge.svg`
                      : `/app/images/blue_badge.svg`
                  }
                ></img>
                <span
                  style={{
                    textDecoration: "underline",
                    color: "#0e1c4e",
                    fontSize: xlUp ? 14 : 12,
                    width: xlUp ? 140 : "120px",
                    marginLeft: 10,
                    marginTop: 5,
                    cursor: "pointer",
                  }}
                >
                  Drive more traffic at no additional cost!
                </span>
              </a>
            </Grid>
          ) : null}
        </Paper>
        {!isOnboardingInComplete(user) ? (
          <div
            style={{
              position: "sticky",
              alignItems: "flex-end",
              bottom: 0,
              width: "100%",
            }}
          >
            <AppBar
              position="sticky"
              style={{
                top: "auto",
                bottom: 0,
                backgroundColor: "#FFFFFF",
                color: "#FA7E61",
                height: 48,
              }}
            >
              <Grid
                container
                item
                justifyContent="flex-end"
                alignContent="center"
                style={{ paddingRight: "5%", height: "100%" }}
              >
                <ButtonBase
                  onClick={() => {
                    if (user.storefrontId || user.parentServiceProviderId) {
                      window.open(
                        `/app/serviceprovider/storefrontview/${user.businessName.replaceAll(
                          "/",
                          "-"
                        )}?storeFrontId=${
                          user.storefrontId || user.parentServiceProviderId
                        }`,
                        "_blank"
                      );
                    }
                  }}
                >
                  <Typography style={{ fontSize: xlUp ? 14 : 12 }}>
                    View Storefront
                  </Typography>
                </ButtonBase>
              </Grid>
            </AppBar>
          </div>
        ) : null}
      </TableContainer>
    </React.Fragment>
  );
}
