import {
  Grid,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import usePaymentHistory from "souqh-react-redux-hooks/homeBuyer/usePaymentHistory";
import Styles from "../../Styles.module.css";
import ViewDocumentDialog from "../DocumentManagementDialogs/ViewDocumentDialog";
import { getUploadedDocuments } from "../../utils/AppUtils";
import NoDataAvailable from "../common/NoDataAvailable";
import moment from "moment";

const statusMap = {
  requires_payment_method: "Pending",
  succeeded: "Success",
};

const columns = [
  "Sr. No.",
  "Service Provider",
  "Transaction Date",
  "Amount",
  "Invoice",
  "Payment\nStatus",
  "Receipt",
];

const useStyles = makeStyles({
  tableCell: {
    paddingLeft: 0,
  },
  link: {
    width: "9rem",
    cursor: "pointer",
    display: "block",
    color: "#0e1c4e",
  },
  title: {
    width: "9rem",
    cursor: "pointer",
  },
});

const PaymentHistory = () => {
  const classes = useStyles();
  const { getPaymentHistory, payments } = usePaymentHistory();
  const history = useHistory();

  const [openDocumentDialog, setOpenDocumentDialog] = useState(false);
  const [documentItem, setDocumentItem] = useState(null);

  useEffect(() => {
    getPaymentHistory();
  }, []);

  if (payments?.length)
    return (
      <React.Fragment>
        <ViewDocumentDialog
          openDocumetDialog={openDocumentDialog}
          setOpenDocumetDialog={setOpenDocumentDialog}
          documentItem={documentItem}
          setDocumentItem={setDocumentItem}
        />
        <Grid
          item
          xs={12}
          style={{
            height: "100%",
            overflow: "hidden",
            padding: "0 1.5rem 1rem",
          }}
        >
          <Typography variant="h4" className={Styles.navyBlueColor}>
            Payment history
          </Typography>
          <Grid container xs={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column}
                        className={clsx(
                          Styles.navyBlueColorForce,
                          Styles.sqEllipsis
                        )}
                        style={{ fontSize: "1rem", whiteSpace: "pre" }}
                        classes={{ root: classes.tableCell }}
                      >
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payments.map((rowData, index) => (
                    <TableRow key={rowData.id}>
                      <TableCell classes={{ root: classes.tableCell }}>
                        <Typography
                          variant="body1"
                          className={Styles.navyBlueColor}
                        >
                          {index + 1}
                        </Typography>
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCell }}>
                        <Tooltip title={rowData.businessName}>
                          <Link
                            underline="always"
                            onClick={() => {
                              history.push(
                                `/app/homebuyer/serviceproviders/details/${rowData.serviceProviderId}`
                              );
                            }}
                          >
                            <Typography
                              variant="body1"
                              className={clsx(
                                Styles.navyBlueColor,
                                classes.title,
                                Styles.sqEllipsis
                              )}
                            >
                              {rowData.businessName}
                            </Typography>
                          </Link>
                        </Tooltip>
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCell }}>
                        <Typography
                          variant="body1"
                          className={Styles.navyBlueColor}
                        >
                          {moment(new Date(rowData.createdDate)).format(
                            "MMM DD, YYYY"
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCell }}>
                        <Typography
                          variant="body1"
                          className={Styles.navyBlueColor}
                        >
                          ${rowData.amount}
                        </Typography>
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCell }}>
                        <Typography variant="body1">
                          {rowData.invoiceDocumentUrls?.map((file) => (
                            <Tooltip
                              key={file.documentId}
                              title={file.fileName}
                            >
                              <Link
                                underline="always"
                                className={clsx(
                                  classes.link,
                                  Styles.sqEllipsis
                                )}
                                onClick={() => {
                                  setDocumentItem({
                                    ...(getUploadedDocuments(file) || {}),
                                    isReferenceDocument: true,
                                  });
                                  setOpenDocumentDialog(true);
                                }}
                              >
                                {file.fileName}
                              </Link>
                            </Tooltip>
                          ))}
                        </Typography>
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCell }}>
                        <Typography
                          variant="body1"
                          className={Styles.navyBlueColor}
                        >
                          {statusMap[rowData.paymentStatus] || ""}
                        </Typography>
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCell }}>
                        <Typography
                          variant="body1"
                          className={Styles.navyBlueColor}
                        >
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={rowData.invoiceUrl}
                            style={{ color: "#0e1c4e" }}
                          >
                            {rowData.invoiceUrl ? "Receipt" : ""}
                          </a>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </React.Fragment>
    );

  return (
    <NoDataAvailable isMarketingCampaignPage>
      <Typography
        style={{
          color: "#868DA6",
          fontSize: "1rem",
          padding: "0px 24px",
        }}
      >
        Looks like you don’t have any payment history
      </Typography>
    </NoDataAvailable>
  );
};

export default PaymentHistory;
