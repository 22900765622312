import {
  Button,
  ButtonBase,
  Divider,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@material-ui/core";
import React from "react";
import { PaperLikeGrid } from "./ServiceList";
import Styles from "../../../Styles.module.css";
import { useMarketingBrochure } from "souqh-react-redux-hooks/serviceProvider/storefront/useMarketingBrochure";
import CancelIcon from "@material-ui/icons/Cancel";

export default function MarketingBrochure() {
  const {
    marketingRelatedDocs,
    validateFiles,
    maxFileSize,
    maxFileLimit,
    actions: { deleteFile },
  } = useMarketingBrochure();

  return (
    <Grid container xs={12} style={{ marginTop: 20 }}>
      <PaperLikeGrid>
        <Typography
          variant="subtitle1"
          className={Styles.navyBlueColor}
          style={{ padding: "24px 24px 0px 24px" }}
        >
          Upload Marketing Brochure
        </Typography>
        <Divider
          orientation="horizontal"
          variant="fullWidth"
          style={{ width: "100%", marginTop: 20, marginBottom: 15 }}
        />
        {marketingRelatedDocs && marketingRelatedDocs.length ? (
          <Grid item xs={12} style={{ padding: "0px 24px" }}>
            <TableContainer style={{ marginBottom: 20 }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>File Name</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {marketingRelatedDocs.map((document, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {document.name
                          ? document.name
                          : (document || "").startsWith("http")
                          ? decodeURIComponent(
                              (document || "").split("/").pop()
                            )
                          : document}
                      </TableCell>
                      <TableCell align="right">
                        <ButtonBase>
                          <CancelIcon
                            onClick={() => {
                              deleteFile(index);
                            }}
                          />
                        </ButtonBase>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ) : (
          ""
        )}
        {maxFileLimit && (
          <Typography
            variant="body2"
            style={{ marginBottom: 10, color: "red", padding: "0px 24px" }}
          >
            Only 25 documents can be uploaded at once.
          </Typography>
        )}
        {maxFileSize && (
          <Typography
            variant="body2"
            style={{ marginBottom: 10, color: "red", padding: "0px 24px" }}
          >
            File size is restricted to 50 MB only.
          </Typography>
        )}
        <Grid item style={{ padding: "0px 24px 24px 24px" }}>
          <input
            accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .png, .jpeg, .bmp, .gif, .CSV"
            style={{ display: "none" }}
            id="marketing-brochure"
            multiple
            type="file"
            onChange={(event) => {
              validateFiles(event);
            }}
          />
          <label htmlFor="marketing-brochure" style={{ marginBottom: 16 }}>
            <Button
              variant="outlined"
              color="default"
              component="span"
              startIcon={<img src="/app/images/Icon material-file-upload.png" />}
              style={{ textTransform: "capitalize" }}
              onClick={() => {}}
              disabled={
                marketingRelatedDocs && marketingRelatedDocs.length === 25
              }
              className={Styles.navyBlueColorForce}
            >
              Upload
            </Button>
          </label>
          <Typography
            variant="body2"
            style={{ marginTop: 10, color: "#afafc1" }}
          >
            You will be able to upload .doc, .docx, .xls, .xlsx, .ppt, .pptx,
            .pdf, .jpeg, .bmp, .png, .jpg files with maximum supported size of
            50 MB per file
          </Typography>
        </Grid>
      </PaperLikeGrid>
    </Grid>
  );
}
