import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import clsx from "clsx";
import { isMobileDevice } from "../../../utils/AppUtils";

function CloseHomeSteps({ steps, xlUp }) {
  return (
    <React.Fragment>
      {steps.map((data, index) => {
        return (
          <Grid
            item
            xs={2}
            container
            key={index}
            justifyContent="center"
            style={{
              height: isMobileDevice() ? "100%" : "85%",
              minWidth: isMobileDevice() && "40%",
            }}
          >
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              style={{
                height: isMobileDevice() ? "40%" : xlUp ? "45%" : "27%",
              }}
            >
              <img
                src={data.image}
                style={{ width: isMobileDevice() ? "70%" : "85%" }}
                alt={data.name}
                title={data.name}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                height: xlUp || isMobileDevice() ? "20%" : "15%",
                textAlign: "center",
              }}
            >
              <Typography
                className={Styles.navyBlueColor}
                style={{ fontSize: xlUp ? 12 : 8 }}
              >
                {data.description}
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={4}
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              style={{
                backgroundColor: "#F5F6F8",
                height: isMobileDevice() ? 40 : xlUp ? 48 : 32,
                width: isMobileDevice() ? 40 : xlUp ? 42 : 32,
                borderRadius: "50%",
                textAlign: "center",
              }}
            >
              <Typography
                className={clsx(Styles.navyBlueColor, Styles.font14)}
                style={{ fontWeight: 500 }}
              >
                {index + 1}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </React.Fragment>
  );
}

export default function CloseHomeJourney({
  xlUp,
  setShowCloseHomeJourney,
  setShowFullJourney,
}) {
  const steps = [
    {
      description: "Compare and connect with lawyers",
      image: "/app/images/close-home-step1.svg",
    },
    {
      description: "Submit documents to lawyers",
      image: "/app/images/close-home-step2.svg",
    },
    {
      description: "Provide remaining down payment to lawyers",
      image: "/app/images/close-home-step3.svg",
    },
    {
      description: "Review and sign documents with lawyers",
      image: "/app/images/close-home-step4.svg",
    },
    {
      description: "Transfer the utilities",
      image: "/app/images/close-home-step5.svg",
    },
    {
      description: "Attend closing",
      image: "/app/images/close-home-step6.svg",
    },
  ];
  return (
    <Grid
      container
      spacing={!isMobileDevice() && 4}
      style={{
        // height: xlUp ? "20.75rem" : "18.5rem",
        height: isMobileDevice() ? 251 : xlUp ? "18.5rem" : 282,
      }}
    >
      <Grid
        item
        xs={12}
        style={{
          padding: isMobileDevice() ? 0 : "10px 0px 0px 20px",
          height: isMobileDevice() ? 0 : xlUp ? "12%" : "15%",
        }}
      >
        <Button
          startIcon={<ArrowBackIosIcon />}
          style={{
            textTransform: "capitalize",
            color: "#C86FC9",
          }}
          onClick={() => {
            setShowCloseHomeJourney(false);
            setShowFullJourney(true);
          }}
        >
          Close Your Home
        </Button>
      </Grid>
      {isMobileDevice() ? (
        <Grid
          container
          item
          xs={12}
          style={
            isMobileDevice()
              ? {
                  display: "flex",
                  flexWrap: "nowrap",
                  overflowX: "auto",
                }
              : {}
          }
        >
          <CloseHomeSteps steps={steps} xlUp={xlUp} />
        </Grid>
      ) : (
        <CloseHomeSteps steps={steps} xlUp={xlUp} />
      )}
    </Grid>
  );
}
