import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ADD_ADDITIONAL_USER,
  ADD_ADDRESS,
  BULK_ADD_ADDITIONAL_USERS,
  DELETE_ADDITIONAL_USER,
  DELETE_ADDRESS,
  REMOVE_ALL_ADDITIONAL_USERS,
  SAVE_ADDRESS_FIELD,
  SAVE_CURRENT_AND_NEW_ADDRESS_FIELD,
  RESET_CURRENT_AND_NEW_ADDRESS_FIELD,
  SAVE_BILLING_ADDRESS_FIELD,
  SET_ADDITIONAL_USER_SUPPORT,
  SET_BILLING_ADDRESS_SAME_AS_OA,
  SET_PRIMARY,
  SET_STORE_FRONT_TYPE,
  SAVE_SUB_BUSINESS_TYPE,
  SET_LICENSED_PRACTITIONER,
  SET_LICENSE_BODY_NAME,
  SET_LICENSE_NUMBER,
  SET_PRE_PROFILE_DATA,
  SAVE_CURRENT_TEAM_MEMBER,
  SET_YEARS_IN_BUSINESS,
  SET_NUM_OF_EMPLOYEES,
  SET_MEMBER_MOBILE_NUMBER,
} from "../../souqh-redux/reducers/actionConstants";
import { forEach, map, find } from "lodash";

import { useActions } from "../useActions";
import { useProvinces } from "../useProvinces";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { useValidations } from "../useValidations";
import { emailValidationMeta } from "../validationMetas";
import { useLanguages } from "../useLanguages";
import { useAreaOfSpec } from "../useAreaOfSpec";
import {
  buinessYrsAndEmployeeMeta,
  mobileNumberValidationMeta,
} from "../validationMetas";
import { useApiClient } from "../useApiClient";
import {
  ADVANCED,
  PREMIUM,
  SERVICE_PROVIDER_USER,
  TEAM_MEMBER_USER,
} from "../../web/src/components/ServiceProvider/serviceProviderConstants";

const addressActionCreators = {
  saveAddressField: (payload) => ({ type: SAVE_ADDRESS_FIELD, payload }),
  setPrimary: (index) => ({ type: SET_PRIMARY, index }),
  deleteAddress: (index) => ({ type: DELETE_ADDRESS, index }),
  addAddress: () => ({ type: ADD_ADDRESS }),
};

export const useOfficeAddress = () => {
  const [allowAction, setAllowAction] = useState(false);
  const addresses = useSelector((state) => state.spSetup.addresses);
  const { provinces } = useProvinces(1); // FIXME get country id from logged in user
  const cities = useSelector((state) => state.spSetup.cities);
  const maxAdditionalAddresses = useSelector(
    (state) => state.spSetup.maxAdditionalAddresses
  );
  const actions = useActions(addressActionCreators);
  useEffect(() => {
    let inValidData;
    if (addresses.length) {
      inValidData = addresses.some((address) => {
        return !(
          address.title &&
          !!address.title.trim() &&
          address.province &&
          !!address.province.name &&
          address.city &&
          !!address.city.name &&
          address.postalCode &&
          !!address.postalCode.trim() &&
          address.streetNumber &&
          !!address.streetNumber.trim()
        );
      });
      setAllowAction(!inValidData);
    }
  }, [addresses]);
  return {
    maxAdditionalAddresses,
    addresses,
    provinces,
    cities,
    actions,
    allowAction,
  };
};

const currentAndNewAddressActionCreators = {
  saveAddressField: (payload) => ({
    type: SAVE_CURRENT_AND_NEW_ADDRESS_FIELD,
    payload,
  }),
  resetCurrentAndNewAddress: ()=>({
    type: RESET_CURRENT_AND_NEW_ADDRESS_FIELD
  })
};

export const useCurrentAndNewAddress = () => {
  const [allowAction, setAllowAction] = useState(false);
  const user = useAuthUser();
  const currentAndNewAddress = useSelector(
    (state) => state.spSetup.currentAndNewAddress
  );
  const { provinces } = useProvinces(1); // FIXME get country id from logged in user
  const cities = useSelector((state) => state.spSetup.cities);
  const actions = useActions(currentAndNewAddressActionCreators);
  useEffect(() => {
    // let inValidAddressData =
    // !(currentAndNewAddress&&
    // currentAndNewAddress.currentAddress &&
    // currentAndNewAddress.currentAddress.province &&
    // !!currentAndNewAddress.currentAddress.province.name &&
    // currentAndNewAddress.currentAddress.city &&
    // !!currentAndNewAddress.currentAddress.city.name &&
    // currentAndNewAddress.currentAddress.postalCode &&
    // !!currentAndNewAddress.currentAddress.postalCode.trim() &&
    // currentAndNewAddress.currentAddress.streetNumber &&
    // !!currentAndNewAddress.currentAddress.streetNumber.trim()&&
    // currentAndNewAddress.currentAddress.county &&
    // !!currentAndNewAddress.currentAddress.county.trim());

    let inValidAddressData = false;
    if(user.userType === SERVICE_PROVIDER_USER){
      inValidAddressData = !(currentAndNewAddress&&
        currentAndNewAddress.currentAddress &&
        currentAndNewAddress.currentAddress.province &&
        !!currentAndNewAddress.currentAddress.province.name &&
        currentAndNewAddress.currentAddress.city &&
        !!currentAndNewAddress.currentAddress.city.name &&
        currentAndNewAddress.currentAddress.postalCode &&
        !!currentAndNewAddress.currentAddress.postalCode.trim() &&
        currentAndNewAddress.currentAddress.streetNumber &&
        !!currentAndNewAddress.currentAddress.streetNumber.trim()&&
        currentAndNewAddress.currentAddress.county &&
        !!currentAndNewAddress.currentAddress.county.trim());
    }
    setAllowAction(!inValidAddressData);
  }, [currentAndNewAddress]);
  return {
    currentAndNewAddress,
    allowAction,
    provinces,
    cities,
    actions,
  };
};

const billingAddressActionCreators = {
  saveAddressField: (payload) => ({
    type: SAVE_BILLING_ADDRESS_FIELD,
    payload,
  }),
  setBillingSameASOA: (payload) => ({
    type: SET_BILLING_ADDRESS_SAME_AS_OA,
    payload,
  }),
};
export const useBillingAddress = () => {
  const officeAddresses = useSelector((state) => state.spSetup.addresses);
  const billingAddress = useSelector((state) => state.spSetup.billingAddress);
  const billingAddressSameAsOA = useSelector(
    (state) => state.spSetup.billingAddressSameAsOA
  );
  const { provinces } = useProvinces(1); // FIXME get country id from logged in user
  const cities = useSelector((state) => state.spSetup.cities);

  const actions = useActions(billingAddressActionCreators);
  return {
    officeAddresses,
    billingAddress,
    billingAddressSameAsOA,
    provinces,
    cities,
    actions,
  };
};

const teamActionCreators = {
  setAdditionalUserSupport: (payload) => ({
    type: SET_ADDITIONAL_USER_SUPPORT,
    payload,
  }),
  addAdditionalUser: (payload) => ({
    type: ADD_ADDITIONAL_USER,
    payload,
  }),
  deleteAdditionalUser: (payload) => ({
    type: DELETE_ADDITIONAL_USER,
    payload,
  }),
  bulkAddAdditionalUsers: (payload) => ({
    type: BULK_ADD_ADDITIONAL_USERS,
    payload,
  }),
  setStoreFrontType: (payload) => ({
    type: SET_STORE_FRONT_TYPE,
    payload,
  }),
  setLicensedPractitioner: (payload) => ({
    type: SET_LICENSED_PRACTITIONER,
    payload,
  }),
  setLicenseName: (payload) => ({
    type: SET_LICENSE_BODY_NAME,
    payload,
  }),
  setLicenseNumber: (payload) => ({
    type: SET_LICENSE_NUMBER,
    payload,
  }),
  saveCurrentTeamMember: (payload) => ({
    type: SAVE_CURRENT_TEAM_MEMBER,
    payload,
  }),
  setMemberMobileNumber: (payload) => ({
    type: SET_MEMBER_MOBILE_NUMBER,
    payload,
  }),
};

const mobileValidationMeta = [mobileNumberValidationMeta];
export const useTeamMobileNumber = () => {
  const actions = useActions(teamActionCreators);
  const { phoneNumber, isRandomContactNo } = useSelector(
    (state) => state.spSetup
  );
  const getFormValues = useCallback(() => {
    return { phoneNumber };
  }, [phoneNumber]);
  const getServerKeysMap = useCallback(() => {
    return {
      contactNumber: "phoneNumber",
    };
  }, []);

  const { validations, addValidations, clearAllValidations } = useValidations(
    mobileValidationMeta,
    getFormValues,
    getServerKeysMap
  );

  return {
    phoneNumber,
    actions,
    validations,
    addValidations,
    isRandomContactNo,
  };
};
export const useTeam = () => {
  const dispatch = useDispatch();
  const user = useAuthUser();

  const [singleStoreFront, setSingleStoreFront] = useState(false);
  const [multipleStoreFront, setMultipleStoreFront] = useState(false);
  const [allowAction, setAllowAction] = useState(false);

  const {
    additionalUsersSupport,
    additionalUsers,
    storeFrontType,
    licensedPractitioner,
    licenseName,
    licenseNumber,
  } = useSelector((state) => state.spSetup.team);

  useEffect(() => {
    if (!additionalUsersSupport) {
      if (additionalUsers.length) {
        dispatch({ type: REMOVE_ALL_ADDITIONAL_USERS });
      }
      setAllowAction(true); // if toggle off dont need validation on additionalUsers.
    } else {
      setAllowAction(false); // if toggle ON , check if any of the data in Team Memeber table is empty
    }
  }, [additionalUsersSupport]);

  useEffect(() => {
    // check if any of the data in Team Memeber table is empty
    let inValidData;
    if (additionalUsers.length) {
      inValidData = additionalUsers.some((user) => {
        return !(!!user.title && !!user.fullName && !!user.email);
      });
      setAllowAction(!inValidData);
    } else if (!additionalUsers.length && additionalUsersSupport) {
      setAllowAction(false);
    } else {
      setAllowAction(true);
    }
  }, [additionalUsers]);

  const actions = useActions(teamActionCreators);
  return {
    user,
    additionalUsersSupport,
    additionalUsers,
    storeFrontType,
    licensedPractitioner,
    licenseName,
    licenseNumber,
    actions,
    singleStoreFront,
    multipleStoreFront,
    allowAction,
  };
};

const validationMeta = [emailValidationMeta];
export const useMember = () => {
  const [auTitle, setAuTitle] = useState("");
  const [auFullName, setAuFullName] = useState("");
  const [email, setEmail] = useState("");
  const [allowAdd, setAllowAdd] = useState(false);
  const team = useSelector((state) => state.spSetup.team);

  const getFormValues = useCallback(() => {
    return { auTitle, auFullName, email };
  }, [auTitle, auFullName, email]);

  const getServerKeysMap = useCallback(() => {
    return {};
  }, []);

  const { validations, addValidations, clearAllValidations } = useValidations(
    validationMeta,
    getFormValues,
    getServerKeysMap
  );

  useEffect(() => {
    let validForm =
      !!email &&
      !!auTitle.trim() &&
      !!auFullName.trim() &&
      !Object.keys(validations).length;
    setAllowAdd(validForm);
  }, [email, auTitle, auFullName, validations]);

  useEffect(() => {
    if (email) {
      const found = find(
        team.additionalUsers,
        (teamMember) =>
          teamMember.email.toUpperCase().trim() === email.toUpperCase().trim()
      );
      if (found) {
        setAllowAdd(false);
        addValidations({
          email: "Team member with same email already exist",
        });
      }
    }
  }, [team, email, auTitle, auFullName]);

  return {
    validations,
    auTitle,
    setAuTitle,
    auFullName,
    setAuFullName,
    email,
    setEmail,
    allowAdd,
  };
};

const licensePractionerValidationMeta = [
  {
    name: "licenseNumber",
    patterns: [
      {
        match: (val) => /^[A-Za-z0-9]*$/i.test(val),
        msg: "Entered license number has invalid characters. Please enter a valid license number.",
      },
    ],
  },
  {
    name: "licenseName",
    patterns: [
      {
        // For now license name field accepting anything later on it might get change
        match: (val) => /(?!^ +$)^.*$/i.test(val),
        msg: "Entered license name has invalid characters. Please enter a valid license name.",
      },
    ],
  },
];
export const useValidationForLicensePractioner = () => {
  const [allowAction, setAllowAction] = useState(true);

  const { licensedPractitioner, licenseName, licenseNumber } = useSelector(
    (state) => state.spSetup.team
  );

  const getFormValues = useCallback(() => {
    return { licenseName, licenseNumber, licensedPractitioner };
  }, [licenseName, licenseNumber, licensedPractitioner]);

  const getServerKeysMap = useCallback(() => {
    return {};
  }, []);

  const { validations, addValidations, clearAllValidations } = useValidations(
    licensePractionerValidationMeta,
    getFormValues,
    getServerKeysMap
  );
  useEffect(() => {
    if (licensedPractitioner) {
      const validData =
        !!licenseName.trim() &&
        !!licenseNumber.trim() &&
        !Object.keys(validations).length;
      setAllowAction(validData);
    } else {
      setAllowAction(true);
    }
  }, [licenseName, licenseNumber, licensedPractitioner, validations]);

  return {
    allowAction,
    validations,
    addValidations,
  };
};

const businessTypeActionCreator = {
  saveSubBusinessType: (payload) => ({ type: SAVE_SUB_BUSINESS_TYPE, payload }),
};
export const useAboutYourBusiness = () => {
  const actions = useActions(businessTypeActionCreator);
  const businessTypeSubCategories = useSelector(
    (state) => state.spSetup.businessTypeSubCategories
  );
  return {
    actions,
    businessTypeSubCategories,
  };
};

export const useSaveProfileData = () => {
  const { apiClient } = useApiClient();
  const { allowAction: allowAddAddress } = useOfficeAddress();
  const { selectedLanguage } = useLanguages();
  const { selectedAreaOfSpec } = useAreaOfSpec();
  const { businessTypeSubCategories } = useAboutYourBusiness();
  const [allowAction, setAllowAction] = useState(false);
  const { yearsInBusiness, numberOfEmployees, validations } =
    useBusinessYrAndEmp();

  const dispatch = useDispatch();
  const user = useAuthUser();
  let profileData = {};

  const data = useSelector((state) => state.spSetup);
  useEffect(() => {
    if (
      !allowAddAddress ||
      !selectedLanguage.length ||
      !selectedAreaOfSpec.length ||
      !yearsInBusiness ||
      !numberOfEmployees ||
      Object.keys(validations).length
    ) {
      setAllowAction(false);
    } else {
      let finalAllowAction =
        true &&
        ((user.businessTypes &&
          user.businessTypes.length &&
          user.businessTypes[0].name === "Other") ||
          businessTypeSubCategories.length)
          ? true
          : false;
      setAllowAction(finalAllowAction);
    }
  }, [
    selectedLanguage,
    selectedAreaOfSpec,
    businessTypeSubCategories,
    allowAddAddress,
    yearsInBusiness,
    numberOfEmployees,
    validations,
  ]);
  const saveProfileData = (onResult) => {
    profileData = {
      addressList: [],
      areaOfSpecializations: data.areaOfSpecializations,
      languageSupport: data.languageSupport,
      yearsInBusiness: data.yearsInBusiness,
      numberOfEmployees: data.numberOfEmployees,
      locationServed: [],
      deletedCities: [],
      serviceProviderId: user.serviceProviderId,
      businessTypeSubCategories: [],
      yourStorefrontTeam: {
        additionalStorefrontUsers: data.team.additionalUsersSupport,
        teamMembers: [],
        licenseName: data.team.licenseName,
        licenseNumber: data.team.licenseNumber,
        licensedPractitioner: data.team.licensedPractitioner,
      },
      preferredServiceProviderDTO: {
        preferredServiceProviders: [],
      },
      preferredSPSupport: data.preferredSPSupport,
      billingAddressSameAsOA: data.billingAddressSameAsOA,
      stripeCustomerId: data.stripeCustomerId,
      deletedCities: data.deletedCities,
      mobileNumber: data.phoneNumber,
    };

    // Deletig contact number if already provided by SP/Team member
    if (!data.isRandomContactNo) {
      delete profileData.mobileNumber;
    }

    // locationsServed
    data.locationsServed.length &&
      forEach(data.locationsServed, (location) => {
        profileData.locationServed.push({
          cityIds: map(location.cities, "id"),
          provinceId: location.province.id,
        });
      });

    // addresses
    if (!data.billingAddressSameAsOA) {
      // if billing address is not same as offc address
      profileData.addressList.push({
        addressNickname: data.billingAddress.title,
        billing: true,
        city_id: data.billingAddress.city.id,
        county: data.billingAddress.county, // currently storing county string over here
        id: null,
        postalCode: data.billingAddress.postalCode,
        primaryAddress: false,
        province_id: data.billingAddress.province.id,
        streetName: data.billingAddress.streetName,
        streetNumber: data.billingAddress.streetNumber,
      });
    }
    data.addresses.length &&
      forEach(data.addresses, (address) => {
        profileData.addressList.push({
          addressNickname: address.title,
          billing:
            data.billingAddressSameAsOA &&
            data.billingAddress.title === address.title
              ? true
              : false,
          city_id: address.city.id,
          county: address.county, // currently storing county string over here
          id: address.id || null,
          postalCode: address.postalCode,
          primaryAddress: address.isPrimary,
          province_id: address.province.id,
          streetName: address.streetName,
          streetNumber: address.streetNumber,
        });
      });

    // businessTypeSubCategories
    data.businessTypeSubCategories.length &&
      profileData.businessTypeSubCategories.push({
        businessTypeId: user.businessTypes[0].id,
        subBusinessTypeCategories: map(data.businessTypeSubCategories, "id"),
      });

    // Your team
    data.team.additionalUsers &&
      forEach(data.team.additionalUsers, (user) => {
        profileData.yourStorefrontTeam.teamMembers.push({
          id: user.id || null,
          title: user.title,
          name: user.fullName,
          email: user.email,
          primaryContact: false,
        });
      });

    // Preferred SP
    data.preferredSPs &&
      forEach(data.preferredSPs, (user) => {
        profileData.preferredServiceProviderDTO.preferredServiceProviders.push({
          businessTypeId: user.spType,
          email: user.spEmail,
          fullName: user.spFullName,
          id: user.id || null,
          contactNumber: user.phoneNumber || "",
          website: user.website || ""
        });
      });
    submitProfileData(onResult);
  };

  const submitProfileData = (onResult) => {
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .post("/service-provider/profile", profileData)
      .then((res) => {
        if (res.data.result && res.data.result.status === 200) {
          dispatch({
            type: SET_PROFILE_STATUS,
            payload: {
              data: {
                key: "profileSetupStatus",
                value: res.data.result.profileSetupStatus,
              },
            },
          });
        }
        dispatch({ type: "SET_TOASTER", value: true });
        onResult && onResult(res);
      })
      .catch((error) => {
        console.error(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    saveProfileData,
    allowAction,
  };
};

export const usePreProfileData = () => {
  const { apiClient } = useApiClient();
  const user = useAuthUser();
  const dispatch = useDispatch();

  const areaOfSpecializations = useSelector(
    (state) => state.spSetup.areaOfSpecializations
  );
  const businessTypeSubCategories = useSelector(
    (state) => state.spSetup.businessTypeSubCategories
  );
  const languageSupport = useSelector((state) => state.spSetup.languageSupport);
  const addresses = useSelector((state) => state.spSetup.addresses);
  const team = useSelector((state) => state.spSetup.team);
  const preferredSPs = useSelector((state) => state.spSetup.preferredSPs);

  let url =
    user.userType === SERVICE_PROVIDER_USER ||
    (user.userType === TEAM_MEMBER_USER &&
      (user.subscriptionPlanName === PREMIUM || user.subscriptionPlanName === ADVANCED))
      ? `/service-provider/profile/${user.serviceProviderId}`
      : `/service-provider/profile/${user.parentServiceProviderId}`;

  const getProfileData = () => {
    dispatch({ type: "SET_LOADING", value: true });
    return apiClient
      .get(url)
      .then(function (response) {
        if (response.data.status === 200) {
          const data = mapKeysToReducer(response.data.result);
          dispatch({
            type: SET_PRE_PROFILE_DATA,
            payload: data,
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      })
      .then(function () {
        dispatch({ type: "SET_LOADING", value: false });
      });
  };
  const mapKeysToReducer = (data) => {
    const addresslist = [];
    const teamMemberList = [];
    const preferredSPList = [];
    data.address.length &&
      forEach(data.address, (address) => {
        addresslist.push({
          title: address.addressNickname,
          billing: address.billing,
          city: address.city,
          county: address.county,
          id: address.id,
          postalCode: address.postalCode,
          isPrimary: address.primaryAddress,
          province: address.province,
          streetName: address.streetName,
          streetNumber: address.streetNumber,
        });
      });

    data.yourStorefrontTeam.teamMembers &&
      data.yourStorefrontTeam.teamMembers.length &&
      forEach(data.yourStorefrontTeam.teamMembers, (teamMember) => {
        teamMemberList.push({
          id: teamMember.id,
          title: teamMember.title,
          fullName: teamMember.name,
          email: teamMember.email,
          primaryContact: teamMember.primaryContact,
        });
      });

    data.preferredServiceProviderDTO.preferredServiceProviders &&
      data.preferredServiceProviderDTO.preferredServiceProviders.length &&
      forEach(
        data.preferredServiceProviderDTO.preferredServiceProviders,
        (preferredSPMember) => {
          preferredSPList.push({
            id: preferredSPMember.id,
            spFullName: preferredSPMember.fullName,
            spEmail: preferredSPMember.email,
            spType: preferredSPMember.businessType.id,
            businessTypeName: preferredSPMember.businessType.name,
            phoneNumber: preferredSPMember.contactNumber || "",
            website: preferredSPMember.website || ""
          });
        }
      );

    return {
      addresses: addresslist,
      billingAddress: {},
      locationsServed: data.locationServed,
      team: {
        licensedPractitioner: data.yourStorefrontTeam.licensedPractitioner,
        licenseName: data.yourStorefrontTeam.licenseName || "",
        licenseNumber: data.yourStorefrontTeam.licenseNumber || "",
        additionalUsersSupport:
          data.yourStorefrontTeam.additionalStorefrontUsers || false,
        additionalUsers: teamMemberList,
      },
      businessTypeSubCategories: data.businessTypeSubCategories[0]
        ? data.businessTypeSubCategories[0].subBusinessTypeCategories
        : [],
      areaOfSpecializations: data.spAreaOfSpecializations,
      languageSupport: data.languageSupport.length
        ? data.languageSupport
        : [{ id: 1, name: "English" }],
      billingAddressSameAsOA: data.billingAddressSameAsOA,
      stripeCustomerId: data.stripeCustomerId,
      preferredSPSupport: data.preferredServiceProviderDTO
        .preferredServiceProviders.length
        ? true
        : false,
      preferredSPs: preferredSPList,
      spProfileStatus: {
        profileSetupStatus: data.profileSetupStatus
          ? data.profileSetupStatus
          : false,
        isPaymentPlanSelected: data.isPaymentPlanSelected
          ? data.isPaymentPlanSelected
          : false,
        isPaymentCardAdded: data.isPaymentCardAdded
          ? data.isPaymentCardAdded
          : false,
      },
      yearsInBusiness: data.yearsInBusiness === "0" ? "" : data.yearsInBusiness,
      numberOfEmployees:
        data.numberOfEmployees === 0 ? "" : data.numberOfEmployees,
      deletedCities: [],
      phoneNumber: data.isRandomContactNo ? "" : data.contactNumber,
      isRandomContactNo: data.isRandomContactNo,
    };
  };

  return {
    getProfileData,
    areaOfSpecializations,
    businessTypeSubCategories,
    languageSupport,
    addresses,
    team,
    preferredSPs,
    user,
  };
};

const businessActionCreators = {
  setBusinessYears: (payload) => ({ type: SET_YEARS_IN_BUSINESS, payload }),
  setNumOfEmp: (payload) => ({ type: SET_NUM_OF_EMPLOYEES, payload }),
};

export const useBusinessYrAndEmp = () => {
  const actions = useActions(businessActionCreators);

  const yearsInBusiness = useSelector((state) => state.spSetup.yearsInBusiness);
  const numberOfEmployees = useSelector(
    (state) => state.spSetup.numberOfEmployees
  );

  const getFormValues = useCallback(() => {
    return { yearsInBusiness, numberOfEmployees };
  }, [yearsInBusiness, numberOfEmployees]);

  const getServerKeysMap = useCallback(() => {
    return {};
  }, []);

  const { validations, addValidations, clearAllValidations } = useValidations(
    buinessYrsAndEmployeeMeta,
    getFormValues,
    getServerKeysMap
  );

  return {
    actions,
    yearsInBusiness,
    numberOfEmployees,
    validations,
  };
};
