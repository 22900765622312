import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import react, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useHBSetupDrawer } from "souqh-react-redux-hooks/homeBuyer/useHBSetupDrawer";
import clsx from "clsx";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import Styles from "../../Styles.module.css";
import { Link } from "react-scroll";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    color: "white",
    "& .MuiLink-underlineHover:hover": {
      textDecoration: "none",
    },
  },
  selectedSubItemText: {
    color: "#17174c",
    backgroundColor: "#FA7E61 !important",
    borderRadius: 8,
  },
  selectedSvg: {
    fontSize: "2.5rem",
  },
  nonSelectedSvg: {
    fontSize: "2rem",
  },
  whiteText: {
    backgroundColor: "white",
    minWidth: "25px",
    marginRight: "12px",
    borderRadius: "3px",
    fontWeight: "bold",
  },
  selectedIcon: {
    fontSize: "1.4rem",
    color: "#17174c",
    fontWeight: "bold",
    backgroundColor: "#FA7E61",
    minWidth: "30px",
    marginRight: "12px",
    borderRadius: "3px",
  },
}));

const HBSetupDrawer = () => {
  const classes = useStyles();
  const {
    activeSection,
    selectedItem,
    setActiveSection,
    setSelectedItem,
    selectedSubItem,
    setSelectedSubItem,
  } = useHBSetupDrawer();
  const history = useHistory();

  const mapping = {
    personaloverview: 1,
    newhomeprofile: 2,
    demographic: 3,
    documentchecklist: 4,
  };

  let location = useLocation();
  let locationParam = location.pathname.split("/"); // read URL and split it on "/"
  let activeSectionFromURL = locationParam[locationParam.length - 1]; // take last section from URL

  useEffect(() => {
    // On load of page set the active section on Drawer according to url.
    if (mapping[activeSectionFromURL]) {
      setActiveSection(activeSectionFromURL);
      setSelectedItem(mapping[activeSectionFromURL]);
    } else {
      setActiveSection("personaloverview");
      setSelectedItem(1);
    }
  }, [location]);

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          style={{ display: "flex" }}
        >
          <img
            style={{ height: "200px", width: "200px" }}
            src="/app/images/souqh_logo_withName_white.svg"
            alt="souqh logo"
            title="souqh logo"
          ></img>
        </ListSubheader>
      }
      className={clsx(classes.root, Styles.primaryBackground)}
    >
      <ListItem
        button
        selected={selectedItem === 1}
        onClick={() => {
          history.push("/app/usersetup/personaloverview");
          setActiveSection(
            activeSection === "personaloverview" ? "" : "personaloverview"
          );
          setSelectedItem(1);
        }}
        className={clsx(classes.root, Styles.primaryBackground, {
          [Styles.primaryTxtAccentForce]: selectedItem === 1,
        })}
      >
        <ListItemIcon
          className={clsx(classes.whiteText, Styles.navyBlueColor, {
            [classes.selectedIcon]: selectedItem === 1,
          })}
        >
          <span style={{ marginLeft: "9px" }}>1</span>
        </ListItemIcon>
        <ListItemText
          primary="Personal Overview"
          primaryTypographyProps={{ variant: "subtitle1" }}
        />
        {activeSection === "personaloverview" ? (
          <ExpandLess
            className={clsx({
              [classes.selectedSvg]: selectedItem === 1,
              [classes.nonSelectedSvg]: selectedItem !== 1,
            })}
          />
        ) : (
          <ExpandMore
            className={clsx({
              [classes.selectedSvg]: selectedItem === 1,
              [classes.nonSelectedSvg]: selectedItem !== 1,
            })}
          />
        )}
      </ListItem>
      <Collapse
        in={activeSection === "personaloverview"}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" style={{ paddingLeft: 60, paddingRight: 18 }}>
          <Link to="contact_information" spy={true} smooth={true}>
            <ListItem
              selected={selectedSubItem === 1}
              onClick={() => {
                setSelectedSubItem(1);
              }}
              button
              className={clsx(classes.root, Styles.primaryBackground, {
                [classes.selectedSubItemText]: selectedSubItem === 1,
              })}
            >
              <ListItemText
                primary="Contact Information"
                primaryTypographyProps={
                  selectedSubItem === 1 && { variant: "subtitle1" }
                }
              />
            </ListItem>
          </Link>
          <Link to="current_address" spy={true} smooth={true}>
            <ListItem
              selected={selectedSubItem === 2}
              onClick={() => {
                setSelectedSubItem(2);
              }}
              button
              className={clsx(classes.root, Styles.primaryBackground, {
                [classes.selectedSubItemText]: selectedSubItem === 2,
              })}
            >
              <ListItemText
                primary="Current Address"
                primaryTypographyProps={
                  selectedSubItem === 2 && { variant: "subtitle1" }
                }
              />
            </ListItem>
          </Link>
        </List>
      </Collapse>
      <ListItem
        button
        selected={selectedItem === 2}
        onClick={() => {
          history.push("/app/usersetup/newhomeprofile");
          setActiveSection(
            activeSection === "newhomeprofile" ? "" : "newhomeprofile"
          );
          setSelectedItem(2);
        }}
        className={clsx(classes.root, Styles.primaryBackground, {
          [Styles.primaryTxtAccentForce]: selectedItem === 2,
        })}
      >
        <ListItemIcon
          className={clsx(classes.whiteText, Styles.navyBlueColor, {
            [classes.selectedIcon]: selectedItem === 2,
          })}
        >
          <span style={{ marginLeft: "9px" }}>2</span>
        </ListItemIcon>
        <ListItemText
          primary="New Home Profile"
          primaryTypographyProps={{ variant: "subtitle1" }}
        />
      </ListItem>
      <ListItem
        button
        selected={selectedItem === 3}
        onClick={() => {
          history.push("/app/usersetup/demographic");
          setActiveSection(
            activeSection === "demographic" ? "" : "demographic"
          );
          setSelectedItem(3);
        }}
        className={clsx(classes.root, Styles.primaryBackground, {
          [Styles.primaryTxtAccentForce]: selectedItem === 3,
        })}
      >
        <ListItemIcon
          className={clsx(classes.whiteText, Styles.navyBlueColor, {
            [classes.selectedIcon]: selectedItem === 3,
          })}
        >
          <span style={{ marginLeft: "9px" }}>3</span>
        </ListItemIcon>
        <ListItemText
          primary="Demographic Data"
          primaryTypographyProps={{ variant: "subtitle1" }}
        />
      </ListItem>
      <ListItem
        button
        selected={selectedItem === 4}
        onClick={() => {
          history.push("/app/usersetup/documentchecklist");
          setActiveSection(
            activeSection === "documentchecklist" ? "" : "documentchecklist"
          );
          setSelectedItem(4);
        }}
        className={clsx(classes.root, Styles.primaryBackground, {
          [Styles.primaryTxtAccentForce]: selectedItem === 4,
        })}
      >
        <ListItemIcon
          className={clsx(classes.whiteText, Styles.navyBlueColor, {
            [classes.selectedIcon]: selectedItem === 4,
          })}
        >
          <span style={{ marginLeft: "9px" }}>4</span>
        </ListItemIcon>
        <ListItemText
          primary="Document Checklist"
          primaryTypographyProps={{ variant: "subtitle1" }}
        />
      </ListItem>
    </List>
  );
};

export default HBSetupDrawer;
