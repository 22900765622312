import {
  Divider,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import Styles from "../../Styles.module.css";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import SearchIcon from "@material-ui/icons/Search";
import MessageChannelItem from "./MessageChannelItem";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { HOME_BUYER_USER } from "../ServiceProvider/serviceProviderConstants";
import { isMobileDevice, isTabletDevice } from "../../utils/AppUtils";

export default function MessageLeftPanel({
  searchUserText,
  setSearchUserText,
  channelList,
  setOpenCreateNewGroupChat,
  setOpenBroadCastMessageDialog,
  leftPanelRef,
  itemRefs,
  onChannelClick,
  selectedChannelData,
}) {
  let userElems = null;
  const souqhUserInfo = useAuthUser();

  if (channelList && channelList.length) {
    const txt = (searchUserText || "").toLowerCase().trim();
    userElems = channelList
      .filter((elem) => (elem.fullName || "").toLowerCase().indexOf(txt) >= 0)
      .map((item, index) => {
        return (
          <MessageChannelItem
            channelItem={item}
            key={item.Username}
            index={index}
            itemRefs={itemRefs}
            onChannelClick={onChannelClick}
            selectedChannelData={selectedChannelData}
          />
        );
      });
  }

  return (
    <Grid
      item
      xs={12}
      style={{
        height: "100%",
        overflow: "hidden",
        paddingTop: 32,
        paddingBottom: (isTabletDevice() || isMobileDevice()) && 64,
      }}
    >
      <Grid
        item
        container
        xs={12}
        style={{
          padding: "0px 16px 16px 16px",
          alignItems: "center",
          marginBottom: isMobileDevice() && 20,
        }}
      >
        <Grid item container xs={8}>
          <Typography
            variant="subtitle1"
            style={{ width: "95%" }}
            className={clsx(Styles.sqEllipsis, Styles.navyBlueColor)}
          >
            Messages ({channelList.length})
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={4}
          style={{ justifyContent: "flex-end", alignItems: "center" }}
        >
          {souqhUserInfo && souqhUserInfo.userType !== HOME_BUYER_USER ? (
            <img
              src="/app/images/broadcast_msg.svg"
              style={{
                width: 20,
                height: 20,
                marginRight: 16,
                cursor: "pointer",
              }}
              alt="broadcast msg"
              title="broadcast msg"
              onClick={() => {
                if (channelList.length < 2) {
                  return;
                }
                setOpenBroadCastMessageDialog(true);
              }}
            />
          ) : null}
          <Tooltip title={"Coming soon"}>
            <GroupAddIcon
              style={{ fill: "#0e1c4e", cursor: "default", opacity: 0.5 }}
              onClick={() => {
                if (channelList.length < 2) {
                  return;
                }
                // setOpenCreateNewGroupChat(true);
              }}
            />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        style={{ padding: "0px 16px 10px 16px", alignItems: "center" }}
      >
        <Grid item xs={12} style={{ position: "relative" }}>
          <TextField
            disabled={channelList.length < 1}
            variant="outlined"
            fullWidth
            placeholder="Search "
            value={searchUserText}
            onChange={(event) => {
              setSearchUserText(event.target.value);
            }}
          />
          <SearchIcon
            style={{
              marginTop: 12,
              paddingRight: 10,
              width: 30,
              height: 30,
              position: "absolute",
              right: 0,
              fill: "#0e1c4e",
            }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        ref={leftPanelRef}
        style={{
          padding: "12px 16px",
          height: "calc(100% - 120px",
          overflow: "auto",
        }}
      >
        {userElems && userElems.length ? (
          userElems
        ) : (
          <Typography
            variant="body1"
            style={{
              color: "#0e1c4e",
              opacity: 0.5,
              fontStyle: "italic",
              fontWeight: 300,
              textAlign: "center",
              paddingTop: 40,
            }}
          >
            No users available
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}
