import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  makeStyles,
  IconButton,
  useMediaQuery,
  useTheme,
  Chip,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import CloseIcon from "@material-ui/icons/Close";
import CircleIcon from "@mui/icons-material/Circle";
import useFeaturedStoreFront from "souqh-react-redux-hooks/homeBuyer/useFeaturedStoreFront";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";
import FeaturedStoreFront from "../Explore/FeaturedStoreFront";
import { useExplore } from "souqh-react-redux-hooks/homeBuyer/useExplore";
import { useHistory } from "react-router-dom";

const isMobile = isMobileDevice();

const serviceProviders = [
  {
    id: 1,
    name: "Real Estate Broker / Agent",
    logoUrl: "Realtor.svg",
    isBusinessType: true,
    storefrontPathName: "real-estate-agent",
  },
  {
    id: 4,
    name: "Property Inspector",
    logoUrl: "PropertyInspector.svg",
    isBusinessType: true,
    storefrontPathName: "property-inspector-services",
  },
  {
    id: 5,
    name: "Property Appraiser",
    logoUrl: "PropertyAppraiser.svg",
    isBusinessType: true,
    storefrontPathName: "property-appraiser-services",
  },
];

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 720,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  marginTop10: {
    marginTop: 10,
  },
  cardBorder: {
    borderRadius: 8,
    padding: 15,
  },
  primarColor: {
    color: "#0e1c4e",
  },
  reLativeCard: {
    position: "relative",
  },
  clickableCard: {
    cursor: "pointer",
  },
  overlay: {
    top: -23,
    right: -29,
    position: "absolute",
    width: 120,
    height: 120,
  },
  media: {
    height: 0,
    paddingTop: "75%", // 4:3
    backgroundSize: "85% 100%",
  },
  svgIcon: {
    color: "white",
    fill: "white",
    stroke: "#0e1c4e",
    width: "0.75rem !important",
    "&.selected": {
      fill: "#0e1c4e",
    },
  },
  svgIconButton: {
    padding: 6,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  content: {
    marginLeft: isMobile ? 0 : "4.5rem",
    padding: "1.5rem",
  },
  heading: {
    marginBottom: "1.5rem",
  },
}));

function FindYourHomeDialog({
  openFindYourHomeDialog,
  setOpenFindYourHomeDialog,
  newHomeProfile,
}) {
  const classes = useStyles();
  const userInfo = useAuthUser();
  const theme = useTheme();
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  const history = useHistory();

  const numberOfCards = isMobile ? 1 : isTabletDevice() ? 2 : xlUp ? 6 : 4;

  const [featuredSFs, setFeaturedSFs] = useState([]);

  const { formSaveCategoryObjAndCallApi } = useExplore();

  const { shuffledItems, activeIndex, setActiveIndex } = useFeaturedStoreFront(
    featuredSFs,
    numberOfCards,
    openFindYourHomeDialog
  );

  useEffect(() => {
    let final = [];
    userInfo.journeyStoreFront &&
      userInfo.journeyStoreFront.length &&
      userInfo.journeyStoreFront.map((item) => final.push(item));
    setFeaturedSFs(final);
  }, [userInfo]);

  return (
    <Dialog
      fullWidth
      disableBackdropClick
      open={openFindYourHomeDialog}
      onClose={() => {
        setOpenFindYourHomeDialog(false);
      }}
      classes={{
        paper: classes.dialogPaper,
      }}
      maxWidth="xl"
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Find Your Home</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => {
            setOpenFindYourHomeDialog(false);
          }}
        >
          <CloseIcon className={Styles.navyBlueColor} />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent classes={{ root: classes.content }}>
        <Grid container xs={12}>
          <Grid
            container
            classes={{ root: classes.reLativeCard }}
            item
            xs={isMobile ? 12 : 8}
          >
            <Grid item xs={12} classes={{ root: classes.heading }}>
              <Typography
                className={Styles.navyBlueColor}
                style={{ fontSize: isMobile ? 18 : 24, fontWeight: 600 }}
              >
                Let's get you in touch with service providers to help you with
                current stage of your journey.
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item container xs={12}>
              <FeaturedStoreFront
                bgColor="#66D7D140"
                storeFrontList={shuffledItems}
              />
              {featuredSFs.length && featuredSFs.length / numberOfCards > 1 ? (
                <Grid
                  style={{ justifyContent: "center", marginTop: 10 }}
                  container
                  item
                  xs={12}
                >
                  {Array(Math.ceil(featuredSFs.length / numberOfCards))
                    .fill(1)
                    .map((item, index) => (
                      <IconButton
                        classes={{ root: classes.svgIconButton }}
                        onClick={() => setActiveIndex(index)}
                      >
                        <CircleIcon
                          classes={{ root: classes.svgIcon }}
                          className={activeIndex === index ? "selected" : ""}
                        />
                      </IconButton>
                    ))}
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          {/* <Grid item xs={12} style={{ marginTop: isMobile ? "1rem" : "2rem" }}> */}
          <Grid
            item
            xs={12}
            style={{ marginTop: featuredSFs.length ? "1rem" : 0 }}
          >
            {serviceProviders.map((provider, index) => (
              <Chip
                key={index}
                variant="outlined"
                label={provider.name}
                className={Styles.navyBlueColorForce}
                style={{
                  marginRight: 8,
                  marginBottom: isMobile ? 8 : 0,
                }}
                onClick={() => {
                  formSaveCategoryObjAndCallApi(provider, userInfo);
                  history.push({
                    pathname: `/app/homebuyer/explore/${provider.storefrontPathName}/${newHomeProfile?.city?.shortName}`,
                    state: {
                      searchedService: provider.name,
                    },
                  });
                  // history.push(
                  //   `/homebuyer/explore/${provider.storefrontPathName}/${newHomeProfile?.city?.name}`
                  // );
                }}
              />
            ))}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default FindYourHomeDialog;
