import {
  AppBar,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useStorefrontPage } from "souqh-react-redux-hooks/homeBuyer/useStorefrontPage";
import Styles from "../../../../Styles.module.css";
import { useTheme } from "souqh-react-redux-hooks/serviceProvider/storefront/useTheme";
import GeneralStorefront from "./GeneralStorefront";
import StorefrontPageHeader from "./StorefrontPageHeader";
import StorefrontPageCards from "./StorefrontPageCards";
import CustomerReviews from "./CustomerReviews";
import HomeIcon from "@material-ui/icons/Home";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import PersonIcon from "@material-ui/icons/Person";
import StorefrontDocuments from "./StorefrontDocuments";
import TeamExperts from "./TeamExperts";
import SendSerivceRequestDialog from "./SendSerivceRequestDialog";
import { useParams } from "react-router-dom";
import DetailReviewsPage from "../../../ServiceProvider/ClientManagement/DetailReviewsPage";
import RateReviewIcon from "@material-ui/icons/RateReview";
import { useSQQuery } from "souqh-react-redux-hooks/common/useSQQuery";
import { useStorefrontClientReviews } from "souqh-react-redux-hooks/common/useStorefrontClientReviews";
import { isTabletDevice } from "../../../../utils/AppUtils";
import { useUserEngagement } from "souqh-react-redux-hooks/serviceProvider/analytics/useUserEngagement";
import Helmet from "react-helmet";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import SocialMediaFeed from "./SocialMediaFeed";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#0e1c4e0a",
  },
  serviceImg: {
    height: 320,
    width: "100%",
    borderRadius: 6,
    border: "1px solid rgba(0, 0,0,0.12)",
  },
  tabBtn: {
    textTransform: "capitalize",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    marginRight: 24,
    minWidth: 100,
    fontSize: 16,
  },
  activeTabBtn: {
    fontWeight: 600,
  },
  tabStartIcon: {
    marginRight: 2,
  },
  noDataAvailable: {
    paddingTop: 120,
    textAlign: "center",
    color: "#D90368",
  },
  topcontainer: {
    position: "relative",
    top: 80,
    paddingTop: 36,
  },
  topcontainerPreview: {
    position: "relative",
    top: 0,
    paddingTop: 0,
  },
  appBar: {
    backgroundColor: "#17174c",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

const pageBar = () => {
  return <div></div>;
};

const Header = () => {
  return (
    <Grid
      style={{ marginTop: 0, marginBottom: 24 }}
      container
      justifyContent="space-between"
    >
      <Typography variant="h4" className={Styles.navyBlueColor}>
        Storefront
      </Typography>
    </Grid>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid item style={{ paddingTop: 36 }}>
          {" "}
          {children}
        </Grid>
      )}
    </div>
  );
}

export default function StorefrontPage({
  previewStorefrontId,
  sourcePage = "",
}) {
  const classes = useStyles();
  const { primaryColor, secondaryColor } = useTheme();
  const { query } = useSQQuery();
  const history = useHistory();

  const {
    openPage,
    documentItem,
    setDocumentItem,
    openDocumetDialog,
    setOpenDocumetDialog,
    noDataAvailable,
    primarySubscriptionPlanName,
    activeTab,
    setActiveTab,
    businessName,
    teamMembers,
    areaOfSpecializations,
    getStorefrontDetails,
    languagesYouSpeak,
    businessTypes,
    storeFrontDetails,
    yearsInBusiness,
    numberOfEmployees,
    addresses,
    getStorefrontDetailsByName,
    storeFrontName: SFName,
    showLimitedView,
    serviceProviderId,
    setServiceProviderId,
    user,
    ownerEmail,
    publicStorefrontId,
    getUploadedDocuments,
    calSyncAccessToken,
    locationsServed,
    getMetadataInfo,
    getInstaPosts,
    getFBPosts,
    instaPosts,
    instagramToken,
    preferredPartnerSFDetails
  } = useStorefrontPage();

  const pageRoute = history.location.state && history.location.state?.pageRoute;

  const { setUserEngagementData, increaseLeadCount } = useUserEngagement();

  const { getReviewStatistics, reviewStatistics } =
    useStorefrontClientReviews();

  const reviewStatsUrl =
    sourcePage === "manageStoreFront"
      ? "storefront/service-review/sp-view/statistics"
      : "storefront/service-review/statistics";

  const [openserviceRequestDialog, setOpenserviceRequestDialog] = useState({
    open: false,
    service: null,
  });
  let { storeFrontName } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    let storeFrontId = query.get("storeFrontId");

    if (sourcePage === "manageStoreFront") {
      storeFrontId = user.storefrontId;
    }
    if (storeFrontId) {
      getStorefrontDetails(storeFrontId);
    } else if (storeFrontName) {
      getStorefrontDetailsByName(storeFrontName);
    } else if (previewStorefrontId) {
      getStorefrontDetails(previewStorefrontId);
    }
    if (
      localStorage.getItem("nonloggedInUser") &&
      JSON.parse(localStorage.getItem("nonloggedInUser"))
        .openserviceRequestDialog
    ) {
      setOpenserviceRequestDialog(
        JSON.parse(localStorage.getItem("nonloggedInUser"))
          .openserviceRequestDialog
      );
      setServiceProviderId(
        JSON.parse(localStorage.getItem("nonloggedInUser")).serviceProviderId
      );
    }
    // getFBPosts();
  }, []);

  useEffect(() => {
    if (instagramToken) {
      getInstaPosts();
    }
  }, [instagramToken]);

  let storeFrontId = query.get("storeFrontId");

  if (sourcePage === "manageStoreFront") {
    storeFrontId = user.storefrontId;
  }
  if (previewStorefrontId) {
    storeFrontId = previewStorefrontId;
  } else {
    storeFrontId = publicStorefrontId;
  }

  useEffect(() => {
    const SFIdForReviewStats = storeFrontId || publicStorefrontId;
    if (SFIdForReviewStats) {
      getReviewStatistics(reviewStatsUrl, SFIdForReviewStats);
    }
  }, [storeFrontId]);

  if (noDataAvailable) {
    return (
      <Grid item>
        <Typography variant="h6" className={classes.noDataAvailable}>
          No Data available
        </Typography>
      </Grid>
    );
  }

  const reviewsTabIndex =
    !getUploadedDocuments(storeFrontDetails).length &&
    !teamMembers.length &&
    !(instaPosts && instaPosts.length)
      ? 1
      : getUploadedDocuments(storeFrontDetails).length &&
        teamMembers.length &&
        (instaPosts && instaPosts.length)
      ? 4
      : (getUploadedDocuments(storeFrontDetails).length &&
          !teamMembers.length &&
          !(instaPosts && instaPosts.length)) ||
        (!getUploadedDocuments(storeFrontDetails).length &&
          teamMembers.length &&
          !(instaPosts && instaPosts.length)) ||
        (!getUploadedDocuments(storeFrontDetails).length &&
          !teamMembers.length &&
          (instaPosts && instaPosts.length))
      ? 2
      : 3;

  const reviewsTabBackground =
    (!getUploadedDocuments(storeFrontDetails).length &&
      !teamMembers.length &&
      !(instaPosts && instaPosts.length) &&
      activeTab === 1) ||
    (getUploadedDocuments(storeFrontDetails).length &&
      teamMembers.length &&
      (instaPosts && instaPosts.length) &&
      activeTab === 4) ||
    (((getUploadedDocuments(storeFrontDetails).length &&
      !teamMembers.length &&
      !(instaPosts && instaPosts.length)) ||
      (!getUploadedDocuments(storeFrontDetails).length &&
        teamMembers.length &&
        !(instaPosts && instaPosts.length)) ||
      (!getUploadedDocuments(storeFrontDetails).length &&
        !teamMembers.length &&
        (instaPosts && instaPosts.length))) &&
      activeTab === 2) ||
    activeTab === 3;

  let ratingAndCards = null;
  if (activeTab === 0) {
    ratingAndCards = (
      <Grid item xs={12} style={{ paddingBottom: isTabletDevice() && 100 }}>
        {/* <Grid item xs={12} style={{ marginTop: 24 }}>
          <CustomerReviews></CustomerReviews>
        </Grid> */}

        <Grid item xs={12} style={{ marginTop: 24, paddingBottom: 48 }}>
          <StorefrontPageCards
            customerPayments={storeFrontDetails.customerPayments}
            areaOfSpecializations={areaOfSpecializations}
            languagesYouSpeak={languagesYouSpeak}
            showLimitedView={showLimitedView}
            primaryColor={primaryColor}
            preferredPartnerSFDetails={preferredPartnerSFDetails}
            userInfo={user}
          />
        </Grid>
      </Grid>
    );
  }

  let topNavBar = null;
  if (openPage) {
    topNavBar = (
      <AppBar position="fixed" className={clsx(classes.appBar)}>
        <Toolbar>
          <img
            style={{ height: "80px", cursor: "pointer" }}
            src="/app/images/souqh_logo_withName_white.svg"
            alt="souqh logo"
            title="souqh logo"
            onClick={() => {
              window.open("https://www.souqh.ca", "_blank");
            }}
          />
          <Grid container justifyContent="flex-end"></Grid>
        </Toolbar>
      </AppBar>
    );
  }

  if (
    previewStorefrontId ||
    sourcePage === "manageStoreFront" ||
    sourcePage === "storefrontWidget"
  ) {
    topNavBar = null;
  }

  const pageContent = (
    <>
      <SendSerivceRequestDialog
        openserviceRequestDialog={openserviceRequestDialog}
        setOpenserviceRequestDialog={setOpenserviceRequestDialog}
        storeFrontDetails={storeFrontDetails}
        serviceProviderId={serviceProviderId}
        businessName={businessName}
        email={ownerEmail}
        setUserEngagementData={setUserEngagementData}
        storeFrontId={publicStorefrontId}
        storeFrontName={SFName}
        pageRoute={pageRoute}
      />
      {topNavBar}
      <Grid
        container
        className={clsx({
          [classes.topcontainerPreview]:
            previewStorefrontId || sourcePage === "storefrontWidget",
          [classes.topcontainer]: openPage,
        })}
        style={{
          backgroundColor: "#F5F5F8",
          padding: isTabletDevice() ? 0 : "0px 24px",
        }}
      >
        <Grid
          container
          style={{
            padding: isTabletDevice()
              ? 0
              : sourcePage === "storefrontWidget"
              ? "0px 10px 64px 10px"
              : "20px 10px 64px 10px",
          }}
        >
          {!previewStorefrontId && !isTabletDevice() && (
            <Grid item xs={12}>
              <Header></Header>
            </Grid>
          )}
          <Grid item xs={12}>
            <Paper style={{ padding: "12px 24px", color: "#0e1c4e" }}>
              <Grid item>
                <img
                  src={
                    storeFrontDetails.bannerPicture
                      ? storeFrontDetails.bannerPicture
                      : "/app/images/default-banner.svg" //default banner image if user has not uploaded bannerPicture
                  }
                  className={classes.serviceImg}
                  alt="default banner"
                  title="default banner"
                />
              </Grid>
              <Grid item container xs={12} style={{ padding: "0px 12px" }}>
                <StorefrontPageHeader
                  businessTypes={businessTypes}
                  businessName={businessName}
                  storeFrontDetails={storeFrontDetails}
                  primarySubscriptionPlanName={primarySubscriptionPlanName}
                  SFName={SFName}
                  showLimitedView={showLimitedView}
                  addresses={addresses}
                  email={ownerEmail}
                  reviewStatistics={reviewStatistics}
                  storeFrontId={publicStorefrontId}
                  storeFrontRouteName={storeFrontDetails.storeFrontRouteName}
                  setUserEngagementData={setUserEngagementData}
                  increaseLeadCount={increaseLeadCount}
                  contactNumber={storeFrontDetails.contactNumber}
                  calSyncAccessToken={calSyncAccessToken}
                  data={locationsServed.length && getMetadataInfo()}
                  pageRoute={pageRoute}
                  storeFrontName={SFName}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ margin: "24px 0px" }} />
              </Grid>
              <Grid item xs={12} style={{ padding: 12 }}>
                <Tabs
                  value={activeTab}
                  className={"sqCustomTab"}
                  onChange={(event, newValue) => {
                    setActiveTab(newValue);
                  }}
                >
                  <Tab
                    icon={<HomeIcon />}
                    onClick={() => {
                      setUserEngagementData(publicStorefrontId, "General");
                    }}
                    label="Overview"
                    {...a11yProps(0)}
                    className={clsx(classes.tabBtn, {
                      [classes.activeTabBtn]: activeTab === 0,
                    })}
                    style={{
                      color: secondaryColor,
                      backgroundColor: activeTab === 0 && primaryColor,
                    }}
                  />
                  {instaPosts && instaPosts.length ? (
                    <Tab
                      icon={<PhoneIphoneIcon />}
                      onClick={() => {
                        setUserEngagementData(publicStorefrontId, "General");
                      }}
                      label="Social Media"
                      {...a11yProps(1)}
                      className={clsx(classes.tabBtn, {
                        [classes.activeTabBtn]: activeTab === 1,
                      })}
                      style={{
                        color: secondaryColor,
                        backgroundColor:
                          activeTab === 1 && instaPosts && instaPosts.length && primaryColor,
                      }}
                    />
                  ) : null}
                  {!showLimitedView &&
                  getUploadedDocuments(storeFrontDetails).length ? (
                    <Tab
                      icon={<InsertDriveFileIcon />}
                      onClick={() => {
                        setUserEngagementData(publicStorefrontId, "Documents");
                      }}
                      label="Documents"
                      {...a11yProps(2)}
                      className={clsx(classes.tabBtn, {
                        [classes.activeTabBtn]: activeTab === 2,
                      })}
                      style={{
                        color: secondaryColor,
                        backgroundColor:
                          ((instaPosts && instaPosts.length && activeTab === 2) ||
                            (!(instaPosts && instaPosts.length) && activeTab === 1)) &&
                          primaryColor,
                      }}
                    />
                  ) : null}
                  {!showLimitedView && teamMembers.length ? (
                    <Tab
                      icon={<PersonIcon />}
                      onClick={() => {
                        setUserEngagementData(publicStorefrontId, "Team");
                      }}
                      label="Team"
                      {...a11yProps(3)}
                      className={clsx(classes.tabBtn, {
                        [classes.activeTabBtn]: activeTab === 3,
                      })}
                      style={{
                        color: secondaryColor,
                        backgroundColor:
                          ((getUploadedDocuments(storeFrontDetails).length &&
                            activeTab === 3) ||
                            (!getUploadedDocuments(storeFrontDetails).length &&
                              activeTab === 2)) &&
                          primaryColor,
                      }}
                    />
                  ) : null}
                  <Tab
                    icon={<RateReviewIcon />}
                    onClick={() => {
                      setUserEngagementData(publicStorefrontId, "Reviews");
                    }}
                    label="Reviews"
                    {...a11yProps(4)}
                    className={clsx(classes.tabBtn, {
                      [classes.activeTabBtn]: activeTab === 4,
                    })}
                    style={{
                      color: secondaryColor,
                      backgroundColor: reviewsTabBackground && primaryColor,
                    }}
                  />
                </Tabs>
                <TabPanel
                  value={activeTab}
                  index={0}
                  style={{ color: primaryColor }}
                >
                  <GeneralStorefront
                    competeText={storeFrontDetails.competeText}
                    companyOverview={storeFrontDetails.companyOverview}
                    services={storeFrontDetails.services}
                    yearsInBusiness={yearsInBusiness}
                    numberOfEmployees={numberOfEmployees}
                    openserviceRequestDialog={openserviceRequestDialog}
                    setOpenserviceRequestDialog={setOpenserviceRequestDialog}
                    user={user}
                    serviceProviderId={serviceProviderId}
                    ownerEmail={ownerEmail}
                    storeFrontId={publicStorefrontId}
                    primaryColor={primaryColor}
                    secondaryColor={secondaryColor}
                    setUserEngagementData={setUserEngagementData}
                  />
                </TabPanel>
                {instaPosts && instaPosts.length ? (
                  <TabPanel
                    value={activeTab}
                    index={1}
                    style={{ color: primaryColor }}
                  >
                    <SocialMediaFeed
                      user={user}
                      serviceProviderId={serviceProviderId}
                      ownerEmail={ownerEmail}
                      storeFrontId={publicStorefrontId}
                      primaryColor={primaryColor}
                      secondaryColor={secondaryColor}
                      setUserEngagementData={setUserEngagementData}
                      instaPosts={instaPosts}
                    />
                  </TabPanel>
                ) : null}
                {getUploadedDocuments(storeFrontDetails).length ? (
                  <TabPanel value={activeTab} index={instaPosts && instaPosts.length ? 2 : 1}>
                    <StorefrontDocuments
                      storeFrontDetails={storeFrontDetails}
                      openDocumetDialog={openDocumetDialog}
                      setOpenDocumetDialog={setOpenDocumetDialog}
                      documentItem={documentItem}
                      setDocumentItem={setDocumentItem}
                      yearsInBusiness={yearsInBusiness}
                      numberOfEmployees={numberOfEmployees}
                      getUploadedDocuments={getUploadedDocuments}
                      setUserEngagementData={setUserEngagementData}
                      storeFrontId={publicStorefrontId}
                    />
                  </TabPanel>
                ) : null}
                {teamMembers.length ? (
                  <TabPanel
                    value={activeTab}
                    index={
                      getUploadedDocuments(storeFrontDetails).length &&
                      publicStorefrontId !== 64 &&
                      instaPosts && instaPosts.length
                        ? 3
                        : !getUploadedDocuments(storeFrontDetails).length &&
                          !(instaPosts && instaPosts.length)
                        ? 1
                        : 2
                    }
                  >
                    <TeamExperts
                      teamMembers={teamMembers}
                      yearsInBusiness={yearsInBusiness}
                      numberOfEmployees={numberOfEmployees}
                      user={user}
                      setUserEngagementData={setUserEngagementData}
                      increaseLeadCount={increaseLeadCount}
                      storeFrontId={publicStorefrontId}
                    />
                  </TabPanel>
                ) : null}
                <TabPanel value={activeTab} index={reviewsTabIndex}>
                  <DetailReviewsPage
                    isViewStorefrontPage={true}
                    storefrontReviews={storeFrontDetails.reviews}
                    servicesList={storeFrontDetails.services}
                    previewStorefrontId={previewStorefrontId}
                    publicStorefrontId={publicStorefrontId}
                    setUserEngagementData={setUserEngagementData}
                  />
                </TabPanel>
              </Grid>
            </Paper>
          </Grid>
          {ratingAndCards}
        </Grid>
      </Grid>
    </>
  );

  let businessTypeName =
    businessTypes && businessTypes.length
      ? businessTypes[0].name === "Other"
        ? businessTypes[0].otherBusinessName
        : businessTypes[0].name
      : "";
  return (
    <Grid
      item
      xs={12}
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Helmet>
        <title>
          {SFName} | {businessTypeName}
        </title>
        {/* <meta name="description" content={storeFrontDetails.companyOverview} /> */}
      </Helmet>
      <Grid
        item
        xs={12}
        style={{
          maxWidth: 1500,
        }}
      >
        {pageContent}
      </Grid>
    </Grid>
  );
}
