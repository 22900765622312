import React from "react";
import { Grid, Link, makeStyles, Paper, Typography } from "@material-ui/core";
import Styles from "../../../../Styles.module.css";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import clsx from "clsx";
import { isMobileDevice, isTabletDevice } from "../../../../utils/AppUtils";
import { HOME_BUYER_USER } from "../../../ServiceProvider/serviceProviderConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#0e1c4e0a",
  },
  displayPicture: {
    height: 164,
    width: 164,
    borderRadius: 8,
    position: "absolute",
    top: -20,
    left: 12,
    boxShadow: "3px 3px 10px #00000029",
  },
  itemHolder: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 16,
    marginLeft: -8,
  },
  paperSlot: {
    padding: "24px 20px",
    height: "100%",
    paddingLeft: 36,
  },
  itemBasis: {
    flexBasis: isMobileDevice() ? "85%" : "32%",
    marginBottom: isMobileDevice() ? 64 : 0,
  },
  paymentItemBox: {
    display: "flex",
    alignItems: "center",
    padding: "8px 12px",
    marginBottom: "12px",
    borderRadius: "4px",
  },
}));

export default function StorefrontPageCards(props) {
  let { showLimitedView } = props;
  const classes = useStyles();
  const { customerPayments, areaOfSpecializations, languagesYouSpeak, preferredPartnerSFDetails, userInfo } = props;

  let specialAreas = null;
  if (areaOfSpecializations) {
    specialAreas = areaOfSpecializations.map((el, index) => {
      return (
        <Grid item xs={12} container className={classes.itemHolder} key={index}>
          <FiberManualRecordIcon
            style={{
              fill: props.primaryColor,
              height: 14,
              marginRight: 8,
            }}
          />
          <Typography
            variant="body1"
            className={Styles.sqEllipsis}
            style={{ width: isMobileDevice() ? "224px" : "83%" }}
          >
            {el.name}
          </Typography>
        </Grid>
      );
    });
  }

  let languages = null;
  if (languagesYouSpeak) {
    languages = languagesYouSpeak.map((el, index) => {
      return (
        <Grid item container className={classes.itemHolder} key={index}>
          <FiberManualRecordIcon
            style={{
              fill: props.primaryColor,
              height: 14,
              marginRight: 8,
            }}
          />
          <Typography variant="body1">{el.name}</Typography>
        </Grid>
      );
    });
  }

  let creditPayment = null;
  if (customerPayments.creditCardInfo.visibility) {
    const selectedTypes = customerPayments.creditCardInfo.config;
    let paymentIcons = [];
    if (selectedTypes && selectedTypes.length) {
      paymentIcons = selectedTypes.map((el, index) => {
        return (
          <img
            src={el.icon.includes("/app") ? el.icon : `/app${el.icon}`}
            style={{ height: 24, width: 24, margin: 2 }}
            alt="app"
            title="app"
            key={index}
          />
        );
      });
    }
    creditPayment = (
      <Grid
        item
        container
        className={clsx(Styles.sqGreyBorder, classes.paymentItemBox)}
      >
        <Grid item container xs={7}>
          <Typography variant="subtitle1">Credit/Debit Card</Typography>
        </Grid>
        <Grid
          item
          container
          xs={5}
          style={{ justifyContent: "flex-end", alignItems: "center" }}
        >
          {paymentIcons}
        </Grid>
      </Grid>
    );
  }

  let payPalPayment = null;
  if (customerPayments.paypalInfo.visibility) {
    payPalPayment = (
      <Grid
        item
        container
        className={clsx(Styles.sqGreyBorder, classes.paymentItemBox)}
      >
        <Grid item container xs={7}>
          <Typography variant="subtitle1">Paypal</Typography>
        </Grid>
        <Grid
          item
          container
          xs={5}
          style={{ justifyContent: "flex-end", alignItems: "center" }}
        >
          <img
            src={"/app/images/paypal.svg"}
            alt="paypal"
            title="paypal"
            style={{ height: 18, width: 18, margin: 2 }}
          />
        </Grid>
      </Grid>
    );
  }

  let wirePayment = null;
  if (customerPayments.wireTransferInfo.selected) {
    wirePayment = (
      <Grid
        item
        container
        className={clsx(Styles.sqGreyBorder, classes.paymentItemBox)}
      >
        <Grid item container xs={7}>
          <Typography variant="subtitle1">Wire Transfer</Typography>
        </Grid>
        <Grid
          item
          container
          xs={5}
          style={{ justifyContent: "flex-end", alignItems: "center" }}
        >
          <img
            src={"/app/images/wire_trans.svg"}
            alt="wire trans"
            title="wire trans"
            style={{ height: 32, width: 32, margin: 2 }}
          />
        </Grid>
      </Grid>
    );
  }

  let eTransferPayment = null;
  if (customerPayments.eTransferInfo.selected) {
    eTransferPayment = (
      <Grid
        item
        container
        className={clsx(Styles.sqGreyBorder, classes.paymentItemBox)}
      >
        <Grid item container xs={7}>
          <Typography variant="subtitle1">E- Transfer</Typography>
        </Grid>
        <Grid item container xs={5}></Grid>
      </Grid>
    );
  }

  let chequePayment = null;
  if (customerPayments.chequeInfo.selected) {
    chequePayment = (
      <Grid
        item
        container
        className={clsx(Styles.sqGreyBorder, classes.paymentItemBox)}
      >
        <Grid item container xs={7}>
          <Typography variant="subtitle1">Cheque</Typography>
        </Grid>
        <Grid item container xs={5}></Grid>
      </Grid>
    );
  }

  let otherPayment = null;
  if (customerPayments.otherInfo.selected) {
    const otherConfig = customerPayments.otherInfo.config;
    if (otherConfig && otherConfig.length) {
      let otherMethods = [];
      otherConfig.map((el, index) => {
        if (el.methodName) {
          otherMethods.push(
            <Grid item container alignItems="center">
              <FiberManualRecordIcon
                style={{ fill: "#0e1c4e", height: 14, marginRight: 8 }}
              />
              <Typography variant="body1" className={Styles.sqEllipsis}>
                {el.methodName}
              </Typography>
            </Grid>
          );
        }
      });
      if (otherMethods && otherMethods.length) {
        otherPayment = (
          <Grid
            item
            container
            className={clsx(Styles.sqGreyBorder, classes.paymentItemBox)}
          >
            <Grid item container xs={12}>
              <Typography variant="subtitle1" style={{ marginBottom: 12 }}>
                Other
              </Typography>
              <Grid item xs={12} style={{ paddingLeft: 12 }}>
                {otherMethods}
              </Grid>
            </Grid>
          </Grid>
        );
      }
    }
  }

  let preferredPartnersSFs = null;
  if (preferredPartnerSFDetails) {
    preferredPartnersSFs = preferredPartnerSFDetails.map((el, index) => {
      return (
        <Grid item container className={classes.itemHolder} key={index} style={{ marginLeft: 0 }}>
          <Grid item xs={1}>
            <img
              style={{ height: "20px", marginRight: 8 }}
              src={`/app/images/Explore-Category/${el.businessTypeLogoUrl}`}
            ></img>
          </Grid>
          <Grid item xs={11} style={{ paddingLeft: 10 }}>
            <Link variant="body1" underline="always" style={{ color: props.primaryColor }} onClick={() => {
              let newPath = "";
              if (userInfo) {
                const path =
                  userInfo.userType === HOME_BUYER_USER
                    ? "homebuyer"
                    : "serviceprovider";
                newPath = `/app/${path}/storefrontview/${el.serviceProviderBusinessName}?storeFrontId=${el.storefrontId}`;
              } else {
                newPath = `/app/storefront/${el.storefrontRouteName}`;
              }
              window.open(newPath, "_blank");
            }}>{el.storefrontName}</Link>
          </Grid>
        </Grid>
      );
    });
  }
  return (
    <Grid container>
      {!showLimitedView ? (
        <Grid
          container
          item
          xs={12}
          style={{
            justifyContent: isMobileDevice() ? "center" : " space-between",
          }}
        >
          <Grid item md={4} xs={12} className={classes.itemBasis}>
            <Paper
              elevation={3}
              className={classes.paperSlot}
              style={{ color: props.primaryColor }}
            >
              <Typography
                variant={
                  isMobileDevice() || isTabletDevice() ? "subtitle1" : "h6"
                }
                style={{
                  marginBottom: isMobileDevice() || isTabletDevice() ? 16 : 36,
                }}
              >
                Areas of Specialization
              </Typography>
              {specialAreas}
              {!specialAreas.length && (
                <Typography>No information available</Typography>
              )}
            </Paper>
          </Grid>
          <Grid item md={4} xs={12} className={classes.itemBasis}>
            <Paper
              elevation={3}
              className={classes.paperSlot}
              style={{ color: props.primaryColor }}
            >
              <Typography
                variant={
                  isMobileDevice() || isTabletDevice() ? "subtitle1" : "h6"
                }
                style={{
                  marginBottom: isMobileDevice() || isTabletDevice() ? 16 : 36,
                }}
              >
                Languages Supported
              </Typography>
              {languages}
              {!languages && <Typography>No information available</Typography>}
            </Paper>
          </Grid>
          {/* <Grid item md={4} xs={12} className={classes.itemBasis}>
            <Paper
              elevation={3}
              className={classes.paperSlot}
              style={{ color: props.primaryColor }}
            >
              <Typography
                variant={
                  isMobileDevice() || isTabletDevice() ? "subtitle1" : "h6"
                }
                style={{
                  marginBottom: isMobileDevice() || isTabletDevice() ? 16 : 36,
                }}
              >
                Payment Methods
              </Typography>
              {creditPayment}
              {payPalPayment}
              {wirePayment}
              {eTransferPayment}
              {chequePayment}
              {otherPayment}
              {!creditPayment &&
                !payPalPayment &&
                !wirePayment &&
                !eTransferPayment &&
                !chequePayment &&
                !otherPayment && (
                  <Typography>No information available</Typography>
                )}
            </Paper> */}
          <Grid item md={4} xs={12} className={classes.itemBasis}>
            <Paper
              elevation={3}
              className={classes.paperSlot}
              style={{ color: props.primaryColor, paddingLeft: 34 }}
            >
              <Typography
                variant={
                  isMobileDevice() || isTabletDevice() ? "subtitle1" : "h6"
                }
                style={{
                  marginBottom: isMobileDevice() || isTabletDevice() ? 16 : 36,
                }}
              >
                Preferred Referral Partners
              </Typography>
              {preferredPartnersSFs}
              {!preferredPartnersSFs && (
                <Typography>No information available</Typography>
              )}
            </Paper>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
}
