import React from "react";
import { Grid, Typography, Card, makeStyles } from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  cardBorder: {
    borderRadius: 4,
    padding: 15,
    border: "1px solid #101D4B1F",
  },
  clickableCard: {
    cursor: "pointer",
  },
}));

export default function AppItem({ data }) {
  const classes = useStyles();
  return (
    <Grid item xs={4}>
      <Card
        elevation={0}
        className={clsx(classes.cardBorder, classes.clickableCard)}
        style={{ padding: 20, minHeight: 142 }}
      >
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <img
              style={{
                width: 48,
                height: 48,
                border: "1px solid #0E1C4E1F",
                borderRadius: 4,
              }}
              alt={data.name}
              title={data.name}
              src={data.imageUrl}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={clsx(Styles.navyBlueColor, Styles.font14)}
              style={{ fontWeight: 600 }}
            >
              {data.title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={clsx(
                Styles.navyBlueColor,
                Styles.font14,
                Styles.sqEllipsisThreeLines
              )}
            >
              {data.description}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
