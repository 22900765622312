import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import { DialogActions } from "@mui/material";
import { useLogout } from "souqh-react-redux-hooks/useLogin";
import { useHistory } from "react-router-dom";

function PlanUpgradedMsgDialog({
  openPlanUpgradedMsgDialog,
  setOpenPlanUpgradedMsgDialog,
}) {
  const { logout } = useLogout();
  let history = useHistory();

  return (
    <Dialog
      fullWidth
      disableBackdropClick
      open={openPlanUpgradedMsgDialog}
      onClose={() => {
        setOpenPlanUpgradedMsgDialog(false);
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">
          Account upgraded successfully
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12} style={{ paddingBottom: 16 }}>
          <Typography variant="body1" className={Styles.navyBlueColor}>
            Your account has been successfully upgraded. Please re-login again
            to view the features as per your plan
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() =>
            logout((res) => {
              if (res.data.status === 200) {
                history.push("/app/login");
              }
            })
          }
          className={Styles.primaryBtnAccent}
          style={{ textTransform: "capitalize" }}
        >
          Logout
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PlanUpgradedMsgDialog;
