import { Avatar, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import Styles from "../../Styles.module.css";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { isGroupChannel } from "../../utils/CommonUtils";
const useStyles = makeStyles((theme) => ({
  root: {},
  subText: {
    fontSize: "12px",
  },
}));

export default function MessageChannelItem({
  channelItem,
  index,
  itemRefs,
  onChannelClick,
  selectedChannelData,
}) {
  const classes = useStyles();
  if (!channelItem) {
    return null;
  }
  let isSelectedChannel = false;
  if (selectedChannelData) {
    if (selectedChannelData.ChannelArn === channelItem.ChannelArn) {
      isSelectedChannel = true;
    }
  }
  return (
    <Grid
      item
      xs={12}
      ref={(ref) => (itemRefs[index] = ref)}
      data-userid={channelItem.Username}
      className={classes.classes}
      style={{
        display: "flex",
        padding: 8,
        alignItems: "center",
        border: "1px solid #0E1C4E1F",
        borderRadius: 4,
        marginBottom: 8,
        cursor: "pointer",
        backgroundColor: isSelectedChannel ? "#0E1C4E1F" : "#FFFFFF",
      }}
      onClick={() => {
        onChannelClick(channelItem);
      }}
    >
      <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
        {channelItem.profilePhoto ? (
          <Avatar
            src={channelItem.profilePhoto}
            style={{ width: 40, height: 40, borderRadius: 4 }}
          />
        ) : channelItem.storeFrontContent ? (
          <Avatar
            src={JSON.parse(channelItem.storeFrontContent).displayPicture}
            style={{ width: 40, height: 40, borderRadius: 4 }}
          />
        ) : isGroupChannel(channelItem.Name) ? (
          <Avatar
            src={"/app/images/message_group.svg"}
            style={{ width: 40, height: 40, borderRadius: 4 }}
          />
        ) : (
          <AccountBoxIcon
            style={{ fontSize: 40, fill: "#0e1c4e", opacity: 0.3 }}
          />
        )}
      </Grid>
      <Grid item xs={8}>
        <Typography
          variant="subtitle1"
          className={clsx(Styles.sqEllipsis, Styles.navyBlueColor)}
          style={{ fontSize: 14, textTransform: "capitalize" }}
        >
          {channelItem.fullName || channelItem.Username}
        </Typography>
        {channelItem.storeFrontName && channelItem.businessName ? (
          <Typography className={clsx(classes.subText, Styles.navyBlueColor)}>
            {channelItem.storeFrontName}, {channelItem.businessName}
          </Typography>
        ) : null}
        {channelItem.categoryName ? (
          <Typography className={clsx(classes.subText, Styles.navyBlueColor)}>
            {channelItem.categoryName}
          </Typography>
        ) : null}

        {channelItem.unreadContent && channelItem.unreadContent.Content ? (
          <Typography
            variant="body1"
            className={clsx(Styles.sqEllipsis, Styles.navyBlueColor)}
            style={{ fontSize: 12, fontWeight: 600 }}
          >
            {channelItem.unreadContent.Content}
          </Typography>
        ) : null}
      </Grid>
      <Grid
        item
        xs={2}
        className={Styles.msgUpdateIndicator}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        {channelItem.unreadContent && channelItem.unreadContent.Content ? (
          <FiberManualRecordIcon style={{ fontSize: 16, fill: "#fa7e61" }} />
        ) : null}
        {/* {channelItem.unreadCount ? (
          <div
            style={{
              height: 20,
              display: "inline-flex",
              padding: "4px 8px",
              fontSize: "0.75rem",
              boxSizing: "border-box",
              alignItems: "center",
              fontWeight: 500,
              alignContent: "center",
              borderRadius: 10,
              flexDirection: "row",
              justifyContent: "center",
              color: "#ffffff",
              backgroundColor: "#fa7e61",
            }}
          >
            {channelItem.unreadCount}
          </div>
        ) : null} */}
      </Grid>
    </Grid>
  );
}
