import {
  Grid,
  TableRow,
  TableCell,
  Typography,
  ButtonBase,
} from "@material-ui/core";
import React, { useState } from "react";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";
import Styles from "../../../Styles.module.css";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import MoreOptionsOnClientInfo from "./MoreOptionsOnClientInfo";
import ClientStatus from "./ClientStatus";
import * as moment from "moment";
import clsx from "clsx";
import { useHistory } from "react-router";
import { useRouteMatch, Link } from "react-router-dom";
import ContactNumber from "../../HomeBuyer/SPManagement/ContactNumber";
import { viewClientDetails } from "../../../utils/AppUtils";
import { useMediaQuery, useTheme } from "@material-ui/core";

export default function ActiveClientTableRow({ data, index }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  let { path } = useRouteMatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const theme = useTheme();
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <>
      <MoreOptionsOnClientInfo
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        showProfileUpdateOption={true}
        showScheduleAppointmentOption={true}
        showSendMessageOption={true}
        showLiveMeetingOption={true}
        email={data.hbDetails.email}
        toUserId={data.hbDetails.userId}
        serviceRequestId={data.serviceRequestId}
      />
      <TableRow>
        <TableCell>
          <Typography className={Styles.navyBlueColor}>{index + 1}</Typography>
        </TableCell>
        <TableCell container>
          <Grid
            container
            style={{ padding: "0px 16px", cursor: "pointer" }}
            onClick={() => {
              // viewClientDetails(history, path, data);
              if (data.userId) {
                history.push({
                  pathname: `${path}/details/${data.userId}`,
                  search: `?serviceRequestId=${data.serviceRequestId}`,
                  state: {
                    assignClient: data.assignClient,
                    assignedUsers: data.assignedUsers,
                    serviceRequestId: data.serviceRequestId,
                  },
                });
              }
            }}
          >
            <Grid item xs={2}>
              <img
                src={data.avatarUrl}
                style={{
                  height: 50,
                  border: "1px solid #0E1C4E1F",
                  borderRadius: 4,
                  marginRight: 10,
                }}
                alt="data"
                title="data"
              />
            </Grid>
            <Grid container item xs={10} style={{ paddingLeft: 10 }}>
              <Typography
                variant="body1"
                className={Styles.navyBlueColor}
                style={{ fontWeight: 600 }}
              >
                {data.name}
              </Typography>
              <Grid container item xs={12}>
                <Grid item xs={1}>
                  <EmailIcon className={Styles.navyBlueColor} />
                </Grid>
                <Grid item xs={11} style={{ paddingLeft: 10 }}>
                  <Typography
                    variant="body1"
                    className={clsx(Styles.navyBlueColor, Styles.sqEllipsis)}
                  >
                    {data.email}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={1}>
                  <CallIcon className={Styles.navyBlueColor} />
                </Grid>
                <Grid item xs={11} style={{ paddingLeft: 10 }}>
                  <ContactNumber contactNumber={data.contactNumber} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell style={{ textTransform: "uppercase" }}>
          <ClientStatus clientStatus={data.hbJourneyStatus} />
        </TableCell>
        <TableCell className={Styles.navyBlueColor}>
          <Typography className={Styles.navyBlueColor}>
            {data.closingDate
              ? moment(data.closingDate).format("MMM DD, YYYY")
              : null}
            {!data.closingDate && "N/A"}
          </Typography>
        </TableCell>
        <TableCell>
          <Grid
            container
            item
            xs={12}
            spacing={1}
            style={{
              width: xlUp ? 300 : 240,
              flexWrap: "nowrap",
              overflowX: "auto",
            }}
          >
            {data.activeServiceProviders &&
            data.activeServiceProviders.length ? (
              data.activeServiceProviders.map((activeSp) => {
                const storeFrontContent = JSON.parse(
                  activeSp.storeFrontContent
                );
                return (
                  <Grid item xs={3}>
                    <>
                      <Link
                        to={{
                          pathname:
                            window.location.origin +
                            `/app/storefront/${activeSp.storeFrontRouteName}`,
                        }}
                        target="_blank"
                      >
                        <img
                          alt="souqh logo"
                          title="souqh logo"
                          src={
                            storeFrontContent.displayPicture ||
                            "/app/images/souqh_logo.svg"
                          }
                          style={{
                            height: 50,
                            border: "1px solid #0E1C4E1F",
                            borderRadius: 4,
                            cursor: "pointer",
                          }}
                        />
                      </Link>
                    </>
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={12}>
                <Typography className={Styles.navyBlueColor} variant="body1">
                  No data available
                </Typography>
              </Grid>
            )}
          </Grid>
        </TableCell>
        <TableCell>
          <ButtonBase disabled={!data.hbDetails.userId}>
            <MoreHorizIcon
              onClick={handleClick}
              className={Styles.navyBlueColor}
            />
          </ButtonBase>
        </TableCell>
      </TableRow>
    </>
  );
}
