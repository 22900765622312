import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import Styles from "../../../Styles.module.css";
// import FacebookLogin from "react-facebook-login";
// import InstagramLogin from "react-instagram-login";

export default function SyncSocialMediaFeed({
  save,
  apiClient,
  instagramToken,
  instagramCode,
}) {
  const { REACT_APP_FACEBOOK_LOGIN_LINK } = process.env;

  const redirectLink = window.location.href;

  return (
    <Grid container item xs={12} id="service_details">
      <Grid style={{ marginTop: 60, marginBottom: 20 }} container>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            className={Styles.navyBlueColor}
            style={{ marginBottom: 8 }}
          >
            Sync Social Media Feed
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            style={{ marginTop: 10 }}
            className={Styles.navyBlueColor}
          >
            Sync your social media posts from Facebook or Instagram directly on
            your storefront by clicking below
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-around">
        <Grid item xs={6}>
          <Button
            disabled
            variant="outlined"
            fullWidth
            className={Styles.navyBlueColor}
            style={{ textTransform: "none" }}
            startIcon={
              <img
                src="/app/images/facebook-icon.svg"
                style={{ height: "25px", width: "25px" }}
              />
            }
            // href={REACT_APP_FACEBOOK_LOGIN_LINK}
            onClick={() => {
              window.open(
                REACT_APP_FACEBOOK_LOGIN_LINK,
                "facebook login",
                "toolbar=no, menubar=no, width=700, height=700, top=100, left=300"
              );
            }}
          >
            Login with Facebook
          </Button>
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: 8 }}>
          <Button
            disabled={instagramToken || instagramCode}
            variant="outlined"
            fullWidth
            className={Styles.navyBlueColor}
            style={{ textTransform: "none" }}
            startIcon={
              <img
                src="/app/images/Instagram-Logo.svg"
                style={{ height: "25px", width: "25px" }}
              />
            }
            // href={REACT_APP_GOOGLE_LOGIN_LINK}
            onClick={() => {
              save(
                (res) => {
                  if (res && res.data.status === 200) {
                    window.open(
                      // REACT_APP_GOOGLE_LOGIN_LINK,
                      `https://api.instagram.com/oauth/authorize?client_id=798994031326657&redirect_uri=${redirectLink}&scope=user_profile,user_media&response_type=code`,
                      // "instagram login",
                      // "toolbar=no, menubar=no, width=700, height=700, top=100, left=300"
                      "_self"
                    );
                  }
                },
                apiClient,
                true
              );
            }}
          >
            Login with Instagram
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
