import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";
import Styles from "../Styles.module.css";
import clsx from "clsx";
import Rating from "@mui/material/Rating";
import PropTypes from "prop-types";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import { useFeedback } from "souqh-react-redux-hooks/common/useFeedback";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#0e1c4e",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0e1c4e",
      },
    },
  },
}));

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon style={{ height: 40, width: 40 }} />,
    label: "POOR",
    rating: 1,
  },
  2: {
    icon: <SentimentDissatisfiedIcon style={{ height: 40, width: 40 }} />,
    label: "BELOW_AVERAGE",
    rating: 2,
  },
  3: {
    icon: <SentimentSatisfiedIcon style={{ height: 40, width: 40 }} />,
    label: "AVERAGE",
    rating: 3,
  },
  4: {
    icon: <SentimentSatisfiedAltIcon style={{ height: 40, width: 40 }} />,
    label: "GOOD",
    rating: 4,
  },
  5: {
    icon: <SentimentVerySatisfiedIcon style={{ height: 40, width: 40 }} />,
    label: "EXCELLENT",
    rating: 5,
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

const StyledRating = withStyles({
  iconFilled: {
    color: "#fa7e61",
  },
  iconHover: {
    color: "#fa7e61",
  },
})(Rating);

export default function FeedbackPage() {
  const {
    rating,
    description,
    setDescription,
    setRating,
    submitFeedback,
    clearData,
  } = useFeedback();
  const classes = useStyles();

  return (
    <Grid container style={{ padding: "26px 110px" }}>
      <Grid item xs={12} style={{ marginBottom: 16 }}>
        <Typography variant="h4" className={Styles.navyBlueColor}>
          Feedback
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={0} style={{ padding: 60 }}>
          <Grid container alignItems="center">
            <Grid container item xs={6} alignContent="flex-start">
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography variant="h6" className={Styles.navyBlueColor}>
                  Your feedback matters to us.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ textAlign: "center", marginBottom: 55 }}
              >
                <Typography
                  className={clsx(Styles.navyBlueColor, Styles.font14)}
                >
                  How satisfied are you with your experience on Souqh platform
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <StyledRating
                  name="highlight-selected-only"
                  getLabelText={(value) => customIcons[value].label}
                  IconContainerComponent={IconContainer}
                  onChange={(event, newValue, value) => {
                    setRating(customIcons[newValue]);
                  }}
                  value={rating && rating.rating}
                  highlightSelectedOnly
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: 44 }}>
                <TextField
                  multiline
                  placeholder="If you have any other feedback, please let us know"
                  required
                  rows={3}
                  maxRows={13}
                  variant="outlined"
                  fullWidth
                  value={description}
                  inputProps={{
                    className: Styles.navyBlueColor,
                    maxLength: 5000,
                  }}
                  classes={{ root: classes.root }}
                  onChange={(event) => {
                    setDescription(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center", marginTop: 48 }}>
                <Button
                  disabled={!rating}
                  variant="contained"
                  className={Styles.primaryBtnAccent}
                  style={{ textTransform: "capitalize", padding: "4px 20px" }}
                  onClick={() => {
                    submitFeedback((res) => {
                      if (res && res.status === 200) {
                        clearData();
                      }
                    });
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <img
                src="/app/images/Feedback_illustartion.svg"
                alt="feedback"
                title="feedback"
                style={{ height: 423, width: 463 }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
