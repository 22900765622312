import {
  Button,
  Divider,
  Grid,
  Radio,
  Switch,
  TextField,
  Typography,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  Chip,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Styles from "../../Styles.module.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PasswordChecks from "../PasswordCheck";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MaskedMobileInput from "../MaskedMobileInput";
import { usePersonalInfo } from "souqh-react-redux-hooks/settings/accountPreferences/useAccountPreferences";
import { useChangePassword } from "souqh-react-redux-hooks/settings/accountPreferences/useChangePassword";
import { passwordValidationMeta } from "souqh-react-redux-hooks/validationMetas";
import { filter, find } from "lodash";
import {
  BUSINESS_TYPE_HOME_DEPOSIT,
  BUSINESS_TYPE_OTHER,
  SERVICE_PROVIDER_USER,
  TEAM_MEMBER_USER,
} from "../ServiceProvider/serviceProviderConstants";
import { useLogout } from "souqh-react-redux-hooks/useLogin";
import { useHistory } from "react-router-dom";
import { useRoleManagement } from "souqh-react-redux-hooks/common/useRoleManagement";
import DeactivateAccountDialog from "./DeactivateAccountDialog";
import DeleteAccountDialog from "./DeleteAccountDialog";
import { useBusinessType } from "souqh-react-redux-hooks/useBusinessType";
// import { Autocomplete } from "@mui/material";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useStoreFrontSearchFilters } from "souqh-react-redux-hooks/homeBuyer/useStoreFrontSearchFilters";
import { useSelector } from "react-redux";
import {
  useAboutYourBusiness,
  usePreProfileData,
} from "souqh-react-redux-hooks/serviceProvider/useSetUp";

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
})(MuiAccordion);

const AccordionDetails = withStyles({
  root: {
    backgroundColor: "#F5F6F8",
  },
  expanded: {},
})(MuiAccordionDetails);

const AccordionSummary = withStyles({
  root: {
    border: "1px solid #D8DBE3",
    borderRadius: 4,
  },
  expanded: {},
})(MuiAccordionSummary);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AccountPreferences() {
  const { isTeamMember } = useRoleManagement();

  const {
    businessTypeSubCategories,
    actions: { saveSubBusinessType },
  } = useAboutYourBusiness();

  const {
    email,
    mobileNumber,
    enable2Fa,
    setMobileNumber,
    setEnable2Fa,
    savePersonalDetails,
    user,
    businessName,
    setBusinessName,
    businessType,
    setBusinessType,
    otherBusinessTypeName,
    setOtherBusinessTypeName,
    selectedBusinessType,
    setSelectedBusinessType,
  } = usePersonalInfo(businessTypeSubCategories);

  const {
    password,
    setPassword,
    validations,
    allowSubmit,
    changePassword,
    confirmPassword,
    setconfirmPassword,
  } = useChangePassword();

  const passwordValidation = filter(validations.password, (pv) => {
    const found = find(passwordValidationMeta.patterns, (pvmp) => {
      return pvmp.msg === pv;
    });
    return !found;
  });
  const [confirmPasswordError, setconfirmPasswordError] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [dirtyForm, setDirtyForm] = useState({ section: "", dirty: false });
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [panel, setPanel] = useState();
  const [deactivateAccount, setDeactivateAccount] = useState(false);
  const [openDeactivateAccountDialog, setOpenDeactivateAccountDialog] =
    useState(false);
  const [deleteAccount, setDeleteAccount] = useState(false);
  const [openDeleteAccountDialog, setOpenDeleteAccountDialog] = useState(false);

  const { businessTypes } = useBusinessType();
  const { getProfileData } = usePreProfileData();

  const { getSubBusinessTypes } = useStoreFrontSearchFilters();

  const { subBusinessTypes } = useSelector((state) => state.explorePageFilters);

  useEffect(() => {
    if (user && user.userType === SERVICE_PROVIDER_USER) {
      getProfileData();
    }
  }, []);

  useEffect(() => {
    if (user && user.businessTypes && user.businessTypes.length) {
      setBusinessType(user.businessTypes[0]);
      setOtherBusinessTypeName(user.businessTypes[0].otherBusinessName);
    }
  }, [user]);

  useEffect(() => {
    if (businessType) {
      getSubBusinessTypes(businessType.id);
    }
    // setSelectedBusinessType([]);
    saveSubBusinessType([]);
  }, [businessType]);

  useEffect(() => {
    setSelectedBusinessType(businessTypeSubCategories);
  }, [businessTypeSubCategories]);

  let history = useHistory();
  const { logout } = useLogout();
  const handleChange = (panel) => (event, isExpanded) => {
    setPanel(panel);
    setIsExpanded(isExpanded);

    if (dirtyForm.dirty) {
      setOpenConfirmationDialog(true);
    } else {
      setExpanded(isExpanded ? panel : false);
    }
  };
  useEffect(() => {
    !!confirmPasswordError &&
      confirmPassword === password &&
      setconfirmPasswordError("");
  }, [confirmPassword, password, confirmPasswordError]);

  const clearDataOnSuccess = (res) => {
    if (res && res.data.status === 200) {
      setOpenConfirmationDialog(false);
      setExpanded(isExpanded ? panel : false);
      setDirtyForm({ section: "", dirty: false });
      logout((res) => {
        const routeTo =
          (user && user.userType === SERVICE_PROVIDER_USER) ||
          user.userType === TEAM_MEMBER_USER
            ? "/app/login"
            : "/app/userlogin";
        history.push(routeTo);
      });
    }
  };
  return (
    <Grid
      container
      item
      xs={12}
      style={{
        padding: "0 24px 120px 24px",
      }}
    >
      <DeactivateAccountDialog
        openDeactivateAccountDialog={openDeactivateAccountDialog}
        setOpenDeactivateAccountDialog={setOpenDeactivateAccountDialog}
        setDeactivateAccount={setDeactivateAccount}
      />
      <DeleteAccountDialog
        openDeleteAccountDialog={openDeleteAccountDialog}
        setOpenDeleteAccountDialog={setOpenDeleteAccountDialog}
      />
      <Dialog fullWidth open={openConfirmationDialog}>
        <DialogTitle className={Styles.navyBlueColor}>
          <Typography variant="subtitle1">Save Changes</Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            Do you want to save the changes made recently?
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setOpenConfirmationDialog(false);
            }}
            className={Styles.navyBlueColorForce}
            style={{ textTransform: "capitalize" }}
          >
            Don't Save
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              dirtyForm.section === "panel1"
                ? savePersonalDetails((res) => {
                    clearDataOnSuccess(res);
                  })
                : changePassword((res) => {
                    clearDataOnSuccess(res);
                  });
            }}
            className={Styles.primaryBtnAccent}
            style={{ textTransform: "capitalize" }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Grid item xs={12}>
        <Typography variant="h4" className={Styles.navyBlueColor}>
          Account Preferences
        </Typography>
      </Grid>

      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        style={{ marginTop: 20, width: "100%" }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            style={{ fontWeight: 600 }}
            className={Styles.navyBlueColor}
          >
            Personal Information
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" className={Styles.navyBlueColor}>
                Email Id
              </Typography>
              <TextField
                id="email"
                placeholder="Email Address"
                name="email"
                variant="outlined"
                fullWidth
                disabled
                value={email}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 32 }}>
              <Typography variant="subtitle1" className={Styles.navyBlueColor}>
                Change Mobile Number
              </Typography>

              <MaskedMobileInput
                autoComplete="new-password"
                id="mobileNumber"
                name="mobileNumber"
                variant="outlined"
                required
                type="tel"
                fullWidth
                value={mobileNumber}
                onChange={(event) => {
                  setDirtyForm({ section: "panel1", dirty: true });
                  setMobileNumber(event.target.value.replaceAll("-", ""));
                }}
              />
            </Grid>
            {user.userType === SERVICE_PROVIDER_USER && (
              <Grid item xs={12} style={{ marginTop: 32 }}>
                <Typography
                  variant="subtitle1"
                  className={Styles.navyBlueColor}
                >
                  Change Business Name
                </Typography>
                <TextField
                  value={businessName}
                  error={!!validations.businessName}
                  helperText={
                    validations.businessName &&
                    validations.businessName.join("\n")
                  }
                  // FormHelperTextProps={helperTextProps}
                  id="businessType"
                  label="Business Name"
                  name="businessType"
                  variant="outlined"
                  fullWidth
                  required
                  // className={classes.textField}
                  onChange={(e) => {
                    setBusinessName(e.target.value);
                  }}
                  inputProps={{
                    maxLength: 256,
                    className: Styles.navyBlueColorForce,
                  }}
                />
              </Grid>
            )}

            {user.userType === SERVICE_PROVIDER_USER && (
              <Grid item xs={12} style={{ marginTop: 32 }}>
                <Typography
                  variant="subtitle1"
                  className={Styles.navyBlueColor}
                >
                  Change Business Type
                </Typography>
                <Autocomplete
                  id="business_type"
                  style={{ width: "100%" }}
                  options={businessTypes || []}
                  value={businessType || ""}
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Business Type"
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                  onChange={(e, businessType) => {
                    if (businessType) {
                      setBusinessType(businessType);
                    } else {
                      setBusinessType("");
                    }
                  }}
                />
                {businessType.name === "Other" ? (
                  <Grid item xs={12} style={{ marginTop: 32 }}>
                    <TextField
                      size="small"
                      fullWidth
                      id="standard-basic"
                      label="Enter Business Type"
                      value={otherBusinessTypeName}
                      onChange={(e) => setOtherBusinessTypeName(e.target.value)}
                      variant="outlined"
                    />
                  </Grid>
                ) : null}
              </Grid>
            )}

            {user.userType === SERVICE_PROVIDER_USER && (
              <Grid item xs={12} style={{ marginTop: 32 }}>
                {businessType &&
                  businessType.name !== BUSINESS_TYPE_OTHER &&
                  businessType.name !== BUSINESS_TYPE_HOME_DEPOSIT && (
                    <Autocomplete
                      multiple
                      fullWidth
                      style={{ display: "inline-flex" }}
                      variant="outlined"
                      id="business-type-options"
                      options={subBusinessTypes || []}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.name}
                      className={Styles.navyBlueColorForce}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8, color: "#0e1c4e" }}
                            checked={selected}
                          />
                          {option.name}
                        </React.Fragment>
                      )}
                      value={businessTypeSubCategories}
                      onChange={(e, v) => {
                        saveSubBusinessType(v);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Please select the types of services you provide*"
                        />
                      )}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            label={option.name}
                            {...getTagProps({ index })}
                            variant="outlined"
                            className={Styles.navyBlueColorForce}
                          />
                        ))
                      }
                      getOptionSelected={(option, value) => {
                        return option.name === value.name;
                      }}
                    />
                  )}
              </Grid>
            )}

            {user.userType === SERVICE_PROVIDER_USER && (
              <>
                <Divider
                  orientation="horizontal"
                  variant="fullWidth"
                  style={{ width: "100%", marginTop: 32 }}
                />
                <Grid container item xs={12} style={{ marginTop: 32 }}>
                  <Typography
                    variant="subtitle1"
                    className={Styles.navyBlueColor}
                  >
                    Enable 2FA
                  </Typography>
                  <Grid container item xs={12}>
                    <Grid item>
                      <Typography className={Styles.navyBlueColor}>
                        Would you like to enable 2FA
                      </Typography>
                    </Grid>
                    <Grid item style={{ marginLeft: 10 }}>
                      <Switch
                        size="small"
                        className={"sqGraySwitch"}
                        checked={enable2Fa}
                        onChange={(event) => {
                          setDirtyForm({ section: "panel1", dirty: true });
                          setEnable2Fa(event.target.checked);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            <Divider
              orientation="horizontal"
              variant="fullWidth"
              style={{ width: "100%", marginTop: 32 }}
            />
            <Grid
              container
              item
              xs={12}
              justifyContent="flex-end"
              style={{ marginTop: 20 }}
            >
              <Button
                variant="outlined"
                className={Styles.navyBlueColor}
                style={{ marginRight: 12, textTransform: "capitalize" }}
                onClick={() => {
                  if (user) {
                    setBusinessName(user.businessName);
                    setMobileNumber(user.contactNumber);
                    if (user.businessTypes && user.businessTypes.length)
                      setBusinessType(user.businessTypes[0]);
                    if (user.userType === SERVICE_PROVIDER_USER) {
                      getProfileData();
                    }
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={
                  !mobileNumber ||
                  (user.userType === SERVICE_PROVIDER_USER && !businessType)
                }
                style={{
                  marginRight: 12,
                  textTransform: "capitalize",
                  padding: "0px 24px",
                }}
                className={Styles.primaryBtnAccent}
                onClick={() =>
                  savePersonalDetails((res) => {
                    clearDataOnSuccess(res);
                  })
                }
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        style={{ marginTop: 10, width: "100%" }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            style={{ fontWeight: 600 }}
            className={Styles.navyBlueColor}
          >
            Change Password
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container item xs={12}>
            <Grid item xs={11}>
              <TextField
                autoComplete="new-password"
                value={password}
                error={!!passwordValidation.length}
                FormHelperTextProps={{ component: "div" }}
                helperText={
                  <>
                    {validations.password && passwordValidation.join(" ")}
                    <PasswordChecks
                      password={password}
                      validations={validations.password}
                    ></PasswordChecks>
                  </>
                }
                id="password"
                label="New Password"
                name="passsword"
                variant="outlined"
                type="password"
                required
                onChange={(e) => {
                  setDirtyForm({ section: "panel2", dirty: true });
                  setPassword(e.target.value);
                }}
                fullWidth
                inputProps={{
                  maxLength: 25,
                  minLength: 8,
                  className: Styles.navyBlueColorForce,
                }}
              />
            </Grid>
            <Grid item xs={11}>
              <TextField
                style={{ marginTop: 10 }}
                value={confirmPassword}
                error={!!confirmPasswordError}
                helperText={confirmPasswordError}
                id="confirmPassword"
                label="Re-enter Password"
                name="confirmPassword"
                variant="outlined"
                fullWidth
                type="password"
                required
                onChange={(e) => {
                  setconfirmPassword(e.target.value);
                }}
                inputProps={{
                  maxLength: 25,
                  minLength: 8,
                  className: Styles.navyBlueColorForce,
                }}
              />
            </Grid>
            <Divider
              orientation="horizontal"
              variant="fullWidth"
              style={{ width: "100%", marginTop: 32 }}
            />
            <Grid
              container
              item
              xs={12}
              justifyContent="flex-end"
              style={{ marginTop: 20 }}
            >
              <Button
                variant="outlined"
                className={Styles.navyBlueColor}
                style={{ marginRight: 12, textTransform: "capitalize" }}
              >
                Cancel
              </Button>
              <Button
                disabled={!allowSubmit}
                style={{
                  marginRight: 12,
                  textTransform: "capitalize",
                  padding: "0px 24px",
                }}
                className={Styles.primaryBtnAccent}
                onClick={() => {
                  if (password !== confirmPassword) {
                    setconfirmPasswordError(
                      "Your passwords do not match. Please re-enter."
                    );
                    return;
                  }
                  changePassword((res) => {
                    clearDataOnSuccess(res);
                  });
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      {!isTeamMember() && (
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          style={{ marginTop: 10, width: "100%" }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              style={{ fontWeight: 600 }}
              className={Styles.navyBlueColor}
            >
              Remove Account
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container item xs={12}>
              <Grid container item xs={12}>
                <Grid
                  item
                  container
                  xs={8}
                  className={Styles.sqGreyBorder}
                  style={{ marginTop: 8, borderRadius: 4 }}
                >
                  <Grid
                    item
                    container
                    xs={12}
                    style={{ borderBottom: "1px solid #17174C1F" }}
                  >
                    <Grid
                      item
                      container
                      xs={7}
                      style={{ alignItems: "center" }}
                    >
                      <Radio
                        checked={deactivateAccount}
                        onChange={(e) => {
                          setDeactivateAccount(e.target.checked);
                          if (e.target.checked) {
                            setOpenDeactivateAccountDialog(true);
                          }
                        }}
                        name="radio-button-demo"
                        className={"sqBlueRadio"}
                      />
                      <Typography
                        variant="subtitle1"
                        className={Styles.navyBlueColor}
                      >
                        Deactivate Account
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={5}
                      style={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        paddingRight: 16,
                      }}
                    >
                      <Typography style={{ color: "#868DA6" }}>
                        This is temporary
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    style={{ alignItems: "center", padding: 12 }}
                    className={Styles.navyBlueColor}
                  >
                    When you deactivate your account, your name, profile picture
                    will be removed but you will be able to retrieve your
                    customers, profile, documents and messages upon
                    reactivation.
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  xs={8}
                  className={Styles.sqGreyBorder}
                  style={{ marginTop: 8, borderRadius: 4 }}
                >
                  <Grid
                    item
                    container
                    xs={12}
                    style={{ borderBottom: "1px solid #17174C1F" }}
                  >
                    <Grid
                      item
                      container
                      xs={7}
                      style={{ alignItems: "center" }}
                    >
                      <Radio
                        checked={deleteAccount}
                        // onChange={(e) => {
                        //   setDeleteAccount(e.target.checked);
                        //   if (e.target.checked) {
                        //     setOpenDeleteAccountDialog(true);
                        //   }
                        // }}
                        name="radio-button-demo"
                        className={"sqBlueRadio"}
                      />
                      <Typography
                        variant="subtitle1"
                        className={Styles.navyBlueColor}
                      >
                        Permanently Delete Account
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={5}
                      style={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        paddingRight: 16,
                      }}
                    >
                      <Typography style={{ color: "#868DA6" }}>
                        This is permanent
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    style={{ alignItems: "center", padding: 12 }}
                    className={Styles.navyBlueColor}
                  >
                    <Grid item xs={12}>
                      <Typography className={Styles.navyBlueColor}>
                        When you delete your Souqh account, you won’t be able to
                        retrieve
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={Styles.navyBlueColor}>
                        1. Customers,
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={Styles.navyBlueColor}>
                        2. Profile
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={Styles.navyBlueColor}>
                        3. Documents
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={Styles.navyBlueColor}>
                        4. Messages
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider
                orientation="horizontal"
                variant="fullWidth"
                style={{ width: "100%", marginTop: 32 }}
              />
              <Grid
                container
                item
                xs={12}
                justifyContent="flex-end"
                style={{ marginTop: 20 }}
              >
                <Button
                  variant="outlined"
                  className={Styles.navyBlueColor}
                  style={{ marginRight: 12, textTransform: "capitalize" }}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    marginRight: 12,
                    textTransform: "capitalize",
                    padding: "0px 24px",
                  }}
                  className={Styles.primaryBtnAccent}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}
      {/* <AppBar
        position="fixed"
        style={{
          top: "auto",
          bottom: 0,
          left: 380,
          width: "75%",
          backgroundColor: "#FFFFFF",
          boxShadow: "0px -3px 20px #0000001c",
        }}
      >
        <Toolbar>
          <Grid container>
            <Grid
              container
              item
              xs={12}
              justifyContent="flex-end"
              style={{ marginRight: 108 }}
            >
              <Button
                variant="outlined"
                className={Styles.navyBlueColor}
                style={{ marginRight: 12, textTransform: "capitalize" }}
              >
                Cancel
              </Button>
              <Button
                style={{
                  marginRight: 12,
                  textTransform: "capitalize",
                  padding: "0px 24px",
                }}
                className={Styles.primaryBtnAccent}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar> */}
    </Grid>
  );
}
