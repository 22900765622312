import {
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  makeStyles,
  useMediaQuery,
  useTheme,
  Paper,
  CardMedia,
  Tooltip,
  Hidden,
  IconButton,
} from "@material-ui/core";
import CircleIcon from "@mui/icons-material/Circle";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import DashboardCard from "../DashboardCard";
import DashboardChip from "../DashboardChip";
import Styles from "../../Styles.module.css";
import { useDocuments } from "souqh-react-redux-hooks/homeBuyer/useDocuments";
import clsx from "clsx";
import SPUploadWizard from "../common/UploadWizard/SPUploadWizard";
import InviteSPDialog from "../ReferralManagement/InviteSPdialog";
import InviteClientsDialog from "../ReferralManagement/InviteClientsDialog";
import { useSPUploadWizard } from "souqh-react-redux-hooks/serviceProvider/documents/useSPUploadWizard";
import { ACTION_REQUIRED } from "./homeBuyerConstants";
import HomeBuyingJourneyCard from "./HomeBuyingJourney/HomeBuyingJourneyCard";
import ServiceRequestsWidget from "./ServiceRequestsWidget";
import { useDropzone } from "react-dropzone";
import ServiceProvidersWidget from "./ServiceProvidersWidget";
import HBKeyStatisticsWidget from "./HBKeyStatisticsWidget";
import HelpCenterWidget from "../common/HelpCenterWidget";
import UpcomingEventsWidget from "../common/UpcomingEventsWidget";
import PendingActionsWidget from "../common/PendingActionsWidget";
import {
  DOMAIN_URL,
  EXPIRING_SOON,
  MEETING_CREATE_MODE,
  WAITING_FOR_OTHER,
} from "../ServiceProvider/serviceProviderConstants";
import { useUpcomingEeventsPendingActions } from "souqh-react-redux-hooks/serviceProvider/upcomingEeventsPendingActions/useUpcomingEeventsPendingActions";
import Toaster from "../../utils/Toaster";
import * as moment from "moment";
import { useSQCalendar } from "souqh-react-redux-hooks/common/useSQCalendar";
import SmallCalendarWidget from "../common/SQCalender/SmallCalendarWidget";
import CreateMeetingDialog from "../common/SQCalender/CreateMeetingDialog";
import { useUpcomingEvents } from "souqh-react-redux-hooks/serviceProvider/upcomingEeventsPendingActions/useUpcomingEvents";
import ShareIcon from "@material-ui/icons/Share";
import { isMobileDevice, isTabletDevice } from "../../utils/AppUtils";
import HomeOwnershipTimelineCard from "./HomeBuyingJourney/HomeOwnershipTimelineCard";
import { useExplore } from "souqh-react-redux-hooks/homeBuyer/useExplore";
import useFeaturedStoreFront from "souqh-react-redux-hooks/homeBuyer/useFeaturedStoreFront";
import PublicIcon from "@material-ui/icons/Public";
import MessageIcon from "@material-ui/icons/Message";
import DateRangeIcon from "@material-ui/icons/DateRange";
import RoomIcon from "@material-ui/icons/Room";
import WebStyles from "../../Styles.module.css";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import FeaturedStoreFront from "./Explore/FeaturedStoreFront";
import { removeDuplicates } from "../../utils/CommonUtils";

const useStyles = makeStyles((theme) => ({
  cardBorder: {
    borderRadius: 8,
  },
  grid: {
    marginBottom: 20,
  },
  cardBackgroundImg: {
    backgroundImage: "url('/app/images/teal_bg_referral.png')",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: 430,
  },
  buttons: {
    textTransform: "capitalize",
    fontWeight: "bold",
    fontSize: "1rem",
  },
  buttonGrids: {
    textAlign: "center",
    borderRadius: 8,
    backgroundColor: "white",
  },
  buttonBase: {
    height: "100%",
    display: "block",
  },
  buttonBaseBorder: {
    borderRight: "1px solid #0E1C4E0A",
  },
  innerGrids: {
    textAlign: "center",
    marginTop: 18,
  },
  uploadBtn: {
    borderRadius: 6,
    textTransform: "capitalize",
  },

  primarColor: {
    color: "#0e1c4e",
  },
  secondarColor: {
    color: "#F86C6B",
  },
  marginTop30: {
    marginTop: 30,
  },
  marginTop10: {
    marginTop: 10,
  },
  marginLeft: {
    marginLeft: 10,
  },
  inputs: {
    marginTop: 0,
    marginBottom: 0,
  },
  marginTop25: {
    marginTop: 25,
  },
  divider: {
    margin: "5px 20px",
  },
  disabledSearch: {
    color: "#17174C1F",
  },
  enabledButton: {
    backgroundColor: "#FA7E61",
  },
  textInput: {
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
  },
  bodyBackgroundImg: {
    backgroundImage:
      "url('/app/images/Explore-Section/blue_bg_shape.svg'), url('/app/images/Explore-Section/orange_shape.svg')",
    backgroundRepeat: "no-repeat, no-repeat",
    backgroundPosition: isMobileDevice() ? "0% 10%,100% 30%" : "0% 5%,100% 95%",
    backgroundSize: isMobileDevice() ? "18%, 12%" : "8%, auto",
    top: 0,
    overflow: "auto",
  },
  appBar: {
    backgroundColor: "#17174c",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  marginTop: {
    marginTop: 20,
  },
  cardBorder: {
    borderRadius: "8px 0 8px 8px",
    padding: 15,
  },
  media: {
    height: 0,
    paddingTop: "75%", // 4:3
    backgroundSize: "85% 100%",
  },
  reLativeCard: {
    position: "relative",
  },
  overlay: {
    top: -23,
    right: -29,
    position: "absolute",
    width: 120,
    height: 120,
  },
  clickableCard: {
    cursor: "pointer",
  },
  svgIcon: {
    color: "white",
    fill: "white",
    stroke: "#0e1c4e",
    width: "0.75rem !important",
    "&.selected": {
      fill: "#0e1c4e",
    },
  },
  svgIconButton: {
    padding: 6,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

function HelpCenterWidgetsection({ userInfo }) {
  return (
    <Grid item xs={12} md={6} lg={3}>
      <DashboardCard title="Get Help">
        <HelpCenterWidget userInfo={userInfo} />
      </DashboardCard>
    </Grid>
  );
}

export default function HBDashboard() {
  const classes = useStyles();
  let history = useHistory();
  const [openInviteSPDialog, setOpenInviteSPDialog] = useState(false);
  const [openInviteClientsDialog, setOpenInviteClientsDialog] = useState(false);
  const [hbJourneyView, setHbJourneyView] = useState("home_buying");
  const [featuredSFs, setFeaturedSFs] = useState([]);

  const theme = useTheme();
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));

  const numberOfCards = isMobileDevice()
    ? 1
    : isTabletDevice()
    ? 2
    : xlUp
    ? 6
    : 4;

  const { shuffledItems, activeIndex, setActiveIndex } = useFeaturedStoreFront(
    featuredSFs,
    numberOfCards
  );

  const {
    categories,
    updateIntercomStatus,
    getTabWiseCategoriesList,
    actionRequiredCount,
    waitingForOtherCount,
    expiringSoonCount,
  } = useDocuments();

  const {
    onUploadFileChange,
    processUploadedFiles,
    openUploadWizard,
    actions: { setOpenUploadWizard, cleanUploadFileDataOnClose },
  } = useSPUploadWizard();

  const {
    upcomingEventsList,
    getUpcomingCalendarEvents,
    getAttendeesTooltip,
    handleEventDialogClose,
    cancelEvent,
    eventSelected,
    setEventSelected,
    clearUpcomingEventsDataDestroy,
  } = useUpcomingEvents();

  const {
    actions: {
      setOpenCreateMeetingDialog,
      setMeetingMode,
      setOpenConfirmCanelMeetingDialog,
    },
    currentCalendarDate,
    setCurrentCalendarDate,
    calendarEvents,
    openCreateMeetingDialog,
    userInfo,
    meetingMode,
    selectedCalendarEvent,
    cancelMeeting,
    title,
    setTitle,
    meetingDate,
    setMeetingDate,
    meetingLink,
    meetingPhoneNumber,
    meetingLocation,
    setMeetingLocation,
    meetingAttachments,
    description,
    setDescription,
    uploadRef,
    textAreaRef,
    contactInputRef,
    handleDialogClose,
    processAttachment,
    removeAttachment,
    copyToClipboard,
    meetingStartTime,
    setMeetingStartTime,
    meetingEndTime,
    setMeetingEndTime,
    addCustomItem,
    selectedContacts,
    customContact,
    setCustomContact,
    allContacts,
    removeSelectedContact,
    addItemOnSelect,
    getAllContacts,
    invalidTimes,
    setInvalidTimes,
    setMeetingData,
    removeMeetingLink,
    addMeetingLink,
    setAddMeetingLink,
    setupEmptyData,
    saveMeeting,
    validateTimeSlot,
    isFullDayEvent,
    setIsFullDayEvent,
    getCalendarEvents,
    eventUserId,
    hasFilledMandatoryData,
    updateMeetingStatus,
    downlodAttachment,
    openConfirmCanelMeetingDialog,
    filteredContacts,
    downloadCalendarAttachment,
    getCalendarEventDetails,
    getAllCalendarEvents,
    setMainCalendarView,
    mainCalendarView,
    setStartDateTimestamp,
    setEndDateTimestamp,
    startDateTimestamp,
    endDateTimestamp,
  } = useSQCalendar({
    calendarListUpdater: true,
    upcomingEventsUpdater: getUpcomingCalendarEvents,
  });

  const {
    actions: { setEventsTab },
    getAllPendingActions,
    pendingActionsList,
    handlePendingAction,
    openAddReviewDialog,
    setOpenAddReviewDialog,
    openHomeBuyingJourneyDialog,
    setOpenHomeBuyingJourneyDialog,
    openViewInvoiceDialog,
    setOpenViewInvoiceDialog,
    viewInvoiceDialogData,
    openSignDocumentDialog,
    setOpenSignDocumentDialog,
    signDocumentItem,
    setSignDocumentItem,
    reloadPendingActionData,
    setEventIndex,
    deletePendingAction,
    setOpenViewTagDialog,
    openViewTagDialog,
    viewTagDialogData,
    openRecommendedSPDialog,
    setOpenRecommendedSPDialog,
    recommendedSpData,
    openRequestedDocumentDialog,
    setOpenRequestedDocumentDialog,
    requestedDocumentData,
    openDocumentAccessRequestDialog,
    setOpenDocumentAccessRequestDialog,
    requestedDocAccessName,
    documentAccessRequestorSP,
    acceptDocumentAccessRequest,
    openDocumetDialog,
    setOpenDocumetDialog,
    documentItem,
    setDocumentItem,
    downloadUrl,
    docCategoryId,
    docNameForDownload,
    docAccessRequestedData,
  } = useUpcomingEeventsPendingActions();

  const {
    getFeaturedStorefrontsForWebsite,
    featuredStoreFrontsForWebsite,
    addImpression,
    getAllPreferredPartners,
    preferredPartners
  } = useExplore();

  const onDrop = (acceptedFiles) => {
    processUploadedFiles(acceptedFiles, null);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const [currentIndex, setCurrentIndex] = useState(0);
  // FIX ME: remove all commented code in this file later if not required
  // const sliceFactor = lgUp ? 0 : mdUp ? 2 : 1;
  // let formattedArray = sliceFactor
  //   ? featuredSFs.slice(currentIndex, currentIndex + sliceFactor)
  //   : featuredSFs;

  useEffect(() => {
    window.scrollTo(0, 0);
    getTabWiseCategoriesList(ACTION_REQUIRED);
    // getCalendarEvents();
    getAllCalendarEvents();
    getUpcomingCalendarEvents(10);
    getAllContacts();
    getAllPreferredPartners(userInfo);
    // if (window.location.origin === "https://app.souqh.ca") {
    if (window.location.href.includes(DOMAIN_URL)) {
      getFeaturedStorefrontsForWebsite();
    }
    addImpression("Dashboard");
    return () => {
      cleanUploadFileDataOnClose();
    };
  }, []);

  useEffect(() => {
    setStartAndEndDates();
  }, [currentCalendarDate, mainCalendarView]);

  useEffect(() => {
    getAllCalendarEvents(true);
  }, [startDateTimestamp, endDateTimestamp]);

  useEffect(() => {
    let final = [];
    userInfo.storeFront &&
      userInfo.storeFront.length &&
      userInfo.storeFront.map((item) => final.push(item));
    userInfo.journeyStoreFront &&
      userInfo.journeyStoreFront.length &&
      userInfo.journeyStoreFront.map((item) => final.push(item));
    featuredStoreFrontsForWebsite.length &&
      featuredStoreFrontsForWebsite.map((item) => final.push(item));
    const uniqueSFs = removeDuplicates(final, "storeFrontId");
    setFeaturedSFs(uniqueSFs);
  }, [userInfo, featuredStoreFrontsForWebsite.length]);

  useEffect(() => {
    if (!userInfo) {
      clearUpcomingEventsDataDestroy();
    } else {
      if (userInfo.clientHomeType === "HOME_BUYER") {
        setHbJourneyView("home_buying");
      } else {
        setHbJourneyView("home_ownership");
      }
    }
  }, [userInfo]);

  const setStartAndEndDates = () => {
    // let currentDate = moment();
    let weekStart = moment(currentCalendarDate).clone().startOf("week");
    let weekEnd = moment(currentCalendarDate).clone().endOf("week");

    let monthStart = moment(currentCalendarDate).clone().startOf("month");
    let monthEnd = moment(currentCalendarDate).clone().endOf("month");
    let startDate = mainCalendarView === "month" ? monthStart : weekStart;
    const endDate = mainCalendarView === "month" ? monthEnd : weekEnd;
    const startDateTS = moment(startDate).unix();
    const endDateTS = moment(endDate).unix();
    setStartDateTimestamp(startDateTS);
    setEndDateTimestamp(endDateTS);
    setTimeout(3000);
  };

  const uploadFileCard = (
    <Grid container style={{ padding: "8px 8px 32px 8px" }}>
      <Grid
        container
        item
        xs={12}
        alignContent="center"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid
          item
          xs={4}
          className={clsx(classes.innerGrids, Styles.navyBlueColor)}
          onClick={() => {
            history.push("/app/homebuyer/documents?tabName=" + ACTION_REQUIRED);
          }}
          style={{ paddingRight: 5, cursor: "pointer" }}
        >
          <Paper
            elevation={0}
            style={{
              border: "1px solid #0E1C4E1F",
              height: "100%",
              padding: 5,
              minHeight: 59,
            }}
          >
            <Typography
              style={{ fontSize: xlUp ? 24 : 18, fontWeight: 700 }}
              className={Styles.navyBlueColor}
            >
              {actionRequiredCount}
            </Typography>
            <Typography
              style={{ fontSize: isMobileDevice() || xlUp ? 14 : 11 }}
              className={Styles.navyBlueColor}
            >
              Action Required
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          xs={4}
          className={classes.innerGrids}
          onClick={() => {
            history.push(
              "/app/homebuyer/documents?tabName=" + WAITING_FOR_OTHER
            );
          }}
          style={{ paddingRight: 2, cursor: "pointer" }}
        >
          <Paper
            elevation={0}
            style={{
              border: "1px solid #0E1C4E1F",
              height: "100%",
              padding: 5,
              minHeight: 59,
            }}
          >
            <Typography
              style={{ fontSize: xlUp ? 24 : 18, fontWeight: 700 }}
              className={Styles.navyBlueColor}
            >
              {waitingForOtherCount}
            </Typography>
            <Typography
              style={{ fontSize: isMobileDevice() || xlUp ? 14 : 11 }}
              className={Styles.navyBlueColor}
            >
              Waiting for Others
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          xs={4}
          className={classes.innerGrids}
          onClick={() => {
            history.push("/app/homebuyer/documents?tabName=" + EXPIRING_SOON);
          }}
          style={{ paddingLeft: 3, cursor: "pointer" }}
        >
          <Paper
            elevation={0}
            style={{
              border: "1px solid #0E1C4E1F",
              height: "100%",
              padding: 5,
              minHeight: 59,
            }}
          >
            <Typography
              style={{ fontSize: xlUp ? 24 : 18, fontWeight: 700 }}
              className={Styles.navyBlueColor}
            >
              {expiringSoonCount}
            </Typography>
            <Typography
              style={{ fontSize: isMobileDevice() || xlUp ? 14 : 11 }}
              className={Styles.navyBlueColor}
            >
              Expiring
            </Typography>
            <Typography
              style={{ fontSize: isMobileDevice() || xlUp ? 14 : 11 }}
              className={Styles.navyBlueColor}
            >
              Soon
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      {/* <Grid item xs={12} style={{ textAlign: "center", marginTop: 35 }}>
        <Typography>Drop Documents to get started</Typography>
      </Grid> */}
      <Grid
        item
        xs={12}
        style={{
          textAlign: "center",
          marginTop: 13,
          marginBottom: 10,
        }}
      >
        <div
          style={{
            height: "100%",
            border: "1px dashed #0E1C4E1F",
            paddingTop: "40px",
          }}
          {...getRootProps()}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <input {...getInputProps()} />
          <Typography align="center" style={{ color: "#868DA6", fontSize: 11 }}>
            Drop documents to get started
          </Typography>
          <input
            style={{ display: "none" }}
            id="contained-button-file"
            multiple
            type="file"
            onChange={onUploadFileChange}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="outlined"
              className={Styles.navyBlueColorForce}
              component="span"
              style={{
                marginLeft: 5,
                textTransform: "capitalize",
                width: 164,
                marginTop: 16,
              }}
            >
              Upload Document
            </Button>
          </label>
        </div>
      </Grid>
    </Grid>
  );

  return (
    <>
      <InviteSPDialog
        openInviteSPDialog={openInviteSPDialog}
        setOpenInviteSPDialog={setOpenInviteSPDialog}
      />
      <InviteClientsDialog
        openInviteClientsDialog={openInviteClientsDialog}
        setOpenInviteClientsDialog={setOpenInviteClientsDialog}
      />
      <CreateMeetingDialog
        openCreateMeetingDialog={openCreateMeetingDialog}
        setOpenCreateMeetingDialog={setOpenCreateMeetingDialog}
        meetingMode={meetingMode}
        setMeetingMode={setMeetingMode}
        cancelMeeting={cancelMeeting}
        selectedCalendarEvent={selectedCalendarEvent}
        title={title}
        setTitle={setTitle}
        meetingDate={meetingDate}
        setMeetingDate={setMeetingDate}
        meetingLink={meetingLink}
        meetingPhoneNumber={meetingPhoneNumber}
        meetingLocation={meetingLocation}
        setMeetingLocation={setMeetingLocation}
        meetingAttachments={meetingAttachments}
        description={description}
        setDescription={setDescription}
        uploadRef={uploadRef}
        textAreaRef={textAreaRef}
        contactInputRef={contactInputRef}
        meetingStartTime={meetingStartTime}
        setMeetingStartTime={setMeetingStartTime}
        meetingEndTime={meetingEndTime}
        setMeetingEndTime={setMeetingEndTime}
        handleDialogClose={handleDialogClose}
        processAttachment={processAttachment}
        removeAttachment={removeAttachment}
        copyToClipboard={copyToClipboard}
        addCustomItem={addCustomItem}
        selectedContacts={selectedContacts}
        customContact={customContact}
        setCustomContact={setCustomContact}
        allContacts={allContacts}
        removeSelectedContact={removeSelectedContact}
        addItemOnSelect={addItemOnSelect}
        invalidTimes={invalidTimes}
        setInvalidTimes={setInvalidTimes}
        setMeetingData={setMeetingData}
        removeMeetingLink={removeMeetingLink}
        addMeetingLink={addMeetingLink}
        setAddMeetingLink={setAddMeetingLink}
        setupEmptyData={setupEmptyData}
        saveMeeting={saveMeeting}
        validateTimeSlot={validateTimeSlot}
        isFullDayEvent={isFullDayEvent}
        setIsFullDayEvent={setIsFullDayEvent}
        eventUserId={eventUserId}
        userInfo={userInfo}
        hasFilledMandatoryData={hasFilledMandatoryData}
        updateMeetingStatus={updateMeetingStatus}
        downlodAttachment={downlodAttachment}
        filteredContacts={filteredContacts}
        downloadCalendarAttachment={downloadCalendarAttachment}
        openConfirmCanelMeetingDialog={openConfirmCanelMeetingDialog}
        setOpenConfirmCanelMeetingDialog={setOpenConfirmCanelMeetingDialog}
      />
      <DashboardChip xlUp={xlUp} />
      <Grid item xs={12} md={12} lg={9} className={classes.grid}>
        {hbJourneyView === "home_buying" ? (
          <HomeBuyingJourneyCard
            xlUp={xlUp}
            isMobileDevice={isMobileDevice}
            setHbJourneyView={setHbJourneyView}
            hbJourneyView={hbJourneyView}
          />
        ) : (
          <HomeOwnershipTimelineCard
            xlUp={xlUp}
            isMobileDevice={isMobileDevice}
            setHbJourneyView={setHbJourneyView}
            hbJourneyView={hbJourneyView}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3} className={classes.grid}>
        <Card
          raised
          style={{
            borderRadius: 8,
            marginRight: !isMobileDevice() && 20,
            height: "100%",
          }}
          className={classes.cardBackgroundImg}
        >
          <CardContent>
            <Grid container>
              <Grid container item xs={1} alignContent="center">
                <ShareIcon style={{ fontSize: 16, color: "white" }} />
              </Grid>
              <Grid item xs={11} style={{ paddingLeft: 5 }}>
                <Typography variant="subtitle1" style={{ color: "white" }}>
                  Make a Referral
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant={isMobileDevice() ? "h6" : xlUp ? "h2" : "h3"}
                style={{ marginTop: xlUp ? 20 : 8, color: "white" }}
              >
                Earn $50 Credit
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{
                  marginTop: xlUp || isTabletDevice() ? 20 : 8,
                  color: "white",
                  lineHeight: 1.25,
                }}
              >
                Help us help you by inviting your friends and service providers
                you work with!
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                marginTop: xlUp || isTabletDevice() ? 50 : 15,
              }}
              className={classes.buttonGrids}
            >
              <Button
                className={clsx(Styles.navyBlueColorForce, classes.buttons)}
                onClick={() => setOpenInviteSPDialog(true)}
              >
                Invite Service Providers
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                marginTop: 8,
              }}
              className={classes.buttonGrids}
            >
              <Button
                className={clsx(Styles.navyBlueColorForce, classes.buttons)}
                onClick={() => setOpenInviteClientsDialog(true)}
              >
                Invite Friends
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} lg={3} className={classes.grid}>
        <DashboardCard
          title="Calendar"
          redirectTo={(event) => {
            event && event.stopPropagation();
            history.push(`/app/homebuyer/calendar`);
          }}
          onAddClick={(e) => {
            setMeetingMode(MEETING_CREATE_MODE);
            setOpenCreateMeetingDialog(true);
          }}
        >
          <Grid item xs={12} style={{ height: "100%", width: "100%" }}>
            <SmallCalendarWidget
              eventsData={calendarEvents}
              width={"100%"}
              height={"95%"}
              currentCalendarDate={currentCalendarDate}
              setCurrentCalendarDate={setCurrentCalendarDate}
              showToolbar={true}
              navigateOnDateClick={true}
              setMainCalendarView={setMainCalendarView}
            />
          </Grid>
        </DashboardCard>
      </Grid>
      <Grid item xs={12} md={6} lg={3} className={classes.grid}>
        <DashboardCard
          title="Upcoming Events"
          redirectTo={(event) => {
            event && event.stopPropagation();
            history.push("/app/homebuyer/events");
            setEventsTab(0);
          }}
          onAddClick={(e) => {
            setMeetingMode(MEETING_CREATE_MODE);
            setOpenCreateMeetingDialog(true);
          }}
        >
          <UpcomingEventsWidget
            xlUp={xlUp}
            upcomingEventsList={upcomingEventsList}
            getAttendeesTooltip={getAttendeesTooltip}
            cancelEvent={cancelEvent}
            setEventSelected={setEventSelected}
            getCalendarEventDetails={getCalendarEventDetails}
            setMeetingData={setMeetingData}
            setMeetingMode={setMeetingMode}
            setOpenCreateMeetingDialog={setOpenCreateMeetingDialog}
          />
        </DashboardCard>
      </Grid>
      <Grid item xs={12} md={6} lg={3} className={classes.grid}>
        <DashboardCard
          title="Pending Actions"
          redirectTo={() => {
            history.push("/app/homebuyer/events?tabIndex=1");
          }}
        >
          <PendingActionsWidget
            setEventsTab={setEventsTab}
            getAllPendingActions={getAllPendingActions}
            pendingActionsList={pendingActionsList}
            userInfo={userInfo}
            handlePendingAction={handlePendingAction}
            openAddReviewDialog={openAddReviewDialog}
            setOpenAddReviewDialog={setOpenAddReviewDialog}
            openHomeBuyingJourneyDialog={openHomeBuyingJourneyDialog}
            setOpenHomeBuyingJourneyDialog={setOpenHomeBuyingJourneyDialog}
            openViewInvoiceDialog={openViewInvoiceDialog}
            setOpenViewInvoiceDialog={setOpenViewInvoiceDialog}
            viewInvoiceDialogData={viewInvoiceDialogData}
            openSignDocumentDialog={openSignDocumentDialog}
            setOpenSignDocumentDialog={setOpenSignDocumentDialog}
            signDocumentItem={signDocumentItem}
            setSignDocumentItem={setSignDocumentItem}
            reloadPendingActionData={reloadPendingActionData}
            setEventIndex={setEventIndex}
            deletePendingAction={deletePendingAction}
            setOpenViewTagDialog={setOpenViewTagDialog}
            openViewTagDialog={openViewTagDialog}
            viewTagDialogData={viewTagDialogData}
            openRecommendedSPDialog={openRecommendedSPDialog}
            setOpenRecommendedSPDialog={setOpenRecommendedSPDialog}
            recommendedSpData={recommendedSpData}
            openRequestedDocumentDialog={openRequestedDocumentDialog}
            setOpenRequestedDocumentDialog={setOpenRequestedDocumentDialog}
            requestedDocumentData={requestedDocumentData}
            openDocumentAccessRequestDialog={openDocumentAccessRequestDialog}
            setOpenDocumentAccessRequestDialog={
              setOpenDocumentAccessRequestDialog
            }
            requestedDocAccessName={requestedDocAccessName}
            documentAccessRequestorSP={documentAccessRequestorSP}
            acceptDocumentAccessRequest={acceptDocumentAccessRequest}
            openDocumetDialog={openDocumetDialog}
            setOpenDocumetDialog={setOpenDocumetDialog}
            documentItem={documentItem}
            setDocumentItem={setDocumentItem}
            downloadUrl={downloadUrl}
            docCategoryId={docCategoryId}
            docNameForDownload={docNameForDownload}
            docAccessRequestedData={docAccessRequestedData}
          />
        </DashboardCard>
      </Grid>
      <Grid item xs={12} md={6} lg={3} className={classes.grid}>
        <DashboardCard
          title="Document Upload"
          redirectTo={() => {
            history.push("/app/homebuyer/documents?tabName=" + ACTION_REQUIRED);
          }}
        >
          <div
            style={{ height: "100%" }}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <input {...getInputProps()} />
            {uploadFileCard}
          </div>
        </DashboardCard>
      </Grid>
      <SPUploadWizard
        openUploadWizard={openUploadWizard}
        setOpenUploadWizard={setOpenUploadWizard}
        categories={categories}
        updateIntercomStatus={updateIntercomStatus}
      />
      <Grid item xs={12} md={6} lg={3} className={classes.grid}>
        <DashboardCard
          title="Active Service Providers"
          redirectTo={() => {
            history.push("/app/homebuyer/serviceproviders?tabIndex=0");
          }}
        >
          <ServiceProvidersWidget xlUp={xlUp} />
        </DashboardCard>
      </Grid>
      <Grid item xs={12} lg={9} className={classes.grid}>
        <DashboardCard
          title="Service Requests"
          redirectTo={() => {
            history.push("/app/homebuyer/serviceproviders?tabIndex=1");
          }}
        >
          <ServiceRequestsWidget xlUp={xlUp} />
        </DashboardCard>
      </Grid>
      <Grid item xs={12} lg={9} className={classes.grid}>
        <DashboardCard title="Key Statistics">
          <HBKeyStatisticsWidget xlUp={xlUp} />
        </DashboardCard>
      </Grid>
      {isTabletDevice() ? (
        <Grid
          container
          item
          xs={12}
          className={classes.grid}
          justifyContent="center"
        >
          <HelpCenterWidgetsection userInfo={userInfo} />
        </Grid>
      ) : (
        <HelpCenterWidgetsection userInfo={userInfo} />
      )}

      {/* <Grid
        item
        container
        xs={12}
        style={{ marginBottom: 80, marginTop: "1rem" }}
      >
        <Grid item container xs={12}>
          <Grid
            className={clsx(
              classes.primarColor,
              WebStyles.hankrndFont,
              WebStyles.samonHeaderTxt
            )}
            style={{
              fontWeight: 700,
              fontSize: isMobileDevice() ? "1rem" : "1.5rem",
              lineHeight: 1.6,
            }}
          >
            Featured Storefronts
          </Grid> */}
      {/* <Grid container item xs={12} spacing={isMobileDevice() ? 0 : 3}>
            <FeaturedStoreFront
              bgColor="#66D7D140"
              storeFrontList={shuffledItems}
            /> */}

            {/* FIX ME: remove below commented section later if not required */}
            {/* <Hidden lgUp>
              <Grid
                item
                xs={1}
                container
                style={{
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <ArrowBackIosIcon
                  disabled={currentIndex < 1}
                  onClick={() => {
                    if (currentIndex > 0) {
                      setCurrentIndex(currentIndex - 1);
                    }
                  }}
                  style={{
                    width: 36,
                    height: 36,
                    fontSize: 36,
                    cursor: currentIndex > 0 ? "pointer" : "default",
                    fill: "#0e1c4e",
                    opacity: currentIndex > 0 ? 1 : "0.5",
                  }}
                />
              </Grid>
            </Hidden> */}

            {/* <Hidden lgUp>
              <Grid
                item
                xs={1}
                container
                style={{
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <ArrowForwardIosIcon
                  onClick={() => {
                    if (currentIndex < shuffledItems.length - sliceFactor) {
                      setCurrentIndex(currentIndex + 1);
                    }
                  }}
                  style={{
                    width: 36,
                    height: 36,
                    fontSize: 36,
                    cursor:
                      currentIndex < shuffledItems.length - 1
                        ? "pointer"
                        : "default",
                    fill: "#0e1c4e",
                    opacity:
                      currentIndex < shuffledItems.length - 1 ? 1 : "0.5",
                  }}
                />
              </Grid>
            </Hidden> */}
      {/* </Grid> */}

      {/* {featuredSFs.length && featuredSFs.length / numberOfCards > 1 ? (
            <Grid
              style={{
                justifyContent: "center",
                marginTop: 10,
                overflowX: "auto",
                flexWrap: "nowrap",
              }}
              container
              item
              xs={12}
            >
              {Array(Math.ceil(featuredSFs.length / numberOfCards))
                .fill(1)
                .map((item, index) => (
                  <IconButton
                    classes={{ root: classes.svgIconButton }}
                    onClick={() => setActiveIndex(index)}
                  >
                    <CircleIcon
                      classes={{ root: classes.svgIcon }}
                      className={activeIndex === index ? "selected" : ""}
                    />
                  </IconButton>
                ))}
            </Grid>
          ) : null}
        </Grid>
      </Grid> */}

      {userInfo.referredSPName && (<Grid
        item
        container
        xs={12}
        style={{ marginBottom: 80, marginTop: "1rem" }}
      >
        <Grid item container xs={12}>
          <Grid
            className={clsx(
              classes.primarColor,
              WebStyles.hankrndFont,
              WebStyles.samonHeaderTxt
            )}
            style={{
              fontWeight: 700,
              fontSize: isMobileDevice() ? "1rem" : "1.5rem",
              lineHeight: 1.6,
            }}
          >
            Preferred Partners for {userInfo.referredSPName}
          </Grid>
          <Grid container item xs={12} spacing={isMobileDevice() ? 0 : 3}>
            <FeaturedStoreFront
              bgColor="#66D7D140"
              storeFrontList={preferredPartners}
            />
          </Grid>

          {preferredPartners.length && preferredPartners.length / numberOfCards > 1 ? (
            <Grid
              style={{
                justifyContent: "center",
                marginTop: 10,
                overflowX: "auto",
                flexWrap: "nowrap",
              }}
              container
              item
              xs={12}
            >
              {Array(Math.ceil(preferredPartners.length / numberOfCards))
                .fill(1)
                .map((item, index) => (
                  <IconButton
                    classes={{ root: classes.svgIconButton }}
                    onClick={() => setActiveIndex(index)}
                  >
                    <CircleIcon
                      classes={{ root: classes.svgIcon }}
                      className={activeIndex === index ? "selected" : ""}
                    />
                  </IconButton>
                ))}
            </Grid>
          ) : null}
        </Grid>
      </Grid>)}
    </>
  );
}
