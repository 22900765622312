import {
  AppBar,
  Box,
  Button,
  ButtonBase,
  Chip,
  Divider,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  Link as MaterialLink,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CircleIcon from "@mui/icons-material/Circle";
import FeaturedStoreFront from "./FeaturedStoreFront";
import { matchSorter } from "match-sorter";
import { useExplore } from "souqh-react-redux-hooks/homeBuyer/useExplore";
import { isEmpty, isEqual } from "lodash";
import { Link } from "react-scroll";
import StoreFrontSearchFilters from "./StoreFrontSearchFilters";
import { useStorefrontPage } from "souqh-react-redux-hooks/homeBuyer/useStorefrontPage";
import Styles from "../../../Styles.module.css";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";
import { useSQQuery } from "souqh-react-redux-hooks/common/useSQQuery";
import { filter } from "lodash";
import InviteSPDialog from "../../ReferralManagement/InviteSPdialog";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import useFeaturedStoreFront from "souqh-react-redux-hooks/homeBuyer/useFeaturedStoreFront";
import { useHistory } from "react-router";
import {
  DOMAIN_URL,
  HOME_BUYER_USER,
  SERVICE_PROVIDER_USER,
} from "../../ServiceProvider/serviceProviderConstants";
import { removeDuplicates } from "../../../utils/CommonUtils";
import { useParams } from "react-router-dom";
import BroadcastServiceRequestDialog from "./BroadcastServiceRequestDialog";
import RentalInquiryFormDialog from "./RentalInquiryFormDialog";
import { useMarketplaceWidget } from "souqh-react-redux-hooks/common/useMarketplaceWidget";
import { useDispatch } from "react-redux";
import { SET_EXPLORE_SERVICES } from "souqh-redux/reducers/actionConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#0e1c4e0a",
  },
  primarColor: {
    color: "#0e1c4e",
  },
  secondarColor: {
    color: "#F86C6B",
  },
  backgroundColor: {
    backgroundColor: "#0e1c4e",
  },
  marginTop30: {
    marginTop: 30,
  },
  marginTop10: {
    marginTop: 10,
  },
  marginLeft: {
    marginLeft: 10,
  },
  chip: {
    fontSize: "1rem",
  },
  cardBorder: {
    padding: 10,
  },
  inputs: {
    marginTop: 0,
    marginBottom: 0,
  },
  marginTop25: {
    marginTop: 25,
  },
  divider: {
    margin: "5px 20px",
  },
  disabledSearch: {
    color: "#17174C1F",
  },
  enabledButton: {
    backgroundColor: "#FA7E61",
  },
  textInput: {
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
  },
  bodyBackgroundImg: {
    backgroundImage:
      "url('/app/images/blue_bg_shape.svg'), url('/app/images/orange_shape.svg')",
    backgroundRepeat: "no-repeat, no-repeat",
    // backgroundPosition: isMobileDevice() ? "0% 10%,100% 30%" : "0% 5%,100% 95%",
    backgroundSize: isMobileDevice() ? "18%, 12%" : "8%, auto",
    top: 0,
    overflow: "auto",
  },
  appBar: {
    backgroundColor: "#17174c",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  svgIcon: {
    color: "white",
    fill: "white",
    stroke: "#0e1c4e",
    width: "0.75rem !important",
    "&.selected": {
      fill: "#0e1c4e",
    },
  },
  svgIconButton: {
    padding: 6,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

function SearchFieldPaper({ children }) {
  return (
    <Paper
      elevation={isMobileDevice() ? 0 : 3}
      style={{
        fontStyle: "italic",
        paddingLeft: isMobileDevice() ? 16 : 25,
        paddingTop: 10,
        paddingBottom: isMobileDevice() && 10,
        border: isMobileDevice() && "1px solid #17174C1A",
      }}
    >
      {children}
    </Paper>
  );
}

function ServicesTextfield({
  services,
  searchedService,
  classes,
  setSearchedService,
  getAllStorefronts,
  storeFrontList,
  setStorefrontPathName,
  formSaveCategoryObjAndCallApi,
}) {
  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };
  const updateServiceList = debounce((event) => {
    getAllStorefronts(event.target.value);
  }, 1000);
  return (
    <Autocomplete
      options={storeFrontList || []}
      value={searchedService}
      freeSolo
      getOptionLabel={(option) => option || ""}
      id="find-services"
      renderInput={(params) => (
        <TextField
          {...params}
          margin="normal"
          className={clsx(classes.inputs, classes.textInput)}
          placeholder="What service are you looking for?"
          onChange={(event, value) => {
            event.persist();
            updateServiceList(event);
          }}
        />
      )}
      // onChange={(event, value) => {
      //   setSearchedService(value);
      //   let allServices = [...businessTypes, ...services];

      //   console.log(value, "----", allServices);
      //   const filteredServiceForCategoryVisit = allServices.filter(
      //     (service) => service.name === value
      //   );
      //   const filteredService = services.filter(
      //     (service) => service.name === value
      //   );
      //   if (
      //     filteredServiceForCategoryVisit &&
      //     filteredServiceForCategoryVisit.length
      //   ) {
      //     formSaveCategoryObjAndCallApi(filteredServiceForCategoryVisit[0]);
      //   }
      //   if (filteredService && filteredService.length) {
      //     setStorefrontPathName(filteredService[0].storefrontPathName);
      //   } else {
      //     setStorefrontPathName(value);
      //   }
      // }}
      onChange={(event, value) => {
        setSearchedService(value);
        const filteredService = services.filter(
          (service) => service.name === value?.trim()
        );
        if (filteredService && filteredService.length) {
          setStorefrontPathName(filteredService[0].storefrontPathName);
          formSaveCategoryObjAndCallApi(filteredService[0]);
        } else {
          setStorefrontPathName(value);
          if (value === "Home Services Professional") {
            formSaveCategoryObjAndCallApi(null, true); // true for isHomeServicesProfessional
          }
        }
      }}
      noOptionsText={"Type in to search"}
      getOptionSelected={(option, value) => {
        return option === value;
      }}
    />
  );
}

function CitiesTextfield({
  cities,
  searchedCity,
  classes,
  setSearchedCity,
  userInfo,
  appSearchedCityId,
}) {
  useEffect(() => {
    if (userInfo && !appSearchedCityId) {
      if (userInfo.hbCurrentAddressCity) {
        setSearchedCity(userInfo.hbCurrentAddressCity);
      } else {
        setSearchedCity({
          id: 9,
          name: "Ontario",
          provinceCode: "ON",
          functional: true,
          selected: true,
          localeCode: "en",
          cityDTO: [
            {
              id: 1,
              name: "Toronto",
              shortName: "toronto",
            },
          ],
        });
      }
    }
  }, []);

  return (
    <Autocomplete
      options={cities || []}
      value={searchedCity}
      // getOptionLabel={(option) => option.name || ""}
      getOptionLabel={(option) => {
        return `${option.cityDTO?.[0]?.shortName} (${option.name})`;
      }}
      id="city"
      debug
      renderInput={(params) => (
        <TextField
          {...params}
          margin="normal"
          className={clsx(classes.inputs, classes.textInput)}
          placeholder="Which city?"
        />
      )}
      // filterOptions={(options, { inputValue }) => {
      //   if (inputValue.length >= 2) {
      //     // return matchSorter(options, inputValue, {
      //     //   keys: ["name"],
      //     let filteredOptions = [];
      //     options.map((option) => {
      //       if (option.cityDTO[0].name.includes(inputValue)) {
      //         filteredOptions.push(option);
      //       }
      //     });
      //     return filteredOptions;
      //   } else {
      //     return [];
      //   }
      // }}
      onChange={(event, value) => {
        setSearchedCity(value);
      }}
      noOptionsText={"Type in to search"}
      // getOptionSelected={(option, value) => {
      //   return option?.name === value?.name;
      // }}
      getOptionSelected={(option, value) => {
        if (
          option &&
          option.cityDTO.length &&
          option.cityDTO[0] &&
          option.cityDTO[0].name
        ) {
          return option?.cityDTO?.[0]?.name === value?.cityDTO?.[0]?.name;
        }
      }}
    />
  );
}

function SearchIconGrid({
  searchedService,
  searchedCity,
  classes,
  setFilterActive,
  getFeaturedStoreFronts,
  getSearchedStoreFronts,
  storefrontPathName,
  route,
  showAllCategories,
}) {
  return (
    <Grid
      container
      item
      xs={1}
      md={2}
      lg={2}
      justifyContent={isMobileDevice() ? "center" : "flex-end"}
      alignContent="center"
    >
      <Link
        to={
          !isEmpty(searchedService) &&
          !isEmpty(searchedCity) &&
          "store_front_search"
        }
        spy={true}
        smooth={true}
        offset={-100}
      >
        {isMobileDevice() ? (
          <ButtonBase
            disabled={isEmpty(searchedService) || isEmpty(searchedCity)}
          >
            <SearchIcon
              className={clsx(classes.primarColor, {
                [classes.disabledSearch]:
                  isEmpty(searchedService) || isEmpty(searchedCity),
              })}
              onClick={() => {
                setFilterActive(false);
                getSearchedStoreFronts(
                  searchedService,
                  searchedCity.cityDTO?.[0]?.id,
                  0
                );
                getFeaturedStoreFronts(
                  searchedService,
                  searchedCity.cityDTO?.[0]?.id
                );
                let url = showAllCategories
                  ? window.location.origin +
                    route +
                    `/${storefrontPathName}/${searchedCity.cityDTO?.[0]?.shortName}/${showAllCategories}`
                  : window.location.origin +
                    route +
                    `/${storefrontPathName}/${searchedCity.cityDTO?.[0]?.shortName}`;
                window.history.pushState(null, null, url);
              }}
            />
          </ButtonBase>
        ) : (
          <Button
            disabled={isEmpty(searchedService) || isEmpty(searchedCity)}
            onClick={() => {
              setFilterActive(false);
              getSearchedStoreFronts(
                searchedService,
                searchedCity.cityDTO?.[0]?.id,
                0
              );
              getFeaturedStoreFronts(
                searchedService,
                searchedCity.cityDTO?.[0]?.id
              );
              // window.location.href =
              //   window.location.origin +
              //   window.location.pathname +
              //   `?appSearchedService=${searchedService}&appSearchedCityId=${searchedCity.id}`;

              let url = showAllCategories
                ? window.location.origin +
                  route +
                  `/${storefrontPathName}/${searchedCity.cityDTO?.[0]?.shortName}/${showAllCategories}`
                : window.location.origin +
                  route +
                  `/${storefrontPathName}/${searchedCity.cityDTO?.[0]?.shortName}`;
              window.history.pushState(null, null, url);
            }}
            className={clsx(classes.primarColor, {
              [classes.disabledSearch]:
                isEmpty(searchedService) || isEmpty(searchedCity),
            })}
            endIcon={<SearchIcon />}
            style={{
              backgroundColor: "#66D7D1",
              textTransform: "capitalize",
              marginTop: "-10px",
              padding: "9px 12px",
              marginRight: "-20px",
            }}
          >
            Search
          </Button>
        )}
      </Link>
    </Grid>
  );
}

export default function Explore() {
  const classes = useStyles();

  const {
    searchedService,
    searchedCity,
    services,
    cities,
    getCitiesList,
    carouselLimit,
    displayServicesList,
    handleNext,
    handleBack,
    lastIndexServices,
    exampleSearches,
    getSearchedStoreFronts,
    searchedStoreFronts,
    getFeaturedStoreFronts,
    featuredStoreFronts,
    showEmailSection,
    setEmail,
    validations,
    elRefs,
    loadingMsg,
    getServicesList,
    setscrollBottom,
    setPageSize,
    getFilteredStoreFronts,
    getAllStorefronts,
    storeFrontList,
    addImpression,
    actions: { setFilterActive, setSearchedService, setSearchedCity },
    getFeaturedStorefrontsForWebsite,
    featuredStoreFrontsForWebsite,
    getFeatureSfsByBusinessType,
    getFeaturedStorefrontsAddon,
    featuredStoreFrontsAddon,
    storefrontPathName,
    setStorefrontPathName,
    formSaveCategoryObjAndCallApi,
    saveCategoryVisit,
    openBroadcastServiceRequestDialog,
    setOpenBroadcastServiceRequestDialog,
    getFeatureSfsByBusinessTypeForNonLoggedInUsers,
    getAllPreferredPartners,
    preferredPartners
  } = useExplore();

  const { getSelectedCategories } = useMarketplaceWidget();

  const userInfo = useAuthUser();
  const history = useHistory();
  const dispatch = useDispatch();

  const { openPage } = useStorefrontPage();
  const { query } = useSQQuery();
  const websiteSearchedServiceId = query.get("websiteSearchedServiceId");
  const websiteSearchedCityId = query.get("websiteSearchedCityId");
  const openBroadcastDialog = query.get("openBroadcastDialog");
  const [openInviteSPDialog, setOpenInviteSPDialog] = useState(false);
  const [featuredSFs, setFeaturedSFs] = useState([]);
  const [openRentalInquiryFormDialog, setOpenRentalInquiryFormDialog] =
    useState(false);

  const [displayPreferred, setDisplayPreferred] = useState([]);


  const theme = useTheme();
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  // const appSearchedService = query.get("appSearchedService");
  // const appSearchedCityId = query.get("appSearchedCityId");

  let { appSearchedService, appSearchedCityId, showAllCategories } =
    useParams();

  const uuid = query.get("uuid");

  const searchedServiceDetails = history.location.state;

  let route = userInfo
    ? userInfo.userType === HOME_BUYER_USER
      ? "/app/homebuyer/explore"
      : "/app/serviceprovider/explore"
    : window.location.href.includes("prepare-for-canada")
    ? "/app/prepare-for-canada/marketplace"
    : "/app/marketplace";

  useEffect(() => {
    if (!uuid) {
      getServicesList();
    }
    getCitiesList();
    getAllPreferredPartners(userInfo);
    // if (window.location.origin === "https://app.souqh.ca") {
    if (window.location.href.includes(DOMAIN_URL)) {
      getFeaturedStorefrontsForWebsite();
      getFeaturedStorefrontsAddon();
    }
    if (openBroadcastDialog) {
      setOpenBroadcastServiceRequestDialog(true)
    }
  }, []);

  useEffect(() => {
    if (userInfo && userInfo.userType === HOME_BUYER_USER)
      addImpression("MarketPlace");
  }, [userInfo]);

  useEffect(() => {
    if (uuid) {
      getSelectedCategories(uuid, (res) => {
        if (res.data.status === 200) {
          dispatch({
            type: SET_EXPLORE_SERVICES,
            payload: { services: res.data.result },
          });
        }
      });
    }
  }, [uuid]);

  // useEffect(() => {
  //   let city = null;
  //   if (cities && cities.length && websiteSearchedCityId) {
  //     city = filter(cities, (city) => city.id == websiteSearchedCityId)[0];
  //     setSearchedCity(city);
  //   }
  //   setSearchedService(websiteSearchedServiceId);
  //   if (city && websiteSearchedServiceId) {
  //     getSearchedStoreFronts(websiteSearchedServiceId, city.id, 0);
  //     getFeaturedStoreFronts(websiteSearchedServiceId, city.id);
  //   }
  // }, [websiteSearchedServiceId, cities.length]);

  const searchedCityId = searchedCity && searchedCity.cityDTO?.[0]?.id;

  useEffect(() => {
    let city = null;
    if (cities && cities.length && appSearchedCityId) {
      city = filter(
        cities,
        (city) => city.cityDTO?.[0]?.shortName === appSearchedCityId
      )[0];
      setSearchedCity(city);
    }
    if (searchedServiceDetails && searchedServiceDetails.searchedService) {
      setSearchedService(searchedServiceDetails.searchedService);
    } else {
      if (services && services.length) {
        if (appSearchedService !== "undefined") {
          const filteredService = services.filter(
            (service) => service.storefrontPathName === appSearchedService
          )[0]?.name;
          filteredService
            ? setSearchedService(filteredService)
            : setSearchedService(appSearchedService);
        }
      }
    }
    setStorefrontPathName(appSearchedService);
  }, [
    appSearchedService,
    appSearchedCityId,
    cities.length,
    searchedServiceDetails,
    services.length,
  ]);

  useEffect(() => {
    if (searchedService && searchedCity) {
      getSearchedStoreFronts(searchedService, searchedCity.cityDTO?.[0]?.id, 0);
      getFeaturedStoreFronts(searchedService, searchedCity.cityDTO?.[0]?.id);
      // let url =
      //   window.location.origin +
      //   window.location.pathname +
      //   `?appSearchedService=${searchedService}&appSearchedCityId=${searchedCity.id}`;

      let url = openBroadcastDialog
        ?
        showAllCategories
          ? window.location.origin +
          route +
          `/${storefrontPathName}/${searchedCity.cityDTO?.[0]?.shortName}/${showAllCategories}?openBroadcastDialog=true`
          : window.location.origin +
          route +
          `/${storefrontPathName}/${searchedCity.cityDTO?.[0]?.shortName}?openBroadcastDialog=true`
        : showAllCategories ? window.location.origin +
          route +
          `/${storefrontPathName}/${searchedCity.cityDTO?.[0]?.shortName}/${showAllCategories}`
          : window.location.origin +
          route +
          `/${storefrontPathName}/${searchedCity.cityDTO?.[0]?.shortName}`
        ;
      const filteredService = services.filter(
        (service) => service.storefrontPathName === storefrontPathName
      )[0]?.name;
      if (!searchedServiceDetails || !searchedServiceDetails.dontReloadPage) {
        if (
          filteredService &&
          (!window.location.href.includes(searchedCity.cityDTO?.[0]?.shortName) ||
            !window.location.href.includes(storefrontPathName))
        ) {
          window.location.replace(url);
        } else {
          window.history.pushState(null, null, url);
        }
      }
    }
  }, [searchedService, searchedCityId, storefrontPathName]);

  let topNavBar = null;
  if (openPage) {
    topNavBar = (
      <AppBar position="fixed" className={clsx(classes.appBar)}>
        <Toolbar>
          <img
            style={{ height: "80px" }}
            src="/app/images/souqh_logo_withName_white.svg"
            alt="souqh logo"
            title="souqh logo"
          ></img>
          <Grid container justifyContent="flex-end"></Grid>
        </Toolbar>
      </AppBar>
    );
  }

  const getParsedFeaturedStoreFrontsList = (array) => {
    if (!array || !array.length) return [];
    return array.map((v) => ({
      storeFrontId: v.storeFrontId,
      businessName: v.businessName,
      businessTypeName:
        v.businessTypes[0].name === "Other"
          ? v.businessTypes[0].otherBusinessName
          : v.businessTypes[0].name,
      primarySubscriptionPlanName: v.primarySubscriptionPlanName,
      storeFrontContent: JSON.parse(v.storeFrontContent),
      storeFrontRouteName: v.storeFrontRouteName,
      storeFrontName: v.storeFrontName,
      email: v.email,
      reviewsCount: v.reviewsCount,
      avgStarRatings: v.avgStarRatings || 0,
      contactNumber: v.contactNumber,
      address: v.address,
      calSyncAccessToken: v.calSyncAccessToken,
      sfServices: v.sfServices,
      serviceProviderId: v.serviceProviderId,
      storefrontPathName: v.businessTypes[0].storefrontPathName,
      subBusinessTypes: v.subBusinessTypes,
      businessTypes: v.businessTypes,
      locationsServed: v.locationsServed,
    }));
  };

  const numberOfCards = isMobileDevice()
    ? 1
    : isTabletDevice()
    ? 2
    : xlUp
    ? 6
    : 4;

  const { shuffledItems, activeIndex, setActiveIndex } = useFeaturedStoreFront(
    featuredSFs,
    numberOfCards
  );

  useEffect(() => {
    if (searchedService) {
      if (userInfo) {
        let final = [];
        getFeatureSfsByBusinessType(searchedService).then((res) => {
          // setFeaturedSFs(res);
          getParsedFeaturedStoreFrontsList(res).map((item) => final.push(item));
        });
        featuredStoreFronts.map((item) => final.push(item));
        const uniqueSFs = removeDuplicates(final, "storeFrontId");
        setFeaturedSFs(uniqueSFs);
        let filteredPreferredPartners = preferredPartners && preferredPartners.length && preferredPartners.filter((item) => item.businessTypes[0].storefrontPathName === storefrontPathName)
        if (filteredPreferredPartners && filteredPreferredPartners.length) {
          setDisplayPreferred(filteredPreferredPartners)
        } else {
          setDisplayPreferred(preferredPartners)
        }
      } else {
        let final = [];
        getParsedFeaturedStoreFrontsList(featuredStoreFrontsForWebsite)
          .length &&
          getParsedFeaturedStoreFrontsList(featuredStoreFrontsForWebsite).map(
            (item) => final.push(item)
          );
        getParsedFeaturedStoreFrontsList(featuredStoreFrontsAddon).length &&
          getParsedFeaturedStoreFrontsList(featuredStoreFrontsAddon).map(
            (item) => final.push(item)
          );
        featuredStoreFronts.map((item) => final.push(item));
        getFeatureSfsByBusinessTypeForNonLoggedInUsers(
          searchedService,
          searchedCityId
        )
          .then((res) => {
            getParsedFeaturedStoreFrontsList(res).map((item) =>
              final.push(item)
            );
          })
          .then(() => {
            const uniqueSFs = removeDuplicates(final, "storeFrontId");
            let filteredSFS = uniqueSFs.filter(
              (storeFront) => storeFront.businessTypeName === searchedService
            );
            setFeaturedSFs(filteredSFS);
          });
      }
    } else {
      let final = [];
      userInfo &&
        getParsedFeaturedStoreFrontsList(userInfo.storeFront).length &&
        getParsedFeaturedStoreFrontsList(userInfo.storeFront).map((item) =>
          final.push(item)
        );
      userInfo &&
        getParsedFeaturedStoreFrontsList(userInfo.journeyStoreFront).length &&
        getParsedFeaturedStoreFrontsList(userInfo.journeyStoreFront).map(
          (item) => final.push(item)
        );
      getParsedFeaturedStoreFrontsList(featuredStoreFrontsForWebsite).length &&
        getParsedFeaturedStoreFrontsList(featuredStoreFrontsForWebsite).map(
          (item) => final.push(item)
        );
      const uniqueSFs = removeDuplicates(final, "storeFrontId");
      setFeaturedSFs(uniqueSFs);
      setDisplayPreferred(preferredPartners)
    }
  }, [
    userInfo,
    featuredStoreFrontsForWebsite.length,
    searchedService,
    featuredStoreFronts.length,
    preferredPartners.length
  ]);

  const getPageUrl = (serviceShortName) => {
    let base;
    let url;
    if (userInfo) {
      const path =
        userInfo.userType === HOME_BUYER_USER ? "homebuyer" : "serviceprovider";
      base = `/app/${path}/explore`;
    } else {
      if (window.location.href.includes("prepare-for-canada")) {
        base = "/app/prepare-for-canada/marketplace";
      } else {
        base = "/app/marketplace";
      }
    }

    if (searchedService && !searchedCity) {
      url = base + `/${serviceShortName}`;
    } else {
      url =
        base + `/${serviceShortName}/${searchedCity?.cityDTO?.[0]?.shortName}`;
    }
    return url;
  };

  return (
    <Grid
      item
      container
      xs={12}
      style={{ marginBottom: isTabletDevice() && 120 }}
    >
      <InviteSPDialog
        openInviteSPDialog={openInviteSPDialog}
        setOpenInviteSPDialog={setOpenInviteSPDialog}
      />
      <RentalInquiryFormDialog
        openRentalInquiryFormDialog={openRentalInquiryFormDialog}
        setOpenRentalInquiryFormDialog={setOpenRentalInquiryFormDialog}
        userInfo={userInfo}
        defaultSearchedCity={searchedCity}
      />
      <BroadcastServiceRequestDialog
        openBroadcastServiceRequestDialog={openBroadcastServiceRequestDialog}
        setOpenBroadcastServiceRequestDialog={
          setOpenBroadcastServiceRequestDialog
        }
        userInfo={userInfo}
        defaultSearchedCity={searchedCity}
        defaultSearchedService={searchedService}
      />
      {topNavBar}
      <Grid
        item
        container
        xs={12}
        style={{
          marginTop: openPage ? 80 : 0,
          // height: "100%",
        }}
      >
        <Paper
          elevation={0}
          className={
            !isMobileDevice() &&
            !isTabletDevice() &&
            clsx(classes.bodyBackgroundImg)
          }
          style={{
            padding: isMobileDevice()
              ? "20px 0px 0px 0px"
              : isTabletDevice()
              ? "124px 80px 20px 80px"
              : "60px 20px 0px 20px",
            color: "#0e1c4e",
            width: !isMobileDevice() && "100%",
            maxWidth: window.screen.width,
            backgroundPosition: isMobileDevice()
              ? "0% 10%,100% 30%"
              : showAllCategories === "false"
              ? "0% 5%,100% 65%"
              : "0% 5%,100% 93%",
          }}
        >
          {isMobileDevice() && (
            <Grid item xs={8}>
              <img
                style={{
                  height: 40,
                  width: 160,
                  cursor: "pointer",
                }}
                src="/app/images/souqh_logo_withName.svg"
                alt="souqh logo"
                title="souqh logo"
                // onClick={() => history.push("/")}
              ></img>
            </Grid>
          )}
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            style={{ padding: isMobileDevice() && "30px 56px 0px  56px" }}
          >
            <Typography
              variant={isMobileDevice() ? "h6" : "h4"}
              className={Styles.navyBlueColor}
              style={{ textAlign: "center" }}
            >
              Find the perfect service provider for you
            </Typography>
          </Grid>

          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            className={classes.marginTop25}
            style={{
              padding: isMobileDevice() && "0px 20px",
              marginBottom: showAllCategories === "false" && 60,
            }}
          >
            <Grid item xs={12} md={11} lg={8}>
              {isMobileDevice() ? (
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <SearchFieldPaper>
                      <ServicesTextfield
                        services={services}
                        searchedService={searchedService}
                        classes={classes}
                        setSearchedService={setSearchedService}
                        getAllStorefronts={getAllStorefronts}
                        storeFrontList={storeFrontList}
                        setStorefrontPathName={setStorefrontPathName}
                        formSaveCategoryObjAndCallApi={
                          formSaveCategoryObjAndCallApi
                        }
                      />
                    </SearchFieldPaper>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: 16 }}>
                    <SearchFieldPaper>
                      <Grid container item xs={12}>
                        <Grid item xs={9}>
                          <CitiesTextfield
                            cities={cities}
                            searchedCity={searchedCity}
                            classes={classes}
                            setSearchedCity={setSearchedCity}
                            userInfo={userInfo}
                            appSearchedCityId={appSearchedCityId}
                          />
                        </Grid>
                        <Divider
                          className={classes.divider}
                          orientation="vertical"
                          flexItem
                        />
                        <SearchIconGrid
                          searchedService={searchedService}
                          searchedCity={searchedCity}
                          classes={classes}
                          setFilterActive={setFilterActive}
                          getFeaturedStoreFronts={getFeaturedStoreFronts}
                          getSearchedStoreFronts={getSearchedStoreFronts}
                          storefrontPathName={storefrontPathName}
                          route={route}
                          showAllCategories={showAllCategories}
                        />
                      </Grid>
                    </SearchFieldPaper>
                  </Grid>
                </Grid>
              ) : (
                <SearchFieldPaper>
                  <Grid container item xs={12}>
                    <Grid item xs={5}>
                      <ServicesTextfield
                        services={services}
                        searchedService={searchedService}
                        classes={classes}
                        setSearchedService={setSearchedService}
                        getAllStorefronts={getAllStorefronts}
                        storeFrontList={storeFrontList}
                        setStorefrontPathName={setStorefrontPathName}
                        formSaveCategoryObjAndCallApi={
                          formSaveCategoryObjAndCallApi
                        }
                      />
                    </Grid>
                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                      flexItem
                    />
                    <Grid item xs={4}>
                      <CitiesTextfield
                        cities={cities}
                        searchedCity={searchedCity}
                        classes={classes}
                        setSearchedCity={setSearchedCity}
                        userInfo={userInfo}
                        appSearchedCityId={appSearchedCityId}
                      />
                    </Grid>
                    <SearchIconGrid
                      searchedService={searchedService}
                      searchedCity={searchedCity}
                      classes={classes}
                      setFilterActive={setFilterActive}
                      getFeaturedStoreFronts={getFeaturedStoreFronts}
                      getSearchedStoreFronts={getSearchedStoreFronts}
                      storefrontPathName={storefrontPathName}
                      route={route}
                      showAllCategories={showAllCategories}
                    />
                  </Grid>
                </SearchFieldPaper>
              )}
            </Grid>
          </Grid>

          {!window.location.href.includes("prepare-for-canada") &&
            (!showAllCategories || showAllCategories === "true") && (
              <Grid
                item
                container
                xs={12}
                justifyContent="center"
                className={classes.marginTop30}
                style={{ padding: isMobileDevice() && "0px 20px" }}
              >
                <Typography style={{ marginTop: 15 }} variant="body1">
                  Example searches:
                </Typography>
                {exampleSearches.length
                  ? exampleSearches.map((searchChip, index) => (
                      <Chip
                        clickable
                        component="a"
                        href={getPageUrl(searchChip.storefrontPathName)}
                        key={index}
                        label={searchChip.name}
                        variant="outlined"
                        className={clsx(
                          classes.chip,
                          classes.marginLeft,
                          classes.primarColor,
                          classes.marginTop10
                        )}
                        onClick={() => {
                          setSearchedService(searchChip.name);
                          setStorefrontPathName(searchChip.storefrontPathName);
                          formSaveCategoryObjAndCallApi(searchChip, userInfo);
                        }}
                      />
                    ))
                  : null}
              </Grid>
            )}

          {(!userInfo ||
            (userInfo && userInfo.userType === HOME_BUYER_USER)) && (
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              style={{ marginTop: 32, marginBottom: 32 }}
            >
              <Grid
                container
                item
                xs={4}
                alignContent="center"
                style={{ paddingRight: 15, paddingLeft: 25 }}
              >
                <Divider orientation="horizontal" style={{ width: "100%" }} />
              </Grid>
              <Grid container item xs={1} justifyContent="center">
                <Typography className={Styles.navyBlueColor}>OR</Typography>
              </Grid>
              <Grid
                container
                item
                xs={4}
                alignContent="center"
                style={{ paddingRight: 25, paddingLeft: 15 }}
              >
                <Divider orientation="horizontal" style={{ width: "100%" }} />
              </Grid>
            </Grid>
          )}

          {(!userInfo ||
            (userInfo && userInfo.userType === HOME_BUYER_USER)) && (
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              style={{ paddingRight: 25, paddingLeft: 15 }}
            >
              <Tooltip
                title={
                  window.location.href.includes("prepare-for-canada") ||
                  (userInfo && userInfo.rentalForNewComer)
                    ? "Send a rental request to all Service Providers"
                    : "Send a broadcast service request to all Service Providers"
                }
              >
                <Button
                  // disabled={el.disabled}
                  variant="contained"
                  onClick={(event) => {
                    if (
                      window.location.href.includes("prepare-for-canada") ||
                      (userInfo && userInfo.rentalForNewComer)
                    ) {
                      setOpenRentalInquiryFormDialog(true);
                    } else {
                      setOpenBroadcastServiceRequestDialog(true);
                    }
                  }}
                  className={clsx(classes.requestBtn)}
                  style={{
                    backgroundColor: "#F37C61",
                    color: "#FFFFFF",
                    textTransform: "none",
                    padding: "4px 30px",
                    fontSize: 16,
                  }}
                >
                  {window.location.href.includes("prepare-for-canada") ||
                  (userInfo && userInfo.rentalForNewComer)
                    ? "Post Rental Request"
                    : "Post a Service Request"}
                </Button>
              </Tooltip>
            </Grid>
          )}

          {(!window.location.href.includes("prepare-for-canada") ||
            (userInfo && userInfo.rentalForNewComer)) &&
            (!showAllCategories || showAllCategories === "true") && (
              <Divider className={classes.marginTop30} />
            )}

          {(!window.location.href.includes("prepare-for-canada") ||
            (userInfo && userInfo.rentalForNewComer)) &&
            (!showAllCategories || showAllCategories === "true") && (
              <Grid
                item
                container
                xs={12}
                className={classes.marginTop30}
                style={{ padding: isMobileDevice() && "0px 20px" }}
              >
                <Grid item container xs={12}>
                  <Typography
                    variant={isMobileDevice() ? "subtitle1" : "h6"}
                    className={Styles.navyBlueColor}
                  >
                    Most Popular Searches
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  className={classes.marginTop30}
                  style={
                    isMobileDevice()
                      ? {
                          display: "flex",
                          flexWrap: "nowrap",
                          overflowX: "auto",
                        }
                      : {}
                  }
                >
                  {carouselLimit && !!lastIndexServices && (
                    <Grid
                      item
                      xs={3}
                      md={1}
                      style={{
                        minWidth: isMobileDevice()
                          ? "28%"
                          : isTabletDevice() && "15%",
                      }}
                    >
                      <ButtonBase
                        onClick={() => {
                          handleBack();
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          style={{
                            width: 75,
                            height: 75,
                            borderRadius: 8,
                            border: "1px solid #0e1c4e",
                            padding: 10,
                          }}
                        >
                          <ArrowBackIosIcon
                            style={{
                              color: "#0e1c4e",
                              fontSize: 50,
                              marginLeft: 10,
                            }}
                          />
                        </Grid>
                      </ButtonBase>
                    </Grid>
                  )}
                  {displayServicesList.map((category, index) => (
                    <Tooltip key={index} title={category.name} arrow>
                      <Grid
                        item
                        xs={3}
                        md={1}
                        style={{
                          minWidth: isMobileDevice()
                            ? "28%"
                            : isTabletDevice() && "15%",
                        }}
                      >
                        <ButtonBase
                          href={getPageUrl(category.storefrontPathName)}
                          onClick={() => {
                            setSearchedService(category.name);
                            setStorefrontPathName(category.storefrontPathName);
                            // let obj;
                            // if (category.isBusinessType) {
                            //   obj = {
                            //     businessTypeId: category.id,
                            //     label:
                            //       window.location.origin ===
                            //         "https://app.souqh.ca" ||
                            //       window.location.origin ===
                            //         "https://development.souqh.co" ||
                            //       window.location.origin ===
                            //         "https://demo.souqh.co" ||
                            //       window.location.origin === "http://localhost:3000"
                            //         ? "App"
                            //         : "Website",
                            //     subBusinessTypeId: null,
                            //     userId: userInfo.userId || null,
                            //   };
                            // } else {
                            //   obj = {
                            //     businessTypeId: null,
                            //     label:
                            //       window.location.origin ===
                            //         "https://app.souqh.ca" ||
                            //       window.location.origin ===
                            //         "https://development.souqh.co" ||
                            //       window.location.origin ===
                            //         "https://demo.souqh.co" ||
                            //       window.location.origin === "http://localhost:300"
                            //         ? "App"
                            //         : "Website",
                            //     subBusinessTypeId: category.id,
                            //     userId: userInfo.userId || null,
                            //   };
                            // }
                            // saveCategoryVisit(obj);
                            formSaveCategoryObjAndCallApi(category, userInfo);
                          }}
                        >
                          <Grid
                            item
                            style={{
                              width: 75,
                              height: 75,
                              borderRadius: 8,
                              padding: 10,
                              border: "1px solid #0e1c4e",
                              marginRight: isMobileDevice() && 8,
                            }}
                          >
                            <img
                              style={{ width: 50, height: 50 }}
                              src={`/app/images/Explore-Category/${category.logoUrl}`}
                              alt={category.name}
                              title={category.name}
                            ></img>
                          </Grid>
                        </ButtonBase>
                        <Grid item>
                          <Typography
                            className={clsx(
                              Styles.categoryText,
                              classes.primarColor
                            )}
                          >
                            <Box m={0.8}>{category.shortName}</Box>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Tooltip>
                  ))}
                  {carouselLimit &&
                    !isEqual(
                      services[services.length - 1],
                      displayServicesList[displayServicesList.length - 1]
                    ) && (
                      <Grid item xs={3} md={1}>
                        <ButtonBase
                          onClick={() => {
                            handleNext();
                          }}
                        >
                          <Grid
                            item
                            style={{
                              width: 75,
                              height: 75,
                              borderRadius: 8,
                              padding: 10,
                              border: "1px solid #0e1c4e",
                            }}
                          >
                            <ArrowForwardIosIcon
                              style={{ fontSize: 50, color: "#0e1c4e" }}
                            />
                          </Grid>
                        </ButtonBase>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            )}

          <Grid
            container
            justifyContent="flex-end"
            alignItems="flex-end"
            style={{ marginTop: 20 }}
          >
            <Typography
              className={classes.primarColor}
              style={{ paddingBottom: 3 }}
            >
              Powered by{" "}
            </Typography>
            <MaterialLink
              target="_blank"
              href="https://souqh.ca"
              className={classes.primarColor}
            >
              <img
                style={{
                  height: 18,
                  width: 67,
                  cursor: "pointer",
                  marginRight: isMobileDevice()
                    ? 10
                    : isTabletDevice()
                    ? -62
                    : -16,
                  marginLeft: 6,
                }}
                src="/app/images/backend/souqh_logo blue.png"
                alt="souqh logo"
                title="souqh logo"
                // onClick={() => history.push("/")}
              ></img>
            </MaterialLink>
          </Grid>
        </Paper>
      </Grid>
      {!window.location.href.includes("prepare-for-canada") &&
      (featuredStoreFronts.length || searchedStoreFronts.response.length) ? (
        <Grid
          item
          xs={12}
          className={classes.marginTop30}
          style={{
            padding: isMobileDevice()
              ? "0px 16px"
              : isTabletDevice() && "0px 0px 0px 80px",
          }}
        >
          <StoreFrontSearchFilters
            searchedService={searchedService}
            setscrollBottom={setscrollBottom}
            setPageSize={setPageSize}
            setFilterActive={setFilterActive}
            getFilteredStoreFronts={getFilteredStoreFronts}
          />
        </Grid>
      ) : null}
      {userInfo && userInfo.userType === HOME_BUYER_USER && userInfo.referredSPName && preferredPartners.length ? (
        <Grid
          item
          xs={12}
          className={classes.marginTop30}
          style={{
            padding: isMobileDevice()
              ? "0px 16px"
              : isTabletDevice() && "0px 80px",
          }}
        >
          <Typography
            className={classes.primarColor}
            variant={isMobileDevice() ? "subtitle1" : "h4"}
          >
            Preferred Partners for {userInfo.referredSPName}
          </Typography>
          <FeaturedStoreFront
            bgColor="#66D7D140"
            storeFrontList={displayPreferred}
            appSearchedService={appSearchedService}
            searchedCity={searchedCity}
            storefrontPathName={storefrontPathName}
            openRentalInquiryFormDialog={openRentalInquiryFormDialog}
            setOpenRentalInquiryFormDialog={setOpenRentalInquiryFormDialog}
          />
          {displayPreferred.length && displayPreferred.length / numberOfCards > 1 ? (
            <Grid
              style={{ justifyContent: "center", marginTop: 10 }}
              container
              item
              xs={12}
            >
              {Array(Math.ceil(displayPreferred.length / numberOfCards))
                .fill(1)
                .map((item, index) => (
                  <IconButton
                    classes={{ root: classes.svgIconButton }}
                    onClick={() => setActiveIndex(index)}
                  >
                    <CircleIcon
                      classes={{ root: classes.svgIcon }}
                      className={activeIndex === index ? "selected" : ""}
                    />
                  </IconButton>
                ))}
            </Grid>
          ) : null}
        </Grid>
      ) : null}
      {(!userInfo || (userInfo && userInfo.userType === SERVICE_PROVIDER_USER)) && shuffledItems.length ? (
        <Grid
          item
          xs={12}
          className={classes.marginTop30}
          style={{
            padding: isMobileDevice()
              ? "0px 16px"
              : isTabletDevice() && "0px 80px",
          }}
        >
          <Typography
            className={classes.primarColor}
            variant={isMobileDevice() ? "subtitle1" : "h4"}
          >
            Featured Storefronts
          </Typography>
          <FeaturedStoreFront
            bgColor="#66D7D140"
            storeFrontList={shuffledItems}
            appSearchedService={appSearchedService}
            searchedCity={searchedCity}
            storefrontPathName={storefrontPathName}
            openRentalInquiryFormDialog={openRentalInquiryFormDialog}
            setOpenRentalInquiryFormDialog={setOpenRentalInquiryFormDialog}
          />
          {featuredSFs.length && featuredSFs.length / numberOfCards > 1 ? (
            <Grid
              style={{ justifyContent: "center", marginTop: 10 }}
              container
              item
              xs={12}
            >
              {Array(Math.ceil(featuredSFs.length / numberOfCards))
                .fill(1)
                .map((item, index) => (
                  <IconButton
                    classes={{ root: classes.svgIconButton }}
                    onClick={() => setActiveIndex(index)}
                  >
                    <CircleIcon
                      classes={{ root: classes.svgIcon }}
                      className={activeIndex === index ? "selected" : ""}
                    />
                  </IconButton>
                ))}
            </Grid>
          ) : null}
        </Grid>
      ) : null}
      <Grid
        item
        xs={12}
        className={classes.marginTop30}
        id="store_front_search"
        style={{
          marginBottom: isMobileDevice() && 120,
          padding: isMobileDevice()
            ? "0px 16px"
            : isTabletDevice() && "0px 80px",
        }}
      >
        {searchedStoreFronts.response && searchedStoreFronts.response.length ? (
          <Typography
            className={classes.primarColor}
            variant={isMobileDevice() ? "subtitle1" : "h4"}
          >
            Search Results ({searchedStoreFronts.totalItems})
          </Typography>
        ) : null}
        {searchedStoreFronts.response && searchedStoreFronts.response.length ? (
          <>
            <FeaturedStoreFront
              elRefs={elRefs}
              storeFrontList={searchedStoreFronts.response}
              appSearchedService={appSearchedService}
              searchedCity={searchedCity}
              storefrontPathName={storefrontPathName}
              openRentalInquiryFormDialog={openRentalInquiryFormDialog}
              setOpenRentalInquiryFormDialog={setOpenRentalInquiryFormDialog}
            />
            {loadingMsg ? (
              <Typography style={{ paddingBottom: 20 }}>
                {loadingMsg}
              </Typography>
            ) : null}
          </>
        ) : showEmailSection ? (
          <Grid
            container
            className={classes.marginTop25}
            style={{ marginBottom: 20 }}
            justifyContent="center"
          >
            <Grid item xs={12} md={6}>
              <Grid item>
                <Typography variant="h4" className={classes.primarColor}>
                  Looks like we don’t have anyone matching that search criteria.
                </Typography>
                <Typography
                  style={{ marginTop: 15 }}
                  className={classes.primarColor}
                >
                  Refer a service provider now and you could both earn a{" "}
                  <b>$50 referral credit.</b>
                </Typography>
                <Typography
                  style={{ fontSize: 12, marginTop: 15 }}
                  className={classes.primarColor}
                >
                  <MaterialLink
                    target="_blank"
                    href="https://help.souqh.ca/en/articles/5342665-invite-service-providers-and-earn-credits"
                  >
                    Click here
                  </MaterialLink>{" "}
                  for more details.
                </Typography>
              </Grid>
              <Grid item className={classes.marginTop10} variant="contained">
                <Button
                  style={{
                    padding: "10px 30px",
                    textTransform: "capitalize",
                    color: "#0E1C4E",
                  }}
                  className={classes.enabledButton}
                  onClick={() => {
                    if (userInfo) {
                      setOpenInviteSPDialog(true);
                    } else {
                      history.push("/app/userlogin");
                    }
                  }}
                >
                  Invite Service Provider
                </Button>
              </Grid>
            </Grid>
            {!isMobileDevice() && (
              <Grid item xs={4}>
                <img
                  src="/app/images/no-result-found-blue.svg"
                  style={{ height: 300, width: 300 }}
                  alt="no results found"
                  title="no results found"
                />
              </Grid>
            )}
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
}
