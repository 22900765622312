import {
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import Styles from "../../../../Styles.module.css";
import RepresentativeMock2 from "../../../SPSetup/StoreFront/RepresentativeMock2.js";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { useHistory, useLocation } from "react-router-dom";
import useLocalStorage from "react-use-localstorage";
import { isMobileDevice, isTabletDevice } from "../../../../utils/AppUtils";
import { SERVICE_PROVIDER_USER } from "../../../ServiceProvider/serviceProviderConstants";
import { useNavigateToMessage } from "souqh-react-redux-hooks/common/useNavigateToMessage";

const useStyles = makeStyles((theme) => ({
  requestBtn: {
    padding: "4px 30px",
    textTransform: "capitalize",
    position: "absolute",
    bottom: isMobileDevice() || isTabletDevice() ? "unset" : 8,
    cursor: "pointer",
  },
  learnBtn: {
    padding: "4px 30px",
    textTransform: "capitalize",
    position: "absolute",
    bottom: isMobileDevice() || isTabletDevice() ? "unset" : 8,
    left: 200,
  },
  serviceImg: {
    width: 360,
    height: 220,
    borderRadius: 6,
    border: "1px solid rgba(0, 0,0,0.12)",
  },
}));

export default function GeneralStorefront(props) {
  const classes = useStyles();
  const {
    competeText,
    companyOverview,
    services,
    yearsInBusiness,
    numberOfEmployees,
    openserviceRequestDialog,
    serviceProviderId,
    setOpenserviceRequestDialog,
    user,
    ownerEmail,
    storeFrontId,
    primaryColor,
    secondaryColor,
    setUserEngagementData,
  } = props;
  const userInfo = useAuthUser();
  const history = useHistory();
  const location = useLocation();

  const [nonloggedInUser, setNonLoggedInUser] =
    useLocalStorage("nonloggedInUser");
  const { navigateMessage } = useNavigateToMessage();

  let showRequestServiceBtn = true;
  if (user && storeFrontId && userInfo.userType === SERVICE_PROVIDER_USER) {
    showRequestServiceBtn = user.storefrontId === storeFrontId ? false : true;
  }

  const requestService = (el, event = null) => {
    if (userInfo && userInfo.userType === SERVICE_PROVIDER_USER) {
      navigateMessage(event, ownerEmail);
    } else if (userInfo) {
      setOpenserviceRequestDialog({
        open: true,
        service: el,
      });
    } else {
      // history.push("/app/userlogin");
      setOpenserviceRequestDialog({
        open: true,
        service: el,
      });
      setNonLoggedInUser(
        JSON.stringify({
          redirectUrl: location.pathname,
          openserviceRequestDialog: { open: true, service: el },
          setOpenserviceRequestDialog,
          serviceProviderId,
        })
      );
    }
  };
  if (user) {
    const requestedServices = user.requestedServices;
    services.forEach((service) => {
      requestedServices &&
        requestedServices.length &&
        requestedServices.map((rService) => {
          if (rService === service.id) {
            return (service.disabled = true);
          }
          return service;
        });
    });
  }
  let serviceList = null;
  if (services && services.length) {
    serviceList = services.map((el, index) => {
      const isOdd = index % 2 == 0;
      return (
        <Grid key={index}>
          <Grid
            item
            container
            xs={12}
            md={11}
            lg={12}
            style={{
              padding: 16,
              marginTop: !isMobileDevice() && 60,
              flexDirection:
                isMobileDevice() || isTabletDevice()
                  ? "column-reverse"
                  : isOdd
                  ? "row"
                  : "row-reverse",
            }}
          >
            <Grid
              item
              xs={12}
              lg={7}
              style={
                isOdd
                  ? { marginTop: -12 }
                  : {
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: -12,
                    }
              }
            >
              <Grid
                item
                xs={12}
                lg={10}
                style={{ position: "relative", height: "100%" }}
              >
                {!isMobileDevice() && !isTabletDevice() && (
                  <Typography
                    variant={
                      isMobileDevice() || isTabletDevice() ? "subtitle1" : "h6"
                    }
                    style={{
                      margin: "0px 0px 16px 0px",
                      marginTop: isMobileDevice() || isTabletDevice() ? 16 : 0,
                    }}
                  >
                    {el.name}
                  </Typography>
                )}
                <Typography
                  variant="body1"
                  style={{
                    margin:
                      isTabletDevice() || isMobileDevice()
                        ? "24px 0px 32px 0px"
                        : "0px 0px 64px 0px",
                    whiteSpace: "pre-line",
                    wordBreak: "break-word",
                    color: primaryColor,
                  }}
                >
                  {el.description}
                </Typography>
                {showRequestServiceBtn && (
                  <Button
                    disabled={el.disabled}
                    variant="contained"
                    onClick={(event) => {
                      if (storeFrontId === 2189) {
                        if (el.name === "Free Credit Score") {
                          window.open(
                            "https://borrowell.com/partner/free-credit-score?utm_source=souqh&utm_medium=referral&utm_campaign=fcs&utm_content=platform",
                            "_blank"
                          );
                        } else if (el.name === "Rent Advantage") {
                          window.open(
                            "https://borrowell.com/partner/rent-advantage?utm_source=souqh&utm_medium=referral&utm_campaign=fcs&utm_content=platform",
                            "_blank"
                          );
                        } else if (el.name === "Credit Builder") {
                          window.open(
                            "https://borrowell.com/partner/credit-builder?utm_source=souqh&utm_medium=referral&utm_campaign=fcs&utm_content=platform",
                            "_blank"
                          );
                        }
                      } else if (storeFrontId === 2194) {
                        if (el.name === "Book your move") {
                          window.open(
                            "https://book.movemate.ca/locations/?discount=SOUQH50&utm_source=souqh&utm_medium=souqh&utm_campaign=souqh2&utm_id=souqh",
                            "_blank"
                          );
                        } else if (el.name === "Business and Retail") {
                          window.open(
                            "https://book.movemate.ca/locations/?discount=SOUQH50&utm_source=souqh&utm_medium=souqh&utm_campaign=souqh2&utm_id=souqh",
                            "_blank"
                          );
                        }
                      } else if(storeFrontId === 2173) {
                        if(el.name === "Tenant Report"){
                          window.open(
                            "https://www.singlekey.com/en-ca/tenant-report/?utm_source=partner_Souqh&utm_medium=referral&utm_campaign=TEN_Rep",
                            "_blank"
                            );
                        }
                        if(el.name === "Rent Collection"){
                          window.open(
                            "https://www.singlekey.com/en-ca/rent-collection/?utm_source=partner_Souqh&utm_medium=referral&utm_campaign=LAN_RC",
                            "_blank"
                            );
                        }
                        if(el.name === "Rent Guarantee"){
                          window.open(
                            "https://www.singlekey.com/en-ca/rent-guarantee/?utm_source=partner_Souqh&utm_medium=referral&utm_campaign=LAN_RG",
                            "_blank"
                            );
                        }
                      } else {
                        requestService(el, event);
                        setUserEngagementData(storeFrontId, "Request_Service");
                      }
                    }}
                    className={clsx(classes.requestBtn)}
                    style={{
                      backgroundColor: !el.disabled && primaryColor,
                      color: !el.disabled && secondaryColor,
                    }}
                  >
                    {el.disabled ? "Pending Service" : "Request Service"}
                  </Button>
                )}
                {el.link || el.uploadDocUrl ? (
                  <Button
                    variant="contained"
                    onClick={() => {
                      window.open(el.link || el.uploadDocUrl, "_blank");
                      setUserEngagementData(storeFrontId, "Learn_more");
                    }}
                    className={clsx(classes.learnBtn, Styles.navyBlueColor)}
                  >
                    Learn More
                  </Button>
                ) : null}
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              lg={5}
              justifyContent="flex-end"
              style={{
                paddingLeft:
                  isOdd && !isMobileDevice() && !isTabletDevice() && 36,
              }}
            >
              <Grid item xs={12}>
                {(isMobileDevice() || isTabletDevice()) && (
                  <Typography
                    variant="h6"
                    style={{
                      margin: "0px 0px 16px 0px",
                      marginTop: isMobileDevice() || isTabletDevice() ? 16 : 0,
                      color: primaryColor,
                    }}
                  >
                    {el.name}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                container
                justifyContent={
                  isMobileDevice() || isTabletDevice() || !isOdd
                    ? "flex-start"
                    : "flex-end"
                }
              >
                <img
                  style={{
                    width: isMobileDevice() ? "60%" : 300,
                    height: isMobileDevice() ? 216 : 300,
                  }}
                  src={el.imageUrl ? el.imageUrl : "/app/images/logo.svg"}
                  className={classes.serviceImg}
                  alt="logo"
                  title="logo"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              margin: isMobileDevice() ? "48px 0 0 0" : "48px 0px",
            }}
          >
            <Divider />
          </Grid>
        </Grid>
      );
    });
  }
  return (
    <Grid item>
      <Grid item container xs={12} style={{ paddingTop: 24 }}>
        <Grid
          item
          xs={12}
          lg={8}
          style={{ padding: isMobileDevice() && "0 16px" }}
        >
          <Grid item xs={12} lg={8}>
            <Typography
              variant={isMobileDevice() ? "h6" : "h4"}
              style={{ margin: "0px 0px 36px 0px", color: primaryColor }}
            >
              {competeText}
            </Typography>

            <Typography
              variant="body1"
              style={{
                margin: "0px 0px 20px 0px",
                color: primaryColor,
                whiteSpace: "pre-line",
                wordBreak: "break-word",
              }}
            >
              {companyOverview}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <RepresentativeMock2
            yearsInBusiness={yearsInBusiness}
            numberOfEmployees={numberOfEmployees}
          ></RepresentativeMock2>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider style={{ margin: "24px 0px" }} />
      </Grid>
      {serviceList}
    </Grid>
  );
}
