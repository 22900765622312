import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  makeStyles,
  Divider,
  Hidden,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";
import CloseIcon from "@material-ui/icons/Close";
import { useError } from "souqh-react-redux-hooks/useError";
import { isTabletDevice, isMobileDevice } from "../../utils/AppUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: 0,
    },
  },
  dialogPaper: {
    height: isMobileDevice() || isTabletDevice() ? 550 : 625,
    minWidth:
      isTabletDevice() || isMobileDevice() ? window.screen.width - 40 : 900,
  },
}));

function ErrorDialog() {
  const classes = useStyles();
  const { errorNotification, sendFeedback, handleClose } = useError();
  return (
    <Dialog
      open={!!errorNotification}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle>
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <Typography variant="subtitle1" className={Styles.navyBlueColor}>
              Error Notification
            </Typography>
          </Grid>
          <Grid container item xs={6} justifyContent="flex-end">
            <Typography variant="subtitle1" className={Styles.navyBlueColor}>
              <CloseIcon
                onClick={(event) => {
                  handleClose();
                }}
                style={{
                  fill: "#0e1c4e",
                  cursor: "pointer",
                  margin: "0px 8px",
                  fontSize: 20,
                  opacity: 1,
                }}
              />
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />

      <DialogContent style={{ overflowY: "hidden" }}>
        <Grid container item xs={12}>
          <Hidden smDown>
            <Grid container item xs={6} justifyContent="center">
              <img
                src="/app/images/error_image.svg"
                style={{ width: 300, height: 380 }}
                alt="error image"
                title="error image"
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={6}>
            <Typography
              variant={isMobileDevice() ? "h6" : "h3"}
              className={Styles.navyBlueColor}
              style={{ marginTop: 10 }}
            >
              Whoops!
            </Typography>
            <Typography
              variant="body1"
              className={Styles.navyBlueColor}
              style={{ marginTop: 32 }}
            >
              Looks like something went wrong - our bad. We've automatically
              sent this feedback to our support team and someone will review the
              issue shortly. If this is an urgent issue, please use our live
              chat option to get in touch with our support team right away.
            </Typography>
            {/* <Typography
              variant="body1"
              className={Styles.navyBlueColor}
              style={{ marginTop: 28 }}
            >
              Thank you for your patience as we complete our beta launch.
            </Typography> */}
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid container item xs={12} justifyContent="flex-end">
          <Button
            variant="outlined"
            color="primary"
            style={{ textTransform: "capitalize", margin: 12 }}
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
          {/* <Button
            onClick={() =>
              sendFeedback(
                window.location.origin + window.location.pathname,
                (res) => {
                  if (res && res.status === 200) {
                    handleClose();
                  }
                }
              )
            }
            color="primary"
            style={{
              textTransform: "capitalize",
              margin: 12,
              padding: "0 24px",
            }}
            className={Styles.primaryBtnAccent}
          >
            Send Feedback
          </Button> */}
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default ErrorDialog;
