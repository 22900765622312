import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router-dom";
import useLocalStorage from "react-use-localstorage";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  font14: {
    fontSize: 14,
  },
  font14Bold: {
    fontSize: 14,
    fontWeight: 700,
  },
  marginTop20: {
    marginTop: 20,
  },
  marginTop10: {
    marginTop: 10,
  },
}));

export default function SignupForFreePopup({
  openSignupForFreePopup,
  setOpenSignupForFreePopup,
  storeFrontName,
  pageRoute,
}) {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const [nonloggedInUser, setNonLoggedInUser] =
    useLocalStorage("nonloggedInUser");
  const [localUserInfo, setUserInfo] = useLocalStorage("userInfo");

  return (
    <Dialog fullWidth open={openSignupForFreePopup} maxWidth={"sm"}>
      <DialogTitle>
        <Grid item container xs={12} justifyContent="flex-end">
          <Button
            style={{ padding: 0, minWidth: 0 }}
            onClick={() => {
              setOpenSignupForFreePopup(false);
            }}
          >
            <CloseIcon className={Styles.navyBlueColorForce} />
          </Button>
        </Grid>
      </DialogTitle>
      {/* <DialogTitle>
        <Grid item container xs={12}>
          <Grid item xs={11}>
            <Typography
              variant="subtitle1"
              className={Styles.navyBlueColorForce}
            >
              Send Service Request
            </Typography>
          </Grid>
          <Grid item xs={1} container style={{ justifyContent: "flex-end" }}>
            <Button
              style={{ padding: 0, minWidth: 0 }}
              onClick={() => {
                setOpenSignupForFreePopup(false);
              }}
            >
              <CloseIcon className={Styles.navyBlueColorForce} />
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider /> */}
      <DialogContent
        className={Styles.navyBlueColorForce}
        style={{ minHeight: 70 }}
      >
        <Grid container className={classes.marginTop10}>
          <Grid item>
            <Typography className={classes.font14}>
              {`Please log in to interact with ${storeFrontName}. Don't have an
                  account? Sign up for your free Souqh account today - should
                  take less than a minute`}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          className={Styles.navyBlueColorForce}
          style={{ textTransform: "capitalize", cursor: "pointer" }}
          onClick={() => {
            // if (pageRoute && pageRoute.includes("prepare-for-canada")) {
            history.push("/app/prepare-for-canada/signup");
            // }
          }}
        >
          Sign up for free
        </Button>

        <Button
          style={{
            textTransform: "capitalize",
            marginRight: 10,
            cursor: "pointer",
          }}
          onClick={() => {
            // if (pageRoute && pageRoute.includes("prepare-for-canada")) {
            history.push("/app/prepare-for-canada/login");
            // }
          }}
          className={clsx(Styles.navyBlueColorForce, Styles.primaryBtnAccent)}
        >
          Login
        </Button>
      </DialogActions>
    </Dialog>
  );
}
