import { Grid, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import CustomCalendarToolBar from "./CustomCalendarToolBar";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { useHistory } from "react-router-dom";
import { HOME_BUYER_USER } from "../../ServiceProvider/serviceProviderConstants";
import {
  isMarketplacePlan,
  isOnboardingInComplete,
  isProPlan,
} from "../../../utils/AppUtils";
import PlanUpgradeMsgDialog from "../PlanUpgradeMsgDialog";

const localizer = momentLocalizer(moment);

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    "& .rbc-month-row": {
      justifyContent: "center",
    },
    "& .rbc-date-cell": {
      color: "#0e1c4e",
      paddingRight: 0,
    },
    "& .rbc-header": {
      color: "#0e1c4e",
    },

    "& .rbc-date-cell.rbc-off-range, & .rbc-day-bg.rbc-off-range-bg": {
      opacity: 0.2,
    },
    "& .rbc-day-bg.rbc-off-range-bg": {
      backgroundColor: "transparent",
    },
    "& .rbc-row  .rbc-date-cell": {
      cursor: "default",
      textAlign: "center",
    },
    "& .rbc-day-bg.rbc-today": {
      borderRadius: 6,
      backgroundColor: "#17174C",
    },
    "& .rbc-day-bg + .rbc-day-bg, & .rbc-month-row + .rbc-month-row, & .rbc-header,  & .rbc-header + .rbc-header, & .rbc-month-view":
      {
        border: "none",
      },
    "& .rbc-row .rbc-event": {
      width: 6,
      height: 6,
      top: -24,
      right: 2,
      position: "absolute",
      padding: 0,
      backgroundColor: "transparent",
    },
    "& .rbc-row .rbc-row-segment": {
      position: "relative",
    },
    "& .rbc-row .rbc-event .rbc-event-content": {
      visibility: "visible",
    },
    "& .rbc-date-cell.rbc-now, & .rbc-date-cell.rbc-current": {
      color: "#f37c61",
      fontWeight: 600,
    },
    "& .rbc-row-content .rbc-row .rbc-event-content": {
      display: "none",
    },
    "& .rbc-row-content .rbc-row:nth-child(2) .rbc-event-content": {
      display: "block",
    },
  },
}));

function dayNameHeader(props) {
  const { label } = props;
  return <div>{(label || "").split("")[0]}</div>;
}

const DateHeader =
  ({
    navigateOnDateClick,
    userInfo,
    history,
    setCurrentCalendarDate,
    setOpenPlanUpgradeMsgDialog,
  }) =>
  (props) => {
    const { label, date } = props;
    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          if (!isOnboardingInComplete(userInfo)) {
            if (navigateOnDateClick && userInfo && history) {
              const selectedDate = date.toGMTString();
              if (userInfo.userType === HOME_BUYER_USER) {
                history.push(
                  "/app/homebuyer/calendar?openDate=" + selectedDate
                );
              } else {
                if (isMarketplacePlan(userInfo)) {
                  setOpenPlanUpgradeMsgDialog(true);
                } else {
                  if (!isProPlan(userInfo)) {
                    if (!isOnboardingInComplete(userInfo)) {
                      history.push(
                        "/app/serviceprovider/calendar?openDate=" + selectedDate
                      );
                    }
                  }
                }
              }
            } else {
              setCurrentCalendarDate(date);
            }
          }
        }}
      >
        {label}
      </div>
    );
  };

function customEventDot(props) {
  return (
    <div>
      <FiberManualRecordIcon
        style={{
          position: "absolute",
          fontSize: 10,
          fill: "#fa7e61",
          right: 0,
        }}
      />
    </div>
  );
}

export default function SmallCalendarWidget({
  eventsData,
  currentCalendarDate,
  setCurrentCalendarDate,
  width,
  height,
  showToolbar,
  navigateOnDateClick,
  setMainCalendarView,
}) {
  const classes = useStyles();
  const userInfo = useAuthUser();
  let history = useHistory();

  const [openPlanUpgradeMsgDialog, setOpenPlanUpgradeMsgDialog] =
    useState(false);

  let components = {
    toolbar: showToolbar ? CustomCalendarToolBar : null,
    month: {
      header: dayNameHeader,
      event: customEventDot,
      dateHeader: DateHeader({
        navigateOnDateClick,
        userInfo,
        history,
        setCurrentCalendarDate,
        setOpenPlanUpgradeMsgDialog,
      }),
    },
  };
  return (
    <Grid item container xs={12} className={classes.root}>
      <PlanUpgradeMsgDialog
        openPlanUpgradeMsgDialog={openPlanUpgradeMsgDialog}
        setOpenPlanUpgradeMsgDialog={setOpenPlanUpgradeMsgDialog}
      />
      <Calendar
        localizer={localizer}
        events={eventsData || []}
        views={["month"]}
        defaultView="month"
        startAccessor="start"
        endAccessor="end"
        selectable={true}
        toolbar={showToolbar}
        style={{ width: width || 300, height: height || 300 }}
        components={components}
        date={new Date(moment(currentCalendarDate).format())}
        onNavigate={(day, e) => {
          setCurrentCalendarDate(day);
          setMainCalendarView("month");
        }}
      />
    </Grid>
  );
}
