import { Typography, Grid } from "@material-ui/core";
import { map } from "lodash-es";
import React from "react";
import { SocialIcon } from "react-social-icons";
import Styles from "../../../../Styles.module.css";
import { isMobileDevice } from "../../../../utils/AppUtils";
import clsx from "clsx";

function SFSocialLink({ i, l, setUserEngagementData, storeFrontId }) {
  return (
    <SocialIcon
      style={{ height: 25, width: 25, margin: "4px 8px" }}
      key={i}
      id={i}
      url={l}
      network={l.includes("www") ? l.split(".")[1] : "sharethis"}
      onClick={(e) => {
        e.preventDefault();
        // let url = l.includes("http") ? l : "http://" + l; //check if link is in valid format or not
        window.open(l, "_blank");
        // window.open(l, "_blank");
        setUserEngagementData(storeFrontId, "Social_icon");
      }}
    />
  );
}
function StorefrontSocialLinks(props) {
  const {
    socialLinks,
    setWebPresenceDrawer,
    setUserEngagementData,
    storeFrontId,
  } = props;
  let symbs = null;
  if (socialLinks && socialLinks.length) {
    symbs = map(socialLinks, (l, i) => {
      let url = l.includes("http") ? l : "http://" + l; //check if link is in valid format or not
      return (
        <React.Fragment>
          {isMobileDevice() ? (
            <Grid
              container
              alignItems="center"
              style={{ margin: isMobileDevice() && "8px 0" }}
              onClick={(e) => {
                if (isMobileDevice()) {
                  setWebPresenceDrawer(false);
                  e.preventDefault();
                  // let url = l.indexOf("//") < 0 ? "http://" + l : l; //check if link is in valid format or not
                  window.open(url, "_blank");
                  // window.open(l, "_blank");
                  setUserEngagementData(storeFrontId, "Social_icon");
                }
              }}
            >
              <Grid item xs={1}>
                <SFSocialLink
                  i={i}
                  l={url}
                  setUserEngagementData={setUserEngagementData}
                  storeFrontId={storeFrontId}
                />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  className={clsx(Styles.navyBlueColorForce, Styles.sqEllipsis)}
                  style={{ margin: "0 8px" }}
                >
                  {url}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <SFSocialLink
              i={i}
              l={url}
              setUserEngagementData={setUserEngagementData}
              storeFrontId={storeFrontId}
            />
          )}
        </React.Fragment>
      );
    });
  }
  return symbs;
}
export default StorefrontSocialLinks;
