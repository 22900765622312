import { Fab, Grid, IconButton, Typography } from "@material-ui/core";
import CropIcon from "@material-ui/icons/Crop";
import ImageUpload from "@material-ui/icons/Photo";
import DoneIcon from "@material-ui/icons/Done";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import DragHandleIcon from "@material-ui/icons/PanTool";
import React, {
  useState,
  useCallback,
  useLayoutEffect,
  useEffect,
} from "react";
import { useResizeDetector } from "react-resize-detector";

import Cropper from "react-easy-crop";
import getCroppedImg from "./ImageProcess";
import BlobManager from "../../utils/BlobManager";
import { includes } from "lodash";

function ImageCropper({
  helperText1,
  helperText2,
  cropWidth,
  cropHeight,
  placeholderImage,
  type,
  image,
  onImageAvailalbe,
  setError,
  validateImage = false,
  edit = false,
  serviceImageHeight,
}) {
  const [hover, sethover] = useState(false);
  const [originalImage, setoriginalImage] = useState(image);
  const [croppedImage, setCroppedImage] = useState(null);
  const [cropOn, setCropOn] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [dynamicHeight, setDynamicHeight] = useState(cropHeight);
  const [acceptImage, setAcceptImage] = useState(false);
  const [cropImageblobURL, setcropImageblobURL] = useState("");
  const { width: containerWidth, ref: containerRef } = useResizeDetector();

  useEffect(() => {
    if (croppedImage && onImageAvailalbe) {
      // To check image size and file extension
      const imageBlob = BlobManager.getByName(croppedImage);
      const { file } = imageBlob;
      if (validateImage && (file.size / 1024 / 1024).toFixed(2) > 15) {
        setError && setError({ sizeExceed: true });
        setoriginalImage(null);
      } else {
        setError && setError({ sizeExceed: false, invalidFile: false });
        onImageAvailalbe(croppedImage);
      }
    }
  }, [croppedImage]);

  useEffect(() => {
    if (image) {
      const cropImageblobURL = BlobManager.getBlobURLByFileName(image);
      setcropImageblobURL(cropImageblobURL);
      setoriginalImage(cropImageblobURL);
      setCrop({ x: 0, y: 0 });
    } else {
      setoriginalImage(null);
      setCroppedImage(null);
      setcropImageblobURL("");
    }
  }, [image]);

  useLayoutEffect(() => {
    const widthRatio = containerWidth / cropWidth;
    const height = cropHeight * widthRatio;
    setDynamicHeight(height);
  }, [containerWidth]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  return (
    <Grid
      ref={containerRef}
      container
      justifyContent="center"
      style={{
        border: hover ? "1px solid #17174C" : "1px solid #17174C1F",
        borderRadius: 4,
        position: "relative",
        height: cropOn ? dynamicHeight : "unset",
        // height: cropOn ? cropHeight : "unset",
        // width: cropWidth,
      }}
      onMouseMove={() => {
        // console.log("mouse entered");
        sethover(true);
      }}
      onMouseLeave={() => {
        sethover(false);
        // console.log("mouse left");
      }}
    >
      {hover && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "lightgray",
            opacity: 0.3,
          }}
        ></div>
      )}
      {cropOn && (
        <Cropper
          showGrid={false}
          onMediaLoaded={({ width, height, naturalHeight, naturalWidth }) => {
            // !croppedAreaPixels && setZoom(cropWidth / width);
          }}
          image={originalImage}
          crop={crop}
          // cropSize={{
          //   width: cropWidth,
          //   height: cropHeight,
          // }}
          aspect={cropWidth / cropHeight}
          objectFit="horizontal-cover"
          zoom={zoom}
          zoomWithScroll={false}
          onCropChange={setCrop}
          // onCropChange={({ x, y }) => {
          //   //clamp x and y axis so that panning wont be possible out of image
          //   const imageHeight = cropHeight * zoom;
          //   const imageWidth = cropWidth * zoom;

          //   const clampBoundY = (imageHeight - cropHeight) / 2;
          //   const clampBoundX = (imageWidth - cropWidth) / 2;
          //   let modiY = y;
          //   if (y < 0) {
          //     if (y < -1 * clampBoundY) {
          //       modiY = -1 * clampBoundY;
          //     }
          //   } else {
          //     if (y > clampBoundY) {
          //       modiY = clampBoundY;
          //     }
          //   }
          //   let modiX = x;
          //   if (x < 0) {
          //     if (x < -1 * clampBoundX) {
          //       modiX = -1 * clampBoundX;
          //     }
          //   } else {
          //     if (x > clampBoundX) {
          //       modiX = clampBoundX;
          //     }
          //   }

          //   setCrop({ x: modiX, y: modiY });
          // }}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          // restrictPosition={true}
        />
      )}
      {!cropOn && (
        <img
          // style={{ width: cropWidth, height: cropHeight }}
          style={{
            width: "100%",
            borderRadius: 4,
            // height: croppedImage ? "unset" : cropHeight,
            // height: croppedImage ? "unset" : dynamicHeight,
            height: !serviceImageHeight ? "100%" : dynamicHeight,
            opacity: !cropImageblobURL && !originalImage ? 0.1 : 1,
          }}
          src={cropImageblobURL || originalImage || placeholderImage}
          crossOrigin="anonymous"
          alt="placeholder"
          title="placeholder"
        />
      )}
      {/* crop mode menu */}
      {cropOn && (
        <>
          <Fab
            aria-label="edit"
            size="small"
            style={{ position: "absolute", top: 16, right: 16 }}
            onClick={() => {
              // setZoom(zoom + 0.1 > 2 ? 2 : zoom + 0.1);
              setZoom(zoom + 0.1);
            }}
          >
            <IconButton
              color="primary"
              aria-label="crop picture"
              component="span"
            >
              <ZoomInIcon />
            </IconButton>
          </Fab>
          <Fab
            aria-label="edit"
            size="small"
            // disabled={zoom <= 1}
            style={{ position: "absolute", top: 64, right: 16 }}
            onClick={() => {
              // setZoom(zoom - 0.1 < 1 ? 1 : zoom - 0.1);
              setZoom(zoom - 0.1);
            }}
          >
            <IconButton
              color="primary"
              aria-label="crop picture"
              component="span"
            >
              <ZoomOutIcon />
            </IconButton>
          </Fab>
          <Fab
            aria-label="edit"
            size="small"
            style={{ position: "absolute", bottom: 16, right: 16 }}
            onClick={async () => {
              const { fileName } = await getCroppedImg(
                originalImage,
                croppedAreaPixels,
                type
              );

              setCroppedImage(fileName);
              setCropOn(false);
            }}
          >
            <IconButton
              color="primary"
              aria-label="crop picture"
              component="span"
            >
              <DoneIcon />
            </IconButton>
          </Fab>
          <Grid
            item
            style={{
              zIndex: 1,
              pointerEvents: "none",
              opacity: 0.7,
              marginTop: 10,
            }}
          >
            <Grid
              style={{
                backgroundColor: "gray",
                color: "white",
                padding: 5,
                display: "flex",
                borderRadius: 3,
              }}
            >
              <DragHandleIcon style={{ marginRight: 5 }}></DragHandleIcon>
              Drag to reposition
            </Grid>
          </Grid>
        </>
      )}
      <Grid
        style={{
          position: "absolute",
          top: "24%",
          bottom: "24%",
          padding: 32,
        }}
      >
        {!cropImageblobURL && !originalImage ? (
          <Typography align="center" style={{ color: "#868DA6", fontSize: 14 }}>
            {helperText1}
          </Typography>
        ) : null}
      </Grid>

      {(hover || acceptImage) && !cropOn && (
        <>
          <Fab
            disabled={!originalImage}
            aria-label="edit"
            size="small"
            style={{ position: "absolute", bottom: 16, right: 16 }}
            onClick={() => {
              setCropOn(true);
            }}
          >
            <IconButton
              disabled={!originalImage}
              color="primary"
              aria-label="crop picture"
              component="span"
            >
              <CropIcon />
            </IconButton>
          </Fab>
          <input
            accept="image/*"
            id={"icon-button-file-" + type}
            type="file"
            style={{ display: "none" }}
            onChange={(e) => {
              const file = e.target.files[0];
              // Check specific file extensions
              const extension = file && file.name.split(".").pop();
              if (
                validateImage &&
                !includes(
                  ["jpg", "jpeg", "bmp", "png", "gif", "jfif"],
                  extension
                )
              ) {
                setError && setError({ invalidFile: true });
              } else {
                setError && setError({ invalidFile: false });
                const objectURL = URL.createObjectURL(file);
                setoriginalImage(objectURL);
                setCrop({ x: 0, y: 0 });
                setCropOn(true);
                // setZoom(1);
                setAcceptImage(false);
              }
            }}
          />

          <Fab
            aria-label="edit"
            size="small"
            style={{ position: "absolute", bottom: 64, right: 16 }}
          >
            <label htmlFor={"icon-button-file-" + type}>
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => {
                  setAcceptImage(true);
                }}
              >
                <ImageUpload />
              </IconButton>
            </label>
          </Fab>
        </>
      )}
    </Grid>
  );
}

export default ImageCropper;
