import {
  AppBar,
  Toolbar,
  Grid,
  Button,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useSetupDrawer } from "souqh-react-redux-hooks/useSetupDrawer";
import Styles from "../../Styles.module.css";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  buttons: {
    textTransform: "capitalize",
    lineHeight: 1.3,
  },
}));

function SetupFooter({
  children,
  review,
  profile,
  onSaveAndDraft,
  onPreview,
  storefront,
  disablePreview,
  isSaveAsDraft = true, // Save as Draft button will be always visible
  page = null,
  manageStoreFront,
  setupPage,
  isMarketplaceplan,
}) {
  const classes = useStyles();
  const history = useHistory();
  const {
    actions: { showInvoiceComponent },
    showInvoice,
  } = useSetupDrawer();
  let { path } = useRouteMatch();
  const theme = useTheme();
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  const showPage = () => {
    if (!review && showInvoice) {
      showInvoiceComponent(false);
    } else if (review && !showInvoice) {
      if (isMarketplaceplan) {
        history.goBack();
        showInvoiceComponent(false);
      } else {
        history.goBack();
        showInvoiceComponent(true);
      }
    } else if (page) {
      history.push(`/app/usersetup/${page}`);
    } else {
      history.push(`/app/setup/${setupPage}`);
    }
  };

  return (
    <AppBar
      position="fixed"
      style={{
        top: "auto",
        bottom: 0,
        backgroundColor: "#f5f5f8",
        boxShadow: "0px -3px 20px #0000001c",
      }}
    >
      <Toolbar>
        <Grid container>
          <Grid container item xs={5} justifyContent="flex-end">
            {storefront && (
              <Button
                variant="outlined"
                className={clsx(Styles.navyBlueColor, classes.buttons)}
                onClick={onPreview}
                disabled={disablePreview}
              >
                Preview
              </Button>
            )}
          </Grid>
          <Grid container item xs={7} justifyContent="flex-end">
            <Grid
              item
              // xs={
              //   page === "demographic"
              //     ? 4
              //     : review || profile || !isSaveAsDraft
              //     ? 6
              //     : 8
              // }
              container
              justifyContent="flex-end"
              style={{
                paddingRight: xlUp ? (manageStoreFront ? 490 : 609) : 88,
              }}
            >
              {!profile && !manageStoreFront && (
                <Button
                  variant="text"
                  className={clsx(Styles.navyBlueColorForce, classes.buttons)}
                  startIcon={<ArrowBackIosIcon />}
                  onClick={() => showPage()}
                  style={{ marginRight: 12 }}
                >
                  Go Back
                </Button>
              )}
              {isSaveAsDraft && (
                <Button
                  variant="outlined"
                  className={classes.buttons}
                  onClick={onSaveAndDraft}
                  style={{ marginRight: 12 }}
                >
                  Save As Draft
                </Button>
              )}
              {children}
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default SetupFooter;
