import {
  Grid,
  Typography,
  makeStyles,
  ButtonBase,
  Card,
  useMediaQuery,
  useTheme,
  Link,
} from "@material-ui/core";
import React, { useEffect } from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import clsx from "clsx";
import { HELP_CENTER_LINK } from "../../ServiceProvider/serviceProviderConstants";

const useStyles = makeStyles((theme) => ({
  spacingControl: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: "bold",
  },
}));

function SubscriptionPlan({
  title,
  amount,
  subscriptionPlanFeatures,
  planId,
  planCase,
  setSelectedTeamPlan,
  planDuration,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  useEffect(() => {
    if (planCase === 3) {
      setSelectedTeamPlan(3);
    } else {
      setSelectedTeamPlan(2);
    }
  }, [planCase]);

  const validity = () => {
    return (
      <Typography className={classes.spacingControl} variant="body1">
        /mo/user
      </Typography>
    );
  };

  const currency = () => {
    return (
      <Typography
        className={classes.spacingControl}
        style={xlUp ? { fontSize: "2rem" } : { fontSize: "1.75rem" }}
      >
        $
      </Typography>
    );
  };
  return (
    <Card
      style={{
        color: "#17174C",
        border: "2px solid #17174c",
        height: "100%",
      }}
    >
      <ButtonBase>
        <Grid container item sm={12} style={{ padding: 20 }}>
          <Grid container item sm={7}>
            <Grid
              container
              item
              sm={12}
              className={classes.spacingControl}
              justifyContent="flex-start"
            >
              <Typography
                className={clsx(classes.spacingControl, classes.title)}
                variant="h5"
              >
                {title}
              </Typography>
            </Grid>
            {amount && (
              <Grid
                container
                item
                sm={12}
                className={classes.spacingControl}
                justifyContent="flex-start"
                alignItems="center"
              >
                {currency()}
                <Typography
                  className={clsx(classes.spacingControl, classes.title)}
                  variant="h4"
                >
                  {amount}
                </Typography>
                {validity()}
              </Grid>
            )}
            {subscriptionPlanFeatures.map((feature, index) => (
              <Grid
                container
                item
                sm={12}
                justifyContent="flex-start"
                key={index}
                style={{ marginTop: 5 }}
              >
                {feature.status === true ? (
                  <CheckCircleIcon />
                ) : (
                  <CancelOutlinedIcon />
                )}
                <Typography variant="body1">
                  {planDuration === "YEARLY" && feature.annualFeatureText
                    ? feature.annualFeatureText
                    : feature.featureText}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Grid container item sm={5}>
            {amount ? (
              <img
                style={{ height: 250, width: 250 }}
                src="/app/images/Premium.svg"
              ></img> // Premium Plan
            ) : (
              <img
                style={{ height: 250, width: 250 }}
                src="/app/images/Enterprise-plan.svg"
              ></img> // Enterprise Plan
            )}
          </Grid>
          <Grid container item sm={12} style={{ marginTop: 10 }}>
            <Link underline="always" href={HELP_CENTER_LINK} target="_blank">
              Learn more
            </Link>
          </Grid>
        </Grid>
      </ButtonBase>
    </Card>
  );
}

export default function MultipleStoreFrontPlans({
  plansList,
  planDuration,
  setSelectedTeamPlan,
}) {
  return (
    <Grid container style={{ marginTop: 20, paddingBottom: 80 }} spacing={2}>
      {plansList.length &&
        plansList.map((plan, index) => (
          <Grid item xs={12} key={index}>
            <SubscriptionPlan
              title={plan.planName}
              amount={
                planDuration === "MONTHLY"
                  ? plan.monthlyPrice
                  : plan.annualPrice
              }
              subscriptionPlanFeatures={plan.subscriptionPlanFeatures}
              planId={plan.id}
              planCase={plansList.case}
              setSelectedTeamPlan={setSelectedTeamPlan}
              planDuration={planDuration}
            ></SubscriptionPlan>
          </Grid>
        ))}
    </Grid>
  );
}
