import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
  makeStyles,
  Grid,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import { useSetupDrawer } from "souqh-react-redux-hooks/useSetupDrawer";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 250,
  },
}));

function PlanUpgradeMsgDialog({
  openPlanUpgradeMsgDialog,
  setOpenPlanUpgradeMsgDialog,
}) {
  const classes = useStyles();
  const history = useHistory();

  const {
    actions: { showInvoiceComponent },
  } = useSetupDrawer();

  return (
    <Dialog
      fullWidth
      open={openPlanUpgradeMsgDialog}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Grid container item xs={12}>
          <Grid item xs={10}>
            <Typography variant="subtitle1">Upgrade</Typography>
          </Grid>
          <Grid container item xs={2} justifyContent="flex-end">
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpenPlanUpgradeMsgDialog(false);
              }}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          In order to view and access features on the Souqh platform, please
          upgrade your plan. Click on the Upgrade button to continue your plan
          upgrade process
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            setOpenPlanUpgradeMsgDialog(false);
            history.push("/app/setup/subscription");
            showInvoiceComponent(false);
          }}
          className={Styles.primaryBtnAccent}
          style={{ textTransform: "capitalize" }}
        >
          Upgrade
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PlanUpgradeMsgDialog;
