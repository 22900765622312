import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Grid,
  Typography,
  CardContent,
  Link,
  FormControl,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import NextPhaseDialog from "./NextPhaseDialog";
import FindHomeJourney from "./FindHomeJourney";
import FundHomeJourney from "./FundHomeJourney";
import CloseHomeJourney from "./CloseHomeJourney";
import MoveIntoHomeJourney from "./MoveIntoHomeJourney";
import { useHomeBuyingJourney } from "souqh-react-redux-hooks/common/useHomeBuyingJourney";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { findIndex } from "lodash";
import RoomIcon from "@material-ui/icons/Room";
import { isTabletDevice } from "../../../utils/AppUtils";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  selectBox: {
    "& .MuiSelect-root": {
      color: "white",
      fontWeight: 700,
      fontSize: "0.875rem", // 14 px
    },
    "& .MuiSelect-icon": {
      color: "white",
    },
    "& .MuiInput-underline::after": {
      borderBottom: "none",
    },
  },
}));

export default function HomeBuyingJourneyCard({
  xlUp,
  isMobileDevice,
  setHbJourneyView,
  hbJourneyView,
}) {
  const [openNextPhaseDialog, setOpenNextPhaseDialog] = useState({
    open: false,
    previousStage: "",
    backwardTransition: false,
    newStageName: "",
  });
  const [phase, setPhase] = useState(0);

  const [showFullJourney, setShowFullJourney] = useState(true);
  const [showFindHomeJourney, setShowFindHomeJourney] = useState(false);
  const [showFundHomeJourney, setShowFundHomeJourney] = useState(false);
  const [showCloseHomeJourney, setShowCloseHomeJourney] = useState(false);
  const [showMoveIntoHomeJourney, setShowMoveIntoHomeJourney] = useState(false);

  const user = useAuthUser();
  const classes = useStyles();
  const {
    getJourneyStatus,
    journeyStatus,
    updateJourneyStatus,
    disableHBjouneryCards,
  } = useHomeBuyingJourney(user.userId);

  useEffect(() => {
    getJourneyStatus();
  }, []);

  const formattedJourneyStatus = journeyStatus.split("_").join(" ");

  const stages = [
    {
      name: "Find Your Home",
      image: "/app/images/Find-your-home.svg",
      description: "Uncover the stages involved in finding your dream home",
    },
    {
      name: "Fund Your Home",
      image: "/app/images/Fund-your-home.svg",
      description:
        "4 steps that will make funding your home a hassle free experience",
    },
    {
      name: "Close Your Home",
      image: "/app/images/Close-Your-Home.svg",
      description: "Uncover the stages involved in finding your dream home",
    },
    {
      name: "Move Into Your Home",
      image: "/app/images/Move-Into-Your-Home.svg",
      description: "Uncover the stages involved in finding your dream home",
    },
  ];

  const journeyStatusId = findIndex(
    stages,
    (stage) => stage.name.toUpperCase() === formattedJourneyStatus
  );

  const getColor = (stageName) => {
    let backgroundColor = "";
    if (stageName) {
      switch (stageName) {
        case "Find Your Home":
          backgroundColor = "#FA7E6140";
          break;
        case "Fund Your Home":
          backgroundColor = "#66D7D140";
          break;
        case "Close Your Home":
          backgroundColor = "#C86FC940";
          break;
        case "Move Into Your Home":
          backgroundColor = "#D9036840";
          break;
      }
    }
    return backgroundColor;
  };

  const showSubSteps = (stageName) => {
    if (stageName) {
      switch (stageName) {
        case "Find Your Home":
          return setShowFindHomeJourney(true);
          break;
        case "Fund Your Home":
          return setShowFundHomeJourney(true);
          break;
        case "Close Your Home":
          return setShowCloseHomeJourney(true);
          break;
        case "Move Into Your Home":
          return setShowMoveIntoHomeJourney(true);
          break;
      }
    }
  };

  return (
    <Grid container item xs={12} style={{ height: "100%" }}>
      <NextPhaseDialog
        openNextPhaseDialog={openNextPhaseDialog.open}
        setOpenNextPhaseDialog={setOpenNextPhaseDialog}
        previousStage={openNextPhaseDialog.previousStage}
        phase={phase}
        updateJourneyStatus={updateJourneyStatus}
        backwardTransition={openNextPhaseDialog.backwardTransition}
        newStageName={openNextPhaseDialog.newStageName}
      />
      <Card
        raised
        style={{
          borderRadius: 8,
          marginRight: !isMobileDevice() && 20,
          height: "100%",
        }}
      >
        <CardHeader
          style={{
            color: "white",
            fontWeight: 700,
            fontSize: xlUp ? "1rem" : "0.875rem", // 14 px
          }}
          className={clsx(Styles.primaryBackground, classes.selectBox)}
          title={
            <Grid container xs={12}>
              <FormControl>
                <Select
                  value={hbJourneyView}
                  onChange={(event) => setHbJourneyView(event.target.value)}
                >
                  <MenuItem value={"home_buying"}>Home Buying Journey</MenuItem>
                  <MenuItem value={"home_ownership"}>
                    Home Ownership Timeline
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          }
          titleTypographyProps={{ variant: "" }}
        />
        <CardContent>
          {showFindHomeJourney && (
            <FindHomeJourney
              setShowFindHomeJourney={setShowFindHomeJourney}
              setShowFullJourney={setShowFullJourney}
              xlUp={xlUp}
            />
          )}

          {showFundHomeJourney && (
            <FundHomeJourney
              setShowFundHomeJourney={setShowFundHomeJourney}
              setShowFullJourney={setShowFullJourney}
              xlUp={xlUp}
            />
          )}

          {showCloseHomeJourney && (
            <CloseHomeJourney
              setShowCloseHomeJourney={setShowCloseHomeJourney}
              setShowFullJourney={setShowFullJourney}
              xlUp={xlUp}
            />
          )}

          {showMoveIntoHomeJourney && (
            <MoveIntoHomeJourney
              setShowMoveIntoHomeJourney={setShowMoveIntoHomeJourney}
              setShowFullJourney={setShowFullJourney}
              xlUp={xlUp}
            />
          )}

          {showFullJourney && (
            <Grid
              container
              justifyContent="space-between"
              spacing={xlUp ? 6 : 3}
              style={
                isMobileDevice() || isTabletDevice()
                  ? {
                      display: "flex",
                      flexWrap: "nowrap",
                      overflowX: "auto",
                    }
                  : {}
              }
            >
              {stages.map((stage, index) => (
                <Grid item xs={8} md={3} lg={3} key={index}>
                  <Grid
                    item
                    style={{
                      backgroundColor: getColor(stage.name),
                      textAlign: "center",
                      height: xlUp ? "18.5rem" : 251,
                      borderRadius: 4,
                      padding:
                        isMobileDevice() || isTabletDevice()
                          ? "8px 20px"
                          : "8px 30px",
                      border:
                        stage.name.toUpperCase() === formattedJourneyStatus &&
                        "2px solid #0E1C4E",
                      cursor: "pointer",
                      minWidth: isMobileDevice() && 200,
                      pointerEvents: disableHBjouneryCards ? "none" : "auto",
                    }}
                    onClick={() => {
                      setOpenNextPhaseDialog({
                        open: index !== journeyStatusId ? true : false,
                        previousStage: formattedJourneyStatus.toLowerCase(),
                        backwardTransition:
                          index < journeyStatusId ? true : false,
                        newStageName: stage.name.toLowerCase(),
                      });
                      setPhase(index);
                    }}
                  >
                    {index === journeyStatusId ? (
                      // <img
                      //   src="/app/images/Omar_pin.svg"
                      //   style={{ height: 23, width: 27 }}
                      // />
                      <RoomIcon
                        className={Styles.navyBlueColor}
                        style={{ height: 23, width: 27 }}
                      />
                    ) : index < journeyStatusId ? (
                      <CheckCircleIcon
                        style={{ color: "#107E3E", fontSize: 23 }}
                      />
                    ) : (
                      <Grid
                        container
                        item
                        xs={4}
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        style={{
                          backgroundColor: "#F5F6F8",
                          height: 23,
                          width: 23,
                          borderRadius: "50%",
                          textAlign: "center",
                          marginLeft: xlUp ? "44%" : "40%",
                          border: "1px solid #0E1C4E",
                          marginBottom: 8,
                        }}
                      >
                        <Typography
                          className={Styles.navyBlueColor}
                          style={{
                            fontWeight: 500,
                            fontSize: 11,
                          }}
                        >
                          {index + 1}
                        </Typography>
                      </Grid>
                    )}
                    <Typography
                      className={Styles.navyBlueColor}
                      style={{
                        fontSize: 11,
                        fontWeight: 600,
                        padding: isMobileDevice() ? "10px" : "10px 20px",
                      }}
                    >
                      {stage.name}
                    </Typography>
                    <img
                      src={stage.image}
                      alt={stage.name}
                      title={stage.name}
                      style={{ height: xlUp ? "50%" : "35%", width: "90%" }}
                    />
                    <Typography
                      className={Styles.navyBlueColor}
                      style={{
                        fontSize: 8,
                        padding: isMobileDevice() && "10px 0px",
                      }}
                    >
                      {stage.description}
                    </Typography>
                    <Link
                      underline="always"
                      style={{ fontSize: 8, cursor: "pointer" }}
                      className={Styles.navyBlueColorForce}
                      onClick={(event) => {
                        event.stopPropagation();
                        setShowFullJourney(false);
                        showSubSteps(stage.name);
                      }}
                    >
                      View Details
                    </Link>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
}
