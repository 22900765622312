import {
  Grid,
  TableRow,
  TableCell,
  Typography,
  ButtonBase,
  Tooltip,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Styles from "../../../Styles.module.css";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import MoreOptionsOnClientInfo from "./MoreOptionsOnClientInfo";
import * as moment from "moment";
import { useCompleteClientInfo } from "souqh-react-redux-hooks/serviceProvider/clientManagement/useCompleteClientInfo";
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import Rating from "@material-ui/lab/Rating";
import clsx from "clsx";
import { viewClientDetails } from "../../../utils/AppUtils";

export default function CompletedClientTableRow({ data, index }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  let { path } = useRouteMatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const review =
    data.hbServiceReviews &&
    data.hbServiceReviews.length &&
    data.hbServiceReviews.filter(
      (ele) => data.serviceRequestId === ele.serviceRequestId
    )[0];

  return (
    <>
      <MoreOptionsOnClientInfo
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        showLiveMeetingOption={true}
        toUserId={data.hbDetails.userId}
        serviceRequestId={data.serviceRequestId}
        isCompletedTab={true}
      />
      <TableRow>
        <TableCell>
          <Typography className={Styles.navyBlueColor}>{index + 1}</Typography>
        </TableCell>
        <TableCell container>
          <Grid
            container
            style={{ cursor: "pointer" }}
            onClick={() => {
              viewClientDetails(history, path, data);
            }}
          >
            <Grid item xs={4}>
              <img
                src={data.avatarUrl}
                style={{
                  height: 50,
                  border: "1px solid #0E1C4E1F",
                  borderRadius: 4,
                  marginRight: 10,
                }}
                alt="avatar"
                title="avatar"
              />
            </Grid>
            <Grid container item xs={8} alignContent="center">
              <Typography
                variant="body1"
                className={Styles.navyBlueColor}
                style={{ fontWeight: 600 }}
              >
                {data.name}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Typography className={Styles.navyBlueColor}>
            {moment(data.requestedDate).format("MMM DD, YYYY")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography className={Styles.navyBlueColor}>
            {moment(data.completeDate).format("MMM DD, YYYY")}
          </Typography>
        </TableCell>
        <TableCell>
          {review ? (
            <Grid container>
              <Grid container item xs={12}>
                <Grid item>
                  <Rating
                    name="rating"
                    value={review.starRating}
                    className={Styles.navyBlueColorForce}
                    style={{ fontSize: 16 }}
                    readOnly
                  />
                </Grid>
                <Grid item style={{ paddingLeft: 10 }}>
                  <Typography
                    className={clsx(Styles.navyBlueColor, Styles.font14)}
                  >
                    {review.serviceName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Tooltip title={review.reviewComments}>
                  <Typography
                    className={clsx(
                      Styles.navyBlueColor,
                      Styles.sqEllipsisTwoLines
                    )}
                  >
                    {review.reviewComments}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography className={clsx(Styles.navyBlueColor, Styles.font14)}>
                No details available
              </Typography>
            </Grid>
          )}
        </TableCell>
        <TableCell>
          <ButtonBase>
            <MoreHorizIcon
              onClick={handleClick}
              className={Styles.navyBlueColor}
            />
          </ButtonBase>
        </TableCell>
      </TableRow>
    </>
  );
}
