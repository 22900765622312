import { useHistory } from "react-router-dom";
import { HOME_BUYER_USER } from "../../web/src/components/ServiceProvider/serviceProviderConstants";
import { useAuthUser } from "../useLogin";

export const useNavigateToMessage = () => {
  const history = useHistory();
  const userInfo = useAuthUser();

  const navigateMessage = (event, email, hasMessageContent) => {
    event.stopPropagation();
    const path = getMessagaPagePath(userInfo, email, hasMessageContent);
    if (path && email) {
      history.push(path);
    }
  };

  const getMessagaPagePath = (userInfo, email, hasMessageContent) => {
    let path = "";
    if (userInfo) {
      const temp = hasMessageContent ? "&hasMessageContent=true" : "";
      if (userInfo.userType === HOME_BUYER_USER) {
        path = "/app/homebuyer/messaging?sendMessage=" + (email || "") + temp;
      } else {
        path = "/app/serviceprovider/messaging?sendMessage=" + (email || "") + temp;
      }
    }
    return path;
  };
  return {
    navigateMessage,
  };
};
