import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useCalendarSync } from "souqh-react-redux-hooks/calendarSync/useCalendarSync";
import { useSQQuery } from "souqh-react-redux-hooks/common/useSQQuery";

export default function NylasRedirection() {
  const { query } = useSQQuery();
  const history = useHistory();
  const { sendAuthToken } = useCalendarSync();

  let code = query.get("code");

  useEffect(() => {
    if (code) {
      const Nylas = require("nylas");

      Nylas.config({
        clientId: "aimf9hv1m0o0e68v97l1wofm4",
        clientSecret: "615ilxgkv5es6a55v0it48mkw",
      });

      // const abcd = Nylas.exchangeCodeForToken(code);

      sendAuthToken(code);
      setTimeout(3000);
      history.push("/app/serviceprovider/calendar");
    }
  }, [code]);
  return <div>Loading..</div>;
}
