import { Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useSQQuery } from "souqh-react-redux-hooks/common/useSQQuery";
import { useHistory, useParams } from "react-router-dom";

export default function RedirectToDeleteTMSettings() {
  const { query } = useSQQuery();
  const history = useHistory();
  let { flag } = useParams();

  useEffect(() => {
    const token = query.get("token");
    if (token) {
      localStorage.setItem("removeMemberToken", token);
      localStorage.setItem("removeTMflag", flag);
      history.push("/app/login");
    }
  }, []);
  return (
    <Grid>
      <Typography>Loading...</Typography>
    </Grid>
  );
}
