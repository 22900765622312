import React from "react";
import {
  MenuList,
  MenuItem,
  Popover,
  Grid,
  Avatar,
  Typography,
  IconButton,
  Divider,
  makeStyles,
} from "@material-ui/core";
import Styles from "../Styles.module.css";
import SettingsIcon from "@material-ui/icons/Settings";
import { useAuthUser, useLogout } from "souqh-react-redux-hooks/useLogin";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useHistory } from "react-router";
import useLocalStorage from "react-use-localstorage";
import SecurityIcon from "@material-ui/icons/Security";
import {
  ADVANCED,
  HOME_BUYER_USER,
  PLUS,
  PREMIUM,
  PRO,
  SERVICE_PROVIDER_USER,
  TEAM_MEMBER_USER,
} from "./ServiceProvider/serviceProviderConstants";
import RedeemIcon from "@material-ui/icons/Redeem";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { useRoleManagement } from "souqh-react-redux-hooks/common/useRoleManagement";
import { useState } from "react";
import NoAccessDialog from "./common/NoAccessDialog";
import FeedbackIcon from "@material-ui/icons/Feedback";
import { isMarketplacePlan, isOnboardingInComplete } from "../utils/AppUtils";
import { useConnectedAccount } from "souqh-react-redux-hooks/common/useConnectedAccount";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "10px 10px 10px 16px",
    width: 292,
    position: "absolute",
    left: "60px !important",
  },
  paddingLeft0: {
    paddingLeft: 0,
  },
  padding0: {
    padding: 0,
  },
}));

export default function ProfileMenuDialog({ anchorEl, handleClose }) {
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const user = useAuthUser();
  let history = useHistory();
  const classes = useStyles();
  const { logout } = useLogout();

  const [userInfo, setUserInfo] = useLocalStorage("userInfo");
  const [openNoAccessDialog, setOpenNoAccessDialog] = useState(false);
  const { isAdmin, isTeamMember } = useRoleManagement();
  const { getStripeAccountUrl } = useConnectedAccount();

  return (
    <React.Fragment>
      <NoAccessDialog
        openNoAccessDialog={openNoAccessDialog}
        setOpenNoAccessDialog={setOpenNoAccessDialog}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: classes.paper,
        }}
      >
        <Grid container style={{ padding: "16px 0px" }}>
          <Grid item>
            <Avatar
              src={user.avatarUrl || "/broken-image.jpg"}
              style={{ marginRight: 10 }}
            />
          </Grid>
          <Grid item>
            <Typography variant="h6" className={Styles.navyBlueColor}>
              {user.userType === TEAM_MEMBER_USER &&
                user.subscriptionPlanName !== PREMIUM && user.subscriptionPlanName !== ADVANCED
                ? user.fullName.split(" ")[0]
                : user.firstName}
            </Typography>

            {user.userType === HOME_BUYER_USER ? (
              <Typography
                style={{ fontSize: 10, cursor: "pointer" }}
                className={Styles.navyBlueColor}
                onClick={() => {
                  handleClose();
                  history.push("/app/usersetup");
                }}
              >
                See your profile
              </Typography>
            ) : (
              <Typography
                style={{ fontSize: 10, cursor: "pointer" }}
                className={Styles.navyBlueColor}
                onClick={() => {
                  handleClose();
                  history.push("/app/serviceprovider/storefront");
                }}
              >
                Manage Storefront
              </Typography>
            )}
          </Grid>
        </Grid>
        <Divider />
        <MenuList id="menu-list-grow" className={Styles.navyBlueColor}>
          <MenuItem
            disabled={isOnboardingInComplete(user)}
            className={classes.padding0}
            onClick={() => {
              if (!isOnboardingInComplete(user)) {
                handleClose();
                if (user.userType !== HOME_BUYER_USER) {
                  history.push(`/app/serviceprovider/settings`);
                } else {
                  history.push(`/app/homebuyer/settings`);
                }
              }
            }}
          >
            <IconButton color="inherit" className={classes.paddingLeft0}>
              <SettingsIcon />
            </IconButton>
            <Typography>Settings</Typography>
          </MenuItem>
          {user.userType === SERVICE_PROVIDER_USER ? (
            <MenuItem
              className={classes.padding0}
              disabled={isOnboardingInComplete(user) || isMarketplacePlan(user)}
              onClick={() => {
                getStripeAccountUrl();
              }}
            >
              <IconButton color="inherit" className={classes.paddingLeft0}>
                <AttachMoneyIcon />
              </IconButton>
              <Typography>Billing History</Typography>
            </MenuItem>
          ) : null}
          <Divider />
          <MenuItem
            disabled={isOnboardingInComplete(user) || isMarketplacePlan(user)}
            className={classes.padding0}
            onClick={() => {
              if (!isOnboardingInComplete(user)) {
                handleClose();
                if (user.userType === HOME_BUYER_USER) {
                  history.push("/app/homebuyer/referralmanagement");
                } else {
                  history.push("/app/serviceprovider/referralmanagement");
                }
              }
            }}
          >
            <IconButton color="inherit" className={classes.paddingLeft0}>
              <RedeemIcon />
            </IconButton>
            <Typography>{user.userType === SERVICE_PROVIDER_USER ? "Preferred Partner Management" : "Referral Management"}</Typography>
          </MenuItem>
          <MenuItem
            className={classes.padding0}
            onClick={() => {
              if (user.userType === HOME_BUYER_USER) {
                history.push("/app/usersetup");
              } else {
                if (isTeamMember()) {
                  history.push("/app/team/updateDetails");
                } else {
                  user.subscriptionPlanName === PRO ||
                  user.subscriptionPlanName === PLUS
                    ? setOpenNoAccessDialog(true)
                    : history.push("/app/setup");
                }
              }
            }}
          >
            <IconButton color="inherit" className={classes.paddingLeft0}>
              <SettingsIcon />
            </IconButton>
            {user.userType === HOME_BUYER_USER ? (
              <Typography>My Profile</Typography>
            ) : (
              <Typography>Profile Setup</Typography>
            )}
          </MenuItem>

          <MenuItem
            className={classes.padding0}
            onClick={() => {
              handleClose();
              user.userType === HOME_BUYER_USER
                ? history.push("/app/homebuyer/feedback")
                : history.push("/app/serviceprovider/feedback");
            }}
          >
            <IconButton color="inherit" className={classes.paddingLeft0}>
              <FeedbackIcon />
            </IconButton>
            <Typography>Give Feedback</Typography>
          </MenuItem>
          <Divider />
          <MenuItem
            className={classes.padding0}
            onClick={() => {
              const usertype = user.userType;
              logout((res) => {
                if (
                  usertype === SERVICE_PROVIDER_USER ||
                  usertype === TEAM_MEMBER_USER
                ) {
                  history.push("/app/login");
                } else {
                  history.push("/app/userlogin");
                }
              });
            }}
          >
            <IconButton color="inherit" className={classes.paddingLeft0}>
              <ExitToAppIcon />
            </IconButton>
            <Typography>Logout</Typography>
          </MenuItem>
        </MenuList>
      </Popover>
    </React.Fragment>
  );
}
