import React, { useState } from "react";
import PublicIcon from "@material-ui/icons/Public";
import MessageIcon from "@material-ui/icons/Message";
import DateRangeIcon from "@material-ui/icons/DateRange";
import RoomIcon from "@material-ui/icons/Room";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import CallIcon from "@material-ui/icons/Call";

import {
  Card,
  CardMedia,
  Grid,
  makeStyles,
  Typography,
  Tooltip,
  Button,
  Link as MaterialLink,
} from "@material-ui/core";
import clsx from "clsx";
import Rating from "@material-ui/lab/Rating";
import { useHistory } from "react-router";
import Styles from "../../../Styles.module.css";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { useNavigateToMessage } from "souqh-react-redux-hooks/common/useNavigateToMessage";
import {
  ADVANCED,
  HOME_BUYER_USER,
  MARKETPLACE,
  PREMIUM,
  SERVICE_PROVIDER_USER,
} from "../../ServiceProvider/serviceProviderConstants";
import {
  formatContactNumber,
  getMetadataInfo,
  isMarketplacePlan,
  isMobileDevice,
  isTabletDevice,
} from "../../../utils/AppUtils";
import ShareIcon from "@material-ui/icons/Share";
import MoreTrafficDialog from "../../ServiceProvider/Dashboard/MoreTrafficDialog";
import { useUserEngagement } from "souqh-react-redux-hooks/serviceProvider/analytics/useUserEngagement";
import AddressDialog from "./StorefrontPage/AddressDialog";
import ScheduledPagesDialog from "../../common/SQCalender/ScheduledPagesDialog";
import PlanUpgradeMsgDialog from "../../common/PlanUpgradeMsgDialog";
import SendSerivceRequestDialog from "./StorefrontPage/SendSerivceRequestDialog";
import useLocalStorage from "react-use-localstorage";
import { Link, useLocation } from "react-router-dom";
import SignupForFreePopup from "./SignupForFreePopup";

const useStyles = makeStyles((theme) => ({
  primarColor: {
    color: "#0e1c4e",
  },
  secondarColor: {
    color: "#F86C6B",
  },
  marginTop: {
    marginTop: 20,
  },
  marginTop10: {
    marginTop: 10,
  },
  marginLeft: {
    marginLeft: 10,
  },
  cardBorder: {
    // borderRadius: "8px 0 8px 8px",
    borderRadius: 8,
    padding: 15,
    // border: "1px solid #0e1c4e",
  },
  media: {
    // height: 0,
    // paddingTop: "75%", // 4:3
    // // backgroundSize: "100% 100%",
    // backgroundSize: "85% 100%",
    height: 80,
    position: "absolute",
    top: 0,
    left: 0,
    backgroundRepeat: "repeat",
    backgroundSize: "cover",
    width: "100%",
    objectFit: "cover",
  },
  reLativeCard: {
    position: "relative",
  },
  overlay: {
    top: -23,
    right: -29,
    position: "absolute",
    width: 120,
    height: 120,
  },
  clickableCard: {
    cursor: "pointer",
  },
}));

export default function FeaturedStoreFront({
  storeFrontList,
  elRefs,
  bgColor,
  appSearchedService,
  searchedCity,
  storefrontPathName,
  openRentalInquiryFormDialog,
  setOpenRentalInquiryFormDialog,
}) {
  const classes = useStyles();
  const history = useHistory();
  const userInfo = useAuthUser();
  const { navigateMessage } = useNavigateToMessage();
  const [openMoreTrafficDialog, setopenMoreTrafficDialog] = useState(false);
  const [openAddressDialog, setOpenAddressDialog] = useState(false);
  const [addressData, setAddressData] = useState([]);
  const [loggedInUser, setLoggedInUser] = useLocalStorage("nonloggedInUser");

  const [moreTrafficData, setMoreTrafficData] = useState({
    storeFrontId: null,
    storeFrontRouteName: "",
    data: null,
  });
  const [openScheduledPagesDialog, setOpenScheduledPagesDialog] =
    useState(false);
  const [openPlanUpgradeMsgDialog, setOpenPlanUpgradeMsgDialog] =
    useState(false);
  const [calSyncAccessToken, setCalSyncAccessToken] = useState(null);
  const [openserviceRequestDialog, setOpenserviceRequestDialog] = useState({
    open: false,
    service: null,
  });
  const [SFServices, setSFServices] = useState([]);
  const { increaseLeadCount, setUserEngagementData } = useUserEngagement();
  const [businessName, setBusinessName] = useState(null);
  const [serviceProviderId, setServiceProviderId] = useState(null);
  const [ownerEmail, setOwnerEmail] = useState(null);
  const [publicStorefrontId, setPublicStorefrontId] = useState(null);
  const [SFName, setSFName] = useState(null);
  const [nonloggedInUser, setNonLoggedInUser] =
    useLocalStorage("nonloggedInUser");

  const [openSignupForFreePopup, setOpenSignupForFreePopup] = useState(false);

  const location = useLocation();

  const requestService = (el, event = null) => {
    if (userInfo && userInfo.userType === SERVICE_PROVIDER_USER) {
      navigateMessage(event, ownerEmail);
    } else if (userInfo) {
      setOpenserviceRequestDialog({
        open: true,
        service: el,
      });
    } else {
      // history.push("/userlogin");
      setOpenserviceRequestDialog({
        open: true,
        service: el,
      });
      setNonLoggedInUser(
        JSON.stringify({
          redirectUrl: location.pathname,
          openserviceRequestDialog: { open: true, service: el },
          setOpenserviceRequestDialog,
          serviceProviderId,
        })
      );
    }
  };

  const businessTypeList = [
    "Real Estate Broker / Agent",
    "Mortgage Broker / Agent",
    "Real Estate Lawyer",
  ];

  const getStorefrontPath = (data) => {
    let newPath = "";
    const storeFrontId = data.storeFrontId;
    const businessName = data.businessName.replaceAll("/", "-");
    let subbusinessMatchFound = data.subBusinessTypes.find(
      (e) => e.storefrontPathName === storefrontPathName
    );
    if (storeFrontId && businessName) {
      if (userInfo) {
        const path =
          userInfo.userType === HOME_BUYER_USER
            ? "homebuyer"
            : "serviceprovider";
        newPath = `/app/${path}/storefrontview/${businessName}?storeFrontId=${storeFrontId}`;
      } else {
        if (storefrontPathName && searchedCity) {
          if (data.storefrontPathName === "home-services-professional") {
            if (subbusinessMatchFound) {
              newPath = `/app/storefront/${data.storeFrontRouteName}/${storefrontPathName}/${searchedCity.cityDTO[0].shortName}`;
            } else {
              newPath = `/app/storefront/${data.storeFrontRouteName}/${data.storefrontPathName}/${searchedCity.cityDTO[0].shortName}`;
            }
          } else {
            newPath = `/app/storefront/${data.storeFrontRouteName}/${data.storefrontPathName}/${searchedCity.cityDTO[0].shortName}`;
          }
        } else {
          newPath = `/app/storefront/${data.storeFrontRouteName}`;
        }
      }
    }

    return newPath;
  };

  return (
    <React.Fragment>
      <SignupForFreePopup
        openSignupForFreePopup={openSignupForFreePopup}
        setOpenSignupForFreePopup={setOpenSignupForFreePopup}
        storeFrontName={SFName}
      />
      <SendSerivceRequestDialog
        openserviceRequestDialog={openserviceRequestDialog}
        setOpenserviceRequestDialog={setOpenserviceRequestDialog}
        serviceProviderId={serviceProviderId}
        businessName={businessName}
        email={ownerEmail}
        setUserEngagementData={setUserEngagementData}
        storeFrontId={publicStorefrontId}
        storeFrontName={SFName}
        sfServices={SFServices}
        isExplorePageCard={true}
        pageRoute={window.location.href}
      />
      <MoreTrafficDialog
        open={openMoreTrafficDialog}
        setopenMoreTrafficDialog={setopenMoreTrafficDialog}
        storeFrontId={moreTrafficData.storeFrontId}
        storeFrontRouteName={moreTrafficData.storeFrontRouteName}
        data={moreTrafficData.data}
      />
      <AddressDialog
        openAddressDialog={openAddressDialog}
        setOpenAddressDialog={setOpenAddressDialog}
        addresses={addressData}
      />
      <PlanUpgradeMsgDialog
        openPlanUpgradeMsgDialog={openPlanUpgradeMsgDialog}
        setOpenPlanUpgradeMsgDialog={setOpenPlanUpgradeMsgDialog}
      />
      <ScheduledPagesDialog
        openScheduledPagesDialog={openScheduledPagesDialog}
        setOpenScheduledPagesDialog={setOpenScheduledPagesDialog}
        calSyncAccessToken={calSyncAccessToken}
      />

      <Grid
        container
        item
        xs={12}
        spacing={isMobileDevice() ? 0 : 3}
        className={classes.marginTop10}
      >
        {storeFrontList.length
          ? storeFrontList.map((data, index) => {
              const businessTypeName =
                data.businessTypeName ||
                (data.businessTypes && data.businessTypes.length
                  ? data.businessTypes[0].name === "Other"
                    ? data.businessTypes[0].otherBusinessName
                    : data.businessTypes[0].name
                  : "");
              const parsedStoreFrontContent =
                typeof data.storeFrontContent === "string"
                  ? JSON.parse(data.storeFrontContent)
                  : data.storeFrontContent;
              let webAddress =
                parsedStoreFrontContent.webPresence.companyWebsite;
              return (
                <Grid
                  key={index}
                  item
                  container={(isMobileDevice() || isTabletDevice()) && true}
                  xs={12}
                  md={6}
                  lg={3}
                  xl={2}
                  style={{
                    marginBottom: isMobileDevice() && 16,
                    justifyContent:
                      (isMobileDevice() || isTabletDevice()) && "center",
                    paddingRight: isMobileDevice() && 4,
                    padding: isMobileDevice() && "0px 20px 0px 0px",
                  }}
                >
                  <MaterialLink
                    underline="none"
                    href={getStorefrontPath(data)}
                    target={
                      !window.location.href.includes("prepare-for-canada") &&
                      "_blank"
                    }
                  >
                    <Card
                      ref={(el) => {
                        if (elRefs) return (elRefs.current[index] = el);
                      }}
                      raised
                      className={clsx(
                        classes.cardBorder,
                        classes.primarColor,
                        classes.reLativeCard,
                        classes.clickableCard
                      )}
                      style={{
                        // backgroundColor: bgColor
                        width:
                          isMobileDevice() || isTabletDevice() ? "100%" : "89%",
                      }}
                      // onClick={() => {
                      //   const storeFrontId = data.storeFrontId;
                      //   const businessName = data.businessName.replaceAll(
                      //     "/",
                      //     "-"
                      //   );
                      //   let subbusinessMatchFound = data.subBusinessTypes.find(
                      //     (e) => e.storefrontPathName === storefrontPathName
                      //   );
                      //   if (storeFrontId && businessName) {
                      //     let newPath = "";
                      //     if (userInfo) {
                      //       const path =
                      //         userInfo.userType === HOME_BUYER_USER
                      //           ? "homebuyer"
                      //           : "serviceprovider";
                      //       newPath = `/app/${path}/storefrontview/${businessName}?storeFrontId=${storeFrontId}`;
                      //     } else {
                      //       if (storefrontPathName && searchedCity) {
                      //         if (
                      //           data.storefrontPathName ===
                      //           "home-services-professional"
                      //         ) {
                      //           if (subbusinessMatchFound) {
                      //             newPath = `/app/storefront/${data.storeFrontRouteName}/${storefrontPathName}/${searchedCity.shortName}`;
                      //           } else {
                      //             newPath = `/app/storefront/${data.storeFrontRouteName}/${data.storefrontPathName}/${searchedCity.shortName}`;
                      //           }
                      //         } else {
                      //           newPath = `/app/storefront/${data.storeFrontRouteName}/${data.storefrontPathName}/${searchedCity.shortName}`;
                      //         }
                      //       } else {
                      //         newPath = `/app/storefront/${data.storeFrontRouteName}`;
                      //       }
                      //     }
                      //     window.open(newPath);

                      //     if (
                      //       window.location.href.includes("prepare-for-canada")
                      //     ) {
                      //       history.push({
                      //         pathname: newPath,
                      //         state: {
                      //           pageRoute: window.location.href,
                      //         },
                      //       });
                      //     } else {
                      //       window.open(newPath);
                      //     }
                      //   }
                      // }}
                    >
                      {/* {data.primarySubscriptionPlanName !== MARKETPLACE && (
                      <div className={classes.overlay}>
                        {data.primarySubscriptionPlanName === PREMIUM ? (
                          <img
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                            src="/images/Premium-Partner.svg"
                          ></img>
                        ) : (
                          <img
                            style={{ width: "100%", height: "100%" }}
                            src="/images/Verified-Partner.svg"
                          ></img>
                        )}
                      </div>
                    )} */}
                      <Grid
                        item
                        // style={{ border: "1px solid #17174C1F" }}
                      >
                        <CardMedia
                          className={classes.media}
                          image={
                            parsedStoreFrontContent.bannerPicture ||
                            "/app/images/logo.svg"
                          }
                          title=""
                        />
                      </Grid>
                      <Grid
                        item
                        container
                        style={{ margin: "20px 0px 0px 0px" }}
                      >
                        <Grid
                          item
                          xs={12}
                          style={{ textAlign: "center", height: "100%" }}
                        >
                          <img
                            src={
                              parsedStoreFrontContent.displayPicture ||
                              "/app/images/logo.svg"
                            }
                            alt="Logo"
                            title="Logo"
                            style={{
                              position: "relative",
                              borderRadius: "50%",
                              height: "42%",
                              width: "42%",
                              // border: "1px solid black",
                              // marginLeft: "20%",
                              backgroundColor: "white",
                              textAlign: "center",
                            }}
                          ></img>
                        </Grid>
                        {/* <Grid
                        container
                        item
                        justifyContent="space-between"
                        style={{ marginTop: 10 }}
                      >
                        <Grid item>
                          <PublicIcon
                            style={{
                              fill: webAddress ? "#0e1c4e" : "#0E1C4E42",
                              cursor: webAddress ? "pointer" : "default",
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                              if (webAddress) {
                                window.open(webAddress, "_blank");
                              }
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <MessageIcon
                            onClick={(event) => {
                              event.stopPropagation();
                              navigateMessage(event, data.email);
                              increaseLeadCount(data.storeFrontId, "Message");
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Tooltip
                            title={
                              formatContactNumber(data.contactNumber) || ""
                            }
                          >
                            <CallIcon
                              className={Styles.navyBlueColor}
                              onClick={(event) => {
                                event.stopPropagation();
                                increaseLeadCount(data.storeFrontId, "Call");
                                if (isMobileDevice()) {
                                  data.contactNumber &&
                                    window.open(
                                      `tel:${data.contactNumber}`,
                                      "_self"
                                    );
                                }
                              }}
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          {data.primarySubscriptionPlanName === PREMIUM ? (
                            <DateRangeIcon
                              className={Styles.navyBlueColorForce}
                              onClick={(event) => {
                                event.stopPropagation();
                                if (userInfo) {
                                  if (data.storeFrontId === 104) {
                                    // For SF name: Silver Burtnick & Associates
                                    window.open(
                                      "http://richardscalendar.torontoism.com/",
                                      "_blank"
                                    );
                                  } else {
                                    if (userInfo.userType === HOME_BUYER_USER) {
                                      history.push(
                                        "/homebuyer/calendar?scheduleMeeting=true"
                                      );
                                    } else {
                                      history.push(
                                        "/serviceprovider/calendar?scheduleMeeting=true"
                                      );
                                    }
                                  }
                                } else {
                                  history.push("/userlogin");
                                }
                              }}
                            />
                          ) : (
                            <RoomIcon
                              onClick={(event) => {
                                event.stopPropagation();
                                setAddressData(data.address);
                                setOpenAddressDialog(true);
                              }}
                            />
                          )}
                        </Grid>
                        <Grid item>
                          <ShareIcon
                            className={Styles.navyBlueColor}
                            style={{ cursor: "pointer" }}
                            onClick={(event) => {
                              event.stopPropagation();
                              setopenMoreTrafficDialog(true);
                              setMoreTrafficData({
                                storeFrontId: data.storeFrontId,
                                storeFrontRouteName: data.storeFrontRouteName,
                              });
                            }}
                          />
                        </Grid>
                      </Grid> */}
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                          <Tooltip title={data.storeFrontName}>
                            <Typography
                              variant="subtitle1"
                              className={Styles.sqEllipsis}
                            >
                              {data.storeFrontName}
                            </Typography>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                          <Tooltip title={data.businessName}>
                            <Typography
                              className={clsx(Styles.font14, Styles.sqEllipsis)}
                            >
                              {data.businessName}
                            </Typography>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                          <Tooltip title={businessTypeName}>
                            <Typography
                              className={clsx(Styles.font14, Styles.sqEllipsis)}
                            >
                              {businessTypeName}
                            </Typography>
                          </Tooltip>
                        </Grid>

                        {data.primarySubscriptionPlanName !== "Marketplace" ? (
                          <Grid
                            container
                            item
                            xs={12}
                            justifyContent="center"
                            alignContent="center"
                          >
                            <Tooltip title={data.primarySubscriptionPlanName}>
                              <img
                                style={{
                                  width: 20,
                                  height: 20,
                                  // marginRight: 12,
                                  marginTop: 4,
                                }}
                                src={
                                  data.primarySubscriptionPlanName === "Premium"
                                    ? "/app/images/blue_badge.svg"
                                    : "/app/images/teal_badge.svg"
                                }
                                alt={
                                  data.primarySubscriptionPlanName === "Premium"
                                    ? "blue badge"
                                    : "teal badge"
                                }
                                title={
                                  data.primarySubscriptionPlanName === "Premium"
                                    ? "blue badge"
                                    : "teal badge"
                                }
                              />
                            </Tooltip>
                          </Grid>
                        ) : (
                          <Grid item container style={{ height: 24 }} />
                        )}

                        <Grid
                          container
                          item
                          justifyContent="space-between"
                          style={{ marginTop: 20, marginBottom: 15 }}
                        >
                          <Grid item>
                            <PublicIcon
                              style={{
                                fill: webAddress ? "#0e1c4e" : "#0E1C4E42",
                                cursor: webAddress ? "pointer" : "default",
                              }}
                              onClick={(event) => {
                                event.stopPropagation();
                                if (webAddress) {
                                  if (webAddress.includes("http")) {
                                    window.open(webAddress, "_blank");
                                  } else {
                                    let webAdd = `https://${webAddress}`;
                                    window.open(webAdd, "_blank");
                                  }
                                }
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <MessageIcon
                              onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                setSFName(data.storeFrontName);
                                // navigateMessage(event, data.email);
                                increaseLeadCount(data.storeFrontId, "Message");
                                if (userInfo) {
                                  if (isMarketplacePlan(userInfo)) {
                                    setOpenPlanUpgradeMsgDialog(true);
                                  } else {
                                    navigateMessage(event, data.email);
                                  }
                                }
                                // else {
                                //   if (window.location.href && window.location.href.includes("prepare-for-canada")) {
                                //     setOpenSignupForFreePopup(true);
                                //   }
                                else {
                                  if (
                                    window.location.href &&
                                    window.location.href.includes(
                                      "prepare-for-canada"
                                    )
                                  ) {
                                    setOpenSignupForFreePopup(true);
                                  } else {
                                    history.push("/app/userlogin");
                                    setLoggedInUser(
                                      JSON.stringify({
                                        redirectUrl: `/app/homebuyer/messaging?sendMessage=${data.email}`,
                                      })
                                    );
                                  }
                                }
                                // }
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <Tooltip
                              title={
                                formatContactNumber(data.contactNumber) || ""
                              }
                            >
                              <CallIcon
                                className={Styles.navyBlueColor}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  event.preventDefault();
                                  increaseLeadCount(data.storeFrontId, "Call");
                                  if (
                                    window.location.href &&
                                    window.location.href.includes(
                                      "prepare-for-canada"
                                    )
                                  ) {
                                    setOpenSignupForFreePopup(true);
                                  } else {
                                    if (
                                      userInfo &&
                                      isMarketplacePlan(userInfo)
                                    ) {
                                      setOpenPlanUpgradeMsgDialog(true);
                                    } else {
                                      if (isMobileDevice()) {
                                        data.contactNumber &&
                                          window.open(
                                            `tel:${data.contactNumber}`,
                                            "_self"
                                          );
                                      }
                                    }
                                  }
                                }}
                              />
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            {data.primarySubscriptionPlanName === PREMIUM || data.primarySubscriptionPlanName === ADVANCED ? (
                              <DateRangeIcon
                                className={Styles.navyBlueColorForce}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  event.preventDefault();
                                  setCalSyncAccessToken(
                                    data.calSyncAccessToken
                                  );
                                  setSFName(data.storeFrontName);
                                  if (data.storeFrontId === 104) {
                                    window.open(
                                      "http://richardscalendar.torontoism.com/",
                                      "_blank"
                                    );
                                  } else {
                                    if (userInfo) {
                                      if (
                                        userInfo.userType === HOME_BUYER_USER
                                      ) {
                                        if (data.calSyncAccessToken) {
                                          setCalSyncAccessToken(
                                            data.calSyncAccessToken
                                          );
                                          setOpenScheduledPagesDialog(true);
                                        } else {
                                          history.push(
                                            "/homebuyer/calendar?scheduleMeeting=true"
                                          );
                                        }
                                      } else {
                                        if (isMarketplacePlan(userInfo)) {
                                          setOpenPlanUpgradeMsgDialog(true);
                                        } else {
                                          if (data.calSyncAccessToken) {
                                            setCalSyncAccessToken(
                                              data.calSyncAccessToken
                                            );
                                            setOpenScheduledPagesDialog(true);
                                          } else {
                                            history.push(
                                              "/app/serviceprovider/calendar?scheduleMeeting=true"
                                            );
                                          }
                                        }
                                      }
                                    } else {
                                      if (
                                        window.location.href &&
                                        window.location.href.includes(
                                          "prepare-for-canada"
                                        )
                                      ) {
                                        setOpenSignupForFreePopup(true);
                                      } else {
                                        history.push("/app/userlogin");
                                      }
                                    }
                                  }
                                }}
                              />
                            ) : (
                              <RoomIcon
                                onClick={(event) => {
                                  event.stopPropagation();
                                  event.preventDefault();
                                  if (
                                    window.location.href &&
                                    window.location.href.includes(
                                      "prepare-for-canada"
                                    )
                                  ) {
                                    setOpenSignupForFreePopup(true);
                                  } else {
                                    setAddressData(data.address);
                                    setOpenAddressDialog(true);
                                  }
                                  setUserEngagementData(
                                    data.storeFrontId,
                                    "Location"
                                  );
                                }}
                              />
                            )}
                          </Grid>
                          <Grid item>
                            <ShareIcon
                              className={Styles.navyBlueColor}
                              style={{ cursor: "pointer" }}
                              onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                setopenMoreTrafficDialog(true);
                                setMoreTrafficData({
                                  storeFrontId: data.storeFrontId,
                                  storeFrontRouteName: data.storeFrontRouteName,
                                  data: getMetadataInfo(data),
                                });
                              }}
                            />
                          </Grid>
                        </Grid>
                        {/* {data.reviewsCount ? (
                        <Grid item container spacing={1} alignItems="center">
                          <Grid item>
                            <Rating
                              name="rating"
                              value={data.avgStarRatings}
                              precision={0.5}
                              className={classes.primarColor}
                              readOnly
                            />
                          </Grid>
                          <Grid item>
                            <Typography
                              className={clsx(
                                classes.secondarColor,
                                Styles.font14
                              )}
                            >
                              {data.reviewsCount} Reviews
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid item container style={{ height: 32 }} />
                      )} */}
                        <Grid
                          item
                          container
                          justifyContent="center"
                          // style={{ marginTop: 8 }}
                        >
                          <Button
                            variant="outlined"
                            className={Styles.navyBlueColorForce}
                            style={{
                              textTransform: "capitalize",
                              borderRadius: 20,
                              paddingLeft: 28,
                              paddingRight: 28,
                              borderColor: "#0e1c4e",
                              fontWeight: 600,
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                              setBusinessName(data.businessName);
                              setServiceProviderId(data.serviceProviderId);
                              setOwnerEmail(data.email);
                              setPublicStorefrontId(data.storeFrontId);
                              setSFName(data.storeFrontName);
                              setSFServices(data.sfServices);
                              // setOpenserviceRequestDialog({
                              //   open: true,
                              //   service: data,
                              // });
                              if (
                                window.location.href.includes(
                                  "prepare-for-canada"
                                )
                              ) {
                                setOpenRentalInquiryFormDialog &&
                                  setOpenRentalInquiryFormDialog(true);
                              } else {
                                requestService(data, event);
                                setUserEngagementData(
                                  data.storeFrontId,
                                  "Request_Service"
                                );
                              }
                            }}
                          >
                            {/* {businessTypeList.includes(data.businessTypeName)
                            ? "Request Service"
                            : "Request Quote"} */}
                            {window.location.href.includes("prepare-for-canada")
                              ? "Request Rental Quote"
                              : "Request Quote"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Card>
                  </MaterialLink>
                </Grid>
              );
            })
          : null}
      </Grid>
    </React.Fragment>
  );
}
