import { useState } from "react";
import { color_success } from "../toasterColors";
import { useAuthUser } from "../useLogin";
import { useDispatch } from "react-redux";
import { useApiClient } from "../useApiClient";

export const useMarketplaceWidget = () => {
  const [rating, setRating] = useState(null);
  const [description, setDescription] = useState("");
  const user = useAuthUser();
  const dispatch = useDispatch();
  const { apiClient } = useApiClient();

  const clearData = () => {
    setRating(null);
    setDescription("");
  };

  const saveSelectedCategories = (
    uuid,
    selectedCategories,
    token,
    onResult
  ) => {
    if (uuid) {
      dispatch({ type: "SET_LOADING", value: true });
      apiClient
        .post("/marketplace-widget", selectedCategories, {
          params: {
            uuid: uuid,
            token: token,
          },
        })
        .then((res) => {
          if (res && res.status === 200) {
            console.log(res);
          }
          onResult && onResult(res);
        })
        .catch((error) => {
          console.error(error);
        })
        .then(function () {
          // always executed
          dispatch({ type: "SET_LOADING", value: false });
        });
    }
  };

  const getSelectedCategories = (uuid, onResult) => {
    console.log("IN HOOK", uuid);
    dispatch({ type: "SET_LOADING", value: true });
    apiClient
      .get("/marketplace-widget", {
        params: {
          uuid: uuid,
        },
      })
      .then((res) => {
        if (res && res.status === 200) {
          console.log(res);
          onResult && onResult(res);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .then(function () {
        // always executed
        dispatch({ type: "SET_LOADING", value: false });
      });
  };

  return {
    setRating,
    rating,
    description,
    setDescription,
    clearData,
    saveSelectedCategories,
    getSelectedCategories,
  };
};
