import { Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useSQQuery } from "souqh-react-redux-hooks/common/useSQQuery";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { HOME_BUYER_USER } from "../ServiceProvider/serviceProviderConstants";
import { useHistory } from "react-router-dom";

export default function RedirectToNotifications() {
  const userInfo = useAuthUser();
  const { query } = useSQQuery();
  const history = useHistory();
  useEffect(() => {
    if (query.get("showNotification")) {
      localStorage.setItem("showNotification", true);
    }
    if (userInfo) {
      if (userInfo.userType === HOME_BUYER_USER) {
        history.push("/app/homebuyer/dashboard");
      } else {
        history.push("/app/serviceprovider/dashboard");
      }
    } else {
      if (query.get("user_type") === HOME_BUYER_USER) {
        history.push("/app/userlogin");
      } else {
        history.push("/app/login");
      }
    }
  }, []);
  return (
    <Grid>
      <Typography>Loading...</Typography>
    </Grid>
  );
}
