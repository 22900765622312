import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  makeStyles,
  Divider,
  Hidden,
} from "@material-ui/core";
import Styles from "../../Styles.module.css";
import CloseIcon from "@material-ui/icons/Close";
import { useError } from "souqh-react-redux-hooks/useError";
import {
  isTabletDevice,
  isMobileDevice,
  isOnboardingInComplete,
  isSpApprovalPending,
} from "../../utils/AppUtils";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: 0,
    },
  },
  dialogPaper: {
    height: isTabletDevice() || isMobileDevice() ? 500 : 300,
    minWidth:
      isTabletDevice() || isMobileDevice() ? window.screen.width - 40 : 300,
  },
}));

export default function CompleteOnboardingDailog({
  openDialog,
  setOpenDailog,
}) {
  const history = useHistory();
  const userInfo = useAuthUser();

  const handleClose = () => {
    setOpenDailog(false);
  };
  const classes = useStyles();
  return (
    <Dialog
      open={openDialog}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle>
        <Grid container item xs={12}>
          <Grid item xs={8}>
            <Typography variant="subtitle1" className={Styles.navyBlueColor}>
              Notice
            </Typography>
          </Grid>
          <Grid container item xs={4} justifyContent="flex-end">
            <Typography variant="subtitle1" className={Styles.navyBlueColor}>
              <CloseIcon
                onClick={(event) => {
                  handleClose();
                }}
                style={{
                  fill: "#0e1c4e",
                  cursor: "pointer",
                  margin: "0px 8px",
                  fontSize: 20,
                  opacity: 1,
                }}
              />
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />

      <DialogContent style={{ overflowY: "hidden" }}>
        <Grid container item xs={12}>
          <Hidden mdUp>
            <Grid container item justifyContent="center" alignItems="center">
              <img
                src="/app/images/non-mobile-friendly.svg"
                style={{ width: 200, height: 200 }}
                alt="non-mobile-friendly"
                title="non-mobile-friendly"
              />
            </Grid>
          </Hidden>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              className={
                isMobileDevice() ? Styles.grayText : Styles.navyBlueColor
              }
              style={{ marginTop: 20 }}
            >
              {isMobileDevice()
                ? `Looks like you're on a mobile device. Please complete your onboarding using a computer to enable navigation and features within the platform.`
                : `Please complete your onboarding to enable navigation and features within the platform.`}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid
          container
          item
          xs={12}
          justifyContent={isMobileDevice() ? "center" : "flex-end"}
        >
          <Button
            className={Styles.navyBlueColor}
            variant="outlined"
            style={{
              textTransform: "capitalize",
              margin: 12,
              color: "#0e1c4e",
            }}
            onClick={() => handleClose()}
          >
            Close
          </Button>
          {!isMobileDevice() ? (
            <Button
              variant="outlined"
              onClick={() => {
                if (isMobileDevice()) {
                  setOpenDailog(true);
                } else {
                  history.push("/app/setup");
                }
              }}
              className={Styles.primaryBtnAccent}
              style={{
                textTransform: "capitalize",
                marginRight: 20,
                height: 40,
                marginTop: 10,
              }}
            >
              Complete Onboarding
            </Button>
          ) : null}
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
