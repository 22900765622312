import {
  Dialog,
  DialogTitle,
  Typography,
  makeStyles,
  DialogContent,
  TextField,
  Grid,
  Card,
  CardMedia,
  Tooltip,
  InputAdornment,
  Button,
} from "@material-ui/core";
import React, { useState } from "react";
import Styles from "../../../Styles.module.css";
import PublicIcon from "@material-ui/icons/Public";
import MessageIcon from "@material-ui/icons/Message";
import DateRangeIcon from "@material-ui/icons/DateRange";
import RoomIcon from "@material-ui/icons/Room";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import clsx from "clsx";
import { useHistory } from "react-router";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { useNavigateToMessage } from "souqh-react-redux-hooks/common/useNavigateToMessage";
import Rating from "@material-ui/lab/Rating";
import SearchIcon from "@material-ui/icons/Search";
import { useAddSPDialog } from "souqh-react-redux-hooks/homeBuyer/spManagement/useAddSPDialog";
import InviteSPDialog from "../../ReferralManagement/InviteSPdialog";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 580,
  },
  secondarColor: {
    color: "#F86C6B",
  },
  marginTop10: {
    marginTop: 10,
  },
  cardBorder: {
    borderRadius: "8px 0 8px 8px",
    padding: 15,
  },
  media: {
    height: 0,
    paddingTop: "75%", // 4:3
  },
  reLativeCard: {
    position: "relative",
  },
  overlay: {
    top: -23,
    right: -29,
    position: "absolute",
    width: 120,
    height: 120,
  },
  clickableCard: {
    cursor: "pointer",
  },
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#0e1c4e",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0e1c4e",
      },
    },
  },
}));
export default function AddSPDialog({ addSpDialog, setAddSpDialog }) {
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");
  const [openInviteSPDialog, setOpenInviteSPDialog] = useState(false);
  const history = useHistory();
  const userInfo = useAuthUser();
  const { navigateMessage } = useNavigateToMessage();

  const {
    getSPData,
    storeFrontList,
    setStoreFrontList,
    noSearchResults,
    setNoSearchResults,
  } = useAddSPDialog();

  return (
    <React.Fragment>
      <InviteSPDialog
        openInviteSPDialog={openInviteSPDialog}
        setOpenInviteSPDialog={setOpenInviteSPDialog}
      />

      <Dialog
        fullWidth
        maxWidth="lg"
        open={addSpDialog}
        classes={{
          paper: classes.dialogPaper,
        }}
        onClose={() => {
          setAddSpDialog(false);
          setSearchText("");
          setStoreFrontList([]);
          setNoSearchResults(false);
        }}
      >
        <DialogTitle className={Styles.navyBlueColor}>
          <Typography variant="subtitle1">Add New Service Provider</Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            classes={{ root: classes.root }}
            value={searchText}
            variant="outlined"
            fullWidth
            placeholder="Search by service provider name, email address, business name or storefront name"
            style={{ backgroundColor: "#FFFFFF" }}
            onChange={(event) => {
              setSearchText(event.target.value);
            }}
            // FIX ME: will remove later if not required
            // onKeyPress={(event) => {
            //   if (event.code === "Enter") {
            //     getSPData(event.target.value);
            //   }
            // }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon
                    className={Styles.navyBlueColor}
                    style={{ cursor: "pointer" }}
                    onClick={(event) => {
                      getSPData(searchText);
                    }}
                  />
                </InputAdornment>
              ),
            }}
          ></TextField>
          <Grid
            container
            item
            xs={12}
            spacing={3}
            className={classes.marginTop10}
          >
            {noSearchResults && (
              <Grid container>
                <Grid item xs={12}>
                  <Typography className={Styles.navyBlueColor}>
                    looks like we couldn’t find any service providers that match
                    your request. Know someone that might be a good fit? Invite
                    them to Souqh and earn referral credits that can be used
                    towards future home services.
                  </Typography>
                </Grid>
                <Grid container item xs={12} alignContent="flex-end">
                  <Button
                    style={{ textTransform: "capitalize" }}
                    className={Styles.primaryBtnAccent}
                    onClick={() => setOpenInviteSPDialog(true)}
                  >
                    Invite
                  </Button>
                </Grid>
              </Grid>
            )}

            {storeFrontList && storeFrontList.length && !noSearchResults
              ? storeFrontList.map((data, index) => {
                  const storeFrontContent = JSON.parse(data.storeFrontContent);
                  return (
                    <Grid key={index} item xs={12} md={4} lg={3} xl={2}>
                      <Card
                        // ref={(el) => {
                        //   if (elRefs) return (elRefs.current[index] = el);
                        // }}
                        raised
                        className={clsx(
                          classes.cardBorder,
                          classes.primarColor,
                          classes.reLativeCard,
                          classes.clickableCard
                        )}
                        onClick={() => {
                          const storeFrontId = data.storeFrontId;
                          const businessName = data.businessName;
                          if (storeFrontId && businessName) {
                            let newPath = "";
                            if (userInfo) {
                              newPath = `/app/homebuyer/storefrontview/${businessName}?storeFrontId=${storeFrontId}`;
                            } else {
                              newPath = `/app/storefront/${data.storeFrontRouteName}`;
                            }
                            history.push(newPath);
                          }
                        }}
                      >
                        <div className={classes.overlay}>
                          {data.primarySubscriptionPlanName === "Premium" ? (
                            <img
                              style={{ width: "100%", height: "100%" }}
                              src="/app/images/Premium-Partner.svg"
                              alt="premium partner"
                              title="premium partner"
                            ></img>
                          ) : (
                            <img
                              style={{ width: "100%", height: "100%" }}
                              src="/app/images/Verified-Partner.svg"
                              alt="premium partner"
                              title="premium partner"
                            ></img>
                          )}
                        </div>
                        <Grid item style={{ border: "1px solid #17174C1F" }}>
                          <CardMedia
                            className={classes.media}
                            image={
                              storeFrontContent.displayPicture ||
                              "/app/images/logo.svg"
                            }
                            title=""
                          />
                        </Grid>
                        <Grid
                          container
                          item
                          justifyContent="space-between"
                          style={{ marginTop: 10 }}
                        >
                          <Grid item>
                            <PublicIcon />
                          </Grid>
                          <Grid item>
                            <MessageIcon
                              onClick={(event) => {
                                navigateMessage(event, data.email);
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <DateRangeIcon />
                          </Grid>
                          <Grid item>
                            <RoomIcon />
                          </Grid>
                          <Grid item>
                            <FavoriteBorderIcon />
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Tooltip title={data.storeFrontName}>
                            <Typography
                              variant="subtitle1"
                              className={Styles.sqEllipsis}
                            >
                              {data.storeFrontName}
                            </Typography>
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <Tooltip title={data.businessTypeName}>
                            <Typography
                              className={clsx(Styles.font14, Styles.sqEllipsis)}
                            >
                              {data.businessTypeName}
                            </Typography>
                          </Tooltip>
                        </Grid>
                        <Grid item container spacing={1} alignItems="center">
                          <Grid item>
                            <Rating
                              name="rating"
                              value={0}
                              precision={0.5}
                              className={classes.primarColor}
                              readOnly
                            />
                          </Grid>
                          <Grid item>
                            <Typography
                              className={clsx(
                                classes.secondarColor,
                                Styles.font14
                              )}
                            >
                              0 Reviews
                            </Typography>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  );
                })
              : null}
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
