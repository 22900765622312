import {
  Checkbox,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import Styles from "../../../Styles.module.css";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { isMobileDevice } from "../../../utils/AppUtils";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  subText: {
    color: "#868DA6",
  },
}));

export function AddOn({ section, campaignData, setStep1Data }) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        xs={12}
        style={{
          border: "1px solid #0000001F",
          borderRadius: 4,
          padding: !isMobileDevice() && "0px 20px",
          marginTop: 30,
        }}
      >
        {section !== 3 && (
          <Grid item xs={2}>
            <Checkbox
              checked={campaignData && campaignData.addOnHomePage}
              icon={icon}
              checkedIcon={checkedIcon}
              color={Styles.navyBlueColorForce}
              onChange={(event) => {
                setStep1Data &&
                  setStep1Data({
                    field: "addOnHomePage",
                    value: event.target.checked,
                  });
              }}
            />
          </Grid>
        )}
        <Grid item xs={isMobileDevice() || section === 3 ? 7 : 4}>
          <Typography variant="subtitle1" className={Styles.navyBlueColor}>
            Add On
          </Typography>
          <Typography variant="subtitle1" className={Styles.primaryTxtAccent}>
            Homepage Feature
            <Tooltip title="This add-on allows you to be advertised right on our homepage as a featured service provider.">
              <InfoOutlinedIcon
                style={{ marginLeft: 12, marginBottom: -5 }}
                className={Styles.navyBlueColor}
                fontSize="small"
              />
            </Tooltip>
          </Typography>
        </Grid>
        {!isMobileDevice() && (
          <Grid item xs={section !== 3 ? 3 : 2}>
            <img
              style={{
                height: section === 3 ? 50 : 80,
                width: section === 3 ? 80 : 160,
                marginTop: 10,
              }}
              src="/app/images/addOn.svg"
            />
          </Grid>
        )}
        <Grid item xs={3}>
          <Typography
            variant="h6"
            className={Styles.navyBlueColor}
            style={{ textAlign: "center" }}
          >
            +${campaignData && campaignData.addOnValue}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

function TimeBudget({ data, step = "" }) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid
        container
        xs={12}
        style={{
          border:
            data && data.selected && step !== "step3"
              ? "1px solid #FA7E61"
              : "1px solid #0000001F",
          borderRadius: 4,
          padding: "10px 20px",
        }}
      >
        <Grid item xs={6}>
          <Typography variant="subtitle1" className={Styles.navyBlueColor}>
            {data && data.days} days
          </Typography>
          <Typography variant="body2" className={classes.subText}>
            {data && data.duration}
          </Typography>
        </Grid>
        <Grid item xs={6} justifyContent="end" style={{ display: "grid" }}>
          <Typography variant="subtitle1" className={Styles.navyBlueColor}>
            ${data && data.amount}
          </Typography>
          <Typography variant="body2" className={classes.subText}>
            {data && data.description}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default TimeBudget;
