import React from "react";
import { Grid, Tooltip, Typography } from "@material-ui/core";

import ImageCropper from "./../../ImageCropper";
import { useBannerPicture } from "souqh-react-redux-hooks/serviceProvider/storefront/useBannerPicture";
import Styles from "../../../Styles.module.css";

function BannerPicture() {
  const {
    bannerPicture,
    actions: { setBannerPicture },
  } = useBannerPicture();
  return (
    <Tooltip title="Add a banner image">
      <Grid container style={{ position: "relative" }}>
        <ImageCropper
          helperText1={"Please add a banner image"}
          helperText2={""}
          type="banner"
          image={bannerPicture}
          cropWidth={960}
          cropHeight={320}
          placeholderImage="/app/images/banner.svg"
          onImageAvailalbe={(image) => {
            setBannerPicture({ bannerPicture: image });
          }}
        ></ImageCropper>
      </Grid>
    </Tooltip>
  );
}

export default BannerPicture;
