import {
  AppBar,
  Avatar,
  ButtonBase,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import Styles from "../../Styles.module.css";
import { useHistory } from "react-router-dom";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { useUpcomingEeventsPendingActions } from "souqh-react-redux-hooks/serviceProvider/upcomingEeventsPendingActions/useUpcomingEeventsPendingActions";
import {
  HOME_BUYER_USER,
  MEETING_READ_MODE,
} from "../ServiceProvider/serviceProviderConstants";
import GroupRoundedIcon from "@material-ui/icons/GroupRounded";
import PersonRoundedIcon from "@material-ui/icons/PersonRounded";
import SharedWithPopup from "../DocumentManagementDialogs/SharedWithPopup";
import ConfirmCancelEventDialog from "./UpcomingEeventsPendingActions/ConfirmCancelEventDialog";
import {
  isMarketplacePlan,
  isMobileDevice,
  isOnboardingInComplete,
  isProPlan,
  stableSort,
  getComparator,
} from "../../utils/AppUtils";
import { MEETING_CREATE_MODE } from "../ServiceProvider/serviceProviderConstants";
import NoDataAvailable from "./NoDataAvailable";
import PlanUpgradeMsgDialog from "./PlanUpgradeMsgDialog";

export default function UpcomingEventsWidget({
  xlUp,
  upcomingEventsList,
  getAttendeesTooltip,
  cancelEvent,
  setEventSelected,
  getCalendarEventDetails,
  setMeetingData,
  setMeetingMode,
  setOpenCreateMeetingDialog,
  openPlanUpgradeMsgDialog,
  setOpenPlanUpgradeMsgDialog,
}) {
  let history = useHistory();

  const [openSharedWithPopup, setOpenSharedWithPopup] = useState({
    open: false,
    sharedWithList: [],
  });
  const [openConfirmCancelEventDialog, setOpenConfirmCancelEventDialog] =
    useState(false);

  const {
    actions: { setEventsTab },
    userInfo,
  } = useUpcomingEeventsPendingActions();

  if (!upcomingEventsList) {
    return null;
  }
  return (
    <React.Fragment>
      <SharedWithPopup
        openSharedWithPopup={openSharedWithPopup.open}
        setOpenSharedWithPopup={setOpenSharedWithPopup}
        sharedWithList={openSharedWithPopup.sharedWithList}
        title="Attendees"
      />
      <ConfirmCancelEventDialog
        openConfirmCancelEventDialog={openConfirmCancelEventDialog}
        setOpenConfirmCancelEventDialog={setOpenConfirmCancelEventDialog}
        cancelEvent={cancelEvent}
      />
      {upcomingEventsList && upcomingEventsList.length ? (
        <TableContainer
          component={Paper}
          elevation={0}
          className="hideScroll"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Table
            stickyHeader
            style={{
              padding: "8px 8px 32px 8px",
              tableLayout: "fixed",
              width: "100%",
            }}
          >
            <TableBody>
              {upcomingEventsList && upcomingEventsList.length
                ? stableSort(
                    upcomingEventsList,
                    getComparator("asc", "startDateTime")
                  )
                    .splice(0, 10)
                    .map((row, index) => {
                      const firstUser = row.attendees[0] || {};
                      const usersToolTip = getAttendeesTooltip(row.attendees);
                      let isAttendee = row.attendees.find(
                        (o) => o.email === userInfo.email
                      );

                      return (
                        <TableRow key={index}>
                          <TableCell
                            style={{ padding: "8px 0px", width: "90%" }}
                          >
                            <Grid container>
                              <Grid item xs={2}>
                                <Avatar style={{ width: 32, height: 32 }}>
                                  {row.attendees.length > 1 ? (
                                    <GroupRoundedIcon />
                                  ) : (
                                    <PersonRoundedIcon />
                                  )}
                                </Avatar>
                              </Grid>
                              <Grid
                                container
                                item
                                xs={10}
                                style={{ paddingLeft: 10 }}
                              >
                                <Grid item xs={12}>
                                  <Typography
                                    className={Styles.navyBlueColor}
                                    style={{
                                      fontWeight: 600,
                                      fontSize:
                                        isMobileDevice() || xlUp ? 14 : 11,
                                    }}
                                  >
                                    {row.displayFromDate}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography
                                    className={Styles.primaryTxtAccentForce}
                                    style={{
                                      fontSize:
                                        isMobileDevice() || xlUp ? 14 : 11,
                                      cursor: !isProPlan(userInfo) && "pointer",
                                    }}
                                    onClick={() => {
                                      if (isMarketplacePlan(userInfo)) {
                                        setOpenPlanUpgradeMsgDialog(true);
                                      } else {
                                        if (!isProPlan(userInfo)) {
                                          // getCalendarEventDetails(
                                          //   row.id,
                                          //   (fullData) => {
                                          //     setMeetingData(fullData);
                                          //     setMeetingMode(MEETING_READ_MODE);
                                          //     setOpenCreateMeetingDialog(true);
                                          //   }
                                          // );
                                          setMeetingData(row);
                                          setMeetingMode(MEETING_READ_MODE);
                                          setOpenCreateMeetingDialog(true);
                                        }
                                      }
                                    }}
                                  >
                                    {row.title}
                                  </Typography>
                                </Grid>
                                <Grid item container xs={12}>
                                  <Typography
                                    style={{
                                      fontSize:
                                        isMobileDevice() || xlUp ? 14 : 11,
                                      width: "100%",
                                    }}
                                    className={Styles.navyBlueColor}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        className={Styles.sqEllipsis}
                                        style={{
                                          maxWidth: usersToolTip
                                            ? "60%"
                                            : "90%",
                                          display: "inline-block",
                                        }}
                                      >
                                        {firstUser.fullName || firstUser.email}
                                        &nbsp;&nbsp;
                                      </div>
                                      {usersToolTip.length ? (
                                        <Link
                                          className={Styles.navyBlueColorForce}
                                          style={{ cursor: "pointer" }}
                                          underline="always"
                                          onClick={() => {
                                            if (isMarketplacePlan(userInfo)) {
                                              setOpenPlanUpgradeMsgDialog(true);
                                            } else {
                                              setOpenSharedWithPopup({
                                                open: true,
                                                sharedWithList: usersToolTip,
                                              });
                                            }
                                          }}
                                        >{`+${
                                          row.attendees.length - 1
                                        } more`}</Link>
                                      ) : null}
                                    </div>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell
                            style={{
                              padding: xlUp ? 16 : "8px 0px",
                              width: "10%",
                            }}
                          >
                            {!isAttendee && !isProPlan(userInfo) ? (
                              <CancelOutlinedIcon
                                className={Styles.navyBlueColor}
                                style={{ fontSize: 16, cursor: "pointer" }}
                                onClick={() => {
                                  if (isMarketplacePlan(userInfo)) {
                                    setOpenPlanUpgradeMsgDialog(true);
                                  } else {
                                    setEventSelected(row);
                                    setOpenConfirmCancelEventDialog(true);
                                  }
                                }}
                              />
                            ) : null}
                          </TableCell>
                        </TableRow>
                      );
                    })
                : null}
            </TableBody>
          </Table>
          {upcomingEventsList.length ? (
            <div
              style={{
                position: "sticky",
                alignItems: "flex-end",
                bottom: 0,
                width: "100%",
              }}
            >
              <AppBar
                position="sticky"
                style={{
                  top: "auto",
                  bottom: 0,
                  backgroundColor: "#FFFFFF",
                  color: "#FA7E61",
                  height: 48,
                }}
              >
                <Grid
                  container
                  item
                  justifyContent="flex-end"
                  alignContent="center"
                  style={{ paddingRight: xlUp ? "7%" : "5%", height: "100%" }}
                >
                  <ButtonBase
                    onClick={() => {
                      if (userInfo.userType === HOME_BUYER_USER) {
                        history.push("/app/homebuyer/events");
                      } else {
                        if (isMarketplacePlan(userInfo)) {
                          setOpenPlanUpgradeMsgDialog(true);
                        } else {
                          history.push("/app/serviceprovider/events");
                        }
                      }
                      setEventsTab(0);
                    }}
                  >
                    <Typography variant={xlUp ? "body1" : "body2"}>
                      View All
                    </Typography>
                  </ButtonBase>
                </Grid>
              </AppBar>
            </div>
          ) : null}
        </TableContainer>
      ) : isProPlan(userInfo) ? (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <img
              src="/app/images/Premium.svg"
              style={{ height: 150, width: 143 }}
              alt="premium"
              title="premium"
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              textAlign: "center",
            }}
          >
            <Typography
              style={{
                color: "#868DA6",
                fontSize: 11,
                marginTop: -60,
                padding: "0px 16px",
              }}
            >
              Interested in this feature? Click{" "}
              <Link
                style={{ cursor: "pointer" }}
                onClick={() => {
                  history.push(
                    "/app/serviceprovider/settings/subscription?panel=panel1"
                  );
                }}
              >
                here
              </Link>{" "}
              to upgrade your plan
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <NoDataAvailable>
          <Typography
            style={{
              color: "#868DA6",
              fontSize: 11,
              marginTop: -60,
              padding: "0px 16px",
            }}
          >
            Looks like you don’t have any upcoming events. Click{" "}
            <Link
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (!isOnboardingInComplete(userInfo)) {
                  if (isMarketplacePlan(userInfo)) {
                    setOpenPlanUpgradeMsgDialog(true);
                  } else {
                    setMeetingMode(MEETING_CREATE_MODE);
                    setOpenCreateMeetingDialog(true);
                  }
                }
              }}
            >
              here
            </Link>{" "}
            to schedule a new meeting
          </Typography>
        </NoDataAvailable>
      )}
    </React.Fragment>
  );
}
