import {
  Avatar,
  Divider,
  Drawer,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  MenuList,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import { useAuthUser, useLogout } from "souqh-react-redux-hooks/useLogin";
import {
  ADVANCED,
  HOME_BUYER_USER,
  PREMIUM,
  SERVICE_PROVIDER_USER,
  TEAM_MEMBER_USER,
} from "../components/ServiceProvider/serviceProviderConstants";
import Styles from "../Styles.module.css";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FeedbackIcon from "@material-ui/icons/Feedback";
import SettingsIcon from "@material-ui/icons/Settings";
import MessageIcon from "@material-ui/icons/Message";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import GroupIcon from "@material-ui/icons/Group";
import StoreIcon from "@material-ui/icons/Store";
import {
  isMobileDevice,
  isOnboardingInComplete,
  isTabletDevice,
} from "../utils/AppUtils";
import SearchIcon from "@material-ui/icons/Search";
import DateRangeIcon from "@material-ui/icons/DateRange";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: "85%",
  },
  drawer: {
    flexShrink: 0,
  },
  padding0: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export default function MobileDrawer({
  mobileDrawer,
  toggleMobileDrawer,
  setNotificationMobileDrawer,
  unreadNotifications,
}) {
  const classes = useStyles();
  const user = useAuthUser();
  let history = useHistory();
  const { logout } = useLogout();

  return (
    <Drawer
      anchor="right"
      open={mobileDrawer}
      className={classes.drawer}
      onClose={toggleMobileDrawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Grid container style={{ padding: "16px" }}>
        <Grid
          container
          item
          xs={3}
          md={2}
          justifyContent="center"
          style={{ marginLeft: isTabletDevice() && -12 }}
        >
          <Avatar
            src={user.avatarUrl || "/broken-image.jpg"}
            style={{ marginRight: 10 }}
          />
        </Grid>
        <Grid item xs={8} md={9}>
          <Typography variant="h6" className={Styles.navyBlueColor}>
            {user.userType === TEAM_MEMBER_USER &&
              user.subscriptionPlanName !== PREMIUM && user.subscriptionPlanName !== ADVANCED
              ? user.fullName.split(" ")[0]
              : user.firstName}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <MenuList id="menu-list-grow" className={Styles.navyBlueColor}>
        {!isTabletDevice() && user.userType !== HOME_BUYER_USER && (
          <MenuItem
            disabled={
              user.userType !== HOME_BUYER_USER && isOnboardingInComplete(user)
            }
            className={classes.padding0}
            onClick={() => {
              toggleMobileDrawer();
              if (user.userType === HOME_BUYER_USER) {
                history.push(`/app/homebuyer/explore`);
              } else {
                history.push(`/app/serviceprovider/explore`);
              }
            }}
          >
            <IconButton color="inherit" className={classes.paddingLeft0}>
              <SearchIcon />
            </IconButton>
            <Typography>Marketplace</Typography>
          </MenuItem>
        )}
        {user.userType === HOME_BUYER_USER && isMobileDevice() && (
          <MenuItem
            className={classes.padding0}
            onClick={() => {
              toggleMobileDrawer();
              history.push(`/app/homebuyer/documents`);
            }}
          >
            <IconButton color="inherit" className={classes.paddingLeft0}>
              <InsertDriveFileIcon />
            </IconButton>
            <Typography>Documents</Typography>
          </MenuItem>
        )}
        {user.userType !== HOME_BUYER_USER && (
          <MenuItem
            className={classes.padding0}
            onClick={() => {
              toggleMobileDrawer();
              history.push("/app/serviceprovider/storefront");
            }}
          >
            <IconButton color="inherit" className={classes.paddingLeft0}>
              <StoreIcon />
            </IconButton>
            <Typography>Storefront</Typography>
          </MenuItem>
        )}
        <MenuItem
          className={classes.padding0}
          onClick={() => {
            toggleMobileDrawer();
            if (user.userType === HOME_BUYER_USER) {
              history.push(`/app/homebuyer/calendar`);
            } else {
              history.push(`/app/serviceprovider/calendar`);
            }
          }}
        >
          <IconButton color="inherit" className={classes.paddingLeft0}>
            <DateRangeIcon />
          </IconButton>
          <Typography>Calendar</Typography>
        </MenuItem>
        {user.userType !== HOME_BUYER_USER && (
          <MenuItem
            disabled={isOnboardingInComplete(user)}
            className={classes.padding0}
            onClick={() => {
              toggleMobileDrawer();
              history.push("/app/serviceprovider/marketing");
            }}
          >
            <img
              src="/app/images/marketing_blue.svg"
              style={{ width: 20, height: 20, padding: 14 }}
            />
            <Typography>Marketing</Typography>
          </MenuItem>
        )}
        <MenuItem
          className={classes.padding0}
          onClick={() => {
            if (
              (user.userType !== HOME_BUYER_USER &&
                !isOnboardingInComplete(user)) ||
              user.userType === HOME_BUYER_USER
            ) {
              toggleMobileDrawer();
              setNotificationMobileDrawer(true);
            }
          }}
        >
          <NotificationsNoneIcon style={{ padding: "14px 14px 14px 11px" }} />
          <Typography>Notifications</Typography>
          {unreadNotifications && unreadNotifications.length ? (
            <FiberManualRecordIcon
              style={{
                fontSize: 16,
                fill: "#fa7e61",
                marginLeft: 10,
              }}
            />
          ) : null}
        </MenuItem>
        <Divider />
        <MenuItem
          className={classes.padding0}
          onClick={() => window.open("http://help.souqh.ca/", "_blank")}
        >
          <IconButton color="inherit" className={classes.paddingLeft0}>
            <HelpOutlineIcon />
          </IconButton>
          <Typography>Help and Support</Typography>
        </MenuItem>
        <Divider />
        <MenuItem
          className={classes.padding0}
          onClick={() => {
            toggleMobileDrawer();
            const usertype = user.userType;
            logout((res) => {
              if (
                usertype === SERVICE_PROVIDER_USER ||
                usertype === TEAM_MEMBER_USER
              ) {
                history.push("/app/login");
              } else {
                history.push("/app/userlogin");
              }
            });
          }}
        >
          <IconButton color="inherit" className={classes.paddingLeft0}>
            <ExitToAppIcon />
          </IconButton>
          <Typography>Logout</Typography>
        </MenuItem>
      </MenuList>
    </Drawer>
  );
}
