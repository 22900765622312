import {
  Button,
  Grid,
  makeStyles,
  Step,
  StepButton,
  Stepper,
  Typography,
} from "@material-ui/core";
import React from "react";
import { covertTimeStampToDate, isMobileDevice } from "../../../utils/AppUtils";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router-dom";
import NoDataAvailable from "../../common/NoDataAvailable";
import Styles from "../../../Styles.module.css";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "24px 0px",
    // justifyContent: "center",
    "& .MuiStepConnector-horizontal": {
      top: 17,
      left: "calc(-50% + 15px)",
      right: "calc(50% + 15px)",
      position: "absolute",
    },
    "& .MuiStepConnector-line": {
      borderTopWidth: 5,
      borderColor: "#66D7D1",
    },
    "& .MuiStep-horizontal": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

export default function IndividualSPTimeline({
  individualSPTimelineData,
  xlUp,
  setShowIndividualSPData,
  actions,
  documentActions,
  isHomeOwnershipWidget,
  isClientsHubTab,
}) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <React.Fragment>
      {isHomeOwnershipWidget && (
        <Grid
          item
          xs={12}
          style={{
            padding: 0,
            height: isMobileDevice() ? 0 : xlUp ? "12%" : "15%",
          }}
        >
          <Button
            startIcon={<ArrowBackIosIcon />}
            style={{
              textTransform: "capitalize",
            }}
            className={Styles.navyBlueColorForce}
            onClick={() => {
              isHomeOwnershipWidget &&
                setShowIndividualSPData &&
                setShowIndividualSPData(false);
              actions && actions.clearIndividualSPTimelineData();
            }}
          >
            {individualSPTimelineData.storeFrontName}
          </Button>
        </Grid>
      )}

      <Stepper
        // activeStep={activeStep}
        alternativeLabel
        className={classes.root}
        style={{
          display: "flex",
          flexWrap: "nowrap",
          overflowX: "auto",
          minHeight: "11.5rem",
          marginTop: (!isHomeOwnershipWidget || isMobileDevice()) && 20,
          maxWidth: !isMobileDevice()
            ? !isHomeOwnershipWidget &&
              (isClientsHubTab
                ? window.innerWidth - 240
                : window.innerWidth - 216)
            : "none",
          //   backgroundColor: !isHomeOwnershipWidget && "transparent",
        }}
      >
        {individualSPTimelineData &&
        individualSPTimelineData.clientHistoryResponseDTOList &&
        individualSPTimelineData.clientHistoryResponseDTOList.length ? (
          individualSPTimelineData.clientHistoryResponseDTOList.map(
            (data, index) => {
              return (
                <Step
                  key={index}
                  style={{
                    minWidth: isMobileDevice()
                      ? "70%"
                      : isHomeOwnershipWidget
                      ? "30%"
                      : isClientsHubTab
                      ? "27%"
                      : "24%",
                    cursor:
                      documentActions &&
                      documentActions.includes(data.clientHistoryEnum) &&
                      "pointer",
                    maxWidth: 300,
                    marginTop: !isHomeOwnershipWidget && 28,
                  }}
                  onClick={() => {
                    documentActions &&
                      documentActions.includes(data.clientHistoryEnum) &&
                      history.push(
                        `/app/homebuyer/serviceproviders/details/${individualSPTimelineData.serviceProviderId}`
                      );
                  }}
                >
                  <StepButton
                    disableRipple
                    icon={
                      <div
                        style={{
                          borderRadius: "50%",
                          backgroundColor: index !== 0 && "#66D7D1",
                          border: "5px solid #66D7D1",
                          height: 22,
                          width: 22,
                          marginTop: 3,
                        }}
                      ></div>
                    }
                    style={{ cursor: "default" }}
                  >
                    <Typography
                      className={Styles.navyBlueColorForce}
                      style={{
                        fontWeight: 600,
                        fontSize: 14,
                        cursor:
                          documentActions &&
                          documentActions.includes(data.clientHistoryEnum) &&
                          "pointer",
                        textDecoration:
                          documentActions &&
                          documentActions.includes(data.clientHistoryEnum) &&
                          "underline",
                      }}
                    >
                      {data.title}
                    </Typography>
                    <Typography
                      className={Styles.navyBlueColorForce}
                      style={{ fontSize: 14 }}
                    >
                      {data.eventOwner}
                    </Typography>
                    <Typography style={{ color: "#868DA6", fontSize: 11 }}>
                      {covertTimeStampToDate(data.historyDateTime)}
                    </Typography>
                  </StepButton>
                </Step>
              );
            }
          )
        ) : (
          <NoDataAvailable>
            <Typography
              style={{
                color: "#868DA6",
                fontSize: 11,
                padding: "0px 16px",
              }}
            >
              Looks like you don't have any information
            </Typography>
          </NoDataAvailable>
        )}
      </Stepper>
    </React.Fragment>
  );
}
