import {
  Grid,
  TableRow,
  TableCell,
  Typography,
  ButtonBase,
  Button,
  Tooltip,
} from "@material-ui/core";
import React, { useState } from "react";
import Styles from "../../../Styles.module.css";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import clsx from "clsx";
import MoreOptionsOnSPInfo from "./MoreOptionsOnSPInfo";
import * as moment from "moment";
import DocumentPreviewDialog from "../../HomeBuyer/Explore/StorefrontPage/DocumentPreviewDialog";
import RejectionReasonDialog from "../../ServiceProvider/ClientManagement/RejectionReasonDialog";
import Attachments from "./Attachments";
import { Link, useHistory } from "react-router-dom";
import { getUploadedDocuments } from "../../../utils/AppUtils";
import { useBroadcastServiceRequest } from "souqh-react-redux-hooks/homeBuyer/useBroadcastServiceRequest";

export default function RequestSPTableRow({
  data,
  index,
  getSpManagementData,
  pageNumber,
  getRequestsSPData,
  getArchiveSPData,
}) {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDocumetDialog, setOpenDocumetDialog] = useState(false);
  const [documentItem, setDocumentItem] = useState(null);
  const sfDetails = data.storefrontDetails;
  const sfContents =
    sfDetails.storeFrontContent && JSON.parse(sfDetails.storeFrontContent);
  const [openRejectionReasonDialog, setOpenRejectionReasonDialog] =
    useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [docCategoryId, setDocCategoryId] = useState(null);
  const [docNameForDownload, setDocNameForDownload] = useState(null);
  const [docUserId, setDocUserId] = useState(null);

  const handleClick = (event) => {
    if (!data.broadCastServiceRequestDto) {
      setAnchorEl(event.currentTarget);
    }
  };

  const { updateBroadcastServiceRequestStatus } = useBroadcastServiceRequest();

  const timelineOptions = [
    { name: "Urgent", value: "URGENT" },
    { name: "As Soon As Possible", value: "AS_SOON_AS_POSSIBLE" },
    { name: "1-2 weeks", value: "ONE_TO_TWO_WEEKS" },
    { name: "2-4 weeks", value: "TWO_TO_FOUR_WEEKS" },
    { name: "4-8 weeks", value: "FOUR_TO_EIGHT_WEEKS" },
    { name: "8 weeks plus", value: "EIGHT_WEEKS_PLUS" },
  ];

  let formattedTimeline =
    data.broadCastServiceRequestDto &&
    timelineOptions.filter(
      (item) => item.value === data.broadCastServiceRequestDto.timeLine
    )[0];

  return (
    <React.Fragment>
      <RejectionReasonDialog
        openRejectionReasonDialog={openRejectionReasonDialog}
        setOpenRejectionReasonDialog={setOpenRejectionReasonDialog}
        getSpManagementData={getSpManagementData}
        title={"Cancel Request"}
        message={`Request Cancelled - ${sfDetails.storeFrontName}`}
        actionType="CANCEL_REQUEST"
      />
      <MoreOptionsOnSPInfo
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        showSendMessageOption={true}
      />
      <DocumentPreviewDialog
        openDocumetDialog={openDocumetDialog}
        setOpenDocumetDialog={setOpenDocumetDialog}
        documentItem={documentItem}
        setDocumentItem={setDocumentItem}
        downloadUrl={downloadUrl}
        source="sp-mgmt"
        docCategoryId={docCategoryId}
        docNameForDownload={docNameForDownload}
        docUserId={docUserId}
      />
      <TableRow>
        <TableCell>
          <Typography className={Styles.navyBlueColor}>{index + 1}</Typography>
        </TableCell>
        <TableCell container>
          {/* <Link
            style={{ textDecoration: "none", cursor: "pointer" }}
            to={`/homebuyer/serviceproviders/details/${data.storefrontDetails.serviceProviderId}`}
          > */}
          {data.broadCastServiceRequestDto ? (
            <Grid container>
              <Grid item xs={12}>
                <Tooltip title={data.broadCastServiceRequestDto?.businessType}>
                  <Typography
                    variant="body1"
                    className={clsx(
                      Styles.navyBlueColor,
                      Styles.sqEllipsisOneLine
                    )}
                    style={{ fontWeight: 600 }}
                  >
                    {data.broadCastServiceRequestDto?.businessType}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <Tooltip title="City">
                  <Typography
                    className={clsx(
                      Styles.navyBlueColor,
                      Styles.sqEllipsisOneLine
                    )}
                    variant="body1"
                  >
                    {data.broadCastServiceRequestDto?.city}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <Tooltip title="Timeline">
                  <Typography
                    className={clsx(
                      Styles.navyBlueColor,
                      Styles.sqEllipsisOneLine
                    )}
                    variant="body1"
                  >
                    {formattedTimeline?.name}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <Tooltip title="Matching Service Providers">
                  <Typography
                    className={clsx(
                      Styles.navyBlueColor,
                      Styles.sqEllipsisOneLine
                    )}
                    variant="body1"
                  >
                    {data.broadCastServiceRequestDto?.matchedSPCount || "0"}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              style={{
                // padding: !isMobileDevice() && "0px 16px",
                cursor: "pointer",
              }}
              onClick={() => {
                history.push({
                  pathname: `/app/homebuyer/serviceproviders/details/${data.storefrontDetails.serviceProviderId}`,
                  state: {
                    email: data.storefrontDetails.email,
                  },
                });
              }}
            >
              <Grid container>
                <Grid item xs={4}>
                  <img
                    src={sfContents.displayPicture}
                    alt="display picture"
                    title="display picture"
                    style={{
                      height: 50,
                      width: 50,
                      border: "1px solid #0E1C4E1F",
                      borderRadius: 4,
                      marginRight: 12,
                    }}
                  />
                </Grid>
                <Grid item xs={8} container alignContent="center">
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      className={Styles.navyBlueColor}
                      style={{ fontWeight: 600 }}
                    >
                      {sfDetails.storeFrontName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Tooltip title={data.serviceName}>
                      <Typography
                        className={clsx(
                          Styles.navyBlueColor,
                          Styles.sqEllipsisOneLine
                        )}
                        variant="body1"
                      >
                        {data.serviceName}
                      </Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* </Link> */}
        </TableCell>
        {/* <TableCell>
          <Typography style={{ textTransform: "uppercase" }}>
            <ClientStatus clientStatus={data.hbJourneyStatus} />
          </Typography>
        </TableCell> */}
        <TableCell>
          <Typography className={Styles.navyBlueColor}>
            {moment(data.requestedDate).format("MMM DD, YYYY")}
          </Typography>
        </TableCell>
        <TableCell>
          <Grid container>
            <Grid item xs={12}>
              <Tooltip title={data.notes}>
                <Typography
                  className={clsx(
                    Styles.navyBlueColor,
                    Styles.sqEllipsisTwoLines
                  )}
                >
                  {data.notes}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              {/* <Grid item xs={1}>
                <AttachmentIcon className={Styles.navyBlueColorForce} />
              </Grid> */}
              {/* <Grid container item xs={11} alignContent="center"> */}
              {data.attachments && data.attachments.length ? (
                <Attachments
                  attachments={data.attachments}
                  limit={1}
                  setDocumentItem={setDocumentItem}
                  getUploadedDocuments={getUploadedDocuments}
                  setOpenDocumetDialog={setOpenDocumetDialog}
                  setDownloadUrl={setDownloadUrl}
                  setDocCategoryId={setDocCategoryId}
                  setDocNameForDownload={setDocNameForDownload}
                  setDocUserId={setDocUserId}
                />
              ) : (
                <Typography
                  className={clsx(Styles.navyBlueColor, Styles.font14)}
                  style={{ fontStyle: "italic" }}
                >
                  No Attachments
                </Typography>
              )}
              {/* </Grid> */}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Button
            variant="outlined"
            className={Styles.navyBlueColorForce}
            style={{ fontSize: 14, textTransform: "capitalize", padding: 8 }}
            onClick={() => {
              if (
                data.broadCastServiceRequestDto &&
                data.broadCastServiceRequestDto.id
              ) {
                updateBroadcastServiceRequestStatus(
                  data.broadCastServiceRequestDto.id,
                  "FULFILLED",
                  (res) => {
                    if (res && res.data.status === 200) {
                      getSpManagementData && getSpManagementData();
                      getRequestsSPData && getRequestsSPData(pageNumber);
                      getArchiveSPData && getArchiveSPData(0);
                    }
                  }
                );
              } else {
                setOpenRejectionReasonDialog({
                  open: true,
                  id: data.serviceRequestId,
                });
              }
            }}
          >
            Cancel request
          </Button>
        </TableCell>
        <TableCell>
          <ButtonBase
            style={{ pointerEvents: data.broadCastServiceRequestDto && "none" }}
          >
            <MoreHorizIcon
              onClick={handleClick}
              className={Styles.navyBlueColor}
              style={{
                color: data.broadCastServiceRequestDto
                  ? "#17174c1f"
                  : "#0e1c4e",
              }}
            />
          </ButtonBase>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
