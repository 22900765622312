import {
  Container,
  Drawer,
  Fab,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import react, { useEffect } from "react";
import { useHistory } from "react-router";
import HBSetupDrawer from "./HBSetupDrawer";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { useAuthUser, useLogout } from "souqh-react-redux-hooks/useLogin";
import clsx from "clsx";
import Styles from "../../Styles.module.css";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import DashboardIcon from "@material-ui/icons/Dashboard";

import PersonalOverview from "./PersonalOverview";
import NewHomeProfile from "./NewHomeProfile";
import DemographicData from "./DemographicData";
import DocumentCheckList from "./DocumentCheckList";
import { useRouteCheck } from "souqh-react-redux-hooks/common/useRouteCheck";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    flexShrink: 0,
    width: drawerWidth,
  },
  drawerPaper: {
    backgroundColor: "#17174c",
    width: drawerWidth,
  },
  bodyBackgroundImg: {
    backgroundImage:
      "url('/app/images/blue_bg_shape.svg'), url('/app/images/square_triangle_shapes.svg'), url('/app/images/orange_shape.svg')",
    backgroundRepeat: "no-repeat, no-repeat, no-repeat",
    backgroundPosition: "0% 3%, 80% 15%, 100% 95%",
    backgroundSize: "10%, auto, auto",
    position: "sticky",
    top: 0,
    left: 365,
    minHeight: "calc(100vh - 0px)",
    width: "calc(100% - 320px)",
    backgroundColor: "#F5F5F8",
  },
  mobileBackground: {
    backgroundColor: "rgba(14, 28, 78, 0.04)",
    minHeight: "calc(100vh - 24px)",
  },
}));

const HBSetup = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { logout } = useLogout();
  let { path } = useRouteMatch();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  const userInfo = useAuthUser();
  const { checkHBRoute } = useRouteCheck();

  useEffect(() => {
    checkHBRoute();
  }, [userInfo]);

  if (!userInfo) {
    return null;
  }
  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <HBSetupDrawer></HBSetupDrawer>
      </Drawer>
      <main
        className={clsx(
          smUp ? classes.bodyBackgroundImg : classes.mobileBackground,
          classes.content
        )}
      >
        <Grid container>
          <Grid item xs={xlUp ? 1 : 1}></Grid>
          <Grid item xs={xlUp ? 7 : 11}>
            <Container
              maxWidth={xlUp ? "lg" : "md"}
              style={{ paddingLeft: xlUp ? 84 : 24 }}
            >
              <Grid container direction="column">
                {/* <Tooltip title="Logout">
                  <Fab
                    aria-label="logout"
                    style={{ position: "fixed", top: 50, right: 40 }}
                  >
                    <IconButton
                      onClick={() => {
                        logout((res) => {
                          history.push("/");
                        });
                      }}
                    >
                      <ExitToAppIcon className={Styles.navyBlueColor} />
                    </IconButton>
                  </Fab>
                </Tooltip> */}
                <Tooltip title="Go to Dashboard">
                  <Fab
                    aria-label="home"
                    style={{ position: "fixed", top: 50, right: 40 }}
                  >
                    <IconButton
                      onClick={() => {
                        history.push("/app/homebuyer/dashboard");
                      }}
                    >
                      <DashboardIcon className={Styles.navyBlueColor} />
                    </IconButton>
                  </Fab>
                </Tooltip>
              </Grid>
              <Grid container item md={12} xs={12} justifyContent="center">
                <Grid container item md={10} xs={12}>
                  <Switch>
                    <Route exact path={`${path}/personaloverview`}>
                      <PersonalOverview />
                    </Route>
                    <Route exact path={`${path}`}>
                      <PersonalOverview />
                    </Route>
                    <Route exact path={`${path}/newhomeprofile`}>
                      <NewHomeProfile />
                    </Route>
                    <Route exact path={`${path}/demographic`}>
                      <DemographicData />
                    </Route>
                    <Route exact path={`${path}/documentchecklist`}>
                      <DocumentCheckList />
                    </Route>
                  </Switch>
                </Grid>
              </Grid>
            </Container>
          </Grid>
          <Grid xs={xlUp ? 4 : 0}></Grid>
        </Grid>
      </main>
    </div>
  );
};

export default HBSetup;
