import {
  Grid,
  makeStyles,
  Typography,
  Divider,
  Button,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useStorefrontClientReviews } from "souqh-react-redux-hooks/common/useStorefrontClientReviews";
import { useStorefrontPage } from "souqh-react-redux-hooks/homeBuyer/useStorefrontPage";
import { useTheme } from "souqh-react-redux-hooks/serviceProvider/storefront/useTheme";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import Styles from "../../../Styles.module.css";
import StorefrontIcons from "../../../components/HomeBuyer/Explore/StorefrontPage/StorefrontIcons";
import StorefrontSocialLinks from "../../../components/HomeBuyer/Explore/StorefrontPage/StorefrontSocialLinks";
import {
  ADVANCED,
  MARKETPLACE,
  PREMIUM,
} from "../../../components/ServiceProvider/serviceProviderConstants";
import { useSQQuery } from "souqh-react-redux-hooks/common/useSQQuery";
import { useParams } from "react-router-dom";
import useLocalStorage from "react-use-localstorage";
import GeneralStorefront from "../../../components/HomeBuyer/Explore/StorefrontPage/GeneralStorefront";
import StorefrontPageCards from "../../../components/HomeBuyer/Explore/StorefrontPage/StorefrontPageCards";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import TeamExperts from "../../../components/HomeBuyer/Explore/StorefrontPage/TeamExperts";
import StorefrontDocuments from "../../../components/HomeBuyer/Explore/StorefrontPage/StorefrontDocuments";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import WebAddress from "../../../components/SPSetup/StoreFront/WebAddress";
import DetailReviewsPage from "../../../components/ServiceProvider/ClientManagement/DetailReviewsPage";
import SendSerivceRequestDialog from "../../../components/HomeBuyer/Explore/StorefrontPage/SendSerivceRequestDialog";
import { useUserEngagement } from "souqh-react-redux-hooks/serviceProvider/analytics/useUserEngagement";
import Helmet from "react-helmet";
import SocialMediaFeed from "../../../components/HomeBuyer/Explore/StorefrontPage/SocialMediaFeed";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#0e1c4e0a",
  },
  serviceImg: {
    height: 142,
    width: "100%",
    borderRadius: 6,
    border: "1px solid rgba(0, 0,0,0.12)",
  },
  displayPicture: {
    height: 62,
    width: 62,
    borderRadius: 8,
    position: "absolute",
    top: -20,
    left: 12,
    boxShadow: "3px 3px 10px #00000029",
  },
  ratingStyle: {
    border: "1px solid",
    borderRadius: 8,
    padding: "4px 8px",
  },
  fullList: {
    width: "auto",
  },
  modal: {
    zIndex: "1400 !important",
  },
  paper: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
}));

function StorefrontPageHeader(props) {
  const user = useAuthUser();
  const { primaryColor, secondaryColor } = useTheme();
  const classes = useStyles();
  const {
    primarySubscriptionPlanName,
    storeFrontDetails,
    businessTypes,
    businessName,
    addresses,
    SFName,
    showLimitedView,
    email,
    reviewStatistics,
    setWebPresenceDrawer,
    storeFrontRouteName,
    storeFrontId,
    setUserEngagementData,
    increaseLeadCount,
    contactNumber,
    calSyncAccessToken,
    data,
  } = props;
  if (!storeFrontDetails) {
    return null;
  }

  return (
    <Grid container item xs={12}>
      <Grid container item xs={12}>
        <Grid item xs={2} style={{ position: "relative" }}>
          <img
            src={
              storeFrontDetails.displayPicture
                ? storeFrontDetails.displayPicture
                : businessTypes.length
                ? `/app/images/Explore-Category/${businessTypes[0].logoUrl}`
                : "" // default image if user has not uploaded displayPicture according to its business Type
            }
            alt={"business-logo"}
            title={"business-logo"}
            className={classes.displayPicture}
          />
        </Grid>
        <Grid item xs={7} style={{ padding: "0px 14px 8px 18px" }}>
          <Typography variant="subtitle1" style={{ color: primaryColor }}>
            {SFName}
          </Typography>
          <Typography
            variant="subtitle2"
            style={{ color: secondaryColor }}
            className={Styles.sqEllipsis}
          >
            {businessTypes[0] ? businessTypes[0].name : ""}
          </Typography>
          <Typography
            variant="body2"
            style={{ color: secondaryColor }}
            className={Styles.sqEllipsis}
          >
            {businessName}
          </Typography>
          <Typography
            variant="body2"
            style={{ color: secondaryColor }}
            className={Styles.sqEllipsisOneLine}
          >
            {storeFrontDetails.competeText}
          </Typography>
        </Grid>
        <Grid item xs={3} style={{ padding: "0px 0px 8px 4px" }}>
          {reviewStatistics && reviewStatistics.avgStarRatings && (
            <Grid
              item
              container
              xs={12}
              style={{ padding: "4px 0px" }}
              justifyContent="flex-end"
            >
              <Grid
                container
                item
                xs={6}
                className={classes.ratingStyle}
                style={{
                  borderColor: primaryColor,
                  marginRight: 10,
                }}
                justifyContent="flex-end"
                alignContent="center"
              >
                <Typography
                  variant="subtitle2"
                  style={{ color: secondaryColor }}
                >
                  {(reviewStatistics &&
                    reviewStatistics.avgStarRatings &&
                    reviewStatistics.avgStarRatings.toFixed(1)) ||
                    "NA"}
                  &nbsp;★
                </Typography>
              </Grid>
              {primarySubscriptionPlanName !== MARKETPLACE && (
                <Grid
                  container
                  item
                  xs={6}
                  justifyContent="center"
                  alignContent="center"
                >
                  <img
                    style={{
                      width: 16,
                      height: 16,
                      marginRight: 12,
                      marginTop: 4,
                    }}
                    src={
                      primarySubscriptionPlanName === PREMIUM || primarySubscriptionPlanName === ADVANCED
                        ? "/app/images/blue_badge.svg"
                        : "/app/images/teal_badge.svg"
                    }
                  />
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ padding: "12px 12px 0px 12px" }}>
        <StorefrontIcons
          addresses={addresses}
          showLimitedView={showLimitedView}
          email={email}
          user={user}
          setWebPresenceDrawer={setWebPresenceDrawer}
          storeFrontId={storeFrontId}
          storeFrontRouteName={storeFrontRouteName}
          setUserEngagementData={setUserEngagementData}
          contactNumber={contactNumber}
          increaseLeadCount={increaseLeadCount}
          calSyncAccessToken={calSyncAccessToken}
          data={data}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider style={{ marginTop: 16 }} />
      </Grid>
    </Grid>
  );
}

export default function MobileStorefrontPage({
  previewStorefrontId,
  sourcePage = "",
}) {
  const { query } = useSQQuery();
  const classes = useStyles();
  const { primaryColor, secondaryColor } = useTheme();
  const {
    openPage,
    documentItem,
    setDocumentItem,
    openDocumetDialog,
    setOpenDocumetDialog,
    noDataAvailable,
    primarySubscriptionPlanName,
    activeTab,
    setActiveTab,
    businessName,
    teamMembers,
    areaOfSpecializations,
    getStorefrontDetails,
    languagesYouSpeak,
    businessTypes,
    storeFrontDetails,
    yearsInBusiness,
    numberOfEmployees,
    addresses,
    getStorefrontDetailsByName,
    storeFrontName: SFName,
    showLimitedView,
    serviceProviderId,
    setServiceProviderId,
    user,
    ownerEmail,
    publicStorefrontId,
    getUploadedDocuments,
    calSyncAccessToken,
    locationsServed,
    getMetadataInfo,
    getInstaPosts,
    getFBPosts,
    instaPosts,
    instagramToken,
    preferredPartnerSFDetails
  } = useStorefrontPage();
  const { getReviewStatistics, reviewStatistics } =
    useStorefrontClientReviews();
  const reviewStatsUrl =
    sourcePage === "manageStoreFront"
      ? "storefront/service-review/sp-view/statistics"
      : "storefront/service-review/statistics";
  const { setUserEngagementData, increaseLeadCount } = useUserEngagement();

  const [openserviceRequestDialog, setOpenserviceRequestDialog] = useState({
    open: false,
    service: null,
  });
  let { storeFrontName } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);

    let storeFrontId = query.get("storeFrontId");

    if (sourcePage === "manageStoreFront") {
      storeFrontId = user.storefrontId;
    }
    if (storeFrontId) {
      getStorefrontDetails(storeFrontId);
    } else if (storeFrontName) {
      getStorefrontDetailsByName(storeFrontName);
    } else if (previewStorefrontId) {
      getStorefrontDetails(previewStorefrontId);
    }

    if (
      localStorage.getItem("nonloggedInUser") &&
      JSON.parse(localStorage.getItem("nonloggedInUser"))
        .openserviceRequestDialog
    ) {
      setOpenserviceRequestDialog(
        JSON.parse(localStorage.getItem("nonloggedInUser"))
          .openserviceRequestDialog
      );
      setServiceProviderId(
        JSON.parse(localStorage.getItem("nonloggedInUser")).serviceProviderId
      );
    } else {
      localStorage.removeItem("nonloggedInUser");
    }
    // getFBPosts();
  }, []);

  useEffect(() => {
    if (instagramToken) {
      getInstaPosts();
    }
  }, [instagramToken]);

  useEffect(() => {
    let storeFrontId = query.get("storeFrontId");

    if (sourcePage === "manageStoreFront") {
      storeFrontId = user.storefrontId;
    }
    if (previewStorefrontId) {
      storeFrontId = previewStorefrontId;
    }
    const SFIdForReviewStats = storeFrontId || publicStorefrontId;
    if (SFIdForReviewStats) {
      getReviewStatistics(reviewStatsUrl, SFIdForReviewStats);
    }
  }, [publicStorefrontId]);

  const [tabDrawer, setTabDrawer] = useState(false);
  const [webPresenceDrawer, setWebPresenceDrawer] = useState(false);
  const [selectedTab, setSelectedTab] = useState("General");

  const moreActionsList = () => (
    <div
      className={classes.fullList}
      role="presentation"
      onClick={() => {
        setTabDrawer(false);
      }}
      onKeyDown={() => {
        setTabDrawer(false);
      }}
    >
      <Grid container item xs={12} style={{ padding: 16 }}>
        <Grid item xs={10}>
          <Typography variant="subtitle1" className={Styles.navyBlueColorForce}>
            More Information
          </Typography>
        </Grid>
        <Grid container item xs={2} justifyContent="flex-end">
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              setTabDrawer(false);
            }}
          />
        </Grid>
      </Grid>
      <Divider />
      <List>
        <ListItem
          onClick={() => {
            setSelectedTab("General");
            setUserEngagementData(publicStorefrontId, "General");
          }}
        >
          <ListItemText
            primary="General"
            className={Styles.navyBlueColorForce}
            style={{ fontSize: 14 }}
          />
        </ListItem>
        <ListItem
          onClick={() => {
            setSelectedTab("Social Media");
            setUserEngagementData(publicStorefrontId, "Social Media");
          }}
        >
          <ListItemText
            primary="Social Media"
            className={Styles.navyBlueColorForce}
            style={{ fontSize: 14 }}
          />
        </ListItem>
        {!showLimitedView && getUploadedDocuments(storeFrontDetails).length ? (
          <ListItem
            onClick={() => {
              setSelectedTab("Documents");
              setUserEngagementData(publicStorefrontId, "Documents");
            }}
          >
            <ListItemText
              primary="Documents"
              className={Styles.navyBlueColorForce}
              style={{ fontSize: 14 }}
            />
          </ListItem>
        ) : null}
        {!showLimitedView && teamMembers.length ? (
          <ListItem
            onClick={() => {
              setSelectedTab("Team");
              setUserEngagementData(publicStorefrontId, "Team");
            }}
          >
            <ListItemText
              primary="Team"
              className={Styles.navyBlueColorForce}
              style={{ fontSize: 14 }}
            />
          </ListItem>
        ) : null}
        <ListItem
          onClick={() => {
            setSelectedTab("Reviews");
            setUserEngagementData(publicStorefrontId, "Reviews");
          }}
        >
          <ListItemText
            primary="Reviews"
            className={Styles.navyBlueColorForce}
            style={{ fontSize: 14 }}
          />
        </ListItem>
      </List>
    </div>
  );
  const webPresenceList = () => (
    <div
      className={classes.fullList}
      role="presentation"
      onClick={() => {
        setWebPresenceDrawer(false);
      }}
      onKeyDown={() => {
        setWebPresenceDrawer(false);
      }}
    >
      <Grid container item xs={12} style={{ padding: 16 }}>
        <Grid item xs={10}>
          <Typography variant="subtitle1" className={Styles.navyBlueColorForce}>
            Web Presence
          </Typography>
        </Grid>
        <Grid container item xs={2} justifyContent="flex-end">
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              setWebPresenceDrawer(false);
            }}
          />
        </Grid>
      </Grid>
      <Divider />
      <List>
        <Grid container item xs={12} alignContent="center">
          {storeFrontDetails.webPresence.companyWebsite && (
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              onClick={(e) => {
                setUserEngagementData(publicStorefrontId, "Web_address");
                setWebPresenceDrawer(false);
                e.preventDefault();
                let compWebsite = storeFrontDetails.webPresence.companyWebsite;
                let url =
                  compWebsite.indexOf("//") < 0
                    ? "http://" + compWebsite
                    : compWebsite; //check if link is in valid format or not
                window.open(url, "_blank");
                // window.open(
                //   storeFrontDetails.webPresence.companyWebsite,
                //   "_blank"
                // );
              }}
            >
              <Grid item xs={1}>
                <WebAddress
                  style={{
                    height: 25,
                    width: 25,
                    margin: "8px",
                    cursor: "pointer",
                  }}
                  id={"web-address"}
                />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  className={clsx(Styles.navyBlueColorForce, Styles.sqEllipsis)}
                  style={{ margin: "0 8px" }}
                >
                  {storeFrontDetails.webPresence.companyWebsite}
                </Typography>
              </Grid>
            </Grid>
          )}
          <StorefrontSocialLinks
            socialLinks={storeFrontDetails.webPresence.socialLinks}
            setWebPresenceDrawer={setWebPresenceDrawer}
            setUserEngagementData={setUserEngagementData}
            storeFrontId={publicStorefrontId}
          ></StorefrontSocialLinks>
        </Grid>
      </List>
    </div>
  );

  const renderTabView = () => {
    let selectedTabView;

    switch (selectedTab) {
      case "General":
        selectedTabView = (
          <Grid item container xs={12}>
            <GeneralStorefront
              competeText={storeFrontDetails.competeText}
              companyOverview={storeFrontDetails.companyOverview}
              services={storeFrontDetails.services}
              yearsInBusiness={yearsInBusiness}
              numberOfEmployees={numberOfEmployees}
              openserviceRequestDialog={openserviceRequestDialog}
              setOpenserviceRequestDialog={setOpenserviceRequestDialog}
              user={user}
              serviceProviderId={serviceProviderId}
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
              setUserEngagementData={setUserEngagementData}
              storeFrontId={publicStorefrontId}
            />
            <Grid item container xs={12} style={{ marginTop: 24 }}>
              <StorefrontPageCards
                customerPayments={storeFrontDetails.customerPayments}
                areaOfSpecializations={areaOfSpecializations}
                languagesYouSpeak={languagesYouSpeak}
                showLimitedView={showLimitedView}
                primaryColor={primaryColor}
                preferredPartnerSFDetails={preferredPartnerSFDetails}
                userInfo={user}
              />
            </Grid>
          </Grid>
        );
        return selectedTabView;
      case "Social Media":
        selectedTabView = (
          <Grid item container xs={12}>
            <SocialMediaFeed instaPosts={instaPosts} />
          </Grid>
        );
        return selectedTabView;
      case "Documents":
        selectedTabView = (
          <StorefrontDocuments
            storeFrontDetails={storeFrontDetails}
            openDocumetDialog={openDocumetDialog}
            setOpenDocumetDialog={setOpenDocumetDialog}
            documentItem={documentItem}
            setDocumentItem={setDocumentItem}
            yearsInBusiness={yearsInBusiness}
            numberOfEmployees={numberOfEmployees}
            getUploadedDocuments={getUploadedDocuments}
            setUserEngagementData={setUserEngagementData}
            storeFrontId={publicStorefrontId}
          />
        );
        return selectedTabView;
      case "Team":
        selectedTabView = (
          <Grid item container xs={12}>
            <TeamExperts
              teamMembers={teamMembers}
              yearsInBusiness={yearsInBusiness}
              numberOfEmployees={numberOfEmployees}
              user={user}
              setUserEngagementData={setUserEngagementData}
              increaseLeadCount={increaseLeadCount}
              storeFrontId={publicStorefrontId}
            />
          </Grid>
        );
        return selectedTabView;
      case "Reviews":
        selectedTabView = (
          <Grid item container xs={12}>
            <DetailReviewsPage
              isViewStorefrontPage={true}
              storefrontReviews={storeFrontDetails.reviews}
              servicesList={storeFrontDetails.services}
              previewStorefrontId={previewStorefrontId}
              publicStorefrontId={publicStorefrontId}
              setUserEngagementData={setUserEngagementData}
            />
          </Grid>
        );
        return selectedTabView;
      default:
        return;
    }
  };
  let businessTypeName =
    businessTypes && businessTypes.length
      ? businessTypes[0].name === "Other"
        ? businessTypes[0].otherBusinessName
        : businessTypes[0].name
      : "";
  return (
    <React.Fragment>
      <Helmet>
        <title>
          {SFName} | {businessTypeName}
        </title>
        {/* <meta name="description" content={storeFrontDetails.companyOverview} /> */}
      </Helmet>
      <SendSerivceRequestDialog
        openserviceRequestDialog={openserviceRequestDialog}
        setOpenserviceRequestDialog={setOpenserviceRequestDialog}
        storeFrontDetails={storeFrontDetails}
        serviceProviderId={serviceProviderId}
        businessName={businessName}
        email={ownerEmail}
        setUserEngagementData={setUserEngagementData}
        storeFrontId={publicStorefrontId}
        storeFrontName={SFName}
      />
      <SwipeableDrawer
        classes={{ modal: classes.modal, paper: classes.paper }}
        anchor="bottom"
        open={tabDrawer || webPresenceDrawer}
        onClose={() => {
          setTabDrawer(false);
        }}
        onOpen={() => {
          setTabDrawer(true);
        }}
      >
        {tabDrawer && moreActionsList()}
        {webPresenceDrawer && webPresenceList()}
      </SwipeableDrawer>

      <Grid container item xs={12} style={{ marginBottom: 100 }}>
        <Grid item xs={12}>
          <img
            src={
              storeFrontDetails.bannerPicture
                ? storeFrontDetails.bannerPicture
                : "/app/images/default-banner.svg" //default banner image if user has not uploaded bannerPicture
            }
            className={classes.serviceImg}
          />
        </Grid>
        <Grid item container xs={12}>
          <StorefrontPageHeader
            businessTypes={businessTypes}
            businessName={businessName}
            storeFrontDetails={storeFrontDetails}
            primarySubscriptionPlanName={primarySubscriptionPlanName}
            SFName={SFName}
            showLimitedView={showLimitedView}
            addresses={addresses}
            email={ownerEmail}
            reviewStatistics={reviewStatistics}
            setWebPresenceDrawer={setWebPresenceDrawer}
            storeFrontId={publicStorefrontId}
            storeFrontRouteName={storeFrontDetails.storeFrontRouteName}
            setUserEngagementData={setUserEngagementData}
            contactNumber={storeFrontDetails.contactNumber}
            increaseLeadCount={increaseLeadCount}
            calSyncAccessToken={calSyncAccessToken}
            data={locationsServed.length && getMetadataInfo()}
          />
        </Grid>
        <Grid item container xs={12}>
          <Grid item container xs={12}>
            <Button
              fullWidth
              variant="outlined"
              className={Styles.navyBlueColorForce}
              style={{ textTransform: "capitalize", margin: "12px 16px 0" }}
              onClick={() => setTabDrawer(true)}
              endIcon={<ExpandMoreIcon />}
            >
              {selectedTab}
            </Button>
          </Grid>
          {renderTabView()}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
