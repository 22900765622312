import { Grid, Popover, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Styles from "../../../Styles.module.css";
import RequestDocumentDialog from "./RequestDocumentDialog";
import { useHistory } from "react-router-dom";
import { useNavigateToMessage } from "souqh-react-redux-hooks/common/useNavigateToMessage";
import { useRequestProfileUpdate } from "souqh-react-redux-hooks/homeBuyer/spManagement/useRequestProfileUpdate";
import TagSomeoneDialog from "./TagSomeoneDialog";
import { useSPeSignWizard } from "souqh-react-redux-hooks/serviceProvider/documents/useSPeSignWizard";
import SPeSignWizard from "../UploadDocuments/SPeSignWizard";
import { useRequestProfileAccess } from "souqh-react-redux-hooks/serviceProvider/clientManagement/useRequestProfileAccess";
import { isPlusPlan, isProPlan } from "../../../utils/AppUtils";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import UpgradePlanIconSection from "../../common/UpgradePlanIconSection";
import { useRoleManagement } from "souqh-react-redux-hooks/common/useRoleManagement";
import { SERVICE_PROVIDER_USER } from "../serviceProviderConstants";

export default function MoreOptionsOnClientInfo({
  anchorEl,
  setAnchorEl,
  showTagMemberOption,
  showProfileUpdateOption,
  showScheduleAppointmentOption,
  showSendMessageOption,
  showLiveMeetingOption,
  showRequestAccessOption,
  email,
  toUserId,
  serviceRequestId,
  notes,
  isDashboardWidget,
  isInactiveTab,
  isCompletedTab,
  isClientDetailsPage,
  setOpenAssignClientDialog,
  assignClientDetails,
  nonPremiumTeamMembersCheck,
  isAdmin,
}) {
  let userInfo = useAuthUser();
  const { requestCompleteProfile } = useRequestProfileUpdate(toUserId);
  const { requestProfileAccess } = useRequestProfileAccess(toUserId);
  const history = useHistory();
  const { navigateMessage } = useNavigateToMessage();
  const { isTeamMember } = useRoleManagement();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [openRequestDocumentDialog, setOpenRequestDocumentDialog] =
    useState(false);
  const [openTagSomeoneDialog, setOpenTagSomeoneDialog] = useState(false);

  const {
    onUploadEsignFileChange,
    openEsignWizard,
    closeAndFinishEsign,
    actions: { setOpenEsignWizard },
  } = useSPeSignWizard();

  useEffect(() => {
    return () => {
      closeAndFinishEsign();
    };
  }, []);

  return (
    <React.Fragment>
      <SPeSignWizard
        openEsignWizard={openEsignWizard}
        setOpenEsignWizard={setOpenEsignWizard}
      />
      <RequestDocumentDialog
        openRequestDocumentDialog={openRequestDocumentDialog}
        setOpenRequestDocumentDialog={setOpenRequestDocumentDialog}
        userId={toUserId}
        serviceRequestId={serviceRequestId}
      />
      <TagSomeoneDialog
        openTagSomeoneDialog={openTagSomeoneDialog}
        setOpenTagSomeoneDialog={setOpenTagSomeoneDialog}
        serviceRequestId={serviceRequestId}
        page="leadsPage"
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Grid style={{ padding: 16 }}>
          {showProfileUpdateOption && (
            <Typography
              style={{ cursor: "pointer" }}
              className={Styles.navyBlueColor}
              variant="body1"
              onClick={() => {
                requestCompleteProfile(serviceRequestId, (res) => {
                  if (res.data.status === 200) {
                    setAnchorEl(null);
                  }
                });
              }}
            >
              Request Profile Update
            </Typography>
          )}
          {showScheduleAppointmentOption && (
            <Typography
              style={{ marginTop: 16, cursor: "pointer" }}
              className={Styles.navyBlueColor}
              variant="body1"
              onClick={() => {
                history.push("/app/serviceprovider/calendar?scheduleMeeting=true");
              }}
            >
              Schedule Appointment
            </Typography>
          )}
          <input
            style={{ display: "none" }}
            id="contained-button-file-esign"
            multiple
            type="file"
            onChange={(event) => {
              onUploadEsignFileChange(event);
              handleClose();
            }}
          />
          <label htmlFor="contained-button-file-esign">
            <Typography
              style={{
                marginTop:
                  (showProfileUpdateOption || showScheduleAppointmentOption) &&
                  16,
                cursor: "pointer",
              }}
              className={Styles.navyBlueColor}
              variant="body1"
            >
              Send Document
            </Typography>
          </label>
          <Typography
            style={{ marginTop: 16, cursor: "pointer" }}
            className={Styles.navyBlueColor}
            variant="body1"
            onClick={() => {
              setOpenRequestDocumentDialog(true);
              handleClose();
            }}
          >
            Request Document
          </Typography>
          {showSendMessageOption && (
            <Typography
              style={{ marginTop: 16, cursor: "pointer" }}
              className={Styles.navyBlueColor}
              variant="body1"
              onClick={(event) => {
                if (notes) {
                  const temp = {
                    email: email,
                    value: notes,
                  };
                  localStorage.setItem("messageContent", JSON.stringify(temp));
                  navigateMessage(event, email, true);
                } else {
                  navigateMessage(event, email, false);
                }
              }}
            >
              Send Message
            </Typography>
          )}
          {showLiveMeetingOption && (
            <Typography
              style={{
                marginTop: 16,
                cursor: "pointer",
                color:
                  (isProPlan(userInfo) || isPlusPlan(userInfo)) && "#0E1C4E1F",
                pointerEvents:
                  isProPlan(userInfo) || isPlusPlan(userInfo) ? "none" : "auto",
              }}
              className={Styles.navyBlueColor}
              variant="body1"
            >
              Live Meeting
            </Typography>
          )}
          {showLiveMeetingOption &&
            (isProPlan(userInfo) || isPlusPlan(userInfo)) && (
              <UpgradePlanIconSection
                iconBtnStyle={{
                  position: "absolute",
                  fontSize: 16,
                  left: 110,
                  top: isDashboardWidget
                    ? 133
                    : isInactiveTab || isCompletedTab
                    ? 92
                    : 210,
                  fill: "#fa7e61",
                }}
                iconStyle={{
                  position: "absolute",
                  fontSize: 16,
                  top: 4,
                  fill: "#fa7e61",
                }}
              />
            )}
          {showRequestAccessOption && (
            <Typography
              style={{ marginTop: 16, cursor: "pointer" }}
              className={Styles.navyBlueColor}
              variant="body1"
              onClick={() => {
                requestProfileAccess(serviceRequestId, (res) => {
                  if (res.data.status === 200) {
                    setAnchorEl(null);
                  }
                });
              }}
            >
              Request Profile Access
            </Typography>
          )}
          {showTagMemberOption && (
            <Typography
              style={{ marginTop: 16, cursor: "pointer" }}
              className={Styles.navyBlueColor}
              variant="body1"
              onClick={(event) => {
                setOpenTagSomeoneDialog(true);
              }}
            >
              Tag Team Member
            </Typography>
          )}
          {isClientDetailsPage &&
          nonPremiumTeamMembersCheck &&
          assignClientDetails?.assignClient &&
          (userInfo.userType === SERVICE_PROVIDER_USER || isAdmin()) ? (
            <Typography
              style={{ marginTop: 16, cursor: "pointer" }}
              className={Styles.navyBlueColor}
              variant="body1"
              onClick={(event) => {
                setOpenAssignClientDialog(true);
              }}
            >
              Assign Client
            </Typography>
          ) : null}
        </Grid>
      </Popover>
    </React.Fragment>
  );
}
