import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { isMobileDevice } from "../../../../utils/AppUtils";

function Feed(props) {
  const { id, caption, media_type, media_url, permalink } = props.feed;

  // let locationParam = permalink.split("/"); // read URL and split it on "/"
  // let ig_shortcode = locationParam[locationParam.length - 2];
  // let ig_shortcode = "CQBc5wZjbA7";
  // const ig_shortcode=permalink.split
  let post;

  function enlargeImg() {
    document.getElementById(id).requestFullscreen();
  }

  switch (media_type) {
    case "VIDEO":
      post = (
        <video
          width={"100%"}
          height={isMobileDevice() ? 354 : "100%"}
          src={media_url}
          type="video/mp4"
          controls
          playsinline
        ></video>
      );
      break;
    case "CAROUSEL_ALBUM":
      post = (
        <img
          width={"100%"}
          height={isMobileDevice() ? 354 : "100%"}
          id={id}
          src={media_url}
          alt={caption}
          onClick={() => enlargeImg()}
        />
      );
      break;
    default:
      post = (
        // <React.Fragment>
        <img
          width={"100%"}
          height={isMobileDevice() ? 354 : "100%"}
          // width={isMobileDevice() ? "100%" : 200}
          // height={isMobileDevice() ? 354 : "auto"}
          id={id}
          src={media_url}
          alt={caption}
          onClick={() => {
            !isMobileDevice() && enlargeImg();
          }}
        />
      );
  }

  return (
    <Grid item xs={12} md={3}>
      <div
        style={{
          border: "1px solid #868da6",
          maxWidth: "100%",
          marginRight: 8,
          marginLeft: isMobileDevice() && 12,
          marginBottom: 16,
          height: isMobileDevice() ? 354 : 200,
        }}
        id="temp-container"
      >
        {post}
      </div>
    </Grid>
  );
}

export default function SocialMediaFeed({ instaPosts }) {
  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v9.0&appId=souqh&autoLogAppEvents=1";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        padding: "12px 0px",
        alignItems: "flex-start",
        marginBottom: 16,
      }}
    >
      <Grid container item xs={12}>
        {instaPosts && instaPosts.length
          ? instaPosts.map((feed) => (
              <Feed key={feed.id} feed={feed} instaPosts={instaPosts} />
            ))
          : null}
      </Grid>
    </Grid>
  );
}
