import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";

import { useRoleManagement } from "souqh-react-redux-hooks/common/useRoleManagement";
import Styles from "../../../Styles.module.css";
import {
  SERVICE_PROVIDER_USER,
  TEAM_MEMBER_USER,
} from "../serviceProviderConstants";

export default function AppsLeftPanel() {
  const user = useAuthUser();
  const history = useHistory();
  const mapping = {
    realtor: 1,
    mortgagebroker: 2,
    lawyers: 3,
    movers: 4,
    propertyinspectors: 5,
    generacontractors: 6,
  };

  const [activeSection, setActiveSection] = useState("realtor");

  let location = useLocation();
  let locationParam = location.pathname.split("/"); // read URL and split it on "/"
  let activeSectionFromURL = locationParam[locationParam.length - 1]; // take last section from URL

  useEffect(() => {
    // On load of page set the active section on Drawer according to url.
    if (mapping[activeSectionFromURL]) {
      setActiveSection(activeSectionFromURL);
    } else {
      setActiveSection("realtor");
    }
  }, [location]);

  let route =
    user.userType === SERVICE_PROVIDER_USER ||
    user.userType === TEAM_MEMBER_USER
      ? "serviceprovider"
      : "homebuyer";

  return (
    <Grid
      item
      xs={12}
      style={{
        height: "100%",
        overflow: "hidden",
        paddingTop: 32,
        position: "fixed",
        width: "inherit",
      }}
    >
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            style={{ display: "flex", marginBottom: 32, paddingLeft: 24 }}
          >
            <ListItemText
              primary="Apps"
              primaryTypographyProps={{ variant: "subtitle1" }}
            />
          </ListSubheader>
        }
        className={Styles.navyBlueColor}
      >
        <ListItem
          button
          selected={activeSection === "realtor"}
          onClick={() => {
            history.push(`/app/${route}/apps/realtor`);
            setActiveSection("realtor");
          }}
          className={{
            [Styles.primaryTxtAccentForce]: activeSection === "realtor",
          }}
          style={{ paddingLeft: 24 }}
        >
          <ListItemText
            primary="Realtor"
            primaryTypographyProps={{ variant: "subtitle1" }}
          />
        </ListItem>
        <ListItem
          button
          selected={activeSection === "mortgagebroker"}
          onClick={() => {
            history.push(`/app/${route}/apps/mortgagebroker`);
            setActiveSection("mortgagebroker");
          }}
          className={{
            [Styles.primaryTxtAccentForce]: activeSection === "mortgagebroker",
          }}
          style={{ paddingLeft: 24 }}
        >
          <ListItemText
            primary="Mortgage Broker"
            primaryTypographyProps={{ variant: "subtitle1" }}
          />
        </ListItem>

        <ListItem
          button
          selected={activeSection === "lawyers"}
          onClick={() => {
            history.push(`/app/${route}/apps/lawyers`);
            setActiveSection("lawyers");
          }}
          className={{
            [Styles.primaryTxtAccentForce]: activeSection === "lawyers",
          }}
          style={{ paddingLeft: 24 }}
        >
          <ListItemText
            primary="Lawyers"
            primaryTypographyProps={{ variant: "subtitle1" }}
          />
        </ListItem>
        <ListItem
          button
          selected={activeSection === "movers"}
          // onClick={() => {
          //   history.push(`/${route}/apps/movers`);
          //   setActiveSection("movers");
          // }}
          className={{
            [Styles.primaryTxtAccentForce]: activeSection === "movers",
          }}
          style={{ paddingLeft: 24 }}
        >
          <ListItemText
            primary="Movers"
            primaryTypographyProps={{ variant: "subtitle1" }}
          />
        </ListItem>
        <ListItem
          button
          selected={activeSection === "propertyinspectors"}
          // onClick={() => {
          //   history.push(`/${route}/apps/propertyinspectors`);
          //   setActiveSection("propertyinspectors");
          // }}
          className={{
            [Styles.primaryTxtAccentForce]:
              activeSection === "propertyinspectors",
          }}
          style={{ paddingLeft: 24 }}
        >
          <ListItemText
            primary="Property Inspectors"
            primaryTypographyProps={{ variant: "subtitle1" }}
          />
        </ListItem>
        <ListItem
          button
          selected={activeSection === "generacontractors"}
          // onClick={() => {
          //   history.push(`/${route}/apps/generacontractors`);
          //   setActiveSection("generacontractors");
          // }}
          className={{
            [Styles.primaryTxtAccentForce]:
              activeSection === "generacontractors",
          }}
          style={{ paddingLeft: 24 }}
        >
          <ListItemText
            primary="General Contractors"
            primaryTypographyProps={{ variant: "subtitle1" }}
          />
        </ListItem>
      </List>
    </Grid>
  );
}
