import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel,
  Link,
  Checkbox,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import { MOVING_CHECKLIST_HEADERS } from "./pendingActionConstants";
import {
  getComparator,
  isMobileDevice,
  isTabletDevice,
  stableSort,
} from "../../../utils/AppUtils";
import { useDispatch } from "react-redux";
import MovingChecklistPopup from "./MovingChecklistPopup";
import MovingChecklistAddressPopup from "./MovingChecklistAddressPopup";
import NoDataAvailable from "../NoDataAvailable";

function EnhancedTableHead({ order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      {MOVING_CHECKLIST_HEADERS.map((headCell) => (
        <TableCell
          className={Styles.navyBlueColorForce}
          key={headCell.id}
          align="left"
          sortDirection={orderBy === headCell.id ? order : false}
          style={
            isMobileDevice() || isTabletDevice()
              ? { minWidth: headCell.width }
              : { width: headCell.width }
          }
        >
          <TableSortLabel
            active={orderBy === headCell.id}
            direction={orderBy === headCell.id ? order : "asc"}
            onClick={createSortHandler(headCell.id)}
            disabled={
              headCell.id === "eventDescription" ||
              headCell.id === "requestedDate" ||
              headCell.id === "dueDate"
            }
          >
            <Typography className={Styles.navyBlueColor} variant="subtitle1">
              {headCell.label}
            </Typography>
          </TableSortLabel>
        </TableCell>
      ))}
    </>
  );
}

export default function MovingChecklist({
  order,
  orderBy,
  handleRequestSort,
  userInfo,
  setEventIndex,
  handleMovingChecklist,
  movingChecklist,
  openMovingChecklistPopup,
  setOpenMovingChecklistPopup,
  setMovingChecklistCheckedValue,
  openMovingChecklistAddressPopup,
  setOpenMovingChecklistAddressPopup,
}) {

  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <MovingChecklistPopup
        openMovingChecklistPopup={openMovingChecklistPopup}
        setOpenMovingChecklistPopup={setOpenMovingChecklistPopup}
      />
      <MovingChecklistAddressPopup
        openMovingChecklistAddressPopup={openMovingChecklistAddressPopup}
        setOpenMovingChecklistAddressPopup={setOpenMovingChecklistAddressPopup}
      />
      {userInfo && userInfo.hbNewAddressCity && userInfo.hbNewAddressCity.cityDTO && userInfo.hbCurrentAddressCity && (userInfo.hbCurrentAddressCity.cityDTO || userInfo.hbCurrentAddressCity.city) ? (<TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {movingChecklist &&
              movingChecklist.length
              ? stableSort(
                movingChecklist,
                  getComparator(order, orderBy)
              ).filter((item) => item.movingChecklistConstant !== "UPDATE_GAS_SERVICE" && item.movingChecklistConstant !== "UPDATE_HYDRO_SERVICE").map((row, index) => (
                  <TableRow key={row.pendingActionId}>
                    <TableCell
                      align="left"
                      component="th"
                      scope="row"
                      style={{ borderBottom: "none" }}
                    >
                      <Typography
                        variant="body1"
                        className={Styles.navyBlueColor}
                      >
                        {index + 1}
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="left"
                      style={{ borderBottom: "none", cursor: "pointer" }}
                    >
                        <Link
                          underline="always"
                          variant="body1"
                          className={Styles.primaryTxtAccentForce}
                        style={{ fontSize: 18 }}
                          onClick={() => {

                            handleMovingChecklist(row);
                            setEventIndex(row.pendingActionId);
                          }}
                        >
                          {row.eventDescription}
                      </Link>
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        borderBottom: "none",
                        paddingRight: !isMobileDevice() && 0,
                        cursor: "pointer",
                      }}
                    >
                      <Checkbox
                        checked={row.checked}
                        onChange={(event) => {
                          setMovingChecklistCheckedValue(event.target.checked, row.id)
                        }}
                        color={Styles.navyBlueColorForce}
                      />
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>) : (
        <NoDataAvailable addMarginTop={true}>
          <Typography style={{ color: "#868DA6" }}>
            Looks like you have not completed your profile.
            Click{" "}
              <Link style={{ cursor: "pointer" }} href={userInfo.hbCurrentAddressCity ? "/app/usersetup/newhomeprofile" : "/app/usersetup"}>
              here
            </Link>{" "}
            to complete the setup. After filling the required details, please sign-in again.
          </Typography>
        </NoDataAvailable>)}
    </React.Fragment>
  );
}
