import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ButtonBase,
  Grid,
  AppBar,
  Avatar,
  useTheme,
  useMediaQuery,
  Link as MaterialLink,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Styles from "../../../Styles.module.css";
import ClientStatus from "../ClientManagement/ClientStatus";
import MessageIcon from "@material-ui/icons/Message";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { useHistory, Link } from "react-router-dom";
import { useNavigateToMessage } from "souqh-react-redux-hooks/common/useNavigateToMessage";
import { useActiveClientInfo } from "souqh-react-redux-hooks/serviceProvider/clientManagement/useActiveClientInfo";
import * as moment from "moment";
import MoreOptionsOnClientInfo from "../ClientManagement/MoreOptionsOnClientInfo";
import {
  isMarketplacePlan,
  isMobileDevice,
  isOnboardingInComplete,
  isProPlan,
  isTabletDevice,
} from "../../../utils/AppUtils";
import clsx from "clsx";
import InviteClientsDialog from "../../ReferralManagement/InviteClientsDialog";
import { useClientManagement } from "souqh-react-redux-hooks/serviceProvider/clientManagement/useClientManagement";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import UpgradePlanIconSection from "../../common/UpgradePlanIconSection";

export default function ActiveClientsWidget({
  openPlanUpgradeMsgDialog,
  setOpenPlanUpgradeMsgDialog,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [hbUserId, setHBUserId] = useState(null);
  const [spId, setSPId] = useState(null);
  const [serviceRequestId, setServiceRequestId] = useState(null);

  let history = useHistory();
  const theme = useTheme();
  const { navigateMessage } = useNavigateToMessage();
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  const userInfo = useAuthUser();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const { getActiveClientData, active, actions } = useActiveClientInfo(5); // page size

  const { openInviteClientsDialog, setOpenInviteClientsDialog } =
    useClientManagement();

  useEffect(() => {
    getActiveClientData();
    return () => {
      actions.clearActiveClientsData();
    };
  }, []);
  return (
    <React.Fragment>
      <InviteClientsDialog
        openInviteClientsDialog={openInviteClientsDialog}
        setOpenInviteClientsDialog={setOpenInviteClientsDialog}
        srcPage="clientManagement"
        getActiveClientData={getActiveClientData}
      />
      <MoreOptionsOnClientInfo
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        showProfileUpdateOption={true}
        showLiveMeetingOption={true}
        toUserId={hbUserId}
        serviceRequestId={serviceRequestId}
        isDashboardWidget={true}
      />
      {active && active.response && active.response.length ? (
        <TableContainer
          component={Paper}
          elevation={0}
          className="hideScroll"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Table
            aria-label="collapsible table"
            className="hideScroll"
            stickyHeader
            style={{
              paddingBottom: 142,
              overflowX: "auto",
              display: "inline-block",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    width: "23%",
                    fontWeight: 600,
                    fontSize: xlUp ? 16 : 12,
                    backgroundColor: "#FFFFFF",
                    lineHeight: "0.5rem",
                  }}
                  className={Styles.navyBlueColorForce}
                >
                  Client Details
                </TableCell>
                <TableCell
                  style={{
                    width: "21%",
                    fontWeight: 600,
                    fontSize: xlUp ? 16 : 12,
                    backgroundColor: "#FFFFFF",
                    lineHeight: "0.5rem",
                    minWidth: (isMobileDevice() || isTabletDevice()) && "13em",
                  }}
                  className={Styles.navyBlueColorForce}
                >
                  Status
                </TableCell>
                <TableCell
                  style={{
                    width: "16%",
                    fontWeight: 600,
                    fontSize: xlUp ? 16 : 12,
                    backgroundColor: "#FFFFFF",
                    lineHeight: "0.5rem",
                    minWidth: (isMobileDevice() || isTabletDevice()) && "7em",
                  }}
                  className={Styles.navyBlueColorForce}
                >
                  Closing Date
                </TableCell>
                <TableCell
                  style={{
                    width: "26%",
                    fontWeight: 600,
                    fontSize: xlUp ? 16 : 12,
                    backgroundColor: "#FFFFFF",
                    lineHeight: "0.5rem",
                    minWidth: (isMobileDevice() || isTabletDevice()) && "13em",
                  }}
                  className={Styles.navyBlueColorForce}
                >
                  Active Service Providers
                </TableCell>
                <TableCell
                  style={{
                    width: "15%",
                    fontWeight: 600,
                    fontSize: xlUp ? 16 : 12,
                    backgroundColor: "#FFFFFF",
                    lineHeight: "0.5rem",
                    minWidth: (isMobileDevice() || isTabletDevice()) && "7em",
                  }}
                  className={Styles.navyBlueColorForce}
                >
                  Quick Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {active.response.slice(0, 5).map((data, index) => (
                <TableRow key={index}>
                  <TableCell container>
                    <Grid container>
                      <Grid item xs={2}>
                        <Avatar
                          style={{ height: 24, width: 24 }}
                          src={data.hbDetails.avatarUrl}
                        >
                          {(data.hbDetails.name || "").charAt(0)}
                        </Avatar>
                      </Grid>
                      <Grid container item xs={10} style={{ paddingLeft: 10 }}>
                        <Grid item xs={12}>
                          <Link
                            onClick={() => {
                              if (isMarketplacePlan(userInfo)) {
                                setOpenPlanUpgradeMsgDialog(true);
                              } else {
                                data.hbDetails.userId &&
                                  history.push(
                                    `/app/serviceprovider/clients/details/${data.hbDetails.userId}?serviceRequestId=${data.serviceRequestId}`
                                  );
                              }
                            }}
                            className={Styles.navyBlueColor}
                            style={{
                              fontWeight: 600,
                              fontSize: isMobileDevice() ? 12 : xlUp ? 16 : 11,
                              pointerEvents: !data.hbDetails.userId && "none",
                            }}
                          >
                            {data.hbDetails.name}
                          </Link>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            style={{
                              fontSize: isMobileDevice() ? 10 : xlUp ? 14 : 8,
                            }}
                            className={clsx(
                              Styles.navyBlueColor,
                              Styles.sqEllipsis
                            )}
                          >
                            {data.hbDetails.email}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell style={{ textTransform: "uppercase" }}>
                    <ClientStatus
                      clientStatus={data.hbJourneyStatus}
                      isDashboardWidget={true}
                    />
                  </TableCell>
                  <TableCell className={Styles.navyBlueColor}>
                    <Typography
                      className={Styles.navyBlueColor}
                      style={{
                        fontSize: isMobileDevice() ? 12 : xlUp ? 16 : 11,
                      }}
                    >
                      {data.hbDetails.closingDate &&
                        moment(data.hbDetails.closingDate).format(
                          "MMM DD, YYYY"
                        )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Grid container>
                      {data.activeServiceProviders &&
                      data.activeServiceProviders.length
                        ? data.activeServiceProviders.map((activeSp, index) => {
                            const storeFrontContent = JSON.parse(
                              activeSp.storeFrontContent
                            );
                            return (
                              <Link
                                key={index}
                                to={{
                                  pathname:
                                    window.location.origin +
                                    `/app/storefront/${activeSp.storeFrontRouteName}`,
                                }}
                                target="_blank"
                              >
                                <img
                                  alt=""
                                  src={
                                    storeFrontContent.displayPicture ||
                                    "/app/images/souqh_logo.svg"
                                  }
                                  style={{
                                    height: 30,
                                    width: 30,
                                    border: "1px solid #0E1C4E1F",
                                    borderRadius: 4,
                                    cursor: "pointer",
                                    marginRight: 8,
                                  }}
                                />
                              </Link>
                            );
                          })
                        : null}
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid container justifyContent="space-between">
                      <Grid item xs={3}>
                        <ButtonBase>
                          <MessageIcon
                            style={{ fontSize: 14 }}
                            className={Styles.navyBlueColor}
                            onClick={(event) => {
                              if (isMarketplacePlan(userInfo)) {
                                setOpenPlanUpgradeMsgDialog(true);
                              } else {
                                data.hbDetails.userId &&
                                  navigateMessage(event, data.hbDetails.email);
                              }
                            }}
                          />
                        </ButtonBase>
                      </Grid>
                      <Grid item xs={3}>
                        {/* <ButtonBase> */}
                        <div style={{ position: "relative", top: 2 }}>
                          <DateRangeIcon
                            style={{
                              fontSize: 14,
                              color: isProPlan(userInfo) && "grey",
                              pointerEvents: isProPlan(userInfo) && "none",
                              cursor: isProPlan(userInfo)
                                ? "default"
                                : "pointer",
                            }}
                            className={Styles.navyBlueColor}
                            onClick={() => {
                              if (isMarketplacePlan(userInfo)) {
                                setOpenPlanUpgradeMsgDialog(true);
                              } else {
                                history.push(
                                  "/app/serviceprovider/calendar?scheduleMeeting=true"
                                );
                              }
                            }}
                          />
                          {isProPlan(userInfo) && (
                            <UpgradePlanIconSection
                              iconBtnStyle={{
                                position: "absolute",
                                fontSize: 16,
                                left: 6,
                                top: -10,
                                fill: "#fa7e61",
                              }}
                              iconStyle={{
                                position: "absolute",
                                fontSize: 16,
                                top: 5,
                                fill: "#fa7e61",
                              }}
                            />
                          )}
                        </div>
                        {/* </ButtonBase> */}
                      </Grid>
                      <Grid item xs={3}>
                        <ButtonBase disabled={!data.hbDetails.userId}>
                          <MoreHorizIcon
                            className={Styles.navyBlueColorForce}
                            onClick={(event) => {
                              if (isMarketplacePlan(userInfo)) {
                                setOpenPlanUpgradeMsgDialog(true);
                              } else {
                                handleClick(event);
                                setHBUserId(data.hbDetails.userId);
                                setSPId(
                                  data.storefrontDetails.serviceProviderId
                                );
                                setServiceRequestId(data.serviceRequestId);
                              }
                            }}
                          />
                        </ButtonBase>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div
            style={{
              position: "sticky",
              bottom: 0,
              width: "100%",
              height: 48,
              display: "inline-block",
            }}
          >
            <AppBar
              position="sticky"
              style={{
                top: "auto",
                bottom: 0,
                backgroundColor: "#FFFFFF",
                color: "#FA7E61",
                height: 48,
              }}
            >
              <Grid
                container
                item
                justifyContent="flex-end"
                alignContent="center"
                style={{ paddingRight: "2%", height: "100%" }}
              >
                <ButtonBase
                  onClick={() => {
                    if (isMarketplacePlan(userInfo)) {
                      setOpenPlanUpgradeMsgDialog(true);
                    } else {
                      history.push("/app/serviceprovider/clients?tabIndex=0");
                    }
                  }}
                >
                  <Typography style={{ fontSize: xlUp ? 14 : 12 }}>
                    View All
                  </Typography>
                </ButtonBase>
              </Grid>
            </AppBar>
          </div>
        </TableContainer>
      ) : (
        <Grid container justifyContent="center" alignItems="center">
          {!isMobileDevice() && (
            <Grid
              item
              xs={12}
              md={7}
              style={{ textAlign: "center", paddingRight: 32 }}
            >
              <Typography style={{ color: "#868DA6", fontSize: 11 }}>
                Looks like you are not working with any active clients. Click{" "}
                <MaterialLink
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (!isOnboardingInComplete(userInfo)) {
                      if (isMarketplacePlan(userInfo)) {
                        setOpenPlanUpgradeMsgDialog(true);
                      } else {
                        setOpenInviteClientsDialog(true);
                      }
                    }
                  }}
                >
                  here
                </MaterialLink>{" "}
                to invite your clients to join you on Souqh
              </Typography>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={3}
            style={{ textAlign: isMobileDevice() ? "center" : "right" }}
          >
            <img
              src="/app/images/no-result-found-orange.svg"
              style={{
                height: !isMobileDevice() && "100%",
                width: !isMobileDevice() && "100%",
              }}
            />
          </Grid>
          {isMobileDevice() && (
            <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
              <Typography
                style={{
                  color: "#868DA6",
                  fontSize: 11,
                  padding: "0px 24px",
                  marginTop: -60,
                }}
              >
                Looks like you are not working with any active clients. Click{" "}
                <MaterialLink
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (!isOnboardingInComplete(userInfo)) {
                      if (isMarketplacePlan(userInfo)) {
                        setOpenPlanUpgradeMsgDialog(true);
                      } else {
                        setOpenInviteClientsDialog(true);
                      }
                    }
                  }}
                >
                  here
                </MaterialLink>{" "}
                to invite your clients to join you on Souqh
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </React.Fragment>
  );
}
