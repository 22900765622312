import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  makeStyles,
  Chip,
  IconButton,
  TextField,
  Button,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import CloseIcon from "@material-ui/icons/Close";
import CircleIcon from "@mui/icons-material/Circle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useNewHomeProfile } from "souqh-react-redux-hooks/homeBuyer/hbProfile/useNewHomeProfile";
import { filter } from "lodash";
import { useExplore } from "souqh-react-redux-hooks/homeBuyer/useExplore";
import useFeaturedStoreFront from "souqh-react-redux-hooks/homeBuyer/useFeaturedStoreFront";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { useHistory } from "react-router";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";
import FeaturedStoreFront from "../Explore/FeaturedStoreFront";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 720,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  marginTop10: {
    marginTop: 10,
  },
  cardBorder: {
    borderRadius: 8,
    padding: 15,
  },
  primarColor: {
    color: "#0e1c4e",
  },
  reLativeCard: {
    position: "relative",
  },
  clickableCard: {
    cursor: "pointer",
  },
  overlay: {
    top: -23,
    right: -29,
    position: "absolute",
    width: 120,
    height: 120,
  },
  media: {
    height: 0,
    paddingTop: "75%", // 4:3
    backgroundSize: "85% 100%",
  },
  svgIcon: {
    color: "white",
    fill: "white",
    stroke: "#0e1c4e",
    width: "0.75rem !important",
    "&.selected": {
      fill: "#0e1c4e",
    },
  },
  chip: {
    margin: "2rem 0 1rem",
  },
  svgIconButton: {
    padding: 6,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  content: {
    marginLeft: "4.5rem",
  },
}));

function FundYourHomeDialog({
  openFundYourHomeDialog,
  setOpenFundYourHomeDialog,
  newHomeProfile,
}) {
  const {
    provinces,
    allowReset,
    propertyTypes,
    saveNewAddress,
    actions: { updateNewHomeProfile, resetNewHomeProfile },
  } = useNewHomeProfile();
  const classes = useStyles();
  let history = useHistory();

  const userInfo = useAuthUser();

  const theme = useTheme();
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  const numberOfCards = isMobileDevice()
    ? 1
    : isTabletDevice()
    ? 2
    : xlUp
    ? 6
    : 4;

  const [featuredSFs, setFeaturedSFs] = useState([]);

  const { formSaveCategoryObjAndCallApi } = useExplore();
  const { shuffledItems, activeIndex, setActiveIndex } = useFeaturedStoreFront(
    featuredSFs,
    numberOfCards,
    openFundYourHomeDialog
  );

  const serviceProviders = [
    {
      id: 2,
      name: "Mortgage Broker",
      logoUrl: "Mortgage-Broker.svg",
      isBusinessType: true,
      storefrontPathName: "mortgage-broker-agent",
    },
    {
      id: 3,
      isBusinessType: true,
      logoUrl: "LegalServices.svg",
      name: "Real Estate Lawyer", // for Legal Services
      storefrontPathName: "real-estate-lawyer",
    },
  ];

  useEffect(() => {
    let final = [];
    userInfo.journeyStoreFront &&
      userInfo.journeyStoreFront.length &&
      userInfo.journeyStoreFront.map((item) => final.push(item));
    setFeaturedSFs(final);
  }, [userInfo]);

  return (
    <Dialog
      fullWidth
      disableBackdropClick
      open={openFundYourHomeDialog}
      onClose={() => {
        setOpenFundYourHomeDialog(false);
      }}
      classes={{
        paper: classes.dialogPaper,
      }}
      maxWidth="xl"
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Fund Your Home</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => {
            setOpenFundYourHomeDialog(false);
          }}
        >
          <CloseIcon className={Styles.navyBlueColor} />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent classes={{ root: classes.content }}>
        <Grid
          container
          alignItems="center"
          alignContent="center"
          justifyContent="center"
        >
          <Grid item xs={12}>
            <Typography variant="subtitle1" className={Styles.navyBlueColor}>
              Congratulations on finding your home! Please provide your new home
              details.
            </Typography>
          </Grid>
          <Grid container item xs={12} spacing={2} style={{ marginTop: 8 }}>
            <Grid item xs={3} style={{ marginLeft: -8 }}>
              <TextField
                autoComplete="new-password"
                variant="outlined"
                fullWidth
                label="Address Line 1"
                value={newHomeProfile && newHomeProfile.streetNumber}
                inputProps={{
                  maxLength: 100,
                }}
                onChange={(event) => {
                  updateNewHomeProfile({
                    streetNumber: event.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                autoComplete="new-password"
                variant="outlined"
                fullWidth
                label="Address Line 2"
                value={newHomeProfile && newHomeProfile.streetName}
                inputProps={{
                  maxLength: 100,
                }}
                onChange={(event) => {
                  updateNewHomeProfile({ streetName: event.target.value });
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <Autocomplete
                fullWidth
                value={(newHomeProfile && newHomeProfile.province) || null}
                options={provinces || []}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => {
                  return option.name === value.name;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="State/Province"
                    variant="outlined"
                    autoComplete="new-password"
                  />
                )}
                required
                onChange={(event, value) => {
                  updateNewHomeProfile({ province: value });
                }}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={2} style={{ marginTop: 10 }}>
            <Grid item xs={3} style={{ marginLeft: -8 }}>
              <Autocomplete
                fullWidth
                noOptionsText={"Please select province first"}
                value={(newHomeProfile && newHomeProfile.city) || null}
                options={
                  newHomeProfile &&
                  newHomeProfile.province &&
                  newHomeProfile.province.cityDTO
                    ? filter(
                        // Replace All cities by Other.
                        newHomeProfile.province.cityDTO,
                        (city) =>
                          (city.name =
                            city.name === "All Cities" ? "Other" : city.name)
                      )
                    : []
                }
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => {
                  return option.name === value.name;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="City"
                    variant="outlined"
                    autoComplete="new-password"
                  />
                )}
                required
                onChange={(event, value) => {
                  updateNewHomeProfile({ city: value });
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant="outlined"
                fullWidth
                aria-label="Postal Code Input"
                label="Postal Code"
                autoComplete="new-password"
                value={newHomeProfile && newHomeProfile.postalCode}
                inputProps={{
                  maxLength: 15,
                }}
                onChange={(event) => {
                  updateNewHomeProfile({ postalCode: event.target.value });
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant="outlined"
                fullWidth
                aria-label="Country Input"
                label="Country"
                autoComplete="new-password"
                value={newHomeProfile && newHomeProfile.county}
                inputProps={{
                  maxLength: 256,
                }}
                onChange={(event) => {
                  updateNewHomeProfile({ county: event.target.value });
                }}
              />
            </Grid>
          </Grid>
          {newHomeProfile &&
            newHomeProfile.city &&
            newHomeProfile.city.id === 1739 && (
              <Grid
                container
                item
                xs={12}
                spacing={2}
                style={{ marginTop: 10 }}
              >
                <Grid item xs={3} style={{ marginLeft: -8 }}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={newHomeProfile && newHomeProfile.otherCity}
                    aria-label="Other City"
                    label="Other City"
                    autoComplete="new-password"
                    inputProps={{
                      maxLength: 256,
                    }}
                    onChange={(event) => {
                      updateNewHomeProfile({ otherCity: event.target.value });
                    }}
                  />
                </Grid>
              </Grid>
            )}

          <Grid container item xs={12} spacing={2} style={{ marginTop: 10 }}>
            <Grid item xs={3} style={{ marginLeft: -8 }}>
              <Autocomplete
                options={propertyTypes || []}
                value={newHomeProfile && newHomeProfile.propertyType}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Property Type"
                    variant="outlined"
                    autoComplete="new-password"
                  />
                )}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => {
                  return option.name === value.name;
                }}
                onChange={(event, value) => {
                  updateNewHomeProfile({ propertyType: value });
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  disableToolbar
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  id="date-picker-inline"
                  label="Closing Date"
                  value={(newHomeProfile && newHomeProfile.closingDate) || null}
                  autoOk={true}
                  onChange={(event) => {
                    updateNewHomeProfile({
                      closingDate: event,
                    });
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid container item xs={3} alignItems="center">
              <Grid container item xs={8} justifyContent="flex-end">
                <Button
                  disabled={!allowReset}
                  variant="outlined"
                  style={{ textTransform: "capitalize" }}
                  className={Styles.navyBlueColorForce}
                  onClick={() => resetNewHomeProfile()}
                >
                  Reset
                </Button>
              </Grid>
              <Grid container item xs={4} justifyContent="flex-end">
                <Button
                  variant="contained"
                  style={{ textTransform: "capitalize" }}
                  className={Styles.primaryBtnAccent}
                  onClick={() => saveNewAddress()}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {newHomeProfile &&
            newHomeProfile.propertyType &&
            newHomeProfile.propertyType.name === "Other" && (
              <Grid
                container
                item
                xs={12}
                spacing={2}
                style={{ marginTop: 10 }}
              >
                <Grid item xs={3} style={{ marginLeft: -8 }}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={newHomeProfile && newHomeProfile.otherPropertyType}
                    aria-label="Other Property Type"
                    label="Other Property Type"
                    inputProps={{
                      maxLength: 256,
                    }}
                    autoComplete="new-password"
                    onChange={(event) => {
                      updateNewHomeProfile({
                        otherPropertyType: event.target.value,
                      });
                    }}
                  />
                </Grid>
              </Grid>
            )}

          <Divider
            orientation="horizontal"
            variant="fullWidth"
            style={{ width: "100%", marginTop: 30, marginBottom: 30 }}
          />
          <Grid container item classes={{ root: classes.reLativeCard }} xs={12}>
            <Grid item xs={9}>
              <Typography
                className={Styles.navyBlueColor}
                style={{ fontSize: 24, fontWeight: 600 }}
              >
                As a next step, let’s get you in touch with service providers to
                help you with the next stage of your journey.
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item container xs={12}>
              <FeaturedStoreFront
                bgColor="#66D7D140"
                storeFrontList={shuffledItems}
              />
              {featuredSFs.length && featuredSFs.length / numberOfCards > 1 ? (
                <Grid
                  style={{ justifyContent: "center", marginTop: 10 }}
                  container
                  item
                  xs={12}
                >
                  {Array(Math.ceil(featuredSFs.length / numberOfCards))
                    .fill(1)
                    .map((item, index) => (
                      <IconButton
                        classes={{ root: classes.svgIconButton }}
                        onClick={() => setActiveIndex(index)}
                      >
                        <CircleIcon
                          classes={{ root: classes.svgIcon }}
                          className={activeIndex === index ? "selected" : ""}
                        />
                      </IconButton>
                    ))}
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid container xs={12} classes={{ root: classes.chip }}>
            {serviceProviders.map((provider, index) => (
              <Chip
                key={index}
                variant="outlined"
                label={provider.name}
                className={Styles.navyBlueColorForce}
                style={{ marginRight: 8 }}
                onClick={() => {
                  formSaveCategoryObjAndCallApi(provider, userInfo);
                  history.push({
                    pathname: `/app/homebuyer/explore/${provider.storefrontPathName}/${newHomeProfile?.city?.shortName}`,
                    state: {
                      searchedService: provider.name,
                    },
                  });
                  // history.push(
                  //   `/homebuyer/explore/${provider.storefrontPathName}/${newHomeProfile?.city?.name}`
                  // );
                }}
              />
            ))}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default FundYourHomeDialog;
