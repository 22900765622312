import { Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import { filter } from "lodash";
import { useTheme } from "souqh-react-redux-hooks/serviceProvider/storefront/useTheme";
import Styles from "../../Styles.module.css";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "12px 0px",
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  semiBold16: {
    fontSize: "1rem",
    fontWeight: 600,
  },
}));

function CurrentAndNewAddress({
  disabled,
  streetNumber,
  streetName,
  province,
  city,
  county,
  postalCode,
  fieldType,
  saveAddressField,
  provinces,
  titleLabel,
}) {
  const classes = useStyles();
  const { primaryColor } = useTheme();
  return (
    <Grid item className={classes.root}>
      <Grid container item xs={12} style={{ padding: "10px 26px 10px 23px" }}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <Typography className={classes.semiBold16}>{titleLabel}</Typography>
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>

        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <TextField
              disabled={!!disabled}
              variant="outlined"
              onChange={(e) => {
                saveAddressField({
                  field: "streetNumber",
                  value: e.target.value,
                  fieldType,
                });
              }}
              value={streetNumber}
              fullWidth
              required
              aria-label="Street number Input"
              id={"street-number-for-" + fieldType}
              label="Address Line 1"
              inputProps={{
                maxLength: 100,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled={!!disabled}
              variant="outlined"
              onChange={(e) => {
                saveAddressField({
                  field: "streetName",
                  value: e.target.value,
                  fieldType,
                });
              }}
              value={streetName}
              fullWidth
              aria-label="Street Name Input"
              id={"street-name-for-" + fieldType}
              label="Address Line 2 (optional)"
              // required
              inputProps={{
                maxLength: 100,
              }}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <Autocomplete
              disabled={!!disabled}
              value={province || ""}
              fullWidth
              onChange={(e, value) => {
                saveAddressField({
                  field: "province",
                  value: value,
                  fieldType,
                });
              }}
              id={"province-for-" + fieldType}
              options={provinces || []}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => {
                return option.name === value.name;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Province"
                  required
                  variant="outlined"
                />
              )}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              disabled={!!disabled}
              value={city}
              fullWidth
              onChange={(e, value) => {
                saveAddressField({
                  field: "city",
                  value: value,
                  fieldType,
                });
              }}
              noOptionsText={"Please select your official municipality"}
              id={"city-for-" + fieldType}
              options={
                province && province.cityDTO
                  ? filter(
                      // Remove All cities option as it is single select.
                      province.cityDTO,
                      (city) => city.name !== "All Cities"
                    )
                  : []
              }
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => {
                return option.name === value.name;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="City"
                  required
                  variant="outlined"
                />
              )}
              required
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <TextField
              disabled={!!disabled}
              variant="outlined"
              onChange={(e) => {
                saveAddressField({
                  field: "county",
                  value: e.target.value,
                  fieldType,
                });
              }}
              value={county}
              fullWidth
              aria-label="County Input"
              id={"county-for-" + fieldType}
              label="Country"
              inputProps={{
                maxLength: 256,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled={!!disabled}
              variant="outlined"
              onChange={(e) => {
                saveAddressField({
                  field: "postalCode",
                  value: e.target.value,
                  fieldType,
                });
              }}
              value={postalCode}
              fullWidth
              aria-label="Postal Code Input"
              id={"postal-code-for-" + fieldType}
              label="Postal Code"
              required
              inputProps={{
                maxLength: 15,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CurrentAndNewAddress;
