import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Styles from "../../Styles.module.css";
import { useHistory, useLocation } from "react-router-dom";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import {
  HOME_BUYER_USER,
  SERVICE_PROVIDER_USER,
  TEAM_MEMBER_USER,
} from "../ServiceProvider/serviceProviderConstants";
import { useRoleManagement } from "souqh-react-redux-hooks/common/useRoleManagement";
import { isMarketplacePlan, isPlusPlan, isProPlan } from "../../utils/AppUtils";
import UpgradePlanIconSection from "../common/UpgradePlanIconSection";

export default function SettingsLeftPanel() {
  const user = useAuthUser();
  const history = useHistory();
  const mapping = {
    account: 1,
    email: 2,
    team: 3,
    subscription: 4,
    ecommerce: 5,
    payment: 6,
  };

  const [activeSection, setActiveSection] = useState("account");
  const { isTeamMember } = useRoleManagement();

  let location = useLocation();
  let locationParam = location.pathname.split("/"); // read URL and split it on "/"
  let activeSectionFromURL = locationParam[locationParam.length - 1]; // take last section from URL

  useEffect(() => {
    // On load of page set the active section on Drawer according to url.
    if (mapping[activeSectionFromURL]) {
      setActiveSection(activeSectionFromURL);
    } else {
      setActiveSection("account");
    }
  }, [location]);

  let route =
    user.userType === SERVICE_PROVIDER_USER ||
    user.userType === TEAM_MEMBER_USER
      ? "serviceprovider"
      : "homebuyer";

  return (
    <React.Fragment>
      <Grid
        item
        xs={12}
        style={{
          height: "100%",
          overflow: "hidden",
          paddingTop: 32,
          position: "fixed",
          width: "inherit",
        }}
      >
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              style={{ display: "flex", marginBottom: 32, paddingLeft: 24 }}
            >
              <ListItemText
                primary="Settings"
                primaryTypographyProps={{ variant: "subtitle1" }}
              />
            </ListSubheader>
          }
          className={Styles.navyBlueColor}
        >
          <ListItem
            button
            selected={activeSection === "account"}
            onClick={() => {
              history.push(`/app/${route}/settings/account`);
              setActiveSection("account");
            }}
            className={{
              [Styles.primaryTxtAccentForce]: activeSection === "account",
            }}
            style={{ paddingLeft: 24 }}
          >
            <ListItemText
              primary="Account Preferences"
              primaryTypographyProps={{ variant: "subtitle1" }}
            />
          </ListItem>
          <ListItem
            button
            selected={activeSection === "email"}
            onClick={() => {
              history.push(`/app/${route}/settings/email`);
              setActiveSection("email");
            }}
            className={{
              [Styles.primaryTxtAccentForce]: activeSection === "email",
            }}
            style={{ paddingLeft: 24 }}
          >
            <ListItemText
              primary="Email Preferences"
              primaryTypographyProps={{ variant: "subtitle1" }}
            />
          </ListItem>
          {user.userType !== HOME_BUYER_USER && !isTeamMember() && (
            <React.Fragment>
              <ListItem
                button={!isMarketplacePlan(user) && true}
                selected={activeSection === "team"}
                onClick={() => {
                  if (!isMarketplacePlan(user)) {
                    history.push("/app/serviceprovider/settings/team");
                    setActiveSection("team");
                  }
                }}
                className={{
                  [Styles.primaryTxtAccentForce]: activeSection === "team",
                  [Styles.grayTextForce]: isMarketplacePlan(user),
                }}
                style={{ paddingLeft: 24 }}
              >
                <ListItemText
                  primary="Team Management"
                  primaryTypographyProps={{ variant: "subtitle1" }}
                />
              </ListItem>

              <ListItem
                button={!isMarketplacePlan(user) && true}
                selected={activeSection === "subscription"}
                onClick={() => {
                  if (!isMarketplacePlan(user)) {
                    history.push("/app/serviceprovider/settings/subscription");
                    setActiveSection("subscription");
                  }
                }}
                className={{
                  [Styles.primaryTxtAccentForce]:
                    activeSection === "subscription",
                  [Styles.grayTextForce]: isMarketplacePlan(user),
                }}
                style={{ paddingLeft: 24 }}
              >
                <ListItemText
                  primary="Subscription & Payment"
                  primaryTypographyProps={{ variant: "subtitle1" }}
                />
              </ListItem>
              {!isTeamMember() ? (
                <React.Fragment>
                  <ListItem
                    button={!isMarketplacePlan(user) && true}
                    disabled={isProPlan(user) || isPlusPlan(user)}
                    selected={activeSection === "ecommerce"}
                    onClick={() => {
                      if (!isMarketplacePlan(user)) {
                        history.push("/app/serviceprovider/settings/ecommerce");
                        setActiveSection("ecommerce");
                      }
                    }}
                    className={{
                      [Styles.primaryTxtAccentForce]:
                        activeSection === "ecommerce",
                      [Styles.grayTextForce]: isMarketplacePlan(user),
                    }}
                    style={{ paddingLeft: 24 }}
                  >
                    <ListItemText
                      primary="eCommerce"
                      primaryTypographyProps={{ variant: "subtitle1" }}
                    />
                  </ListItem>
                  {(isProPlan(user) || isPlusPlan(user)) && (
                    <UpgradePlanIconSection
                      iconBtnStyle={{
                        position: "absolute",
                        fontSize: 16,
                        left: 118,
                        top: 280,
                        fill: "#fa7e61",
                      }}
                      iconStyle={{
                        position: "absolute",
                        fontSize: 16,
                        top: 4,
                        fill: "#fa7e61",
                      }}
                    />
                  )}
                </React.Fragment>
              ) : null}
            </React.Fragment>
          )}
          {user.userType === HOME_BUYER_USER && (
            <ListItem
              button
              selected={activeSection === "payment"}
              onClick={() => {
                history.push("/app/homebuyer/settings/payment");
              }}
              className={{
                [Styles.primaryTxtAccentForce]: activeSection === "payment",
              }}
              style={{ paddingLeft: 24 }}
            >
              <ListItemText
                primary="Payment History"
                primaryTypographyProps={{ variant: "subtitle1" }}
              />
            </ListItem>
          )}
        </List>
      </Grid>
    </React.Fragment>
  );
}
