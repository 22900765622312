import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  Divider,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import { isMobileDevice } from "../../../utils/AppUtils";

export default function EditDocWarningDialog({
  openEditWarningDialog,
  setOpenEditWarningDialog,
  updateShowDialogFlag,
  showDialogFlag,
  setOpenEditEsignDocumentDialog,
}) {
  const [checkBoxFlag, setCheckBoxFlag] = useState(false);

  useEffect(() => {
    setCheckBoxFlag(!showDialogFlag);
  }, [showDialogFlag]);

  const handleDialogClose = (event, reason) => {
    setOpenEditWarningDialog(false);
  };
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={openEditWarningDialog || false}
      onClose={handleDialogClose}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Information</Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText
          style={{ marginTop: 12 }}
          className={Styles.navyBlueColor}
        >
          <Typography variant="body1" style={{ marginBottom: 12 }}>
            Note: Once you complete your edits, please click Save and Close at
            the top of the document, before proceeding with the 'Next' button.
          </Typography>
          <img
            src="/app/images/esign-iframe-screenshot.png"
            style={{ width: 400, height: 241 }}
          />
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Grid item container xs={12}>
          <Grid item container xs={9} md={6} style={{ alignItems: "center" }}>
            <Checkbox
              checked={checkBoxFlag}
              onChange={(event) => {
                setCheckBoxFlag(event.target.checked);
              }}
              color={Styles.navyBlueColorForce}
            />
            <Typography
              variant="body1"
              className={Styles.navyBlueColor}
              style={{ fontSize: isMobileDevice() && 14 }}
            >
              Don't show this again
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={3}
            md={6}
            style={{
              justifyContent: "flex-end",
              paddingRight: !isMobileDevice() && 8,
            }}
          >
            <Button
              onClick={() => {
                updateShowDialogFlag(!checkBoxFlag);
                setOpenEditWarningDialog(false);
                setOpenEditEsignDocumentDialog(true);
              }}
              className={Styles.primaryBtnAccent}
              style={{ textTransform: "capitalize", padding: "4px 32px" }}
            >
              Proceed
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
