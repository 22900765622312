import {
  Grid,
  TableRow,
  TableCell,
  Typography,
  ButtonBase,
  Tooltip,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Styles from "../../../Styles.module.css";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import MoreOptionsOnClientInfo from "./MoreOptionsOnClientInfo";
import * as moment from "moment";
import { useInactiveClientInfo } from "souqh-react-redux-hooks/serviceProvider/clientManagement/useInactiveClientInfo";
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import clsx from "clsx";
import { viewClientDetails } from "../../../utils/AppUtils";

export default function InActiveClientTableRow({ data, index }) {
  const [anchorEl, setAnchorEl] = useState(null);
  let history = useHistory();
  let { path } = useRouteMatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <MoreOptionsOnClientInfo
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        showLiveMeetingOption={true}
        showRequestAccessOption={true}
        toUserId={data.hbDetails.userId}
        serviceRequestId={data.serviceRequestId}
        isInactiveTab={true}
      />
      <TableRow>
        <TableCell>
          <Typography className={Styles.navyBlueColor}>{index + 1}</Typography>
        </TableCell>
        <TableCell container>
          <Grid
            style={{ cursor: data.profileAccessRequestGranted && "pointer" }}
            container
            onClick={() => {
              data.profileAccessRequestGranted &&
                viewClientDetails(history, path, data);
            }}
          >
            <Grid item xs={4}>
              <img
                src={data.avatarUrl}
                style={{
                  height: 50,
                  border: "1px solid #0E1C4E1F",
                  borderRadius: 4,
                  marginRight: 10,
                }}
                alt="avatar"
                title="avatar"
              />
            </Grid>
            <Grid container item xs={8} alignContent="center">
              <Typography
                variant="body1"
                className={Styles.navyBlueColor}
                style={{
                  fontWeight: 600,
                  color: !data.profileAccessRequestGranted && "#868DA6",
                }}
              >
                {data.name}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Typography className={Styles.navyBlueColor}>
            {moment(data.requestedDate).format("MMM DD, YYYY")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography className={Styles.navyBlueColor}>
            {moment(data.archiveInActiveDate).format("MMM DD, YYYY")}
          </Typography>
        </TableCell>
        <TableCell>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Tooltip title={data.notes}>
                <Typography
                  className={clsx(
                    Styles.navyBlueColor,
                    Styles.sqEllipsisTwoLines
                  )}
                >
                  {data.notes || "No details available"}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <ButtonBase>
            <MoreHorizIcon
              onClick={handleClick}
              className={Styles.navyBlueColor}
            />
          </ButtonBase>
        </TableCell>
      </TableRow>
    </>
  );
}
