import { Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Styles from "../../../Styles.module.css";
import { useHistory } from "react-router-dom";
import RequestDocumentDialog from "./RequestDocumentDialog";
import TagSomeoneDialog from "./TagSomeoneDialog";
import OtherActionDialog from "./OtherActionDialog";
import RecommendSPDialog from "./RecommendSPDialog";
import CompletionReasonDialog from "./CompletionReasonDialog";
import { useSQQuery } from "souqh-react-redux-hooks/common/useSQQuery";
import SendInvoiceDialog from "./SendInvoiceDialog";
import { useNavigateToMessage } from "souqh-react-redux-hooks/common/useNavigateToMessage";
import RejectionReasonDialog from "./RejectionReasonDialog";
import { useSPeSignWizard } from "souqh-react-redux-hooks/serviceProvider/documents/useSPeSignWizard";
import SPeSignWizard from "../UploadDocuments/SPeSignWizard";
import { isMobileDevice, isTabletDevice } from "../../../utils/AppUtils";
import { useConnectedAccount } from "souqh-react-redux-hooks/common/useConnectedAccount";
import { useDispatch } from "react-redux";
import { color_info } from "souqh-react-redux-hooks/toasterColors";

function SendMessageAction({ navigateMessage, primaryApplicant }) {
  return (
    <Grid
      container
      item
      xs={6}
      md={3}
      lg={2}
      alignContent="center"
      style={{
        border: "1px solid #0000001F",
        height: 144,
        cursor: "pointer",
        maxWidth: isTabletDevice() ? "23%" : "48%",
      }}
      onClick={(event) => {
        navigateMessage(event, primaryApplicant.email);
      }}
    >
      <Grid container item xs={12} justifyContent="center">
        <img src="/app/images/send_message.svg" style={{ height: 40, width: 40 }} />
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <Typography
          variant="body1"
          className={Styles.navyBlueColor}
          style={{ fontWeight: 600, padding: "0px 8px", textAlign: "center" }}
        >
          Send Message
        </Typography>
      </Grid>
    </Grid>
  );
}

function SendDocumentAction({ onUploadEsignFileChange }) {
  return (
    <Grid
      container
      item
      xs={6}
      md={3}
      lg={2}
      alignContent="center"
      style={{
        border: "1px solid #0000001F",
        height: 144,
        cursor: "pointer",
        maxWidth: isTabletDevice() ? "23%" : "48%",
      }}
    >
      <input
        style={{ display: "none" }}
        id="contained-button-file-esign"
        multiple
        type="file"
        onChange={(event) => {
          onUploadEsignFileChange(event);
        }}
      />
      <label htmlFor="contained-button-file-esign" style={{ width: "100%" }}>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          style={{ cursor: "pointer" }}
        >
          <img
            src="/app/images/send_document.svg"
            style={{ height: 40, width: 40 }}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          style={{ cursor: "pointer" }}
        >
          <Typography
            variant="body1"
            className={Styles.navyBlueColor}
            style={{ fontWeight: 600, textAlign: "center" }}
          >
            Send Document
          </Typography>
        </Grid>
      </label>
    </Grid>
  );
}

function RequestDocumentAction({ setOpenRequestDocumentDialog, xlUp }) {
  return (
    <Grid
      container
      item
      xs={6}
      md={3}
      lg={2}
      alignContent="center"
      style={{
        border: "1px solid #0000001F",
        height: 144,
        cursor: "pointer",
        maxWidth: isTabletDevice() ? "23%" : "48%",
      }}
      onClick={() => setOpenRequestDocumentDialog(true)}
    >
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        alignContent="flex-end"
        // style={{ height: xlUp ? "30%" : isTabletDevice() ? "32%" : "50%" }}
      >
        <img
          src="/app/images/request_document.svg"
          style={{ height: 40, width: 40 }}
        />
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <Typography
          variant="body1"
          className={Styles.navyBlueColor}
          style={{ fontWeight: 600, textAlign: "center" }}
        >
          Request Document
        </Typography>
      </Grid>
    </Grid>
  );
}

function ScheduleEventAction({ history }) {
  return (
    <Grid
      container
      item
      xs={6}
      md={3}
      lg={2}
      alignContent="center"
      style={{
        border: "1px solid #0000001F",
        height: 144,
        maxWidth: isTabletDevice() ? "23%" : "48%",
        cursor: "pointer",
      }}
      onClick={() => {
        history.push("/app/serviceprovider/calendar?scheduleMeeting=true");
      }}
    >
      <Grid container item xs={12} justifyContent="center">
        <img
          src="/app/images/schedule_event.svg"
          style={{ height: 40, width: 40 }}
        />
      </Grid>
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        style={{ fontWeight: 600 }}
      >
        <Typography
          variant="body1"
          className={Styles.navyBlueColor}
          style={{ fontWeight: 600, padding: "0px 8px", textAlign: "center" }}
        >
          Schedule Event
        </Typography>
      </Grid>
    </Grid>
  );
}

function TagSomeoneAction({ setOpenTagSomeoneDialog }) {
  return (
    <Grid
      container
      item
      xs={6}
      md={3}
      lg={2}
      alignContent="center"
      style={{
        border: "1px solid #0000001F",
        height: 144,
        cursor: "pointer",
        maxWidth: isTabletDevice() ? "23%" : "48%",
      }}
      onClick={() => setOpenTagSomeoneDialog(true)}
    >
      <Grid container item xs={12} justifyContent="center">
        <img src="/app/images/tag_someone.svg" style={{ height: 40, width: 40 }} />
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <Typography
          variant="body1"
          className={Styles.navyBlueColor}
          style={{ fontWeight: 600, padding: "0px 9px", textAlign: "center" }}
        >
          Tag Someone
        </Typography>
      </Grid>
    </Grid>
  );
}

function RecommmendSPAction({ setOpenRecommendSPDialog }) {
  return (
    <Grid
      container
      item
      xs={6}
      md={3}
      lg={2}
      alignContent="center"
      style={{
        border: "1px solid #0000001F",
        height: 144,
        cursor: "pointer",
        maxWidth: isTabletDevice() ? "23%" : "48%",
      }}
      onClick={() => setOpenRecommendSPDialog(true)}
    >
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        alignContent="flex-end"
        style={{ height: "45%" }}
      >
        <img src="/app/images/recommend_sp.svg" style={{ height: 45, width: 50 }} />
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <Typography
          variant="body1"
          className={Styles.navyBlueColor}
          style={{ fontWeight: 600, textAlign: "center" }}
        >
          Recommend Service Provider
        </Typography>
      </Grid>
    </Grid>
  );
}

function InactiveClientAction({
  setOpenRejectionReasonDialog,
  serviceRequestId,
}) {
  return (
    <Grid
      container
      item
      xs={6}
      md={3}
      lg={2}
      alignContent="center"
      style={{
        border: "1px solid #0000001F",
        height: 144,
        cursor: "pointer",
        maxWidth: isTabletDevice() ? "23%" : "48%",
      }}
      onClick={() => {
        setOpenRejectionReasonDialog({
          open: true,
          id: serviceRequestId,
        });
      }}
    >
      <Grid container item xs={12} justifyContent="center">
        <img
          src="/app/images/inactive_client.svg"
          style={{ height: 40, width: 40 }}
        />
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <Typography
          variant="body1"
          className={Styles.navyBlueColor}
          style={{ fontWeight: 600, padding: "0px 8px", textAlign: "center" }}
        >
          In-Active Client
        </Typography>
      </Grid>
    </Grid>
  );
}

function CompleteClientAction({
  setOpenCompletionReasonDialog,
  serviceRequestId,
}) {
  return (
    <Grid
      container
      item
      xs={6}
      md={3}
      lg={2}
      alignContent="center"
      style={{
        border: "1px solid #0000001F",
        height: 144,
        cursor: "pointer",
        maxWidth: isTabletDevice() ? "23%" : "48%",
      }}
      onClick={() =>
        setOpenCompletionReasonDialog({
          open: true,
          id: serviceRequestId,
        })
      }
    >
      <Grid container item xs={12} justifyContent="center">
        <img
          src="/app/images/complete_client.svg"
          style={{ height: 40, width: 40 }}
        />
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <Typography
          variant="body1"
          className={Styles.navyBlueColor}
          style={{ fontWeight: 600, padding: "0px 8px", textAlign: "center" }}
        >
          Complete Client
        </Typography>
      </Grid>
    </Grid>
  );
}

function SendInvoiceAction({
  setOpenSendInvoiceDialog,
  connectedAccountDetails,
}) {
  const dispatch = useDispatch();

  return (
    <Grid
      container
      item
      xs={6}
      md={3}
      lg={2}
      alignContent="center"
      style={{
        border: "1px solid #0000001F",
        height: 144,
        cursor: "pointer",
        maxWidth: isTabletDevice() ? "23%" : "48%",
      }}
      onClick={() => {
        setOpenSendInvoiceDialog(true);
      }}
    >
      <Grid container item xs={12} justifyContent="center">
        <img src="/app/images/send_invoice.svg" style={{ height: 40, width: 40 }} />
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <Typography
          variant="body1"
          className={Styles.navyBlueColor}
          style={{ fontWeight: 600, padding: "0px 14px", textAlign: "center" }}
        >
          Send Invoice
        </Typography>
      </Grid>
    </Grid>
  );
}

function OtherAction({ setOpenOtherActionDialog }) {
  return (
    <Grid
      container
      item
      xs={6}
      md={3}
      lg={2}
      alignContent="center"
      style={{
        border: "1px solid #0000001F",
        height: 144,
        cursor: "pointer",
        maxWidth: isTabletDevice() ? "23%" : "48%",
      }}
      onClick={() => setOpenOtherActionDialog(true)}
    >
      <Grid container item xs={12} justifyContent="center">
        <img src="/app/images/other_action.svg" style={{ height: 40, width: 40 }} />
      </Grid>
      <Grid container item xs={12} justifyContent="center">
        <Typography
          variant="body1"
          className={Styles.navyBlueColor}
          style={{ fontWeight: 600, padding: "0px 14px", textAlign: "center" }}
        >
          Other Action
        </Typography>
      </Grid>
    </Grid>
  );
}

export default function QuickActions({ primaryApplicant, userId }) {
  const history = useHistory();
  const { query } = useSQQuery();

  const { navigateMessage } = useNavigateToMessage();

  const [openRequestDocumentDialog, setOpenRequestDocumentDialog] =
    useState(false);
  const [openTagSomeoneDialog, setOpenTagSomeoneDialog] = useState(false);
  const [openOtherActionDialog, setOpenOtherActionDialog] = useState(false);
  const [openRecommendSPDialog, setOpenRecommendSPDialog] = useState(false);
  const [openCompletionReasonDialog, setOpenCompletionReasonDialog] =
    useState(false);
  const [openSendInvoiceDialog, setOpenSendInvoiceDialog] = useState(false);
  const [openRejectionReasonDialog, setOpenRejectionReasonDialog] = useState({
    open: false,
    serviceRequestId: null,
  });

  const theme = useTheme();
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  let serviceRequestId = query.get("serviceRequestId");

  const {
    onUploadEsignFileChange,
    openEsignWizard,
    closeAndFinishEsign,
    actions: { setOpenEsignWizard },
  } = useSPeSignWizard();

  const {
    // getEnableEcommerceDetails,
    connectedAccountDetails,
    getConnectedAccountDetails,
  } = useConnectedAccount();

  useEffect(() => {
    // getEnableEcommerceDetails();
    getConnectedAccountDetails();
    return () => {
      closeAndFinishEsign();
    };
  }, []);

  return (
    <Grid item xs={12} container>
      <SPeSignWizard
        openEsignWizard={openEsignWizard}
        setOpenEsignWizard={setOpenEsignWizard}
      />
      <CompletionReasonDialog
        openCompletionReasonDialog={openCompletionReasonDialog}
        setOpenCompletionReasonDialog={setOpenCompletionReasonDialog}
      />
      <SendInvoiceDialog
        openSendInvoiceDialog={openSendInvoiceDialog}
        setOpenSendInvoiceDialog={setOpenSendInvoiceDialog}
        primaryApplicant={primaryApplicant}
        serviceRequestId={serviceRequestId}
        connectedAccountDetails={connectedAccountDetails}
      />
      <RequestDocumentDialog
        openRequestDocumentDialog={openRequestDocumentDialog}
        setOpenRequestDocumentDialog={setOpenRequestDocumentDialog}
        userId={userId}
        serviceRequestId={serviceRequestId}
      />
      <TagSomeoneDialog
        openTagSomeoneDialog={openTagSomeoneDialog}
        setOpenTagSomeoneDialog={setOpenTagSomeoneDialog}
        serviceRequestId={serviceRequestId}
        page="clientHub"
      />
      <OtherActionDialog
        openOtherActionDialog={openOtherActionDialog}
        setOpenOtherActionDialog={setOpenOtherActionDialog}
        serviceRequestId={serviceRequestId}
      />
      <RecommendSPDialog
        openRecommendSPDialog={openRecommendSPDialog}
        setOpenRecommendSPDialog={setOpenRecommendSPDialog}
        userId={userId}
      />
      <RejectionReasonDialog
        openRejectionReasonDialog={openRejectionReasonDialog}
        setOpenRejectionReasonDialog={setOpenRejectionReasonDialog}
        title={"Reason for In-Active"}
        message={`Your have marked ${primaryApplicant.fullName} inactive with reason `}
        actionType="IN_ACTIVE_REQUEST"
      />
      <Grid item xs={12} style={{ marginBottom: 10 }}>
        <Typography variant="h6" className={Styles.navyBlueColor}>
          Quick Actions
        </Typography>
      </Grid>

      <Grid container item xs={12} style={{ marginBottom: 25 }}>
        {isMobileDevice() ? (
          <React.Fragment>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              style={{ marginBottom: 16 }}
            >
              <SendMessageAction
                navigateMessage={navigateMessage}
                primaryApplicant={primaryApplicant}
              />

              <SendDocumentAction
                onUploadEsignFileChange={onUploadEsignFileChange}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              style={{ marginBottom: 16 }}
            >
              <RequestDocumentAction
                setOpenRequestDocumentDialog={setOpenRequestDocumentDialog}
                xlUp={xlUp}
              />
              <ScheduleEventAction history={history} />
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              style={{ marginBottom: 16 }}
            >
              <TagSomeoneAction
                setOpenTagSomeoneDialog={setOpenTagSomeoneDialog}
              />
              <RecommmendSPAction
                setOpenRecommendSPDialog={setOpenRecommendSPDialog}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              style={{ marginBottom: 16 }}
            >
              <InactiveClientAction
                setOpenRejectionReasonDialog={setOpenRejectionReasonDialog}
                serviceRequestId={serviceRequestId}
              />
              <CompleteClientAction
                setOpenCompletionReasonDialog={setOpenCompletionReasonDialog}
                serviceRequestId={serviceRequestId}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              style={{ marginBottom: 16 }}
            >
              <SendInvoiceAction
                setOpenSendInvoiceDialog={setOpenSendInvoiceDialog}
                connectedAccountDetails={connectedAccountDetails}
              />
              <OtherAction
                setOpenOtherActionDialog={setOpenOtherActionDialog}
              />
            </Grid>
          </React.Fragment>
        ) : isTabletDevice() ? (
          <React.Fragment>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              style={{ marginBottom: 16 }}
            >
              <SendMessageAction
                navigateMessage={navigateMessage}
                primaryApplicant={primaryApplicant}
              />

              <SendDocumentAction
                onUploadEsignFileChange={onUploadEsignFileChange}
              />
              <RequestDocumentAction
                setOpenRequestDocumentDialog={setOpenRequestDocumentDialog}
                xlUp={xlUp}
              />
              <ScheduleEventAction history={history} />
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              style={{ marginBottom: 16 }}
            >
              <TagSomeoneAction
                setOpenTagSomeoneDialog={setOpenTagSomeoneDialog}
              />
              <RecommmendSPAction
                setOpenRecommendSPDialog={setOpenRecommendSPDialog}
              />
              <InactiveClientAction
                setOpenRejectionReasonDialog={setOpenRejectionReasonDialog}
                serviceRequestId={serviceRequestId}
              />
              <CompleteClientAction
                setOpenCompletionReasonDialog={setOpenCompletionReasonDialog}
                serviceRequestId={serviceRequestId}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              style={{ marginBottom: 16 }}
            >
              <SendInvoiceAction
                setOpenSendInvoiceDialog={setOpenSendInvoiceDialog}
                connectedAccountDetails={connectedAccountDetails}
              />
              <OtherAction
                setOpenOtherActionDialog={setOpenOtherActionDialog}
              />
              <Grid
                container
                item
                md={3}
                style={{ maxWidth: isTabletDevice() ? "23%" : "48%" }}
              />
              <Grid
                container
                item
                md={3}
                style={{ maxWidth: isTabletDevice() ? "23%" : "48%" }}
              />
            </Grid>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              style={{ marginBottom: 10 }}
            >
              <SendMessageAction
                navigateMessage={navigateMessage}
                primaryApplicant={primaryApplicant}
              />
              <SendDocumentAction
                onUploadEsignFileChange={onUploadEsignFileChange}
              />
              <RequestDocumentAction
                setOpenRequestDocumentDialog={setOpenRequestDocumentDialog}
                xlUp={xlUp}
              />
              <ScheduleEventAction history={history} />
              <TagSomeoneAction
                setOpenTagSomeoneDialog={setOpenTagSomeoneDialog}
              />
            </Grid>
            <Grid container item xs={12} justifyContent="space-between">
              <RecommmendSPAction
                setOpenRecommendSPDialog={setOpenRecommendSPDialog}
              />
              <InactiveClientAction
                setOpenRejectionReasonDialog={setOpenRejectionReasonDialog}
                serviceRequestId={serviceRequestId}
              />
              <CompleteClientAction
                setOpenCompletionReasonDialog={setOpenCompletionReasonDialog}
                serviceRequestId={serviceRequestId}
              />
              <SendInvoiceAction
                setOpenSendInvoiceDialog={setOpenSendInvoiceDialog}
                connectedAccountDetails={connectedAccountDetails}
              />
              <OtherAction
                setOpenOtherActionDialog={setOpenOtherActionDialog}
              />
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
}
