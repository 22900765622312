import {
  Divider,
  Grid,
  IconButton,
  Typography,
  Chip,
  Tooltip,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useService } from "souqh-react-redux-hooks/serviceProvider/storefront/useServices";
import { PaperLikeGrid } from "./ServiceList";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ServiceForm from "./ServiceForm";
import Styles from "../../../Styles.module.css";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { isEmpty } from "lodash";
import ConfirmationDialog from "../../ConfirmationDialog";
import { Draggable } from "react-beautiful-dnd";
import BlobManager from "../../../utils/BlobManager";
import { useDispatch } from "react-redux";
import { color_error } from "souqh-react-redux-hooks/toasterColors";

function ServiceListItem({
  index,
  setexistingUpdate,
  setShowNewForm,
  sourcePage,
  services,
}) {
  const {
    service,
    actions: { removeService },
    getFormattedFileName,
    deleteService,
  } = useService(index);
  const [edit, setedit] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setexistingUpdate(edit);
  }, [edit]);

  const image = service && service.imageUrl;
  useEffect(() => {
    if (image) {
      setImageUrl(BlobManager.getBlobURLByFileName(image));
    }
  }, [image]);
  if (edit) {
    return (
      <Grid container style={{ marginBottom: 5 }}>
        <ServiceForm
          operation="update"
          index={index}
          onUpdate={() => {
            setedit(false);
          }}
          edit={edit}
          setShowNewForm={setShowNewForm}
          sourcePage={sourcePage}
        ></ServiceForm>
      </Grid>
    );
  }

  return (
    <Draggable draggableId={service.timeStampId} index={index}>
      {(provided) => (
        <Grid
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Grid>
            <ConfirmationDialog
              open={openConfirmationDialog}
              handleYes={() => {
                if (service.id) {
                  deleteService(index, service.id);
                } else {
                  removeService({ index });
                }
                setOpenConfirmationDialog(false);
              }}
              handleNo={() => {
                setOpenConfirmationDialog(false);
              }}
              sectionTitle={
                "Are you sure you want to delete this Product/Service?"
              }
            />
            <Tooltip title={"Drag this service to reorder"}>
              <PaperLikeGrid
                style={{
                  padding: "16px 16px 34px 16px",
                  marginBottom: 5,
                  cursor: "pointer",
                }}
              >
                <Grid container item xs={12}>
                  <Grid
                    container
                    item
                    xs={2}
                    justifyContent="center"
                    alignContent="center"
                    style={{ marginTop: 8 }}
                  >
                    <img
                      height={100}
                      width={100}
                      src={
                        imageUrl || "/app/images/logo.svg" // if image not present show placeholder logo
                      }
                      style={{ borderRadius: 4, border: "1px solid #17174C1F" }}
                    ></img>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={8}
                    direction="column"
                    style={{ padding: "0px 20px" }}
                  >
                    <Typography
                      variant="subtitle1"
                      className={Styles.navyBlueColor}
                    >
                      {service.name}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={Styles.navyBlueColor}
                      style={{
                        whiteSpace: "pre-line",
                        wordBreak: "break-word",
                      }}
                    >
                      {service.description}
                    </Typography>

                    <Typography
                      variant="body1"
                      className={Styles.navyBlueColor}
                      style={{ marginTop: 10 }}
                    >
                      {service.label}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={Styles.navyBlueColor}
                    >
                      {service.link}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={2}
                    justifyContent="flex-end"
                    alignContent="center"
                  >
                    <IconButton
                      size="small"
                      onClick={() => {
                        setedit(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => {
                        if (services.length === 1) {
                          dispatch({
                            type: "SET_TOASTER",
                            payload: {
                              message: `Storefront must contain at least one Service`,
                              bgColor: color_error,
                              open: true,
                            },
                          });
                        } else {
                          setOpenConfirmationDialog(true);
                        }
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider
                  orientation="horizontal"
                  variant="fullWidth"
                  style={{ width: "100%", marginTop: 16 }}
                />
                <Grid item xs={12}>
                  {service.uploadDocUrl ? (
                    <Chip
                      label={getFormattedFileName(service.uploadDocUrl)}
                      variant="outlined"
                      style={{ marginTop: 16 }}
                      icon={<InsertDriveFileIcon />}
                    />
                  ) : null}
                </Grid>
              </PaperLikeGrid>
            </Tooltip>
          </Grid>
        </Grid>
      )}
    </Draggable>
  );
}

export default ServiceListItem;
