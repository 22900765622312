import {
  Container,
  Grid,
  Typography,
  makeStyles,
  Button,
  Paper,
  AppBar,
  Toolbar,
  Divider,
  TextField,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Styles from "../../../Styles.module.css";
import clsx from "clsx";
import { useNewHomeProfile } from "souqh-react-redux-hooks/homeBuyer/hbProfile/useNewHomeProfile";
import SetupFooter from "../../SPSetup/SetupFooter";
import CurrentAddress from "../../HBSetup/PersonalOverview/CurrentAddress";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  padding: {
    padding: 10,
  },
  marginTop: {
    marginTop: 20,
  },
  buttons: {
    padding: "5px 30px",
    textTransform: "capitalize",
  },
}));
const NewHomeProfile = () => {
  let history = useHistory();
  const classes = useStyles();
  const {
    provinces,
    newHomeProfile,
    allowSave,
    allowReset,
    propertyTypes,
    saveNewAddress,
    getNewProfileData,
    actions: {
      updateNewHomeProfile,
      saveNewHomeProfile,
      resetNewHomeProfile,
      clickEdit,
    },
  } = useNewHomeProfile();
  useEffect(() => {
    window.scroll(0, 0);
    getNewProfileData();
  }, []);
  return (
    <Grid container className={clsx(classes.root, classes.marginTop)}>
      <Container maxWidth="md" style={{ paddingBottom: 70 }}>
        <Grid
          container
          item
          xs={12}
          alignItems="baseline"
          style={{ marginBottom: 48, marginTop: 64 }}
        >
          <Typography item variant="h4" className={Styles.navyBlueColor}>
            New Home Profile
          </Typography>
          <Typography
            item
            variant="body1"
            className={Styles.navyBlueColor}
            style={{ marginLeft: 5 }}
          >
            (If applicable)
          </Typography>
        </Grid>
        <Grid>
          <Typography
            variant="subtitle1"
            style={{ marginBottom: 8 }}
            className={Styles.navyBlueColor}
          >
            Address
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} style={{ padding: "16px 0px" }}>
            <CurrentAddress
              newHomeAddress={true}
              updateAddress={updateNewHomeProfile}
              provinces={provinces}
              address={newHomeProfile}
              saveAddress={saveNewHomeProfile}
              clickEdit={clickEdit}
              allowSave={allowSave}
              allowReset={allowReset}
              resetAddress={resetNewHomeProfile}
              propertyTypes={propertyTypes}
            ></CurrentAddress>
          </Paper>
        </Grid>
      </Container>
      <SetupFooter isSaveAsDraft={false} page="personaloverview">
        <Button
          variant="outlined"
          className={Styles.navyBlueColorForce}
          style={{ textTransform: "capitalize", marginRight: 10 }}
          onClick={() => history.push("/app/usersetup/demographic")}
        >
          Skip This Step
        </Button>
        <Button
          disabled={!allowSave}
          variant="contained"
          onClick={() => {
            saveNewAddress((res) => {
              if (res && res.status === 200) {
                history.push("/app/usersetup/demographic");
              }
            });
          }}
          className={clsx(Styles.primaryBtnAccent, classes.buttons)}
        >
          Save and continue
        </Button>
      </SetupFooter>
    </Grid>
  );
};

export default NewHomeProfile;
