import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Styles from "../../../Styles.module.css";
import { useHistory } from "react-router-dom";
import { useSave } from "souqh-react-redux-hooks/serviceProvider/storefront/useSave";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";
import { useApiClient } from "souqh-react-redux-hooks/useApiClient";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 625,
    minWidth: 900,
  },
}));

function SkipSFCreationDialog({
  openSkipSFCreationDialog,
  setOpenSkipSFCreationDialog,
  storefrontName,
  companyOverview,
  services,
  competeText,
}) {
  const classes = useStyles();
  let history = useHistory();
  const userInfo = useAuthUser();
  const { apiClient } = useApiClient();

  const {
    actions: { save },
  } = useSave();

  return (
    <Dialog
      fullWidth
      open={openSkipSFCreationDialog}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle className={Styles.navyBlueColor}>
        <Typography variant="subtitle1">Skip Storefront Creation</Typography>
      </DialogTitle>
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        style={{ width: "100%" }}
      />
      <DialogContent>
        <Grid container className={Styles.navyBlueColor}>
          <Grid
            container
            item
            xs={6}
            alignContent="center"
            style={{ marginTop: 30 }}
          >
            <img src="/app/images/Skip Storefront creation.svg" />
          </Grid>
          <Grid
            container
            direction="column"
            item
            xs={5}
            alignContent="center"
            style={{ marginTop: 30 }}
          >
            <Grid item>
              <Typography variant="h3" style={{ marginBottom: 20 }}>
                Are you sure?
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ marginBottom: 20 }}>
                Most of our service providers have had a higher success rate
                when they completed their storefront. Your storefront is what
                you customers will land upon in Souqh.
              </Typography>
            </Grid>
            <Grid item>
              {storefrontName &&
              companyOverview &&
              competeText &&
              services.length ? (
                <Typography variant="subtitle1">
                  How would you like to proceed?
                </Typography>
              ) : (
                <Typography>
                  Please enter below details to proceed
                  <ul>
                    {!storefrontName && <li>Store Front name</li>}
                    {!competeText && (
                      <li>What separates you from the competition</li>
                    )}
                    {!companyOverview && <li>Company overview</li>}
                    {!services.length && <li>Create atleast one service</li>}
                  </ul>
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        style={{ width: "100%" }}
      />
      {storefrontName && companyOverview && competeText && services.length ? (
        <DialogActions style={{ paddingBottom: 16, paddingTop: 16 }}>
          <Button
            variant="outlined"
            className={Styles.primaryBtnAccent}
            style={{ textTransform: "capitalize", marginRight: 16 }}
            onClick={() => {
              save((res) => {
                if (res && res.data.status === 200) {
                  history.push("/app/setup/subscription");
                }
              }, apiClient);
            }}
          >
            Skip This Step
          </Button>
          <Button
            variant="outlined"
            className={Styles.navyBlueColorForce}
            style={{
              textTransform: "capitalize",
              paddingLeft: 20,
              paddingRight: 20,
              marginRight: 24,
            }}
            onClick={() => setOpenSkipSFCreationDialog(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button
            variant="outlined"
            className={Styles.navyBlueColorForce}
            style={{
              textTransform: "capitalize",
              paddingLeft: 20,
              paddingRight: 20,
              marginRight: 24,
            }}
            onClick={() => setOpenSkipSFCreationDialog(false)}
          >
            Ok
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default SkipSFCreationDialog;
