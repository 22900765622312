import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ButtonBase,
  Grid,
  AppBar,
  Avatar,
  Link as MaterialLink,
  Tooltip,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Styles from "../../Styles.module.css";
import ClientStatus from "../ServiceProvider/ClientManagement/ClientStatus";
import MessageIcon from "@material-ui/icons/Message";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { useHistory, Link } from "react-router-dom";
import MoreOptionsOnSPInfo from "../HomeBuyer/SPManagement/MoreOptionsOnSPInfo";
import { useNavigateToMessage } from "souqh-react-redux-hooks/common/useNavigateToMessage";
import { useRequestSPInfo } from "souqh-react-redux-hooks/homeBuyer/spManagement/useRequestSPInfo";
import * as moment from "moment";
import Attachments from "./SPManagement/Attachments";
import DocumentPreviewDialog from "./Explore/StorefrontPage/DocumentPreviewDialog";
import {
  getUploadedDocuments,
  isMobileDevice,
  isTabletDevice,
} from "../../utils/AppUtils";
import clsx from "clsx";

export default function ServiceRequestsWidget({ xlUp }) {
  let history = useHistory();
  const { navigateMessage } = useNavigateToMessage();

  const [openDocumetDialog, setOpenDocumetDialog] = useState(false);
  const [documentItem, setDocumentItem] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [docCategoryId, setDocCategoryId] = useState(null);
  const [docNameForDownload, setDocNameForDownload] = useState(null);
  const [docUserId, setDocUserId] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const { getRequestsSPData, requests } = useRequestSPInfo(5);

  useEffect(() => {
    getRequestsSPData();
  }, []);

  const timelineOptions = [
    { name: "Urgent", value: "URGENT" },
    { name: "As Soon As Possible", value: "AS_SOON_AS_POSSIBLE" },
    { name: "1-2 weeks", value: "ONE_TO_TWO_WEEKS" },
    { name: "2-4 weeks", value: "TWO_TO_FOUR_WEEKS" },
    { name: "4-8 weeks", value: "FOUR_TO_EIGHT_WEEKS" },
    { name: "8 weeks plus", value: "EIGHT_WEEKS_PLUS" },
  ];

  return (
    <React.Fragment>
      <DocumentPreviewDialog
        openDocumetDialog={openDocumetDialog}
        setOpenDocumetDialog={setOpenDocumetDialog}
        documentItem={documentItem}
        setDocumentItem={setDocumentItem}
        downloadUrl={downloadUrl}
        source="sp-mgmt"
        docCategoryId={docCategoryId}
        docNameForDownload={docNameForDownload}
        docUserId={docUserId}
      />
      <MoreOptionsOnSPInfo anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      {requests && requests.response && requests.response.length ? (
        <TableContainer
          component={Paper}
          elevation={0}
          className="hideScroll"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Table
            aria-label="collapsible table"
            className="hideScroll"
            stickyHeader
            style={{
              paddingBottom: 122,
              overflowX: "auto",
              display: "inline-block",
              scrollbarWidth: "none",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    width: "25%",
                    fontWeight: 600,
                    fontSize: xlUp ? 16 : 12,
                    backgroundColor: "#FFFFFF",
                    lineHeight: "0.5rem",
                    minWidth: isMobileDevice()
                      ? "15em"
                      : isTabletDevice() && "13em",
                  }}
                  className={Styles.navyBlueColorForce}
                >
                  Service Provider
                </TableCell>
                {/* <TableCell
                  style={{
                    width: "22%",
                    fontWeight: 600,
                    fontSize: xlUp ? 16 : 12,
                    backgroundColor: "#FFFFFF",
                    lineHeight: "0.5rem",
                    minWidth: (isMobileDevice() || isTabletDevice()) && "13em",
                  }}
                  className={Styles.navyBlueColorForce}
                >
                  Status
                </TableCell> */}
                <TableCell
                  style={{
                    width: "16%",
                    fontWeight: 600,
                    fontSize: xlUp ? 16 : 12,
                    backgroundColor: "#FFFFFF",
                    lineHeight: "0.5rem",
                    minWidth: (isMobileDevice() || isTabletDevice()) && "7em",
                  }}
                  className={Styles.navyBlueColorForce}
                >
                  Request Date
                </TableCell>
                <TableCell
                  style={{
                    width: "44%",
                    fontWeight: 600,
                    fontSize: xlUp ? 16 : 12,
                    backgroundColor: "#FFFFFF",
                    lineHeight: "0.5rem",
                  }}
                  className={Styles.navyBlueColorForce}
                >
                  Service Notes
                </TableCell>
                <TableCell
                  style={{
                    width: "18%",
                    fontWeight: 600,
                    fontSize: xlUp ? 16 : 12,
                    backgroundColor: "#FFFFFF",
                    lineHeight: "0.5rem",
                    minWidth: (isMobileDevice() || isTabletDevice()) && "7em",
                  }}
                  className={Styles.navyBlueColorForce}
                >
                  Quick Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {requests.response.map((data, index) => {
                const storeFrontContent = JSON.parse(
                  data.storefrontDetails.storeFrontContent
                );
                let formattedTimeline =
                  data.broadCastServiceRequestDto &&
                  timelineOptions.filter(
                    (item) =>
                      item.value === data.broadCastServiceRequestDto.timeLine
                  )[0];
                return (
                  <TableRow key={index}>
                    <TableCell container>
                      {data.broadCastServiceRequestDto ? (
                        <Grid container>
                          <Grid item xs={12}>
                            <Tooltip
                              title={
                                data.broadCastServiceRequestDto?.businessType
                              }
                            >
                              <Typography
                                variant="body1"
                                className={Styles.navyBlueColor}
                                style={{
                                  fontWeight: 600,
                                  fontSize: isMobileDevice()
                                    ? 12
                                    : xlUp
                                    ? 16
                                    : 11,
                                }}
                              >
                                {data.broadCastServiceRequestDto?.businessType}
                              </Typography>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={12}>
                            <Tooltip
                              title={data.broadCastServiceRequestDto?.city}
                            >
                              <Typography
                                className={clsx(
                                  Styles.navyBlueColor,
                                  Styles.sqEllipsisOneLine
                                )}
                                style={{
                                  fontSize: isMobileDevice()
                                    ? 12
                                    : xlUp
                                    ? 16
                                    : 11,
                                }}
                                variant="body1"
                              >
                                {data.broadCastServiceRequestDto?.city}
                              </Typography>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={12}>
                            <Tooltip title={formattedTimeline?.name}>
                              <Typography
                                className={clsx(
                                  Styles.navyBlueColor,
                                  Styles.sqEllipsisOneLine
                                )}
                                style={{
                                  fontSize: isMobileDevice()
                                    ? 12
                                    : xlUp
                                    ? 16
                                    : 11,
                                }}
                                variant="body1"
                              >
                                {formattedTimeline?.name}
                              </Typography>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container>
                          <Grid item xs={3} md={2}>
                            <Link
                              key={index}
                              to={{
                                pathname:
                                  window.location.origin +
                                  `/app/storefront/${data.storefrontDetails.storeFrontRouteName}`,
                              }}
                              target="_blank"
                            >
                              <Avatar
                                style={{ height: 30, width: 30 }}
                                src={storeFrontContent.displayPicture}
                              >
                                {(
                                  data.storefrontDetails.storeFrontName || ""
                                ).charAt(0)}
                              </Avatar>
                            </Link>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={9}
                            md={10}
                            alignContent="center"
                            style={{ paddingLeft: 10 }}
                          >
                            <Grid item xs={12}>
                              <Typography
                                className={Styles.navyBlueColor}
                                style={{
                                  fontWeight: 600,
                                  fontSize: isMobileDevice()
                                    ? 12
                                    : xlUp
                                    ? 16
                                    : 11,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  history.push(
                                    "/app/homebuyer/serviceproviders?tabIndex=1"
                                  );
                                }}
                              >
                                {data.storefrontDetails.storeFrontName}
                              </Typography>
                            </Grid>
                            {/* <Grid item xs={12}>
                            <Typography
                              style={{ fontSize: xlUp ? 14 : 8 }}
                              className={Styles.navyBlueColor}
                            >
                              {data.email}
                            </Typography>
                          </Grid> */}
                          </Grid>
                        </Grid>
                      )}
                    </TableCell>
                    {/* <TableCell style={{ textTransform: "uppercase" }}>
                      <ClientStatus
                        clientStatus={data.hbJourneyStatus}
                        isDashboardWidget={true}
                      />
                    </TableCell> */}
                    <TableCell className={Styles.navyBlueColor}>
                      <Typography
                        className={Styles.navyBlueColor}
                        style={{
                          fontSize: isMobileDevice() ? 12 : xlUp ? 16 : 11,
                        }}
                      >
                        {data.requestedDate &&
                          moment(data.requestedDate).format("MMM DD, YYYY")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            className={Styles.navyBlueColor}
                            style={{
                              fontSize: isMobileDevice() ? 12 : xlUp ? 14 : 11,
                            }}
                          >
                            {data.notes || "No details available"}
                          </Typography>
                        </Grid>
                        <Grid container item xs={12}>
                          {/* <Grid item xs={3}>
                            <AttachmentIcon
                              className={Styles.navyBlueColorForce}
                            />
                          </Grid> */}
                          <Grid container item xs={12} alignContent="center">
                            {data.attachments && data.attachments.length ? (
                              <Attachments
                                attachments={data.attachments}
                                limit={1}
                                setDocumentItem={setDocumentItem}
                                getUploadedDocuments={getUploadedDocuments}
                                setOpenDocumetDialog={setOpenDocumetDialog}
                                isDashboardWidget={true}
                                setDownloadUrl={setDownloadUrl}
                                setDocCategoryId={setDocCategoryId}
                                setDocNameForDownload={setDocNameForDownload}
                                setDocUserId={setDocUserId}
                              />
                            ) : (
                              <Typography
                                className={Styles.navyBlueColor}
                                style={{
                                  fontWeight: 600,
                                  fontSize: xlUp ? 14 : 11,
                                }}
                              >
                                No Attachments
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid container justifyContent="space-between">
                        <Grid item xs={3}>
                          <ButtonBase
                            style={{
                              pointerEvents:
                                data.broadCastServiceRequestDto && "none",
                            }}
                          >
                            <MessageIcon
                              style={{
                                fontSize: 12,
                                color: data.broadCastServiceRequestDto
                                  ? "#17174c1f"
                                  : "#0e1c4e",
                              }}
                              // className={Styles.navyBlueColorForce}
                              onClick={(event) => {
                                if (!data.broadCastServiceRequestDto) {
                                  navigateMessage(
                                    event,
                                    data.storefrontDetails.email
                                  );
                                }
                              }}
                            />
                          </ButtonBase>
                        </Grid>
                        <Grid item xs={3}>
                          <ButtonBase
                            style={{
                              pointerEvents:
                                data.broadCastServiceRequestDto && "none",
                            }}
                          >
                            <DateRangeIcon
                              style={{
                                fontSize: 12,
                                color: data.broadCastServiceRequestDto
                                  ? "#17174c1f"
                                  : "#0e1c4e",
                              }}
                              // className={Styles.navyBlueColorForce}
                              onClick={() => {
                                if (!data.broadCastServiceRequestDto) {
                                  history.push(
                                    "/app/homebuyer/calendar?scheduleMeeting=true"
                                  );
                                }
                              }}
                            />
                          </ButtonBase>
                        </Grid>
                        <Grid item xs={3}>
                          <ButtonBase
                            style={{
                              pointerEvents:
                                data.broadCastServiceRequestDto && "none",
                            }}
                          >
                            <MoreHorizIcon
                              // className={Styles.navyBlueColorForce}
                              style={{
                                color: data.broadCastServiceRequestDto
                                  ? "#17174c1f"
                                  : "#0e1c4e",
                              }}
                              onClick={handleClick}
                            />
                          </ButtonBase>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <div
            style={{
              position: "sticky",
              bottom: 0,
              width: "100%",
              height: 48,
              display: "inline-block",
            }}
          >
            <AppBar
              position="sticky"
              style={{
                top: "auto",
                bottom: 0,
                backgroundColor: "#FFFFFF",
                color: "#FA7E61",
                height: 48,
              }}
            >
              <Grid
                container
                item
                justifyContent="flex-end"
                alignContent="center"
                style={{ paddingRight: "3%", height: "100%" }}
              >
                <ButtonBase
                  onClick={() => {
                    history.push("/app/homebuyer/serviceproviders?tabIndex=1");
                  }}
                >
                  <Typography style={{ fontSize: xlUp ? 14 : 12 }}>
                    View All
                  </Typography>
                </ButtonBase>
              </Grid>
            </AppBar>
          </div>
        </TableContainer>
      ) : (
        <Grid container justifyContent="center" alignItems="center">
          {!isMobileDevice() && (
            <Grid
              item
              xs={12}
              md={7}
              style={{ textAlign: "center", paddingRight: 32 }}
            >
              <Typography style={{ color: "#868DA6", fontSize: 11 }}>
                Looks like you do not have any active service requests. Click{" "}
                <MaterialLink
                  style={{ cursor: "pointer" }}
                  href="/app/homebuyer/explore"
                >
                  here
                </MaterialLink>{" "}
                to explore storefronts to request services
              </Typography>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={3}
            style={{ textAlign: isMobileDevice() ? "center" : "right" }}
          >
            <img
              src="/app/images/no-result-found-orange.svg"
              alt="no results found"
              title="no results found"
              style={{
                height: !isMobileDevice() && "100%",
                width: !isMobileDevice() && "100%",
              }}
            />
          </Grid>
          {isMobileDevice() && (
            <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
              <Typography
                style={{
                  color: "#868DA6",
                  fontSize: 11,
                  padding: "0px 24px",
                  marginTop: -60,
                }}
              >
                Looks like you do not have any active service requests. Click{" "}
                <MaterialLink
                  style={{ cursor: "pointer" }}
                  href="/app/homebuyer/explore"
                >
                  here
                </MaterialLink>{" "}
                to explore storefronts to request services
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </React.Fragment>
  );
}
