import React, { useEffect } from "react";
import {
  Button,
  ButtonBase,
  Grid,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import clsx from "clsx";
import Styles from "../../../Styles.module.css";
import { useHistory } from "react-router-dom";
import { useDocuments } from "souqh-react-redux-hooks/serviceProvider/documents/useDocuments";
import {
  ACTION_REQUIRED,
  EXPIRING_SOON,
  WAITING_FOR_OTHER,
} from "../serviceProviderConstants";
import { useDropzone } from "react-dropzone";
import {
  isMarketplacePlan,
  isMobileDevice,
  isOnboardingInComplete,
} from "../../../utils/AppUtils";
import { useAuthUser } from "souqh-react-redux-hooks/useLogin";

const useStyles = makeStyles((theme) => ({
  innerGrids: {
    textAlign: "center",
  },
  uploadBtn: {
    borderRadius: 6,
    textTransform: "none !important",
  },
}));

export default function DocumentUpload({
  processEsignUploadedFiles,
  onUploadEsignFileChange,
  onUploadTemplateFileChange,
  openPlanUpgradeMsgDialog,
  setOpenPlanUpgradeMsgDialog,
}) {
  const classes = useStyles();
  let history = useHistory();

  const theme = useTheme();
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  const {
    actionRequiredCount,
    waitingForOtherCount,
    expiringSoonCount,
    getTabWiseCategoriesList,
    actions: { setDocumentTab },
  } = useDocuments();
  const userInfo = useAuthUser();

  useEffect(() => {
    getTabWiseCategoriesList(ACTION_REQUIRED);
  }, []);

  const onDrop = (acceptedFiles) => {
    processEsignUploadedFiles(acceptedFiles, null);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Grid container style={{ padding: "8px 8px 32px 8px" }}>
      <div
        style={{ height: "100%" }}
        {...getRootProps()}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <input {...getInputProps()} />
        <Grid
          container
          item
          xs={12}
          alignContent="center"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid
            item
            xs={4}
            className={clsx(classes.innerGrids, Styles.navyBlueColor)}
            onClick={() => {
              if (!isOnboardingInComplete(userInfo)) {
                if (isMarketplacePlan(userInfo)) {
                  setOpenPlanUpgradeMsgDialog(true);
                } else {
                  history.push(
                    "/app/serviceprovider/documents?tabName=" + ACTION_REQUIRED
                  );
                }
              }
            }}
            style={{ paddingRight: 5, cursor: "pointer" }}
          >
            <Paper
              elevation={0}
              style={{
                border: "1px solid #0E1C4E1F",
                height: "100%",
                padding: 5,
                minHeight: 60,
              }}
            >
              <Typography
                style={{ fontSize: xlUp ? 24 : 18, fontWeight: 700 }}
                className={Styles.navyBlueColor}
              >
                {actionRequiredCount}
              </Typography>
              <Typography
                style={{ fontSize: isMobileDevice() || xlUp ? 14 : 11 }}
                className={Styles.navyBlueColor}
              >
                Action Required
              </Typography>
            </Paper>
          </Grid>
          <Grid
            item
            xs={4}
            className={clsx(classes.innerGrids, Styles.navyBlueColor)}
            onClick={() => {
              if (!isOnboardingInComplete(userInfo)) {
                if (isMarketplacePlan(userInfo)) {
                  setOpenPlanUpgradeMsgDialog(true);
                } else {
                  history.push(
                    "/app/serviceprovider/documents?tabName=" +
                      WAITING_FOR_OTHER
                  );
                }
              }
            }}
            style={{ paddingRight: 2, cursor: "pointer" }}
          >
            <Paper
              elevation={0}
              style={{
                border: "1px solid #0E1C4E1F",
                height: "100%",
                padding: 5,
                minHeight: 60,
              }}
            >
              <Typography
                style={{ fontSize: xlUp ? 24 : 18, fontWeight: 700 }}
                className={Styles.navyBlueColor}
              >
                {waitingForOtherCount}
              </Typography>
              <Typography
                style={{ fontSize: isMobileDevice() || xlUp ? 14 : 11 }}
                className={Styles.navyBlueColor}
              >
                Waiting for Others
              </Typography>
            </Paper>
          </Grid>
          <Grid
            item
            xs={4}
            className={clsx(classes.innerGrids, Styles.navyBlueColor)}
            onClick={() => {
              if (!isOnboardingInComplete(userInfo)) {
                if (isMarketplacePlan(userInfo)) {
                  setOpenPlanUpgradeMsgDialog(true);
                } else {
                  history.push(
                    "/app/serviceprovider/documents?tabName=" + EXPIRING_SOON
                  );
                }
              }
            }}
            style={{ paddingLeft: 3, cursor: "pointer" }}
          >
            <Paper
              elevation={0}
              style={{
                border: "1px solid #0E1C4E1F",
                height: "100%",
                padding: 5,
                minHeight: 60,
              }}
            >
              <Typography
                style={{ fontSize: xlUp ? 24 : 18, fontWeight: 700 }}
                className={Styles.navyBlueColor}
              >
                {expiringSoonCount}
              </Typography>
              <Typography
                style={{ fontSize: isMobileDevice() || xlUp ? 14 : 11 }}
                className={Styles.navyBlueColor}
              >
                Expiring
              </Typography>
              <Typography
                style={{ fontSize: isMobileDevice() || xlUp ? 14 : 11 }}
                className={Styles.navyBlueColor}
              >
                Soon
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        {!isOnboardingInComplete(userInfo) ? (
          <Grid
            item
            container
            justifyContent="center"
            alignContent="flex-start"
            xs={12}
            style={{
              marginBottom: 10,
              marginTop: 30,
            }}
          >
            <div
              style={{
                height: "100%",
                border: "1px dashed #0E1C4E1F",
                padding: "20px 0px",
                textAlign: "center",
              }}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <input {...getInputProps()} />
              <Typography
                align="center"
                style={{ color: "#868DA6", fontSize: 11, marginBottom: 16 }}
              >
                Drop documents to get started
              </Typography>
              <input
                style={{ display: "none" }}
                id="contained-button-file"
                multiple
                type="file"
                onChange={onUploadEsignFileChange}
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="outlined"
                  className={Styles.navyBlueColorForce}
                  component="span"
                  style={{
                    paddingLeft: 20,
                    paddingRight: 20,
                    width: 220,
                    textTransform: "none",
                  }}
                  onClick={() => {
                    if (isMarketplacePlan(userInfo)) {
                      document.getElementById(
                        "contained-button-file"
                      ).disabled = true;
                      setOpenPlanUpgradeMsgDialog(true);
                    }
                  }}
                >
                  Send eSign Documents
                </Button>
              </label>

              <input
                style={{ display: "none" }}
                id="contained-button-file-temp"
                multiple
                type="file"
                onChange={onUploadTemplateFileChange}
              />
              <label htmlFor="contained-button-file-temp">
                <Button
                  variant="outlined"
                  className={Styles.navyBlueColorForce}
                  component="span"
                  style={{
                    textTransform: "capitalize",
                    width: 220,
                    marginTop: 20,
                  }}
                  onClick={() => {
                    if (isMarketplacePlan(userInfo)) {
                      document.getElementById(
                        "contained-button-file-temp"
                      ).disabled = true;
                      setOpenPlanUpgradeMsgDialog(true);
                    }
                  }}
                >
                  Add Template
                </Button>
              </label>
            </div>
          </Grid>
        ) : null}
      </div>
    </Grid>
  );
}
