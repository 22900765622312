import { Grid, Popover, Typography } from "@material-ui/core";
import React, { useState } from "react";
import Styles from "../../../Styles.module.css";
import { useHistory, Link } from "react-router-dom";
import AddReviewDialog from "./AddReviewDialog";
import MoveSPToArchiveDialog from "./MoveSPToArchiveDialog";
import MoveSPToCompletedDialog from "./MoveSPToCompleted";
import { useNavigateToMessage } from "souqh-react-redux-hooks/common/useNavigateToMessage";
import RejectionReasonDialog from "../../ServiceProvider/ClientManagement/RejectionReasonDialog";
import { useSPUploadWizard } from "souqh-react-redux-hooks/serviceProvider/documents/useSPUploadWizard";
import SPUploadWizard from "../../common/UploadWizard/SPUploadWizard";
import SendSerivceRequestDialog from "../Explore/StorefrontPage/SendSerivceRequestDialog";

export default function MoreOptionsOnSPInfo({
  anchorEl,
  setAnchorEl,
  userId,
  serviceRequestId,
  storefrontId,
  displayPicture,
  storefrontName,
  serviceName,
  showAddReviewOption,
  email,
  contactNumber,
  showRequestServiceOption,
  showMoveToCompletedOption,
  showRemoveAndArchiveOption,
  showRecommendToOthersOption,
  storeFrontRouteName,
  showSendMessageOption,
  getSpManagementData,
  storefrontDetails,
  sfServiceId,
  actionType,
}) {
  const history = useHistory();
  const { navigateMessage } = useNavigateToMessage();
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [openAddReviewDialog, setOpenAddReviewDialog] = useState({
    open: false,
    data: {},
  });
  const [openMoveSPToArchiveDialog, setOpenMoveSPToArchiveDialog] =
    useState(false);
  const [openMoveSPToCompletedDialog, setOpenMoveSPToCompletedDialog] =
    useState(false);
  const [openRejectionReasonDialog, setOpenRejectionReasonDialog] =
    useState(false);

  const {
    onUploadFileChange,
    openUploadWizard,
    actions: { setOpenUploadWizard },
  } = useSPUploadWizard();

  const [openserviceRequestDialog, setOpenserviceRequestDialog] = useState({
    open: false,
    service: null,
  });

  return (
    <React.Fragment>
      <SendSerivceRequestDialog
        openserviceRequestDialog={openserviceRequestDialog}
        setOpenserviceRequestDialog={setOpenserviceRequestDialog}
        storeFrontDetails={storefrontDetails}
        serviceProviderId={
          storefrontDetails && storefrontDetails.serviceProviderId
        }
        businessName={storefrontName}
        email={email}
        serviceName={serviceName}
        srcPage="SPManagement"
        sfServiceId={sfServiceId}
      />
      <SPUploadWizard
        openUploadWizard={openUploadWizard}
        setOpenUploadWizard={setOpenUploadWizard}
      />
      <MoveSPToCompletedDialog
        openMoveSPToCompletedDialog={openMoveSPToCompletedDialog}
        setOpenMoveSPToCompletedDialog={setOpenMoveSPToCompletedDialog}
        userId={userId}
        serviceRequestId={serviceRequestId}
        storefrontId={storefrontId}
        displayPicture={displayPicture}
        storefrontName={storefrontName}
        serviceName={serviceName}
        email={email}
        contactNumber={contactNumber}
        getSpManagementData={getSpManagementData}
      />
      <MoveSPToArchiveDialog
        openMoveSPToArchiveDialog={openMoveSPToArchiveDialog}
        setOpenMoveSPToArchiveDialog={setOpenMoveSPToArchiveDialog}
        serviceRequestId={serviceRequestId}
        storefrontName={storefrontName}
      />
      <AddReviewDialog
        openAddReviewDialog={openAddReviewDialog}
        setOpenAddReviewDialog={setOpenAddReviewDialog}
      />
      <RejectionReasonDialog
        openRejectionReasonDialog={openRejectionReasonDialog}
        setOpenRejectionReasonDialog={setOpenRejectionReasonDialog}
        getSpManagementData={getSpManagementData}
        title={"Remove and Archive"}
        message={`Request archived ${storefrontName}: `}
        actionType={actionType}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Grid style={{ padding: 16 }}>
          {showRequestServiceOption && (
            <Typography
              style={{ cursor: "pointer" }}
              className={Styles.navyBlueColor}
              variant="body1"
              onClick={() => {
                setOpenserviceRequestDialog({
                  open: true,
                  service: null,
                });
                handleClose();
              }}
            >
              Request Service
            </Typography>
          )}
          {showSendMessageOption && (
            <Typography
              style={{
                marginTop: showRequestServiceOption && 16,
                cursor: "pointer",
              }}
              className={Styles.navyBlueColor}
              variant="body1"
              onClick={(event) => {
                navigateMessage(event, email);
              }}
            >
              Send Message
            </Typography>
          )}
          <input
            style={{ display: "none" }}
            id="contained-button-file"
            multiple
            type="file"
            onChange={(event) => {
              onUploadFileChange(event);
              handleClose();
            }}
          />
          <label htmlFor="contained-button-file">
            <Typography
              style={{
                marginTop:
                  (showSendMessageOption || showRequestServiceOption) && 16,
                cursor: "pointer",
              }}
              className={Styles.navyBlueColor}
              variant="body1"
            >
              Send Document
            </Typography>
          </label>
          <Typography
            style={{ marginTop: 16, cursor: "pointer" }}
            className={Styles.navyBlueColor}
            variant="body1"
            onClick={() => {
              history.push("/app/homebuyer/calendar?scheduleMeeting=true");
            }}
          >
            Schedule Meeting
          </Typography>
          {showAddReviewOption && (
            <Typography
              style={{ marginTop: 16, cursor: "pointer" }}
              className={Styles.navyBlueColor}
              variant="body1"
              onClick={() => {
                setOpenAddReviewDialog({
                  open: true,
                  data: {
                    userId,
                    serviceRequestId,
                    storefrontId,
                    displayPicture,
                    storefrontName,
                    serviceName,
                    email,
                    contactNumber,
                  },
                });
                handleClose();
              }}
            >
              Add Review
            </Typography>
          )}
          {showMoveToCompletedOption && (
            <Typography
              style={{ marginTop: 16, cursor: "pointer" }}
              className={Styles.navyBlueColor}
              variant="body1"
              onClick={() => {
                setOpenMoveSPToCompletedDialog(true);
                handleClose();
              }}
            >
              Move To Completed
            </Typography>
          )}
          {showRemoveAndArchiveOption && (
            <Typography
              style={{ marginTop: 16, cursor: "pointer" }}
              className={Styles.navyBlueColor}
              variant="body1"
              onClick={() => {
                setOpenRejectionReasonDialog({
                  open: true,
                  id: serviceRequestId,
                });
                handleClose();
              }}
            >
              Remove and Archive
            </Typography>
          )}
          {showRecommendToOthersOption && (
            <Typography
              style={{ marginTop: 16, cursor: "pointer" }}
              className={Styles.navyBlueColor}
              variant="body1"
            >
              Recommend to Others
            </Typography>
          )}
        </Grid>
      </Popover>
    </React.Fragment>
  );
}
